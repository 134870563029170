<script setup lang="ts">
import GetInTouchModuleService from '@/Modules/GetInTouch/Services/GetInTouchModuleService';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppWhiteboard from '@/Components/Containers/Whiteboard/Whiteboard.vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import { computed, onMounted, Ref, ref } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import FormField from '@/Assets/Libraries/Form/FormField';
import Form from '@/Assets/Libraries/Form/Form';
import AppInputIdentityNumber from '@/Components/Inputs/InputIdentityNumber/InputIdentityNumber.vue';
import AppInputEmail from '@/Components/Inputs/InputEmail/InputEmail.vue';
import AppPhoneWithCountry from '@/Components/Inputs/PhoneWithCountry/PhoneWithCountry.vue';
import AppAddressFinder from '@/Components/Inputs/AddressFinder/AddressFinder.vue';
import AppInputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import AppButtonPrimaryAlternative from '@/Components/Buttons/ButtonPrimaryAlternative/ButtonPrimaryAlternative.vue';
import AppSvg from '@/Components/Other/Svg/Svg.vue';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { useDefine } from '@/Composables/Define';
import TransferStateService from '@/Core/ServerState/TransferStateService';

const { translateForType, applyTranslationType, type, translate } = useTranslate();

const getInTouchModuleService: GetInTouchModuleService = GetInTouchModuleService.getInstance();

const translationType: string = 'components';

const title: Ref<string> = ref('');
const description: Ref<string> = ref('');

const agreeText: Ref<string> = computed(() => {
    const field: DynamicDictionary = getInTouchModuleService.getInTouchModuleLeadsCollection.value
        ? getInTouchModuleService.getInTouchModuleLeadsCollection.value.cmsFieldValue('checkboxAgree')
        : '';

    return translated(useDefine().isSet(field.label) ? field.label : 'leads_collection_agree');
});

const marketingText: Ref<string> = computed(() => {
    const field: DynamicDictionary = getInTouchModuleService.getInTouchModuleLeadsCollection.value
        ? getInTouchModuleService.getInTouchModuleLeadsCollection.value.cmsFieldValue('receiveMarketing')
        : '';

    return translated(useDefine().isSet(field.label) ? field.label : 'leads_collection_marketing');
});

function translated(stringId: string): string {
    return translateForType(stringId, translationType);
}

function formExists(name: string): boolean {
    return getInTouchModuleService.getInTouchModuleLeadsCollection.value
        ? getInTouchModuleService.getInTouchModuleLeadsCollection.value.getInTouchForm.exists(name)
        : false;
}

function formField(name: string): FormField {
    return getInTouchModuleService!.getInTouchModuleLeadsCollection.value!.getInTouchForm.field(name);
}

function isRequired(name: string): boolean {
    return getInTouchModuleService.getInTouchModuleLeadsCollection.value
        ? getInTouchModuleService.getInTouchModuleLeadsCollection.value.cmsFieldIsRequired(name)
        : false;
}

function getInTouchForm(): Form {
    return getInTouchModuleService!.getInTouchModuleLeadsCollection.value!.getInTouchForm;
}

function onSubmitStep(): void {
    if (getInTouchModuleService.getInTouchModuleLeadsCollection.value) {
        getInTouchModuleService.getInTouchModuleLeadsCollection.value.prepareAndSubmit();
    }
}

onMounted(() => {
    const transfer: TransferStateService = TransferStateService.getInstance();
    title.value = transfer.get('title') || '';
    description.value = transfer.get('description') || '';
});
</script>

<template>
    <div class="leads-collection">
        <app-whiteboard
            :data-type="'whiteboard-leads'"
            :title="translated(title)"
            :title-size="'huge'"
            :sub-title="translated(description)"
            :sub-title-size="'small'"
        >
            <app-custom-form v-if="getInTouchForm().isReady()" :form="getInTouchForm()">
                <div class="inputs row">
                    <div class="input-block">
                        <h4 class="title block-name block-name-tall">{{ translated('contact_details') }}</h4>
                        <div class="elements-block grid-utility grid-columns-1 sm:grid-columns-12">
                            <app-input-text
                                v-if="formExists('name')"
                                class="column-span-12 sm:column-span-4"
                                :label="translated('name')"
                                :form-field="formField('name')"
                                :placeholder="translated('name_placeholder')"
                                :autocomplete="'given-name'"
                                :required="isRequired('name')"
                                :data-store-disabled="true"
                            >
                            </app-input-text>
                            <app-input-text
                                v-if="formExists('surname')"
                                class="column-span-12 sm:column-span-4"
                                :label="translated('surname')"
                                :form-field="formField('surname')"
                                :placeholder="translated('surname_placeholder')"
                                :autocomplete="'family-name'"
                                :required="isRequired('surname')"
                                :data-store-disabled="true"
                            >
                            </app-input-text>
                            <app-input-identity-number
                                v-if="formExists('personCode')"
                                class="column-span-12 sm:column-span-4"
                                :form-field="formField('personCode')"
                                :label="translated('person_code')"
                                :placeholder="translated('person_code_placeholder')"
                                :required="isRequired('personCode')"
                                :data-store-disabled="true"
                            >
                            </app-input-identity-number>
                        </div>
                        <div class="elements-block grid-utility grid-columns-1 sm:grid-columns-12">
                            <app-address-finder
                                v-if="formExists('address')"
                                class="column-span-12 sm:column-span-12"
                                :label="translated('address')"
                                :address-label="translated('address_placeholder')"
                                :mode="'mixed'"
                                :required="isRequired('address')"
                                :skip-apartment-validation="true"
                                :form-field="formField('address')"
                            >
                            </app-address-finder>
                        </div>
                        <div class="elements-block grid-utility grid-columns-1 sm:grid-columns-12">
                            <app-input-email
                                v-if="formExists('email')"
                                class="column-span-12 sm:column-span-6"
                                :label="translated('email')"
                                :placeholder="translated('email_placeholder')"
                                :form-field="formField('email')"
                                :required="isRequired('email')"
                                :data-store-disabled="true"
                            >
                            </app-input-email>
                            <app-phone-with-country
                                v-if="formExists('phone')"
                                class="column-span-12 sm:column-span-6"
                                :label="translated('phone')"
                                :placeholder="translated('phone_placeholder')"
                                :required="isRequired('phone')"
                                :disable-error-text="true"
                                :mobile-mode-enabled="true"
                                :form-field="formField('phone')"
                                :data-store-disabled="true"
                            >
                            </app-phone-with-country>
                        </div>
                        <h4 class="title block-name block-name-tall">{{ translated('device_details') }}</h4>
                        <div class="elements-block grid-utility grid-columns-1 sm:grid-columns-12">
                            <app-input-text
                                v-if="formExists('imei')"
                                class="imei column-span-6 sm:column-span-6"
                                :label="translated('imei')"
                                :form-field="formField('imei')"
                                :placeholder="translated('imei_placeholder')"
                                :required="isRequired('imei')"
                                :data-store-disabled="true"
                                :description="translated('imei_description')"
                            >
                            </app-input-text>
                        </div>
                        <div class="elements-block elements-block-last grid-utility grid-columns-1 sm:grid-columns-12">
                            <app-input-checkbox
                                v-if="formExists('receiveMarketing')"
                                class="marketing base column-span-2 sm:column-span-12"
                                :data-store-disabled="true"
                                :required="isRequired('receiveMarketing')"
                                :form-field="formField('receiveMarketing')"
                                data-type="checkboxMarketing"
                                ><span class="basic-text-formatted" v-html="marketingText"></span>
                            </app-input-checkbox>
                            <app-input-checkbox
                                v-if="formExists('checkboxAgree')"
                                class="agree base column-span-2 sm:column-span-12"
                                :data-store-disabled="true"
                                :required="isRequired('checkboxAgree')"
                                :form-field="formField('checkboxAgree')"
                                data-type="checkboxCertify"
                                ><span class="basic-text-formatted" v-html="agreeText"></span>
                            </app-input-checkbox>
                            <app-button-primary-alternative
                                class="submit-button base column-span-2 sm:column-span-12"
                                @click="onSubmitStep()"
                            >
                                {{ translated('submit_form') }}
                                <template #end>
                                    <app-svg src="images/one/arrow-right.svg" />
                                </template>
                            </app-button-primary-alternative>
                        </div>
                    </div>
                </div>
            </app-custom-form>
        </app-whiteboard>
    </div>
</template>

<style scoped lang="scss">
.leads-collection {
    width: 100%;
    max-width: 1368px !important;
    padding: 0 var(--size-normal);
    margin: var(--size-small) auto;

    @include respond-above('sm') {
        margin: var(--size-huge) auto;
    }

    .whiteboard {
        display: block;

        :deep(.wrapper) {
            display: block;
        }
    }

    .row {
        .input-block {
            .block-name {
                margin-bottom: var(--size-big);
                margin-top: var(--size-big);

                @include respond-below('sm') {
                    margin-bottom: var(--size-small);
                    margin-top: var(--size-small);
                }
            }

            .elements-block {
                gap: var(--size-small);
                margin-top: var(--size-big);

                @include respond-below('sm') {
                    margin-top: var(--size-small);
                }

                &-last {
                    margin-top: var(--size-small);
                    gap: 0;
                }

                :deep(.checkbox) {
                    .basic-text-formatted {
                        color: var(--component-color-text-value);
                        font-size: var(--font-size-nano);
                        font-weight: 600;

                        a {
                            color: var(--text-color-link);

                            &:hover {
                                color: var(--text-color-link-hover);
                                text-decoration: underline;
                            }

                            &:active {
                                color: var(--text-color-link-hover);
                                text-decoration: none;
                            }

                            &:focus-visible {
                                outline: solid 2px var(--button-color-background-primary-green-focus);
                                outline-offset: 2px;
                                border-radius: 2px;
                            }
                        }
                    }
                }

                :deep(.address) {
                    .fields {
                        .fields-group {
                            gap: var(--size-small);

                            .switchable-input {
                                @include respond-below('sm') {
                                    margin-bottom: 0;
                                }
                            }

                            .sub-group {
                                gap: var(--size-small);
                            }
                        }
                    }
                }

                :deep(.input) {
                    .label {
                        label {
                            font-weight: 600;
                        }
                    }
                }

                .marketing {
                    margin-top: var(--size-femto);
                    margin-bottom: var(--size-femto);

                    @include respond-above('sm') {
                        margin-top: var(--size-small);
                        margin-bottom: var(--size-small);
                    }

                    &.checkbox {
                        align-items: flex-start;
                    }
                }

                .agree {
                    margin-bottom: var(--size-normal);

                    @include respond-above('sm') {
                        margin-bottom: var(--size-big);
                    }

                    &.checkbox {
                        align-items: flex-start;
                    }
                }

                .submit-button {
                    @include respond-below('sm') {
                        margin-bottom: var(--size-small);
                    }
                }

                .one-button {
                    min-width: 190px;
                }

                &:empty {
                    display: none;
                }
            }
        }
    }
}
</style>
