import AjaxAgreement from '@/Components/Policies/PolicyBlock/Interfaces/AjaxAgreementInterface';
import Agreement from '@/Components/Policies/PolicyBlock/Interfaces/AgreementInterface';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import { AjaxAgreementBuilder } from '@/Components/Policies/PolicyBlock/Builders/AjaxAgreementBuilder';
import PolicyDetailsPopup from '@/Pages/OneDashboard/Insurances/PolicyDetailsPopup';
import OneBaseService from '@/Services/OneBaseService';
import PolicyDetailsContent from '@/Components/Policies/PolicyDetails/Interfaces/PolicyDetailsContentInterface';
import PolicyDetails from '@/Components/Policies/PolicyDetails/Services/PolicyDetailsService';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import EventBus from '@/Services/event.bus.service';
import Claim from '@/Components/Claims/ClaimBlock/Interfaces/ClaimInterface';
import ClaimPopup from '@/Pages/OneDashboard/Claims/ClaimPopup';
import ClaimDetails from '@/Components/Claims/ClaimDetails/Services/ClaimDetailsService';
import ClaimDetailsContent from '@/Components/Claims/ClaimDetails/Interface/ClaimDetailsContent';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import UserClaim from '@/Interfaces/user.claim.interface';
import Translations from '@/Services/translations.service';

export const useMobileApp = (): MobileAppParams => {
    const ClaimView: string = 'claim';
    const AgreementView: string = 'agreement';

    const TranslationType: string = 'mobile_app';

    const FallbackEvent: string = 'mobile-view-fallback';
    const AgreementFallbackMessage: string = 'agreement_not_found_fallback';
    const ClaimFallbackMessage: string = 'claim_not_found_fallback';

    const FallbackMessagesByDesignation: Designation = {
        agreement: AgreementFallbackMessage,
        claim: ClaimFallbackMessage,
    };

    const ajaxAgreement = (): AjaxAgreement => {
        return new AjaxAgreementBuilder().fromAgreement(storedAgreement()).build();
    };

    const designation = (): Designation => {
        return {
            agreement: AgreementView,
            claim: ClaimView,
        };
    };

    const agreementView = (): PolicyDetailsPopup => {
        const agreement: Agreement = storedAgreement();

        return new PolicyDetailsPopup(agreement, 'dashboard', OneBaseService.getInstance().user.current)
            .applySubscriptionHistory((agreement.subscriptionHistory as Agreement[]) ?? [])
            .applyUpcomingAgreements((agreement.upcomingAgreements as Agreement[]) ?? []);
    };

    const claimView = (): ClaimPopup => {
        return new ClaimPopup(currentClaim(), 'dashboard', OneBaseService.getInstance().user.current);
    };

    const hasStoredAgreement = (): boolean => {
        return !!storedAgreement();
    };

    const hasStoredClaim = (): boolean => {
        return !!storedClaim();
    };

    const fallbackEvent = (): string => {
        return FallbackEvent;
    };

    const emitFallbackEvent = (view: string): void => {
        const message: string = Translations.getInstance().localized(
            FallbackMessagesByDesignation[view],
            TranslationType,
        );
        EventBus.getInstance().emit(FallbackEvent, { message: message });
    };

    const viewContent = (tab: string, view: string): DynamicDictionary => {
        let result: PolicyDetailsContent | ClaimDetailsContent | undefined;
        switch (view) {
            case AgreementView:
                result = PolicyDetails.getInstance().getContent();
                break;
            case ClaimView:
                result = ClaimDetails.getInstance().getContent();
                break;
            default:
                break;
        }

        return result!.tabs.elements.filter((element) => element.id === tab)[0].content;
    };

    const currentClaim = (): Claim => {
        return transformedClaim(storedClaim().claimInfo);
    };

    const storedAgreement = (): Agreement => {
        return TransferStateService.getInstance().get('agreement') as Agreement;
    };

    const storedClaim = (): RawClaim => {
        return TransferStateService.getInstance().get('claim') as RawClaim;
    };

    const transformedClaim = (value: UserClaim): Claim => {
        return {
            id: value.Id,
            claimType: '',
            claimNumber: value.ClaimCaseNo,
            claimId: value.Uuid,
            policyId: value.PolicyId,
            policyNumber: value.PolicyNumber,
            policyType: value.PolicyType,
            submitDate: OneDate.short(value.ApplicationDate),
            status: value.ClaimCaseInternalStatus.toLowerCase(),
            title: value.NotificationTypeText,
        } as Claim;
    };

    return {
        ajaxAgreement,
        agreementView,
        claimView,
        hasStoredAgreement,
        hasStoredClaim,
        viewContent,
        emitFallbackEvent,
        fallbackEvent,
        designation,
        currentClaim,
    };
};

interface RawClaim {
    claimAttachments: { id: string; name: string; created: string }[];
    claimDocuments: object;
    claimInfo: UserClaim;
}

interface Designation {
    agreement: string;
    claim: string;
    [key: string]: any;
}

export interface MobileAppParams {
    ajaxAgreement: () => AjaxAgreement;
    agreementView: () => PolicyDetailsPopup;
    claimView: () => ClaimPopup;
    hasStoredAgreement: () => boolean;
    hasStoredClaim: () => boolean;
    viewContent: (tab: string, view: string) => DynamicDictionary;
    emitFallbackEvent: (view: string) => void;
    fallbackEvent: () => string;
    designation: () => Designation;
    currentClaim: () => Claim;
}
