import 'vite/modulepreload-polyfill';
import 'moment/dist/locale/lv';
import 'moment/dist/locale/lt';
import 'moment/dist/locale/et';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/ru';
import { App } from 'vue';
import { createPinia } from 'pinia';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import VueApp from '@/Core/Routing/VueApp';
import { ComponentPublicInstance } from 'vue';
import Router from '@/Core/Routing/Router';
import GlobalDirectives from '@/Directives/Directives';
import AppComponent from '@/Apps/MobileApp/App.vue';
import AgreementOverviewPage from '@/Apps/MobileApp/Pages/AgreementOverviewPage.vue';
import AgreementInvoicesPage from '@/Apps/MobileApp/Pages/AgreementInvoicesPage.vue';
import AgreementPaymentCardPage from '@/Apps/MobileApp/Pages/AgreementPaymentCardPage.vue';
import ClaimOverviewPage from '@/Apps/MobileApp/Pages/ClaimOverviewPage.vue';
import RouteStepper from '@/Components/Routing/RouteStepper/RouteStepper.vue';
import MobileLayout from '@/Layouts/MobileLayout.vue';
import AppPolicyDetailsOverview from '@/Components/Policies/PolicyDetailsOverview/PolicyDetailsOverview.vue';
import AppPolicyDetailsInvoices from '@/Components/Policies/PolicyDetailsInvoices/PolicyDetailsInvoices.vue';
import AppPolicyDetailsPaymentMethod from '@/Components/Policies/PolicyDetailsPaymentMethod/PolicyDetailsPaymentMethod.vue';
import AppClaimDetailsOverview from '@/Components/Claims/ClaimOverview/ClaimOverview.vue';
import AppPopup from '@/Components/Popups/Popup/Popup.vue';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import AppCompactFileUploader from '@/Components/Files/CompactFileUploader/CompactFileUploader.vue';
import AppFilesUploader from '@/Components/Files/FilesUploader/FilesUploader.vue';
import AppProcessInnerToaster from '@/Components/Tooltips/ProcessInnerToaster/ProcessInnerToaster.vue';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import CommonComponents from '@/Apps/CommonComponents';

const pages: Components = {
    AgreementOverviewPage: AgreementOverviewPage,
    AgreementInvoicesPage: AgreementInvoicesPage,
    AgreementPaymentCardPage: AgreementPaymentCardPage,
    ClaimOverviewPage: ClaimOverviewPage,
};

const components: Components = {
    MobileLayout: MobileLayout,
    AppRouteStepper: RouteStepper,
    AppPolicyDetailsOverview: AppPolicyDetailsOverview,
    AppPolicyDetailsInvoices: AppPolicyDetailsInvoices,
    AppPolicyDetailsPaymentMethod: AppPolicyDetailsPaymentMethod,
    AppClaimDetailsOverview: AppClaimDetailsOverview,
    AppPopup: AppPopup,
    AppContentLoader: AppContentLoader,
    AppCompactFileUploader: AppCompactFileUploader,
    AppFilesUploader: AppFilesUploader,
    AppProcessInnerToaster: AppProcessInnerToaster,
    AppButtonWithCallback: AppButtonWithCallback,
};

export default class {
    public app(selector: string, routes: BackendRoute[]): ComponentPublicInstance {
        const app: App = new VueApp(AppComponent)
            .withComponents({ ...pages, ...components, ...(CommonComponents.components as Components) })
            .withDirectives(GlobalDirectives)
            .create();
        app.use(new Router(app, routes).create());
        app.use(createPinia());
        return app.mount(selector);
    }
}
