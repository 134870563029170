import {
    NavigationGuardNext,
    RouteLocationNormalized,
    RouteLocationNormalizedLoaded,
    Router,
    useRouter,
} from 'vue-router';
import Storage from '@/Apps/ActivePlus/Services/Storage';
import Steps from '@/Apps/ActivePlus/Enums/Steps';
import { useScroll } from '@/Composables/Scroll';
import OneBase from '@/Interfaces/OneBaseInterface';
import OneBaseService from '@/Services/OneBaseService';

export default class StepsGuard {
    private static instance: StepsGuard;
    private router: Router = useRouter();
    private storage: Storage;
    private btaBase: OneBase = OneBaseService.getInstance();

    private constructor(storage: Storage) {
        this.storage = storage;
    }

    public static getInstance(storage: Storage): StepsGuard {
        if (!StepsGuard.instance) {
            StepsGuard.instance = new StepsGuard(storage);
        }

        return StepsGuard.instance;
    }

    public init(): void {
        this.preventRouteDirectAccess();
        this.hideAdditionalModules();
        this.router.beforeEach(
            (to: RouteLocationNormalized, _from: RouteLocationNormalizedLoaded, next: NavigationGuardNext): void => {
                this.storage.destroyRoute();
                let isInvalidRoute: boolean = false;
                switch (to.name) {
                    case Steps.Insurance:
                        this.btaBase.captcha.resetCaptcha();
                        break;
                    case Steps.InsuredObjects:
                        if (!this.isProgramAndCoverageValid()) {
                            isInvalidRoute = true;
                        }
                        break;
                    case Steps.InsuredPersons:
                        if (!this.isSelectedObjectsValid()) {
                            isInvalidRoute = true;
                        }
                        break;
                    case Steps.SummaryAndPayment:
                        if (!this.isInsuredPersonsValid()) {
                            isInvalidRoute = true;
                        }
                        break;
                    default:
                }
                isInvalidRoute ? next(false) : next();
            },
        );
        this.router.afterEach((_to: RouteLocationNormalized, _from: RouteLocationNormalizedLoaded): void => {
            useScroll().scrollToTop().then();
        });
    }

    private isProgramAndCoverageValid(): boolean {
        return this.storage.fields?.programIc !== '' && this.storage.fields?.coverage !== 0;
    }

    private isSelectedObjectsValid(): boolean {
        return (
            this.storage.fields?.selectedObjects &&
            Array.isArray(this.storage.fields.selectedObjects) &&
            this.storage.fields.selectedObjects.length > 0 &&
            this.storage.fields.selectedObjects.every(
                (obj) =>
                    obj &&
                    typeof obj === 'object' &&
                    'formName' in obj &&
                    'model' in obj &&
                    obj.formName.trim() !== '' &&
                    obj.model.trim() !== '',
            )
        );
    }

    private isInsuredPersonsValid(): boolean {
        return (
            this.storage.fields?.insuredPersons &&
            Array.isArray(this.storage.fields.insuredPersons) &&
            this.storage.fields.insuredPersons.length > 0 &&
            this.storage.fields.insuredPersons.every(
                (person) =>
                    person &&
                    typeof person === 'object' &&
                    'formName' in person &&
                    'person' in person &&
                    person.formName.trim() !== '' &&
                    person.person &&
                    typeof person.person === 'object' &&
                    'name' in person.person &&
                    'surname' in person.person &&
                    'personCode' in person.person &&
                    person.person.name.trim() !== '' &&
                    person.person.surname.trim() !== '' &&
                    person.person.personCode.trim() !== '',
            )
        );
    }

    public preventRouteDirectAccess(): void {}

    private hideAdditionalModules(): void {
        const isInitialStep: boolean = this.router.currentRoute.value.name === Steps.Insurance;
        const additionalModules: JQuery<HTMLElement> = $('.page-one-active-plus').children(
            '.module:not(.spa-active-plus)',
        );
        additionalModules.each((_index: number, module: HTMLElement): void => {
            isInitialStep ? $(module).show() : $(module).hide();
        });
    }
}
