import TransferStateService from '@/Core/ServerState/TransferStateService';
import Translations from '@/Services/translations.service';

export const useClassifiers = (): ClassifierParams => {
    type Classifier = {
        ic: string;
        name_en: string;
        name_lv: string;
        name_ru: string;
        name_ee: string;
        name_lt: string;
    };
    const damageDeductible: string = 'ATB_TL_DEDUC_DAMAGE';

    const atbTlDeductibleDamage = (ic: string): string => {
        let result: string = '';
        const classifier: Classifier[] = JSON.parse(TransferStateService.getInstance().get(damageDeductible));
        const classifierEntry: Classifier | undefined = classifier.find((item) => item.ic === ic);
        if (classifierEntry) {
            result = translated(classifierEntry);
        }

        return result;
    };

    const translated = (classifier: Classifier): string => {
        let result;
        const language: string = Translations.getInstance().language.toLowerCase();
        switch (language) {
            case 'ee':
                result = classifier.name_ee;
                break;
            case 'en':
                result = classifier.name_en;
                break;
            case 'lt':
                result = classifier.name_lt;
                break;
            case 'lv':
                result = classifier.name_lv;
                break;
            case 'ru':
                result = classifier.name_ru;
                break;
            default:
                result = `missing classifier entry for: ${classifier.ic}`;
        }
        if (result === '') {
            result = defaultText(classifier);
        }

        return result;
    };

    const defaultText = (classifier: Classifier): string => {
        return `missing classifier entry for: ${classifier.ic}`;
    };

    return {
        atbTlDeductibleDamage,
    };
};

export interface ClassifierParams {
    atbTlDeductibleDamage: (ic: string) => string;
}
