export enum FieldNames {
    WhatWasDamaged = 'whatWasDamaged',
    InfoAboutLuggage = 'infoAboutLuggage',
    LuggageItem = 'luggageItem',

    WhoWasSupervising = 'whoWasSupervising',
    HaveConfirmationIssuedStolen = 'haveConfirmationIssuedStolen',
    ConfirmationIssuedSum = 'confirmationIssuedSum',
    DayInstitutionInformed = 'dayInstitutionInformed',
    ListOfEssentialsStolen = 'listOfEssentialsStolen',

    HaveConfirmationIssuedDelayed = 'haveConfirmationIssuedDelayed',
    HowManyHoursLuggageDelayed = 'howManyHoursLuggageDelayed',
    AmountOfCompensationByCarrier = 'amountOfCompensationByCarrier',
    ListOfEssentialsDelayed = 'listOfEssentialsDelayed',
}
