<script setup lang="ts">
import { onMounted, PropType, Ref, ref, watch } from 'vue';
import FormField from '@/Assets/Libraries/Form/FormField';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import CssClass from '@/Enums/CssClassEnum';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import IconSide from '@/Components/Tooltips/TextWithTip/IconSide.enum';
import AppTextWithTip from '@/Components/Tooltips/TextWithTip/TextWithTip.vue';

interface GroupedOptions {
    tooltip?: string;
    group: string;
    options: InputOption[];
}

const props = defineProps({
    formField: { type: Object as PropType<FormField<DynamicDictionary>>, required: true },
    options: { type: Array as PropType<InputOption[]>, default: () => [] },
    optionClass: { type: String as PropType<'filled' | ''>, default: '' },
});

const groupedOptions: Ref<GroupedOptions[]> = ref([]);

const emit = defineEmits(['change']);

watch(
    () => props.formField.value,
    (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
            emit('change', newValue, oldValue);
        }
    },
);

onMounted(() => {
    groupedOptions.value = groupOptions(props.options);
});

function groupOptions(options: InputOption[]): GroupedOptions[] {
    return Object.values(
        options.reduce<Record<string, GroupedOptions>>(
            (groups, option) => {
                const group = option.group || '';
                if (!groups[group]) {
                    groups[group] = {
                        tooltip: option.tooltipText,
                        group: group,
                        options: [],
                    };
                }
                groups[group].options.push(option);
                return groups;
            },
            {} as Record<string, GroupedOptions>,
        ),
    );
}

function onOptionClick(option: InputOption): void {
    if (!option.disabled) {
        select(String(option.value));
    }
}

function itemClasses(selected: InputOption): string {
    return [
        'options-list-radio',
        itemIsSelected(selected) ? CssClass.Selected : null,
        selected.disabled ? CssClass.Disabled : null,
        selected.hidden ? CssClass.Invisible : null,
        props.optionClass,
    ]
        .filter(Boolean)
        .join(' ');
}

function itemIsSelected(selected: InputOption): boolean {
    return String(selected.value) === props.formField.value.selected;
}

function select(value: string): void {
    props.formField.setValue({
        selected: String(value),
        customText: {},
    });
    props.formField.markAsTouched();
    props.formField.markAsDirty();
}
</script>

<template>
    <div :id="formField.name" class="options-list">
        <template v-for="group in groupedOptions" :key="group.group">
            <app-text-with-tip
                class="group-title"
                :info-icon-side="IconSide.Before"
                :title="group.group"
                title-tag="h5"
                :tip-description="group.tooltip"
            />
            <div
                v-for="option in group.options"
                :key="String(option.value)"
                class="options-list-option"
                :class="itemClasses(option)"
            >
                <button class="options-list-panel" @click="onOptionClick(option)">
                    <span class="radio-icon"></span>
                    {{ option.name }}
                </button>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.options-list {
    width: 100%;

    .group-title {
        margin-bottom: var(--size-small);

        &:not(:first-of-type) {
            margin-top: var(--size-medium);
        }
    }

    &-option {
        position: relative;
        width: 100%;
        min-height: 52px;
        margin-bottom: var(--size-nano);
        background-color: var(--component-color-background-base);
        border: 2px solid transparent;
        border-radius: 8px;
        transition: border-color 0.6s;

        &:focus:not(.disabled, .selected),
        &:hover:not(.disabled, .selected) {
            border-color: var(--brand-teal);
        }

        &:not(:focus, :hover, .disabled, .selected, .error)::before {
            content: '';
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            border: 1px solid var(--component-color-border-default);
            border-radius: 8px;
            margin-top: -1px;
            margin-left: -1px;
        }
    }

    &-panel {
        position: relative;
        width: 100%;
        text-align: left;
        font-size: var(--font-size-tiny);
        line-height: var(--line-height-accented);
        font-weight: 600;
        color: var(--text-color-default);
        padding: 13px 22px 13px 55px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -12px;
            margin-left: 14px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 2px solid var(--component-color-border-default);
        }

        .radio-icon {
            display: none;
            position: absolute;
            top: 50%;
            margin-top: -6px;
            left: 20px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: var(--component-color-background-base);
        }
    }

    &-radio {
        .options-list-panel {
            &::before {
                border-radius: 50%;
            }
        }
    }

    .selected {
        border-color: var(--text-color-link);

        .options-list-panel {
            &::before {
                border-color: var(--text-color-link);
            }

            .radio-icon {
                display: block;
                background-color: var(--text-color-link);
            }
        }
    }

    .separator-text {
        color: var(--text-color-subtlest);
        align-self: center;
        margin: var(--size-small) 0;
        font-weight: 500;
    }

    .options-list-option.disabled {
        background-color: var(--background-light);

        .options-list-panel {
            color: var(--black-200);

            .radio-icon {
                display: none;
            }
        }
    }

    .options-list-option.invisible {
        visibility: hidden;
        height: 0;
        min-height: 0;
        margin: 0;
        padding: 0;
    }

    .options-list-option.filled {
        .options-list-panel {
            padding: 20px 20px 20px 64px;

            &::before {
                margin-left: 20px;
            }
        }

        &.selected {
            background-color: var(--system-color-success-light);

            .options-list-panel {
                &::before {
                    background-color: var(--system-color-success-dark);
                }

                .radio-icon {
                    margin-top: -4px;
                    width: var(--size-pico);
                    height: var(--size-pico);
                    left: 28px;
                    background-color: var(--background-light);
                }
            }
        }
    }
}
</style>
