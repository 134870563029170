<script setup lang="ts">
import { computed, onMounted, onUnmounted, PropType, Ref, watch } from 'vue';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import User from '@/Services/user.service';
import { useTranslate } from '@/Composables/Translate';
import FormField from '@/Assets/Libraries/Form/FormField';
import Form from '@/Assets/Libraries/Form/Form';
import AppInputBankAccount from '@/Components/Inputs/InputBankAccount/InputBankAccount.vue';
import AppInputRadioOverlayed from '@/Components/Inputs/InputRadioOverlayed/InputRadioOverlayed.vue';
import PersonBankDetails from '@/Interfaces/PersonBankDetailsInterface';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import Validation from '@/Services/validation.service';

const { translateForType } = useTranslate();

const props = defineProps({
    formField: {
        type: Object as PropType<FormField<PersonBankDetails>>,
        default: () => new FormField(''),
    },
    translationType: {
        type: String,
        default: () => 'components',
    },
    bankAccountCurrency: {
        type: Array as PropType<string[]>,
        required: false,
        default: undefined,
    },
});

const emit = defineEmits(['change']);

const user: User = User.getInstance();

const form: Form<PersonBankDetails> = new Form({ useValidationV2: true });
const formChanges = new Subject<void>();
let onSubmitAttemptSubscription: Subscription | undefined;
let formChangeSubscription: Subscription | undefined;
let patchSubscription: Subscription;

const isLogged: Ref<boolean> = computed(() => {
    return user.isLogged();
});

const currencyOptions: Ref<InputOption[]> = computed(() => {
    const result: InputOption[] = [];
    props.bankAccountCurrency?.forEach((currency: string) => {
        result.push(new InputOptionBuilder().setName(currency).setValue(currency).build());
    });

    return result;
});

onMounted((): void => {
    setupForm();
    prepareFormField();
    setupSubscriptions();
    setDefaultValues();
    patchSubscription = props.formField.onPatch.subscribe(() => {
        defaultFields();
    });
});

onUnmounted((): void => {
    onSubmitAttemptSubscription?.unsubscribe();
    formChangeSubscription?.unsubscribe();
    patchSubscription?.unsubscribe();
});

function setupForm(): void {
    form.addField(new FormField('account', '', { required: Validation.required }));
    form.addField(new FormField('currency', '', { required: Validation.required }));
    form.setReady();
}

function prepareFormField(): void {
    props.formField.addValidators({
        childrenAreValid: () => form.isValid(),
    });
}

function setupSubscriptions(): void {
    onSubmitAttemptSubscription = props.formField.onSubmitAttempt.subscribe(() => {
        form.submitAttempt();
    });

    formChangeSubscription = formChanges.pipe(debounceTime(1)).subscribe(() => {
        props.formField.setValue({
            account: form.field('account').value,
            currency: form.field('currency').value,
        });
        props.formField.markAsTouched();
        emit('change', props.formField.value);
    });
}

function authenticatedFields(): void {
    form.field('account').setValue(user.current.bank);
    form.field('currency').setValue(user.current.bankCurrency);
}

function defaultFields(): void {
    form.field('account').setValue('');
    form.field('currency').setValue('');
}

function restoredFields(): void {
    form.field('account').setValue(props.formField.value.account);
    form.field('currency').setValue(props.bankAccountCurrency?.[0] || '');
}

function setDefaultValues(): void {
    if (!props.formField.isEmpty()) {
        restoredFields();
    } else if (isLogged.value) {
        authenticatedFields();
    } else {
        defaultFields();
    }
}

function translated(key: string): string {
    return translateForType(key, props.translationType);
}

function applyValues(): void {
    formChanges.next();
}
</script>

<template>
    <div
        v-if="form.isReady()"
        :id="formField.name"
        class="grid-utility grid-columns-1 sm:grid-columns-12"
        :class="{ ...formField.classes() }"
        :data-store="formField.name"
        :data-store-value="JSON.stringify(formField.value)"
    >
        <app-input-bank-account
            class="column-span-12 sm:column-span-6 sm:only-child:column-span-12"
            :label="translated('account')"
            :placeholder="translated('account_placeholder')"
            :form-field="form.field('account')"
            :data-store-disabled="true"
            @change="applyValues"
        >
        </app-input-bank-account>
        <app-input-radio-overlayed
            v-if="bankAccountCurrency"
            class="column-span-12 sm:column-span-3"
            :label="translated('currency')"
            :options="currencyOptions"
            :form-field="form.field('currency')"
            :data-store-disabled="true"
            @change="applyValues"
        >
        </app-input-radio-overlayed>
    </div>
</template>
