<script setup lang="ts">
import { onMounted, computed, Ref, onUnmounted } from 'vue';
import Form from '@/Assets/Libraries/Form/Form';
import { Subscription } from 'rxjs';

const props = defineProps({
    form: { type: Form<any>, default: () => new Form() },
    formFetchSkip: { type: Boolean, default: false },
});

const emit = defineEmits(['enter-pressed', 'change', 'changeAfterRestore']);

const isFormTouched: Ref<boolean> = computed(() => props.form?.isTouched());
let onChange$: Subscription | undefined;
let changeAfterRestore$: Subscription | undefined;

onMounted((): void => {
    document.addEventListener('keypress', onKeyPress);
    onChange$ = props.form.onChange.subscribe(() => {
        emit('change');
    });
    changeAfterRestore$ = props.form.onChangeAfterRestore.subscribe(() => {
        emit('changeAfterRestore');
    });
});

onUnmounted((): void => {
    document.removeEventListener('keypress', onKeyPress);
    onChange$?.unsubscribe();
    changeAfterRestore$?.unsubscribe();
});

function onKeyPress(event: KeyboardEvent): void {
    const targetElement: HTMLInputElement = event.target as HTMLInputElement;
    if (event.key === 'Enter' && props.form.exists(targetElement.name)) {
        emit('enter-pressed');
    }
}
</script>
<template>
    <section
        :id="'form-' + form.name"
        class="form custom-form"
        :data-form-name="form.name"
        :data-form-store="form.name"
        :data-form-fetch-skip="String(formFetchSkip)"
        :data-form-store-skip="String(!isFormTouched)"
    >
        <slot></slot>
    </section>
</template>
