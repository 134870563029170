import VueApp from '@/Core/Routing/VueApp';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import { App } from 'vue';
import { createPinia } from 'pinia';
import { ComponentPublicInstance } from 'vue';
import Router from '@/Core/Routing/Router';
import GlobalDirectives from '@/Directives/Directives';
import AppComponent from '@/Apps/SolarPanels/App.vue';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/Routing/RouteStepper/RouteStepper.vue';
import InsurancePage from '@/Apps/SolarPanels/Pages/InsurancePage.vue';
import CoveragePage from '@/Apps/SolarPanels/Pages/CoveragePage.vue';
import AddressPage from '@/Apps/SolarPanels/Pages/AddressPage.vue';
import SummaryAndPaymentPage from '@/Apps/SolarPanels/Pages/SummaryAndPaymentPage.vue';
import CoveredPopupSolarPanelsMulti from '@/Components/Popups/CoveredPopup/CoveredPopupSolarPanelsMulti.vue';
import SolarPanelsMini from '@/Components/Other/ProductBlock/Components/MiniPlans/SolarPanelsMini.vue';
import ProductBlockInnerSolarPanelsPolicy from '@/Components/Other/ProductBlock/Components/ProductBlockInnerSolarPanelsPolicy.vue';
import TextWithTip from '@/Components/Tooltips/TextWithTip/TextWithTip.vue';
import ButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import InputDateWithCalendar from '@/Components/Inputs/InputDateWithCalendar/InputDateWithCalendar.vue';
import PopupOpener from '@/Components/Popups/PopupOpener/PopupOpener.vue';
import InputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import InputIdentityNumber from '@/Components/Inputs/InputIdentityNumber/InputIdentityNumber.vue';
import InputEmail from '@/Components/Inputs/InputEmail/InputEmail.vue';
import PhoneWithCountry from '@/Components/Inputs/PhoneWithCountry/PhoneWithCountry.vue';
import InputText from '@/Components/Inputs/InputText/InputText.vue';
import LanguageSwitcher from '@/Components/Widgets/LanguageSwitcher/LanguageSwitcher.vue';
import Popup from '@/Components/Popups/Popup/Popup.vue';
import Tooltipster from '@/Components/Tooltips/Tooltipster/Tooltipster.vue';
import InputRadio from '@/Components/Inputs/InputRadio/InputRadio.vue';
import InputRadioOverlayed from '@/Components/Inputs/InputRadioOverlayed/InputRadioOverlayed.vue';
import ContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import CustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import InputCountry from '@/Components/Inputs/InputCountry/InputCountry.vue';
import InputDate from '@/Components/Inputs/InputDate/InputDate.vue';
import PersonListWithSearch from '@/Components/Lists/PersonListWithSearch/PersonListWithSearch.vue';
import InputSelect from '@/Components/Inputs/InputSelect/InputSelect.vue';
import InputDateWithDropdown from '@/Components/Inputs/InputDateWithDropdown/InputDateWithDropdown.vue';
import InputNumber from '@/Components/Inputs/InputNumber/InputNumber.vue';
import Drawer from '@/Components/Modals/Drawer/Drawer.vue';
import ProductBlock from '@/Components/Other/ProductBlock/ProductBlock.vue';
import AddressFinder from '@/Components/Inputs/AddressFinder/AddressFinder.vue';
import ConsentsList from '@/Components/Lists/ConsentsList/ConsentsList.vue';
import SelectAccountDropdown from '@/Components/Widgets/SelectAccountDropdown/SelectAccountDropdown.vue';
import Badge from '@/Components/Buttons/Badge/Badge.vue';
import ItemsListWithIcons from '@/Components/Lists/ItemsListWithIcons/ItemsListWithIcons.vue';
import ProductLayout from '@/Layouts/ProductLayout.vue';
import CommunicationNotificator from '@/Components/TwoWayCommunication/CommunicationNotificator/CommunicationNotificator.vue';
import CommonComponents from '@/Apps/CommonComponents';

const pages: Components = {
    InsurancePage: InsurancePage,
    CoveragePage: CoveragePage,
    AddressPage: AddressPage,
    SummaryAndPaymentPage: SummaryAndPaymentPage,
};

const components: Components = {
    AppProductLayout: ProductLayout,
    AppRouteStepper: RouteStepper,
    AppTextWithTip: TextWithTip,
    AppButtonWithCallback: ButtonWithCallback,
    AppProductBlockInnerSolarPanelsPolicy: ProductBlockInnerSolarPanelsPolicy,
    AppSolarPanelsMini: SolarPanelsMini,
    AppCoveredPopupSolarPanelsMulti: CoveredPopupSolarPanelsMulti,
    AppInputDateWithCalendar: InputDateWithCalendar,
    AppPopupOpener: PopupOpener,
    AppInputText: InputText,
    AppInputCheckbox: InputCheckbox,
    AppInputIdentityNumber: InputIdentityNumber,
    AppInputEmail: InputEmail,
    AppPhoneWithCountry: PhoneWithCountry,
    AppLanguageSwitcher: LanguageSwitcher,
    AppPopup: Popup,
    AppTooltipster: Tooltipster,
    AppContentLoader: ContentLoader,
    AppDrawer: Drawer,
    AppProductBlock: ProductBlock,
    AppCustomForm: CustomForm,
    AppInputRadio: InputRadio,
    AppInputRadioOverlayed: InputRadioOverlayed,
    AppInputSelect: InputSelect,
    AppInputNumber: InputNumber,
    AppInputDateWithDropdown: InputDateWithDropdown,
    AppInputCountry: InputCountry,
    AppInputDate: InputDate,
    AppPersonListWithSearch: PersonListWithSearch,
    AppAddressFinder: AddressFinder,
    AppConsentsList: ConsentsList,
    AppSelectAccountDropdown: SelectAccountDropdown,
    AppBadge: Badge,
    AppItemsListWithIcons: ItemsListWithIcons,
    AppCommunicationNotificator: CommunicationNotificator,
};

export default class {
    public app(selector: string, routes: BackendRoute[]): ComponentPublicInstance {
        const app: App = new VueApp(AppComponent)
            .withComponents({ ...pages, ...components, ...(CommonComponents.components as Components) })
            .withDirectives(GlobalDirectives)
            .create();
        app.use(new Router(app, routes).create());
        app.use(createPinia());
        return app.mount(selector);
    }
}
