import Tooltipster from '@/Interfaces/tooltipster.interface';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { DetailList } from '@/Interfaces/DetailListInterface';

type AllowedValueTypes = Array<string | number>;

export class DetailsListBuilder<ValueType extends AllowedValueTypes = AllowedValueTypes> {
    private detailList: DetailList<ValueType> = {
        label: '',
        value: [''] as ValueType,
        isTotal: false,
        highlight: false,
    };

    public setLabel(label: string): this {
        this.detailList.label = label;

        return this;
    }

    public setValue(value: ValueType): this {
        this.detailList.value = value;

        return this;
    }

    public setIsTotal(isTotal: boolean): this {
        this.detailList.isTotal = isTotal;
        return this;
    }

    public setHighlight(highlight: boolean): this {
        this.detailList.highlight = highlight;
        return this;
    }

    public build(): DetailList<ValueType> {
        return this.detailList;
    }
}
