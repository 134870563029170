<script lang="ts">
import { computed, defineComponent, ref, Ref, nextTick } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import Form from '@/Assets/Libraries/Form/Form';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import PolicyDetailsInvoices from '@/Components/Policies/PolicyDetailsInvoices/Interfaces/PolicyDetailsInvoicesInterface';
import PolicyDetailsInvoicesContent from '@/Components/Policies/PolicyDetailsInvoices/Interfaces/PolicyDetailsInvoicesContentInterface';
import InvoiceDocumentBlock from '@/Interfaces/OnePolicy/invoice.document.block.interface';
import PaymentWidget from '@/Interfaces/OnePolicy/payment.widget.interface';
import { OneDashboardInterface, useOneDashboard } from '@/Pages/OneDashboard/Composables/OneDashboard';
import { Subscription } from 'rxjs';
import Invoice from '@/Components/Policies/PolicyBlock/Interfaces/InvoiceInterface';
import PaymentsContentTab from '@/Pages/OneDashboard/Payments/Enums/PaymentsContentTabEnum';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { useTranslate } from '@/Composables/Translate';
import Url from '@/Enums/UrlEnum';
import PolicyState from '@/Enums/OnePolicy/PolicyStateEnum';
import Method from '@/Enums/MethodEnum';
import PolicyDetailsInvoicesBuilder from '@/Components/Policies/PolicyDetailsInvoices/Builders/PolicyDetailsInvoicesBuilder';
import { useDefine } from '@/Composables/Define';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import FormField from '@/Assets/Libraries/Form/FormField';
import SettingsService from '@/Services/settings.service';
import AgreementTypeMap from '@/Components/Policies/PolicyBlock/Classes/AgreementTypeMap';
import UrlBuilder from '@/Assets/Libraries/Url/UrlBuilder';
import Translations from '@/Services/translations.service';
import RequestService from '@/Services/request.service';
import { AxiosResponse } from 'axios';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const { isSet } = useDefine();
        const oneDashboard: OneDashboardInterface = useOneDashboard();
        const { translateForType, applyTranslationType, language, type } = useTranslate();

        const CurrentStep: number = 1;
        const Facility: string = 'dashboard';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const inputOptionsContentTabs: Ref<InputOption[]> = ref([]);
        const isDataLoaded: Ref<boolean> = ref(false);
        const paymentsLateInvoicesDocuments: Ref<PolicyDetailsInvoices> = ref(
            new (class implements PolicyDetailsInvoices {
                public component: string = '';
                public content: PolicyDetailsInvoicesContent = new (class implements PolicyDetailsInvoicesContent {
                    public documentBlocks: InvoiceDocumentBlock[] = [];
                    public title: string = '';
                    public useBadge: boolean = false;
                    public paymentWidget: PaymentWidget = new (class implements PaymentWidget {
                        public enabled: boolean = false;
                        public additionalButtonText: string = '';
                        public invoiceCount: number = 0;
                        public totalAmount: string = '';
                    })();
                })();
            })(),
        );
        const paymentsUnpaidDocuments: Ref<PolicyDetailsInvoices> = ref(
            new (class implements PolicyDetailsInvoices {
                public component: string = '';
                public content: PolicyDetailsInvoicesContent = new (class implements PolicyDetailsInvoicesContent {
                    public documentBlocks: InvoiceDocumentBlock[] = [];
                    public title: string = '';
                    public useBadge: boolean = false;
                    public paymentWidget: PaymentWidget = new (class implements PaymentWidget {
                        public enabled: boolean = false;
                        public additionalButtonText: string = '';
                        public invoiceCount: number = 0;
                        public totalAmount: string = '';
                    })();
                })();
            })(),
        );
        const paymentsPaidDocuments: Ref<PolicyDetailsInvoices> = ref(
            new (class implements PolicyDetailsInvoices {
                public component: string = '';
                public content: PolicyDetailsInvoicesContent = new (class implements PolicyDetailsInvoicesContent {
                    public documentBlocks: InvoiceDocumentBlock[] = [];
                    public title: string = '';
                    public useBadge: boolean = false;
                    public paymentWidget: PaymentWidget = new (class implements PaymentWidget {
                        public enabled: boolean = false;
                        public additionalButtonText: string = '';
                        public invoiceCount: number = 0;
                        public totalAmount: string = '';
                    })();
                })();
            })(),
        );
        const tooltipMessage: Ref<string> = ref('');
        const agreements: Ref<string[]> = ref([]);

        const selectAllLateInvoicesText: Ref<string> = computed(() => {
            let result: string = 'btar_dashboard_delayed_invoices';
            if (currentTab.value === PaymentsContentTab.Late) {
                result = allLateInvoicesAreSelected.value
                    ? 'btar_dashboard_insurances_reset'
                    : 'btar_dashboard_insurances_select_all';
            }

            return oneDashboard.translated(result);
        });

        const selectAllUnpaidInvoicesText: Ref<string> = computed(() => {
            const result: string = allUnpaidInvoicesAreSelected.value
                ? 'btar_dashboard_insurances_reset'
                : 'btar_dashboard_insurances_select_all';

            return oneDashboard.translated(result);
        });

        const allLateInvoicesAreSelected: Ref<boolean> = computed(() => {
            const lateDocumentsCount: number = documentsCountInAllBlocks(lateDocuments.value);
            let selected: number = 0;
            lateDocuments.value.forEach((block: InvoiceDocumentBlock): void => {
                block.documents.forEach((document: Invoice): void => {
                    if (document.selected) {
                        selected++;
                    }
                });
            });

            return selected === lateDocumentsCount;
        });

        const allUnpaidInvoicesAreSelected: Ref<boolean> = computed(() => {
            const unpaidDocumentsCount: number = documentsCountInAllBlocks(unpaidDocuments.value);
            let selected: number = 0;
            unpaidDocuments.value.forEach((block: InvoiceDocumentBlock): void => {
                block.documents.forEach((document: Invoice): void => {
                    if (document.selected) {
                        selected++;
                    }
                });
            });

            return selected === unpaidDocumentsCount;
        });

        const hasLateInvoices: Ref<boolean> = computed(() => {
            return documentsCountInAllBlocks(lateDocuments.value) > 0;
        });

        const inputOptionsTabs: Ref<InputOption[]> = computed(() => {
            return inputOptionsContentTabs.value;
        });

        const currentTab: Ref<PaymentsContentTab> = computed(() => {
            return form.field('input-radio-tabs').value;
        });

        const contentTitle: Ref<string> = computed(() => {
            return contentTabTitle(currentTab.value);
        });

        const paymentsUnpaidInvoicesDocumentsTabList: Ref<PolicyDetailsInvoicesContent | null> = computed(() => {
            return paymentsLateInvoicesDocuments.value.content;
        });

        const paymentsScheduleDocumentsTabList: Ref<PolicyDetailsInvoicesContent | null> = computed(() => {
            return paymentsUnpaidDocuments.value.content;
        });

        const autoSelectFirstScheduleInvoice: Ref<boolean> = computed(() => {
            return paymentsScheduleDocumentsTabList.value !== null && !paymentsUnpaidInvoicesDocumentsTabList.value;
        });

        const paymentsHistoryTabList: Ref<PolicyDetailsInvoicesContent | null> = computed(() => {
            return paymentsPaidDocuments.value.content;
        });

        const paymentsContentTab: Ref<typeof PaymentsContentTab> = computed(() => {
            return PaymentsContentTab;
        });

        const selectedInvoicesSum: Ref<string> = computed(() => {
            return String(
                selectedInvoices()
                    .map((invoice: Invoice): number => invoice.amount)
                    .reduce((partial: number, next: number): number => partial + next, 0)
                    .toFixed(2),
            );
        });

        const documentsCount: Ref<number> = computed(() => {
            return lateDocumentsCount.value || unpaidDocumentsCount.value;
        });

        const selectedInvoicesCount: Ref<number> = computed(() => {
            return selectedInvoices().length;
        });

        const showTabsContainer: Ref<boolean> = computed(() => {
            return lateDocumentsCount.value > 0 || paidDocumentsCount.value > 0 || unpaidDocumentsCount.value > 0;
        });

        const showNoInvoicesBlock: Ref<boolean> = computed(() => {
            return (
                (!showPaymentWidget.value || (isDataLoaded.value && !showTabsContainer.value)) &&
                currentTab.value !== PaymentsContentTab.Paid
            );
        });

        const showPaymentWidget: Ref<boolean> = computed(() => {
            return (
                (lateDocumentsCount.value > 0 || unpaidDocumentsCount.value > 0) &&
                currentTab.value !== PaymentsContentTab.Paid
            );
        });

        const lateDocumentsCount: Ref<number> = computed(() => {
            let result: number = 0;
            lateDocuments.value.forEach((block: InvoiceDocumentBlock): void => {
                result += block.documents.length;
            });

            return result;
        });

        const unpaidDocumentsCount: Ref<number> = computed(() => {
            let result: number = 0;
            unpaidDocuments.value.forEach((block: InvoiceDocumentBlock): void => {
                result += block.documents.length;
            });

            return result;
        });

        const paidDocumentsCount: Ref<number> = computed(() => {
            let result: number = 0;
            paidDocuments.value.forEach((block: InvoiceDocumentBlock): void => {
                result += block.documents.length;
            });

            return result;
        });

        const lateDocuments: Ref<InvoiceDocumentBlock[]> = computed(() => {
            return paymentsLateInvoicesDocuments.value.content.documentBlocks;
        });

        const unpaidDocuments: Ref<InvoiceDocumentBlock[]> = computed(() => {
            return paymentsUnpaidDocuments.value.content.documentBlocks;
        });

        const paidDocuments: Ref<InvoiceDocumentBlock[]> = computed(() => {
            return paymentsPaidDocuments.value.content.documentBlocks;
        });

        function init(): void {
            PopupService.getInstance().show(new OnePopup().withType().loading);
            applyTranslationType('dashboard');
            RequestService.getInstance()
                .get({ uri: Url.Ajax.oneDashboardPayments })
                .then((response: AxiosResponse): void => {
                    const invoices: DynamicDictionary = response.data.data.body;
                    buildDocumentsBlock(invoices.late, PolicyState.Late);
                    buildDocumentsBlock(invoices.unpaid, PolicyState.Unpaid);
                    buildDocumentsBlock(invoices.pending.concat(invoices.paid).flat(), PolicyState.Paid);
                    buildTabs();
                    setupForm();
                    PopupService.getInstance().hide();
                    isDataLoaded.value = true;
                });
        }

        function buildDocumentsBlock(blocks: InvoiceDocumentBlock[], policyState: PolicyState): void {
            const paymentsDocumentsBuilder: PolicyDetailsInvoicesBuilder = new PolicyDetailsInvoicesBuilder();
            paymentsDocumentsBuilder.withLanguage(language());
            blocks.forEach((block: InvoiceDocumentBlock, blockIndex: number): void => {
                paymentsDocumentsBuilder.startNewDocumentBlock(block.dateFrom);
                block.documents.forEach((document: Invoice, documentIndex: number): void => {
                    paymentsDocumentsBuilder
                        .startNewDocument()
                        .withInvoiceId(document.id)
                        .withInvoiceNumber(document.paymentNumber)
                        .withDocumentId(document.agreementId)
                        .withAgreementTypeIc(document.agreementTypeIc)
                        .withDocumentTitle(documentTitle(document))
                        .withDocumentAgreement(document.agreementNumber)
                        .withDocumentPolicyPeriod('unknown')
                        .withDocumentPayDate(payDate(document, policyState))
                        .withDocumentPayType(document.status)
                        .withDocumentAmount(invoiceAmount(document, policyState));
                    if (document.status === PolicyState.Late) {
                        paymentsDocumentsBuilder.withDocumentSelected(false);
                    } else if (
                        document.status === PolicyState.Unpaid &&
                        documentsCountInAllBlocks(lateDocuments.value) === 0 &&
                        blockIndex === 0 &&
                        documentIndex === 0
                    ) {
                        paymentsDocumentsBuilder.withDocumentSelected(false);
                    }
                    if (
                        (document.status === PolicyState.Unpaid || document.status === PolicyState.Late) &&
                        policyState === PolicyState.Paid
                    ) {
                        paymentsDocumentsBuilder.asPartialPayment();
                    }
                });
            });
            switch (policyState) {
                case PolicyState.Late:
                    paymentsLateInvoicesDocuments.value = paymentsDocumentsBuilder.build();
                    break;
                case PolicyState.Unpaid:
                    paymentsUnpaidDocuments.value = paymentsDocumentsBuilder.build();
                    break;
                default:
                    paymentsPaidDocuments.value = paymentsDocumentsBuilder.build();
            }
        }

        function setupForm(): void {
            const firstVisibleTab: InputOption | undefined = inputOptionsContentTabs.value.find(
                (tab: InputOption): boolean => tab.hidden === false,
            );
            const activeTab: string =
                typeof firstVisibleTab?.value !== 'undefined' ? (firstVisibleTab.value as string) : '';
            form.addField(new FormField('input-radio-tabs'));
            form.field('input-radio-tabs').patch(activeTab);
            form.setReady();
            formIsReady.value = true;
        }

        function onInvoiceClick(invoice: Invoice): Invoice {
            validateInvoicesSelection(invoice);

            return invoice;
        }

        function onSelectAllLateInvoicesClick(): void {
            if (currentTab.value === PaymentsContentTab.Unpaid) {
                resetUnpaidDocuments();
                selectAllLateDocuments();
            } else {
                if (allLateInvoicesAreSelected.value) {
                    resetAllLateDocuments();
                } else {
                    selectAllLateDocuments();
                }
            }
        }

        function onPolicyDetailsDocumentClick(document: Invoice): void {
            console.debug(document.agreementNumber, document.selected);
        }

        function onMakePaymentClick(bankLinkId: number): void {
            PopupService.getInstance().show(new OnePopup().withType().loading);
            const newPaymentForm: HTMLFormElement = paymentForm();
            const newPaymentDetails: DynamicDictionary = paymentDetails();
            newPaymentDetails.bank = bankLinkId;
            Object.keys(newPaymentDetails).forEach((key: string): void => {
                if (newPaymentDetails[key] !== '') {
                    const inputElement: HTMLInputElement = document.createElement('input');
                    inputElement.type = 'hidden';
                    inputElement.name = key;
                    inputElement.value = newPaymentDetails[key];
                    newPaymentForm.append(inputElement);
                }
            });
            $('body').append(newPaymentForm);
            newPaymentForm.submit();
        }

        function isVisibleContentTab(contentTab: string): boolean {
            return currentTab.value === contentTab;
        }

        function contentTabTitle(tab: PaymentsContentTab): string {
            const prefix: string = 'one_dashboard_invoices_';

            return oneDashboard.translated(prefix + String(tab).replaceAll('-', '_'));
        }

        function invoiceAmount(invoice: Invoice, policyState: PolicyState): number {
            let result: number = invoice.amount;
            switch (policyState) {
                case PolicyState.Paid:
                    if (isSet(invoice.acceptedCoveredAmount) && invoice.uncoveredAmount !== 0.0) {
                        result = invoice.acceptedCoveredAmount!;
                    }
                    break;
                case PolicyState.Unpaid:
                case PolicyState.Late:
                    if (isSet(invoice.acceptedCoveredAmount) && invoice.uncoveredAmount !== 0.0) {
                        result = invoice.uncoveredAmount;
                    }
                    break;
                default:
                    break;
            }

            return result;
        }

        function payDate(invoice: Invoice, policyState: PolicyState): string {
            let result: string = invoice.dueDate.date;
            switch (policyState) {
                case PolicyState.Paid:
                    if (
                        (isSet(invoice.acceptedCoveredAmount) && invoice.uncoveredAmount !== 0.0) ||
                        invoice.coverageDate
                    ) {
                        result = invoice.coverageDate!.date;
                    }
                    break;
                default:
                    break;
            }

            return OneDate.short(result);
        }

        function documentTitle(document: Invoice): string {
            return document.agreementType + ' - ' + document.objectName;
        }

        function buildTabs(): void {
            inputOptionsContentTabs.value.push(
                new InputOptionBuilder()
                    .setName(contentTabTitle(paymentsContentTab.value.Late))
                    .setValue(paymentsContentTab.value.Late)
                    .setCustom(documentsCountInAllBlocks(lateDocuments.value))
                    .setHidden(lateDocumentsCount.value === 0)
                    .build(),
            );
            inputOptionsContentTabs.value.push(
                new InputOptionBuilder()
                    .setName(contentTabTitle(paymentsContentTab.value.Unpaid))
                    .setValue(paymentsContentTab.value.Unpaid)
                    .setCustom(documentsCountInAllBlocks(unpaidDocuments.value.concat(lateDocuments.value).flat()))
                    .setHidden(unpaidDocumentsCount.value === 0)
                    .build(),
            );
            inputOptionsContentTabs.value.push(
                new InputOptionBuilder()
                    .setName(contentTabTitle(paymentsContentTab.value.Paid))
                    .setValue(paymentsContentTab.value.Paid)
                    .setCustom(documentsCountInAllBlocks(paidDocuments.value))
                    .setHidden(paidDocumentsCount.value === 0)
                    .build(),
            );
        }

        function documentsCountInAllBlocks(blocks: InvoiceDocumentBlock[]): number {
            let result: number = 0;
            blocks.forEach((block: InvoiceDocumentBlock): void => {
                result += block.documents.length;
            });

            return result;
        }

        function documentsSelectedCountInAllBlocks(blocks: InvoiceDocumentBlock[]): number {
            let result: number = 0;
            blocks.forEach((block: InvoiceDocumentBlock): void => {
                block.documents.forEach((document: Invoice): void => {
                    if (document.selected) {
                        result++;
                    }
                });
            });

            return result;
        }

        function documentsSelectedSumInAllBlocks(blocks: InvoiceDocumentBlock[]): number {
            let result: number = 0;
            blocks.forEach((block: InvoiceDocumentBlock): void => {
                block.documents.forEach((document: Invoice): void => {
                    if (document.selected) {
                        result += document.amount;
                    }
                });
            });

            return result;
        }

        function selectAllLateDocuments(): void {
            lateDocuments.value.forEach((block: InvoiceDocumentBlock): void => {
                block.documents.forEach((document: Invoice): void => {
                    document.selected = true;
                });
            });
        }

        function resetAllLateDocuments(): void {
            lateDocuments.value.forEach((block: InvoiceDocumentBlock, blockIndex: number): void => {
                block.documents.forEach((document: Invoice, documentIndex: number): void => {
                    document.selected = blockIndex === 0 && documentIndex === 0;
                });
            });
        }

        function selectUnpaidDocuments(): void {
            unpaidDocuments.value.forEach((block: InvoiceDocumentBlock): void => {
                block.documents.forEach((document: Invoice): void => {
                    document.selected = true;
                });
            });
        }

        function resetUnpaidDocuments(): void {
            unpaidDocuments.value.forEach((block: InvoiceDocumentBlock, blockIndex: number): void => {
                block.documents.forEach((document: Invoice, documentIndex: number): void => {
                    if (hasLateInvoices.value) {
                        document.selected = false;
                    } else {
                        document.selected = blockIndex === 0 && documentIndex === 0;
                    }
                });
            });
        }

        function paymentForm(): HTMLFormElement {
            const formElement: HTMLFormElement = document.createElement('form');
            formElement.action = new UrlBuilder()
                .withLanguage(Translations.getInstance().language)
                .withUri('/Policies/payInvoices')
                .build();
            formElement.method = Method.Post;

            return formElement;
        }

        function paymentDetails(): DynamicDictionary {
            // TODO @kvecvagars add card number for recurring payments
            return {
                total_amount: selectedInvoicesSum.value,
                pay_type: 'schedule',
                schedule_items: JSON.stringify(scheduleItems()),
                _token: csrfToken(),
                // card_number_part: this.cardNumberPart(),
            };
        }

        function selectedInvoices(): Invoice[] {
            return lateDocuments.value
                .map((documentBlock: InvoiceDocumentBlock): Invoice[] => documentBlock.documents)
                .flat()
                .concat(
                    unpaidDocuments.value
                        .map((documentBlock: InvoiceDocumentBlock): Invoice[] => documentBlock.documents)
                        .flat(),
                )
                .filter((invoice: Invoice): boolean => invoice.selected);
        }

        function scheduleItems(): DynamicDictionary[] {
            return selectedInvoices().map((invoice: Invoice): DynamicDictionary => {
                return {
                    id: invoice.id,
                    number: invoice.paymentNumber,
                    price: invoice.amount,
                    agreementId: invoice.agreementId,
                    agreementNumber: invoice.agreementNumber,
                    agreementTypeIc: invoice.agreementTypeIc,
                };
            });
        }

        function csrfToken(): string {
            return document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ?? '';
        }

        function validateInvoicesSelection(invoice: Invoice): void {
            invoice.selected = !invoice.selected;
            nextTick((): void => {
                updateDisabledStatus();
            });
        }

        function updateDisabledStatus(): void {
            tooltipMessage.value = '';
            resetDisabledStatus();
            if (selectedOfOneKindMaximumIsReached()) {
                disableAllPoliciesThatDoesntMatchToSelectedStack();
                tooltipMessage.value = translateForType('max_selected_payments_of_one_kind', type(), {
                    '%count%': SettingsService.getInstance().selectedPoliciesMaximum(),
                });
            } else {
                const healthCombineText: string = oneDashboard.translated('cant_combine_health_with_other');
                if (selectedInvoicesHasHealth()) {
                    disableAllExceptHealth();
                    tooltipMessage.value = healthCombineText;
                } else if (selectedInvoices().length > 0) {
                    tooltipMessage.value = healthCombineText;
                    disableHealth();
                }
            }
        }

        function selectedOfOneKindMaximumIsReached(): boolean {
            const maxSelectedOfOneKind: number = SettingsService.getInstance().selectedPoliciesMaximum();
            const selectedAgreements: Invoice[] = selectedInvoices();
            agreements.value = [];
            selectedAgreements.forEach((invoice: Invoice): void => {
                if (!agreements.value.includes(invoice.agreementNumber)) {
                    agreements.value.push(invoice.agreementNumber);
                }
            });

            return agreements.value.length >= maxSelectedOfOneKind;
        }

        function disableAllPoliciesThatDoesntMatchToSelectedStack(): void {
            lateDocuments.value.map((documentBlock: InvoiceDocumentBlock): void => {
                documentBlock.documents.map((document: Invoice): void => {
                    if (!agreements.value.includes(document.agreementNumber)) {
                        document.disabled = true;
                    }
                });
            });
            unpaidDocuments.value.map((documentBlock: InvoiceDocumentBlock): void => {
                documentBlock.documents.map((document: Invoice): void => {
                    if (!agreements.value.includes(document.agreementNumber)) {
                        document.disabled = true;
                    }
                });
            });
        }

        function disableAllExceptHealth(): void {
            const agreementTypeMap: AgreementTypeMap = new AgreementTypeMap();
            lateDocuments.value.map((documentBlock: InvoiceDocumentBlock): void => {
                documentBlock.documents.map((document: Invoice): void => {
                    document.disabled = !agreementTypeMap.isHealthAgreement(document.agreementTypeIc);
                });
            });
            unpaidDocuments.value.map((documentBlock: InvoiceDocumentBlock): void => {
                documentBlock.documents.map((document: Invoice): void => {
                    document.disabled = !agreementTypeMap.isHealthAgreement(document.agreementTypeIc);
                });
            });
        }

        function disableHealth(): void {
            const agreementTypeMap: AgreementTypeMap = new AgreementTypeMap();
            lateDocuments.value.map((documentBlock: InvoiceDocumentBlock): void => {
                documentBlock.documents.map((document: Invoice): void => {
                    document.disabled = agreementTypeMap.isHealthAgreement(document.agreementTypeIc);
                });
            });
            unpaidDocuments.value.map((documentBlock: InvoiceDocumentBlock): void => {
                documentBlock.documents.map((document: Invoice): void => {
                    document.disabled = agreementTypeMap.isHealthAgreement(document.agreementTypeIc);
                });
            });
        }

        function resetDisabledStatus(): void {
            lateDocuments.value.map((documentBlock: InvoiceDocumentBlock): void => {
                documentBlock.documents.map((document: Invoice): void => {
                    document.disabled = false;
                });
            });
            unpaidDocuments.value.map((documentBlock: InvoiceDocumentBlock): void => {
                documentBlock.documents.map((document: Invoice): void => {
                    document.disabled = false;
                });
            });
        }

        function selectedInvoicesHasHealth(): boolean {
            let result: boolean = false;
            const agreementTypeMap: AgreementTypeMap = new AgreementTypeMap();
            selectedInvoices().forEach((invoice: Invoice): void => {
                if (agreementTypeMap.isHealthAgreement(invoice.agreementTypeIc)) {
                    result = true;
                }
            });

            return result;
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                oneDashboard,
                init,
                setupForm,
                onInvoiceClick,
                onSelectAllLateInvoicesClick,
                onPolicyDetailsDocumentClick,
                documentTitle,
                buildTabs,
                isVisibleContentTab,
                onMakePaymentClick,
                documentsSelectedCountInAllBlocks,
                documentsSelectedSumInAllBlocks,
                selectUnpaidDocuments,
                inputOptionsContentTabs,
                tooltipMessage,
                isDataLoaded,
                showPaymentWidget,
                selectAllLateInvoicesText,
                selectAllUnpaidInvoicesText,
                allLateInvoicesAreSelected,
                allUnpaidInvoicesAreSelected,
                hasLateInvoices,
                inputOptionsTabs,
                currentTab,
                contentTitle,
                paymentsUnpaidInvoicesDocumentsTabList,
                paymentsScheduleDocumentsTabList,
                autoSelectFirstScheduleInvoice,
                paymentsHistoryTabList,
                paymentsContentTab,
                selectedInvoicesSum,
                documentsCount,
                selectedInvoicesCount,
                showTabsContainer,
                showNoInvoicesBlock,
                lateDocumentsCount,
                unpaidDocumentsCount,
                paidDocumentsCount,
                lateDocuments,
                unpaidDocuments,
                paidDocuments,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        this.oneDashboard.init();
        const subscription: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.init();
            subscription.unsubscribe();
        });
    },
});
</script>
