<script setup lang="ts">
import { onMounted, onUnmounted, PropType } from 'vue';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { useTranslate } from '@/Composables/Translate';
import FormField from '@/Assets/Libraries/Form/FormField';
import Form from '@/Assets/Libraries/Form/Form';
import TermsAndAgreements from '@/Interfaces/TermsAndAgreementsInterface';
import Validation from '@/Services/validation.service';
import AppInputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import TermsAndAgreementsTranslations from '@/Components/Widgets/TermsAndAgreements/Interfaces/TermsAndAgreementsTranslationsInterface';

const { translateForType } = useTranslate();

const props = defineProps({
    formField: {
        type: Object as PropType<FormField<boolean>>,
        default: () => new FormField(''),
    },
    translations: {
        type: Object as PropType<TermsAndAgreementsTranslations>,
        default: () => ({
            type: '',
            keys: {
                terms: '',
                agreeTerms: '',
            },
        }),
    },
});
const translationType: string = 'components';
const emit = defineEmits(['change']);
const form: Form<TermsAndAgreements> = new Form({ useValidationV2: true });
const formChanges = new Subject<void>();
let onSubmitAttemptSubscription: Subscription | undefined;
let formChangeSubscription: Subscription | undefined;

onMounted((): void => {
    setupForm();
    prepareFormField();
    setupSubscriptions();
});

onUnmounted((): void => {
    onSubmitAttemptSubscription?.unsubscribe();
    formChangeSubscription?.unsubscribe();
});

function setupForm(): void {
    form.addField(new FormField('agreeTerms', false, { required: Validation.required }));
    form.setReady();
}

function prepareFormField(): void {
    props.formField.addValidators({
        childrenAreValid: () => form.isValid(),
    });
}

function setupSubscriptions(): void {
    onSubmitAttemptSubscription = props.formField.onSubmitAttempt.subscribe(() => {
        form.submitAttempt();
    });

    formChangeSubscription = formChanges.pipe(debounceTime(1)).subscribe(() => {
        props.formField.setValue(form.field('agreeTerms').value);
        props.formField.markAsTouched();
        emit('change', props.formField.value);
    });
}

function translated(key: string): string {
    return translateForType(key, props.translations.type);
}

function applyValues(): void {
    formChanges.next();
}
</script>

<template>
    <div class="content-text grid-utility grid-columns-12">
        <p class="purchase-terms column-span-12" v-html="translated(props.translations.keys.terms)"></p>
        <hr class="line-break column-span-12" />
        <app-input-checkbox
            class="column-span-12 terms-checkbox"
            :form-field="form.field('agreeTerms')"
            @change="applyValues"
        >
            {{ translated(props.translations.keys.agreeTerms) }}
        </app-input-checkbox>
        <hr v-if="$slots['subscriptions']" class="line-break column-span-12" />
        <slot name="subscriptions"></slot>
    </div>
</template>
<style lang="scss" scoped>
.line-break {
    width: 100%;
    height: 1px;
    background-color: var(--component-color-border-default);
}

.purchase-terms {
    font-size: var(--font-size-nano);
}

.terms-checkbox {
    :deep(label) {
        color: var(--text-color-default);
        font-weight: 400;
    }
}
</style>
