import DrawerService from '@/Services/drawer.service';
import Translations from '@/Services/translations.service';
import UserCredentials from '@/Interfaces/user.credentials.interface';
import ClaimDetailsContentTabsBuilder from '@/Components/Claims/ClaimDetails/Builders/ClaimDetailsContentTabsBuilder';
import ClaimOverviewBuilder from '@/Components/Claims/ClaimOverview/Builders/ClaimOverviewBuilder';
import ClaimDetailsContentBuilder from '@/Components/Claims/ClaimDetails/Builders/ClaimDetailsContentBuilder';
import ClaimDetailsTabs from '@/Components/Claims/ClaimDetails/Enums/ClaimDetailsTabsEnum';
import ClaimDetailsDocumentsBuilder from '@/Components/Claims/ClaimDocuments/Builders/ClaimDetailsDocumentsBuilder';
import { useDefine } from '@/Composables/Define';
import Claim from '@/Components/Claims/ClaimBlock/Interfaces/ClaimInterface';
import { useTranslate } from '@/Composables/Translate';
import TwoWayCommunicationsBlock from '@/Apps/TwoWayCommunication/Interfaces/TwoWayCommunicationsBlockInterface';
import DecisionBuilder from '@/Components/Claims/ClaimOverview/Builders/DecisionBuilder';
import Decision from '@/Components/Claims/ClaimOverview/Interfaces/Decision';

const { isSet } = useDefine();

export default class ClaimPopup {
    private contentTabsBuilder: ClaimDetailsContentTabsBuilder = new ClaimDetailsContentTabsBuilder();
    private drawerOverviewBuilder: ClaimOverviewBuilder = new ClaimOverviewBuilder();
    private drawerDocumentsBuilder: ClaimDetailsDocumentsBuilder = new ClaimDetailsDocumentsBuilder();
    private translations: Translations = Translations.getInstance();
    private user: UserCredentials;
    private readonly claim!: Claim;
    private readonly translationType: string;
    private decisions?: TwoWayCommunicationsBlock[];

    public constructor(
        claim: Claim,
        translationType: string,
        user: UserCredentials,
        decisions?: TwoWayCommunicationsBlock[],
    ) {
        this.claim = claim;
        this.translationType = translationType;
        this.user = user;
        this.decisions = decisions;
    }

    public buildDetails(): void {
        this.buildClaim();
        this.buildTabs();
    }

    public showDetails(): void {
        this.buildClaim();
        this.buildTabs();
        this.buildDrawer();
    }

    public contentBuilder(): ClaimDetailsContentBuilder {
        return new ClaimDetailsContentBuilder()
            .withTranslationsType(this.translationType)
            .withType(this.claim.claimType)
            .withDescriptionTitle(this.claim.title)
            .withTabs(this.contentTabsBuilder.build())
            .withActiveTab(0);
    }

    public applyDecisions(value: TwoWayCommunicationsBlock[]): ClaimPopup {
        this.decisions = value;

        return this;
    }

    private buildClaim(): void {
        this.buildClaimOverview();
    }

    private buildClaimOverview(): void {
        this.drawerOverviewBuilder
            .withUploadBlockEnabled()
            .withContactsBlockEnabled()
            .withUploadBlockPolicyId(this.claim.policyId)
            .withUploadBlockPolicyNumber(this.claim.policyNumber)
            .withUploadBlockUploaderPolicyType(this.claim.policyType)
            .withUploadBlockUploaderClaimNumber(this.claim.claimNumber)
            .withUploadBlockUploaderClaimId(this.claim.claimId)
            .withDetailsBlockClaimType(this.claim.claimType)
            .withDetailsBlockClaimNumber(this.claim.claimNumber)
            .withDetailsBlockSubmitDate(this.claim.submitDate)
            .withDetailsBlockPolicyNumber(this.claim.policyNumber)
            .withDetailsBlockCompensationSum(this.claim.sumApproved ?? '')
            .withDetailsBlockCompensationDate(this.claim.compensationDate ?? '')
            .withDetailsBlockCloseDate(this.claim.closeDate ?? '');
        if (this.decisions !== undefined) {
            const decisionBlockData: Decision[] = [];
            this.decisions.forEach((decision: TwoWayCommunicationsBlock, index: number): void => {
                const decisionBuilder: DecisionBuilder = new DecisionBuilder()
                    .withBlockTitle(useTranslate().translate('claim_decision'))
                    .withBlockDescription(decision.body)
                    .withDocumentTitle(useTranslate().translate('decision_documents'))
                    .withCreatedAt(decision.createdAt)
                    .withDocuments(decision.attachments);
                if (index === 0) {
                    decisionBuilder.withDecisionOpened();
                }
                decisionBlockData.push(decisionBuilder.build());
            });
            this.drawerOverviewBuilder.withDecisionBlockEnabled().withDecisionBlockData(decisionBlockData);
        }
        /*TODO: setup real data from IRIS
        const documents: Attachment[] = [
            {
                id: '0',
                name: 'Health claim decision',
                type: 'pdf',
                downloadLink: 'some/url/to.pdf',
            },
            {
                id: '1',
                name: 'Other document',
                type: 'pdf',
                downloadLink: 'some/url/to.pdf',
            }
        ];
        this.drawerOverviewBuilder.withStatusBlockEnabled()
            .withStatusBlockProgressSteps(['Claim submitted', 'Documents awaited', 'In claim handling', 'Decision done'])
            .withStatusBlockCurrentStep(0)
            .withDecisionBlockEnabled()
            .withDecisionBlockTitle(useTranslate().translate('claim_decision'))
            .withDecisionBlockContent('Pre formatted claim decision content...')
            .withDecisionBlockDocumentsTitle(useTranslate().translate('decision_documents'))
            .withDecisionBlockDocuments(documents);
        */
    }

    private buildTabs(): void {
        this.contentTabsBuilder
            .startNewTab(ClaimDetailsTabs.Overview)
            .withTitle(this.translations.localized(ClaimDetailsTabs.Overview, this.translationType))
            .withModule(this.drawerOverviewBuilder.build());
        if (isSet(this.claim.attachments) && this.claim.attachments.length > 0) {
            this.contentTabsBuilder
                .startNewTab(ClaimDetailsTabs.Documents)
                .withTitle(this.translations.localized(ClaimDetailsTabs.Documents, this.translationType))
                .withModule(this.drawerDocumentsBuilder.build());
        }
        //DETAILS
        //COMMUNICATIONS
    }

    private buildDrawer(): void {
        DrawerService.getInstance().withComponentContent(this.contentBuilder().build()).show();
    }
}
