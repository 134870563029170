<script setup lang="ts">
import {
    computed,
    markRaw,
    onMounted,
    reactive,
    ref,
    Ref,
    shallowReactive,
    ShallowUnwrapRef,
    UnwrapNestedRefs,
} from 'vue';
import Form from '@/Assets/Libraries/Form/Form';
import {
    TravelChangesForms,
    WhatHappenedToTransportFields,
    HasCarriersTripCancellationConfirmationFields,
    ReasonOfTripCancellationFields,
    HowWasTripPlannedFields,
    HadOtherInsuredPersonsWhoDidNotGoFields,
    HadIndemnificationOfLosesFields,
} from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentDetails/TravelChangesForms';
import { TranslateReplaceParts, useTranslate } from '@/Composables/Translate';
import Option from '@/Apps/ClaimsTravel/Interfaces/Option';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { FormNames as TravelChangesFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/TravelChanges/FormNames';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import { FieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/TravelChanges/FieldNames';
import FormField from '@/Assets/Libraries/Form/FormField';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import { useClaimsTravelHtml } from '@/Apps/ClaimsTravel/Composables/ClaimsTravelHtml';
import ClaimsTravelFormFields from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelFormFields';
import ClaimsTravelObject from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelObject';
import OneBase from '@/Interfaces/OneBaseInterface';
import OneBaseService from '@/Services/OneBaseService';
import { useDefine } from '@/Composables/Define';
import Sanitizer from '@/Services/sanitizer.service';
import GuardsService from '@/Apps/ClaimsTravel/Services/GuardsService';
import ClaimsTravelStepUid from '@/Apps/ClaimsTravel/Enums/ClaimsTravelStepUid';
import { Router, useRouter } from 'vue-router';
import AppSvg from '@/Components/Other/Svg/Svg.vue';
import AppButtonPrimary from '@/Components/Buttons/ButtonPrimary/ButtonPrimary.vue';
import { Forms as AccidentDetailsForms } from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentDetails/Forms';
import AppRepeatableLastActionItem from '@/Components/Other/Repeatable/RepeatableLastActionItem.vue';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppRepeatable from '@/Components/Other/Repeatable/Repeatable.vue';
import Validation from '@/Services/validation.service';
import AppWhiteboard from '@/Components/Containers/Whiteboard/Whiteboard.vue';
import AppOptionsSmartList from '@/Components/Options/OptionsSmartList/OptionsSmartList.vue';
import { useFormHelper } from '@/Composables/FormHelper';
import { StoredPerson } from '@/Apps/ClaimsTravel/Interfaces/StoredObjects';
import AppNotification from '@/Components/Widgets/Notification/Notification.vue';
import AppInputError from '@/Components/Inputs/InputError/InputError.vue';

interface PersonForm {
    name: string;
}

interface Person {
    id: string;
    form: Form<PersonForm>;
}

const props = defineProps({
    subType: { type: String, required: true },
});

const emit = defineEmits<{
    (event: 'completed'): void;
}>();

const { translateForType, applyTranslationType, type, translate } = useTranslate();
const { submitAttemptMultiple, isMultipleFormsValid } = useFormHelper();
const router: Router = useRouter();

const claimsTravelService: ClaimsTravelService = ClaimsTravelService.getInstance();
const base: OneBase = OneBaseService.getInstance();

type FormNames = `${TravelChangesFormNames}`;
const Step: number = 2;

const persons: Ref<Person[]> = ref([]);

const hintIconHtml =
    '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M6.86001 3.07347L1.21335 12.5001C1.09693 12.7018 1.03533 12.9303 1.03467 13.1631C1.03402 13.396 1.09434 13.6249 1.20963 13.8272C1.32492 14.0294 1.49116 14.198 1.69182 14.316C1.89247 14.4341 2.12055 14.4976 2.35335 14.5001H13.6467C13.8795 14.4976 14.1076 14.4341 14.3082 14.316C14.5089 14.198 14.6751 14.0294 14.7904 13.8272C14.9057 13.6249 14.966 13.396 14.9654 13.1631C14.9647 12.9303 14.9031 12.7018 14.7867 12.5001L9.14001 3.07347C9.02117 2.87754 8.85383 2.71555 8.65414 2.60313C8.45446 2.4907 8.22917 2.43164 8.00001 2.43164C7.77086 2.43164 7.54557 2.4907 7.34588 2.60313C7.1462 2.71555 6.97886 2.87754 6.86001 3.07347V3.07347Z" stroke="#1D1F23" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M8 6.5V9.16667" stroke="#1D1F23" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M8 11.8335H8.00667" stroke="#1D1F23" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>' +
    translated('leave_field_empty_if_no_expenses');

const forms: ShallowUnwrapRef<{ [FormKey in keyof TravelChangesForms]: Form<TravelChangesForms[FormKey]> }> =
    shallowReactive({}) as ShallowUnwrapRef<{
        [FormKey in keyof TravelChangesForms]: Form<TravelChangesForms[FormKey]>;
    }>;
const inputOptions: UnwrapNestedRefs<Record<FormNames, Option>> = reactive({}) as UnwrapNestedRefs<
    Record<FormNames, Option>
>;

const selectedPolicy: Ref<ClaimsTravelObject> = computed(() => {
    return claimsTravelService.storageFields.personInsuredObjects.find(
        (object) => object.id === claimsTravelService.fields.policy?.policy.object,
    )!;
});

const isTravelChanges: Ref<boolean> = computed(() => {
    return claimsTravelService.fields.whatHappened?.whatHappened.selected === 'transport_changes';
});

const isTripInterruption: Ref<boolean> = computed(() => {
    return claimsTravelService.fields.whatHappened?.whatHappened.selected === 'travel_interruption';
});

const isTransportDelayed: Ref<boolean> = computed(() => {
    return (
        forms[TravelChangesFormNames.WhatHappenedToTransport].field(FieldNames.WhatHappenedToTransport).value
            .selected === 'delayed'
    );
});

const hasCarriersDelayConfirmation: Ref<boolean> = computed(() => {
    return (
        forms[TravelChangesFormNames.HasCarriersTripCancellationConfirmation].field(
            FieldNames.HasCarriersTripCancellationConfirmation,
        ).value.selected === 'yes'
    );
});

const isOtherPersonInputVisible: Ref<boolean> = computed(() => {
    const isOther: boolean =
        claimsTravelService.fields!.sufferingPerson!.sufferingPerson.selected !==
            OneBaseService.getInstance().currentUser.value.personId && otherInsuredPersonsList().length > 0;

    return isOther || (!isOther && otherInsuredPersonsList().length === 0);
});

function otherInsuredPersonsList(): ClaimsTravelObject[] {
    return claimsTravelService.storageFields.allInsuredObjects.filter((object: ClaimsTravelObject) => {
        return (
            object.policyNumber === selectedPolicy.value.policyNumber &&
            object.objectAttribute.valueid !== selectedPolicy.value.objectAttribute.valueid
        );
    });
}

const riskIc: Ref<string> = computed(() => {
    return claimsTravelService.travelTypes.find(
        (travelType) =>
            travelType.travelSubtype.toLowerCase() === claimsTravelService.fields.whatHappened?.whatHappened.selected,
    )!.travelTypeRiskIc;
});

const riskLimit: Ref<number> = computed(() => {
    return Number(selectedPolicy.value.risks.find((risk) => risk.agrrisk === riskIc.value)!.limit!);
});

function preparePanels(): void {
    Object.values(TravelChangesFormNames).forEach((panelName: FormNames) => {
        inputOptions[panelName] = reactive(
            new (class implements Option {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean =
                    claimsTravelService.storageFields.formVisibility[panelName as keyof AccidentDetailsForms] ?? false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            })(),
        );
    });

    buildWhatHappenedToTransportOptions();
    buildHasCarriersTripCancellationConfirmation();

    // second step
    buildReasonOfTripCancellation();
    buildHowWasTripPlanned();
    buildHadOtherInsuredPersonsWhoDidNotGo();
    buildHadIndemnificationOfLoses();
}

function buildWhatHappenedToTransportOptions(): void {
    inputOptions[TravelChangesFormNames.WhatHappenedToTransport].options = [
        new InputOptionBuilder<string>().setValue('delayed').setName(translated('transport_delayed')).build(),
        new InputOptionBuilder<string>()
            .setValue('other_changes')
            .setName(translated('transport_other_changes'))
            .build(),
    ];
}

function buildHasCarriersTripCancellationConfirmation(): void {
    inputOptions[TravelChangesFormNames.HasCarriersTripCancellationConfirmation].options = [
        new InputOptionBuilder<string>().setValue('yes').setName(translated('yes')).build(),
        new InputOptionBuilder<string>().setValue('no').setName(translated('no')).build(),
    ];
}

function buildReasonOfTripCancellation(): void {
    claimsTravelService.tripInterruptionReasons.forEach((reason: string) => {
        inputOptions[TravelChangesFormNames.ReasonOfTripCancellation].options.push(
            new InputOptionBuilder().setValue(reason).setName(translated(reason)).build(),
        );
    });
}

function buildHowWasTripPlanned(): void {
    inputOptions[TravelChangesFormNames.HowWasTripPlanned].options = [
        new InputOptionBuilder().setValue('travel_agency').setName(translated('travel_agency')).build(),
        new InputOptionBuilder().setValue('independently').setName(translated('independently')).build(),
    ];
}

function buildHadOtherInsuredPersonsWhoDidNotGo(): void {
    inputOptions[TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo].options = [
        new InputOptionBuilder().setValue('yes').setName(translated('yes')).build(),
        new InputOptionBuilder().setValue('no').setName(translated('no')).build(),
    ];
}

function buildOtherInsuredPersonsOptions(): InputOption[] {
    const otherInsuredPersons = claimsTravelService.storageFields.allInsuredObjects.filter((object) => {
        return (
            object.policyNumber === selectedPolicy.value.policyNumber &&
            object.objectAttribute.valueid !== selectedPolicy.value.objectAttribute.valueid
        );
    });
    const options: InputOption[] = [];
    otherInsuredPersons.forEach((person) => {
        options.push(new InputOptionBuilder().setValue(person.name).setName(person.name).build());
    });

    return options;
}

function buildHadIndemnificationOfLoses(): void {
    inputOptions[TravelChangesFormNames.HadIndemnificationOfLoses].options = [
        new InputOptionBuilder<string>().setValue('yes').setName(translated('yes')).build(),
        new InputOptionBuilder<string>().setValue('no').setName(translated('no')).build(),
    ];
}

function isPanelVisible(panel: FormNames): boolean {
    return inputOptions[panel].visible;
}

function setupForms(): void {
    const requiredInterruptionOptionValidator = {
        required: (value: { selected?: string }) =>
            useDefine().isSet(value?.selected) && value.selected !== '' && isTripInterruption.value,
    };
    const requiredChangesOptionValidator = {
        required: (value: { selected?: string }) =>
            useDefine().isSet(value?.selected) && value.selected !== '' && isTravelChanges.value,
    };
    const otherPersonsRepeatableRequired = {
        required: (value: { selected?: string }) =>
            isTripInterruption.value &&
            forms[TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo].field(
                FieldNames.HadOtherInsuredPersonsWhoDidNotGo,
            ).value.selected === 'yes' &&
            isOtherPersonInputVisible.value
                ? persons.value.length > 0
                : true,
    };
    const otherPersonsRequired = {
        required: (value: { selected?: string }) =>
            isTripInterruption.value &&
            forms[TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo].field(
                FieldNames.HadOtherInsuredPersonsWhoDidNotGo,
            ).value.selected === 'yes' &&
            !isOtherPersonInputVisible.value
                ? useDefine().isSet(value?.selected) && value.selected !== ''
                : true,
    };
    const losesRequired = {
        required: (value: string) =>
            isTripInterruption.value &&
            forms[TravelChangesFormNames.HadIndemnificationOfLoses].field(FieldNames.HadIndemnificationOfLoses).value
                .selected === 'yes'
                ? useDefine().isSet(value) && value !== ''
                : true,
    };
    const hoursRequired = {
        required: (value: { selected?: string }) =>
            isTravelChanges.value &&
            forms[TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo].field(
                FieldNames.HadOtherInsuredPersonsWhoDidNotGo,
            ).value.selected === 'delayed'
                ? useDefine().isSet(value?.selected) && value.selected !== ''
                : true,
    };

    const whatHappenedToTransportForm: Form<WhatHappenedToTransportFields> = new Form({ useValidationV2: true });
    whatHappenedToTransportForm.addField(
        new FormField(FieldNames.WhatHappenedToTransport, '', requiredChangesOptionValidator),
    );
    forms[TravelChangesFormNames.WhatHappenedToTransport] = whatHappenedToTransportForm;

    const hasCarriersTripCancellationConfirmationForm: Form<HasCarriersTripCancellationConfirmationFields> = new Form({
        useValidationV2: true,
    });
    hasCarriersTripCancellationConfirmationForm.addField(
        new FormField(FieldNames.HasCarriersTripCancellationConfirmation, '', requiredChangesOptionValidator),
    );
    hasCarriersTripCancellationConfirmationForm.addField(
        new FormField(FieldNames.HoursDelayed, '', hoursRequired, Sanitizer.cleanInputInteger),
    );
    hasCarriersTripCancellationConfirmationForm.addField(
        new FormField(
            FieldNames.TotalExpensesBecauseOfDelay,
            '',
            {
                inRange: (value: string) => value === '' || Validation.inRange(value, 0, riskLimit.value),
            },
            Sanitizer.cleanNumber,
        ),
    );
    forms[TravelChangesFormNames.HasCarriersTripCancellationConfirmation] = hasCarriersTripCancellationConfirmationForm;

    const reasonOfTripCancellationForm: Form<ReasonOfTripCancellationFields> = new Form({ useValidationV2: true });
    reasonOfTripCancellationForm.addField(
        new FormField(FieldNames.ReasonOfTripCancellation, '', requiredInterruptionOptionValidator),
    );
    forms[TravelChangesFormNames.ReasonOfTripCancellation] = reasonOfTripCancellationForm;

    const howWasTripPlannedForm: Form<HowWasTripPlannedFields> = new Form({ useValidationV2: true });
    howWasTripPlannedForm.addField(
        new FormField(FieldNames.HowWasTripPlanned, '', requiredInterruptionOptionValidator),
    );
    forms[TravelChangesFormNames.HowWasTripPlanned] = howWasTripPlannedForm;

    const hadOtherInsuredPersonsWhoDidNotGoFrom: Form<HadOtherInsuredPersonsWhoDidNotGoFields> = new Form({
        useValidationV2: true,
    });
    hadOtherInsuredPersonsWhoDidNotGoFrom.addField(
        new FormField(FieldNames.HadOtherInsuredPersonsWhoDidNotGo, '', requiredInterruptionOptionValidator),
    );
    hadOtherInsuredPersonsWhoDidNotGoFrom.addField(
        new FormField(FieldNames.OtherInsuredPersonsWhoDidNotGo, '', otherPersonsRepeatableRequired),
    );
    hadOtherInsuredPersonsWhoDidNotGoFrom.addField(
        new FormField(FieldNames.OtherInsuredPersonsFromPolicy, '', otherPersonsRequired),
    );
    forms[TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo] = hadOtherInsuredPersonsWhoDidNotGoFrom;

    const hadIndemnificationOfLosesForm: Form<HadIndemnificationOfLosesFields> = new Form({ useValidationV2: true });
    hadIndemnificationOfLosesForm.addField(
        new FormField(FieldNames.HadIndemnificationOfLoses, '', requiredInterruptionOptionValidator),
    );
    hadIndemnificationOfLosesForm.addField(
        new FormField(FieldNames.SumIndemnified, '', losesRequired, Sanitizer.cleanNumber),
    );
    hadIndemnificationOfLosesForm.addField(
        new FormField(FieldNames.TripCancellationRelatedLoses, '', losesRequired, Sanitizer.cleanNumber),
    );
    forms[TravelChangesFormNames.HadIndemnificationOfLoses] = hadIndemnificationOfLosesForm;
}

function firstFormName(): string {
    return isTravelChanges.value
        ? TravelChangesFormNames.WhatHappenedToTransport
        : TravelChangesFormNames.ReasonOfTripCancellation;
}

function scrollToLastForm(): void {
    const lastForm = Object.keys(inputOptions).findLast((form) => inputOptions[form as FormNames].visible);

    useClaimsTravelHtml().scrollToPanel(lastForm ? lastForm : firstFormName());
}

function clearForms(): void {
    Object.keys(forms).forEach((form) => {
        claimsTravelService.storageFields.formVisibility[form as keyof ClaimsTravelFormFields] = false;
        clearForm(form as FormNames);
    });
}

function clearFormsAhead(currentForm: FormNames): void {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);
    const nextFormKeys: FormNames[] = Object.keys(forms).slice(
        currentFormIndex + 1,
        Object.keys(forms).length,
    ) as FormNames[];
    nextFormKeys.forEach((formName: FormNames) => {
        clearForm(formName);
        inputOptions[formName].visible = false;
        claimsTravelService.storageFields.formVisibility[formName] = false;
    });
}

function clearForm(formName: FormNames): void {
    const form = forms[formName];
    switch (formName) {
        case TravelChangesFormNames.WhatHappenedToTransport: {
            const whatHappenedToTransportForm = form as Form<WhatHappenedToTransportFields>;
            whatHappenedToTransportForm.field(FieldNames.WhatHappenedToTransport).setValue({ selected: '', extra: '' });
            break;
        }
        case TravelChangesFormNames.HasCarriersTripCancellationConfirmation: {
            const hasCarriersTripCancellationConfirmationForm =
                form as Form<HasCarriersTripCancellationConfirmationFields>;
            hasCarriersTripCancellationConfirmationForm
                .field(FieldNames.HasCarriersTripCancellationConfirmation)
                .setValue({ selected: '', extra: '' });
            hasCarriersTripCancellationConfirmationForm.field(FieldNames.HoursDelayed).setValue('');
            hasCarriersTripCancellationConfirmationForm.field(FieldNames.TotalExpensesBecauseOfDelay).setValue('');
            break;
        }
        case TravelChangesFormNames.ReasonOfTripCancellation: {
            const reasonOfTripCancellationForm = form as Form<ReasonOfTripCancellationFields>;
            reasonOfTripCancellationForm
                .field(FieldNames.ReasonOfTripCancellation)
                .setValue({ selected: '', extra: '' });
            break;
        }
        case TravelChangesFormNames.HowWasTripPlanned: {
            const howTripWasPlannedForm = form as Form<HowWasTripPlannedFields>;
            howTripWasPlannedForm.field(FieldNames.HowWasTripPlanned).setValue({ selected: '', extra: '' });
            break;
        }
        case TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo: {
            const hadOtherInsuredPersonsWhoDidNotGoForm = form as Form<HadOtherInsuredPersonsWhoDidNotGoFields>;
            hadOtherInsuredPersonsWhoDidNotGoForm
                .field(FieldNames.HadOtherInsuredPersonsWhoDidNotGo)
                .setValue({ selected: '', extra: '' });
            hadOtherInsuredPersonsWhoDidNotGoForm.field(FieldNames.OtherInsuredPersonsWhoDidNotGo).setValue([]);
            hadOtherInsuredPersonsWhoDidNotGoForm
                .field(FieldNames.OtherInsuredPersonsFromPolicy)
                .setValue({ selected: '', extra: '' });
            break;
        }
        case TravelChangesFormNames.HadIndemnificationOfLoses: {
            const hadOtherInsuredPersonsWhoDidNotGoForm = form as Form<HadIndemnificationOfLosesFields>;
            hadOtherInsuredPersonsWhoDidNotGoForm
                .field(FieldNames.HadIndemnificationOfLoses)
                .setValue({ selected: '', extra: '' });
            hadOtherInsuredPersonsWhoDidNotGoForm.field(FieldNames.SumIndemnified).setValue('');
            hadOtherInsuredPersonsWhoDidNotGoForm.field(FieldNames.TripCancellationRelatedLoses).setValue('');
            break;
        }
    }

    storeForm(formName);
}

function onProceedClick(value: FormNames, nextFormName: FormNames | null = null): void {
    forms[value].submitAttempt().then(() => {
        if (forms[value].isValid()) {
            proceedToNextForm(value, nextFormName);
        }
    });
}

function onProceedOtherPersons(): void {
    if (isOtherPersonInputVisible.value) {
        const personForms: Form<PersonForm>[] = persons.value.map((person: Person): Form<PersonForm> => person.form);
        submitAttemptMultiple(personForms).then(() => {
            if (isMultipleFormsValid(personForms)) {
                claimsTravelService.fields.hadOtherInsuredPersonsWhoDidNotGo!.otherInsuredPersonsWhoDidNotGo =
                    persons.value.map((person: Person): StoredPerson => {
                        return {
                            formName: person.form.name,
                            name: person.form.field('name').value,
                        };
                    });
                onProceedClick(TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo);
            }
        });
    } else {
        onProceedClick(TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo);
    }
}

function proceedToNextStep(name: FormNames): void {
    const form = forms[name];
    form.submitAttempt().then(() => {
        if (form.isValid()) {
            GuardsService.getInstance().applyStepValidity(Step, true);
            router.push({ name: ClaimsTravelStepUid.UploadFiles });
        }
    });
}

function proceedToNextForm(formName: FormNames, nextFormName: FormNames | null = null): void {
    const nextPanel = nextFormName !== null ? nextFormName : findNextFormName(formName);
    if (nextPanel) {
        inputOptions[nextPanel].visible = true;
        claimsTravelService.storageFields.formVisibility[nextPanel] = true;
        useClaimsTravelHtml().scrollToPanel(nextPanel);
    }
}

function findNextFormName(currentForm: FormNames): FormNames | undefined {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);

    return Object.keys(forms).at(currentFormIndex + 1) as FormNames | undefined;
}

function markFormsAsReady(): void {
    Object.values(forms).forEach((form) => form.setReady());
}

function translated(stringId: string, replaceParts?: TranslateReplaceParts): string {
    return translateForType(stringId, type(), replaceParts);
}

function storeForm(formName: FormNames): void {
    return claimsTravelService.storeForm(formName as keyof ClaimsTravelFormFields, forms[formName]);
}

function isValueSelected(field: FormField): boolean {
    return field.value.selected !== '';
}

function isActionsVisible(form: FormNames): boolean {
    const next = findNextFormName(form);

    return next !== undefined ? !inputOptions[next].visible : false;
}

function onFormInputChange(formName: FormNames): void {
    clearFormsAhead(formName);
}

function createPersonFactory(id: string) {
    const form: Form<PersonForm> = new Form(id, { useValidationV2: true });
    form.addField(new FormField('name').addValidators({ required: Validation.required }));
    form.setReady();
    return { id: id, form: markRaw(form) };
}

function onRemovePerson(item: Person) {
    item.form.destroy();
}

function restorePersons(): void {
    if (
        isOtherPersonInputVisible.value &&
        Array.isArray(claimsTravelService.fields.hadOtherInsuredPersonsWhoDidNotGo?.otherInsuredPersonsWhoDidNotGo)
    ) {
        persons.value = (
            claimsTravelService.fields.hadOtherInsuredPersonsWhoDidNotGo?.otherInsuredPersonsWhoDidNotGo || []
        ).map((storedPerson: StoredPerson): Person => {
            const person: Person = createPersonFactory(storedPerson.formName);
            person.form.restoreValues({
                name: storedPerson.name,
            });

            return person;
        });
    }
}

onMounted(() => {
    applyTranslationType(ClaimsTravelService.translationType);
    setupForms();
    claimsTravelService.restoreForms(forms);
    restorePersons();
    markFormsAsReady();
    preparePanels();
});

defineExpose({
    clearForms,
    scrollToLastForm,
    forms,
    claimsTravelService,
});
</script>

<template>
    <app-custom-form
        v-if="forms[TravelChangesFormNames.WhatHappenedToTransport]?.isReady() && isTravelChanges"
        :form="forms[TravelChangesFormNames.WhatHappenedToTransport]"
        class="form"
        @change="storeForm(TravelChangesFormNames.WhatHappenedToTransport)"
    >
        <app-whiteboard
            :data-type="'whiteboard-2'"
            :data-scroll="TravelChangesFormNames.WhatHappenedToTransport"
            :title="translated('what_happened_to_transport')"
        >
            <div class="inputs row">
                <div class="input-radio-container row mobile-column">
                    <app-options-smart-list
                        :form-field="
                            forms[TravelChangesFormNames.WhatHappenedToTransport].field(
                                FieldNames.WhatHappenedToTransport,
                            )
                        "
                        :options="inputOptions[TravelChangesFormNames.WhatHappenedToTransport].options"
                        :option-class="'filled'"
                        :type="'radio'"
                        :show-error-borders="true"
                        @change="onFormInputChange(TravelChangesFormNames.WhatHappenedToTransport)"
                    >
                    </app-options-smart-list>
                </div>
            </div>
            <app-button-primary
                v-if="isActionsVisible(TravelChangesFormNames.WhatHappenedToTransport)"
                @click="onProceedClick(TravelChangesFormNames.WhatHappenedToTransport)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="
            forms[TravelChangesFormNames.HasCarriersTripCancellationConfirmation]?.isReady() &&
            isTravelChanges &&
            isPanelVisible(TravelChangesFormNames.HasCarriersTripCancellationConfirmation)
        "
        :form="forms[TravelChangesFormNames.HasCarriersTripCancellationConfirmation]"
        class="form"
        @change="storeForm(TravelChangesFormNames.HasCarriersTripCancellationConfirmation)"
    >
        <app-whiteboard
            :data-type="'whiteboard-3'"
            :data-scroll="TravelChangesFormNames.HasCarriersTripCancellationConfirmation"
            :title="translated('carriers_trip_cancellation_confirmation')"
        >
            <div class="inputs">
                <div class="input-block-full-width">
                    <div class="input-radio-container column">
                        <app-options-smart-list
                            :form-field="
                                forms[TravelChangesFormNames.HasCarriersTripCancellationConfirmation].field(
                                    FieldNames.HasCarriersTripCancellationConfirmation,
                                )
                            "
                            :options="
                                inputOptions[TravelChangesFormNames.HasCarriersTripCancellationConfirmation].options
                            "
                            :type="'radio'"
                            :option-class="'filled'"
                            @change="onFormInputChange(TravelChangesFormNames.HasCarriersTripCancellationConfirmation)"
                        ></app-options-smart-list>
                        <div
                            v-if="
                                !hasCarriersDelayConfirmation &&
                                isValueSelected(
                                    forms[TravelChangesFormNames.HasCarriersTripCancellationConfirmation].field(
                                        FieldNames.HasCarriersTripCancellationConfirmation,
                                    ),
                                )
                            "
                            class="info-text"
                        >
                            <app-notification
                                :body="translated('indemnity_note')"
                                :with-close="false"
                                :with-icon="true"
                                :with-title="false"
                            >
                            </app-notification>
                        </div>
                    </div>
                </div>
                <div
                    v-if="
                        isValueSelected(
                            forms[TravelChangesFormNames.HasCarriersTripCancellationConfirmation].field(
                                FieldNames.HasCarriersTripCancellationConfirmation,
                            ),
                        ) && isTravelChanges
                    "
                    class="input-texts-container"
                    :class="{ 'full-width mobile-column': isTransportDelayed }"
                >
                    <div v-if="isTransportDelayed" class="with-title">
                        <h5 class="title">{{ translated('how_many_hours_delayed') }}</h5>
                        <app-input-text
                            :inside-label="translated('hours')"
                            :inside-label-class="'clean'"
                            :placeholder="translated('enter_hours')"
                            :form-field="
                                forms[TravelChangesFormNames.HasCarriersTripCancellationConfirmation].field(
                                    FieldNames.HoursDelayed,
                                )
                            "
                        >
                        </app-input-text>
                    </div>
                    <div class="with-title">
                        <h5 class="title">{{ translated('total_delay_expenses') }}</h5>
                        <app-input-text
                            :inside-label="translated('eur')"
                            :inside-label-class="'clean'"
                            :placeholder="translated('enter_delay_expenses')"
                            :hint-label="hintIconHtml"
                            :form-field="
                                forms[TravelChangesFormNames.HasCarriersTripCancellationConfirmation].field(
                                    FieldNames.TotalExpensesBecauseOfDelay,
                                )
                            "
                        >
                            <template #error>
                                <app-input-error>
                                    {{ translated('limit_error_out_of_range', { '%limit%': riskLimit }) }}
                                </app-input-error>
                            </template>
                        </app-input-text>
                    </div>
                </div>
            </div>
            <app-button-primary
                class="extra-margin"
                @click="proceedToNextStep(TravelChangesFormNames.HasCarriersTripCancellationConfirmation)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="forms[TravelChangesFormNames.ReasonOfTripCancellation]?.isReady() && !isTravelChanges"
        :form="forms[TravelChangesFormNames.ReasonOfTripCancellation]"
        class="form"
        @change="storeForm(TravelChangesFormNames.ReasonOfTripCancellation)"
    >
        <app-whiteboard
            :data-type="'whiteboard-2'"
            :data-scroll="TravelChangesFormNames.ReasonOfTripCancellation"
            :title="translated('reason_of_trip_cancellation')"
        >
            <div class="inputs row">
                <div class="input-block-full-width">
                    <app-options-smart-list
                        :form-field="
                            forms[TravelChangesFormNames.ReasonOfTripCancellation].field(
                                FieldNames.ReasonOfTripCancellation,
                            )
                        "
                        :options="inputOptions[TravelChangesFormNames.ReasonOfTripCancellation].options"
                        :option-class="'filled'"
                        :type="'radio'"
                        @change="onFormInputChange(TravelChangesFormNames.ReasonOfTripCancellation)"
                    >
                    </app-options-smart-list>
                </div>
            </div>
            <app-button-primary
                v-if="isActionsVisible(TravelChangesFormNames.ReasonOfTripCancellation)"
                @click="onProceedClick(TravelChangesFormNames.ReasonOfTripCancellation)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="
            forms[TravelChangesFormNames.HowWasTripPlanned]?.isReady() &&
            !isTravelChanges &&
            isPanelVisible(TravelChangesFormNames.HowWasTripPlanned)
        "
        :form="forms[TravelChangesFormNames.HowWasTripPlanned]"
        class="form"
        @change="storeForm(TravelChangesFormNames.HowWasTripPlanned)"
    >
        <app-whiteboard
            :data-type="'whiteboard-3'"
            :data-scroll="TravelChangesFormNames.HowWasTripPlanned"
            :title="translated('how_trip_was_planned')"
        >
            <div class="inputs row">
                <div class="input-block-full-width">
                    <app-options-smart-list
                        :form-field="
                            forms[TravelChangesFormNames.HowWasTripPlanned].field(FieldNames.HowWasTripPlanned)
                        "
                        :options="inputOptions[TravelChangesFormNames.HowWasTripPlanned].options"
                        :option-class="'filled'"
                        :type="'radio'"
                        @change="onFormInputChange(TravelChangesFormNames.HowWasTripPlanned)"
                    >
                    </app-options-smart-list>
                </div>
            </div>
            <app-button-primary
                v-if="isActionsVisible(TravelChangesFormNames.HowWasTripPlanned)"
                @click="onProceedClick(TravelChangesFormNames.HowWasTripPlanned)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="
            forms[TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo]?.isReady() &&
            !isTravelChanges &&
            isPanelVisible(TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo)
        "
        :form="forms[TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo]"
        class="form"
        @change="storeForm(TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo)"
    >
        <app-whiteboard
            :data-type="'whiteboard-4'"
            :data-scroll="TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo"
            :title="translated('had_other_insured_persons_whi_did_not_go')"
        >
            <div class="inputs row">
                <div class="input-block-full-width">
                    <div class="input-radio-container row">
                        <app-options-smart-list
                            :form-field="
                                forms[TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo].field(
                                    FieldNames.HadOtherInsuredPersonsWhoDidNotGo,
                                )
                            "
                            :options="inputOptions[TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo].options"
                            :option-class="'filled'"
                            :type="'radio'"
                            @change="onFormInputChange(TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo)"
                        >
                        </app-options-smart-list>
                    </div>
                </div>
            </div>
            <div
                v-if="
                    forms[TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo].field(
                        FieldNames.HadOtherInsuredPersonsWhoDidNotGo,
                    ).value.selected === 'yes'
                "
                class="inputs row"
            >
                <div class="input-radio-container mobile-full margin-top-large">
                    <h5 class="title">
                        {{
                            translated(
                                !isOtherPersonInputVisible ? 'other_insured_persons' : 'provide_other_insured_persons',
                            )
                        }}
                    </h5>
                    <app-repeatable
                        v-if="isOtherPersonInputVisible"
                        v-model:items="persons"
                        :min="1"
                        :factory="createPersonFactory"
                        @remove="onRemovePerson"
                    >
                        <template #default="{ item, index, repeatable }">
                            <app-repeatable-last-action-item
                                :add-button-text="translated('add_person')"
                                :remove-button-text="translated('remove_person')"
                                :item="item"
                                :index="index"
                                :actions-visible="
                                    isActionsVisible(TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo)
                                "
                                :repeatable="repeatable"
                            >
                                <app-custom-form class="item-form" :form="item.form">
                                    <app-input-text
                                        class="full-width"
                                        :form-field="item.form.field('name')"
                                        :placeholder="translated('placeholder_enter_name_surname')"
                                    ></app-input-text>
                                </app-custom-form>
                            </app-repeatable-last-action-item>
                        </template>
                    </app-repeatable>
                    <app-options-smart-list
                        v-if="!isOtherPersonInputVisible"
                        :form-field="
                            forms[TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo].field(
                                FieldNames.OtherInsuredPersonsFromPolicy,
                            )
                        "
                        :skip-options-change-form-reset="true"
                        :options="buildOtherInsuredPersonsOptions()"
                        :option-class="'filled'"
                        :type="'checkbox'"
                        @change="onFormInputChange(TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo)"
                    >
                    </app-options-smart-list>
                </div>
            </div>
            <app-button-primary
                v-if="isActionsVisible(TravelChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo)"
                @click="onProceedOtherPersons()"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="
            forms[TravelChangesFormNames.HadIndemnificationOfLoses]?.isReady() &&
            !isTravelChanges &&
            isPanelVisible(TravelChangesFormNames.HadIndemnificationOfLoses)
        "
        :form="forms[TravelChangesFormNames.HadIndemnificationOfLoses]"
        class="form"
        @change="storeForm(TravelChangesFormNames.HadIndemnificationOfLoses)"
    >
        <app-whiteboard
            :data-type="'whiteboard-5'"
            :data-scroll="TravelChangesFormNames.HadIndemnificationOfLoses"
            :title="translated('had_indemnification_of_loses')"
        >
            <div class="inputs row">
                <div class="input-block-full-width">
                    <div class="input-radio-container row">
                        <app-options-smart-list
                            :form-field="
                                forms[TravelChangesFormNames.HadIndemnificationOfLoses].field(
                                    FieldNames.HadIndemnificationOfLoses,
                                )
                            "
                            :options="inputOptions[TravelChangesFormNames.HadIndemnificationOfLoses].options"
                            :type="'radio'"
                            :option-class="'filled'"
                            @change="onFormInputChange(TravelChangesFormNames.HadIndemnificationOfLoses)"
                        ></app-options-smart-list>
                    </div>
                </div>
                <div
                    v-if="
                        forms[TravelChangesFormNames.HadIndemnificationOfLoses].field(
                            FieldNames.HadIndemnificationOfLoses,
                        ).value.selected === 'yes'
                    "
                    class="input-texts-container full-width mobile-column"
                >
                    <div class="with-title">
                        <h5 class="title">{{ translated('total_expenses_because_of_delay') }}</h5>
                        <app-input-text
                            :inside-label="translated('eur')"
                            :inside-label-class="'clean'"
                            :placeholder="translated('enter_delay_expenses')"
                            :form-field="
                                forms[TravelChangesFormNames.HadIndemnificationOfLoses].field(FieldNames.SumIndemnified)
                            "
                        >
                        </app-input-text>
                    </div>
                    <div class="with-title">
                        <h5 class="title">{{ translated('trip_cancellation_related_loses') }}</h5>
                        <app-input-text
                            :inside-label="translated('eur')"
                            :inside-label-class="'clean'"
                            :placeholder="translated('enter_cancellation_expenses')"
                            :form-field="
                                forms[TravelChangesFormNames.HadIndemnificationOfLoses].field(
                                    FieldNames.TripCancellationRelatedLoses,
                                )
                            "
                        >
                        </app-input-text>
                    </div>
                </div>
            </div>
            <app-button-primary @click="proceedToNextStep(TravelChangesFormNames.HadIndemnificationOfLoses)">
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
</template>

<style scoped lang="scss">
.form {
    .input-block-full-width {
        width: 100%;

        &.column {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

    .button-with-callback {
        height: 52px;
    }

    .title {
        margin-bottom: var(--size-small);
    }

    .input-radio-container {
        width: 50%;

        &.row {
            .options-smart-list {
                display: flex;
                gap: var(--size-small);
                flex-direction: row;

                :deep(.options-smart-list-option) {
                    margin-bottom: unset;
                }
            }
        }

        &.column {
            width: 100%;

            .options-smart-list {
                width: 50%;
                display: flex;
                gap: var(--size-small);
                flex-direction: row;

                @include respond-below('sm') {
                    width: 100%;
                }

                :deep(.options-smart-list-option) {
                    margin-bottom: unset;
                }
            }
        }

        &.mobile-column {
            @include respond-below('sm') {
                width: 100%;

                .options-smart-list {
                    flex-direction: column;
                }
            }
        }

        &.mobile-full {
            @include respond-below('sm') {
                width: 100%;
            }
        }
    }

    .input-texts-container {
        display: flex;
        flex-direction: row;
        gap: var(--size-small);
        margin-top: var(--size-big);

        .with-title {
            width: 100%;

            .input-text {
                width: 50%;
            }
        }

        &.full-width {
            .input-text {
                width: 100%;
            }
        }

        &.mobile-column {
            @include respond-below('sm') {
                width: 100%;
                flex-direction: column;
            }
        }
    }

    :deep(.repeatable) {
        .label {
            font-weight: 600;
        }
    }

    .info-text {
        margin-top: var(--size-tiny);
    }

    .medical-expenses {
        margin: var(--size-medium) 0;
    }

    :deep(.hint-label) {
        display: flex;
        gap: 10px;
        color: var(--text-color-subtlest);
        font-size: var(--font-size-nano);
        font-style: normal;
        align-items: center;
        font-weight: 500;

        @include respond-below('sm') {
            bottom: -48px;
        }
    }

    .margin-top-large {
        margin-top: var(--size-big);
    }

    .one-button {
        margin-top: var(--size-big);
    }

    .extra-margin {
        @include respond-below('sm') {
            margin-top: var(--size-huge);
        }
    }
}
</style>
