<script setup lang="ts">
import { getCurrentInstance, onMounted, ref, Ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import AppClaimDetailsOverview from '@/Components/Claims/ClaimOverview/ClaimOverview.vue';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import RequestService from '@/Services/request.service';
import { AxiosResponse } from 'axios';
import { useMobileApp } from '@/Apps/MobileApp/Composables/MobileApp';
import ClaimPopup from '@/Pages/OneDashboard/Claims/ClaimPopup';
import TwoWayAjaxCalls from '@/Apps/TwoWayCommunication/Enums/TwoWayAjaxCallsEnum';
import TwoWayCommunicationsBlock from '@/Apps/TwoWayCommunication/Interfaces/TwoWayCommunicationsBlockInterface';

const Tab: string = 'overview';

const viewIsReady: Ref<boolean> = ref(false);
const ajaxContentIsReady: Ref<boolean> = ref(false);
const content: Ref<DynamicDictionary> = ref({});

const { currentClaim, designation, claimView, hasStoredClaim, viewContent, emitFallbackEvent } = useMobileApp();

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    if (hasStoredClaim()) {
        prepareContent();
    } else {
        emitFallbackEvent(designation().claim);
    }
});

const prepareContent = (): void => {
    PopupService.getInstance().show(new OnePopup().withType().loading);
    const view: ClaimPopup = claimView();
    view.buildDetails();
    view.contentBuilder().stashContent();
    applyViewContent();
    viewIsReady.value = true;
    PopupService.getInstance().hide();
    prepareAdditionalContent();
};

const applyViewContent = (): void => {
    content.value = viewContent(Tab, designation().claim);
};

const prepareAdditionalContent = (): void => {
    RequestService.getInstance()
        .get({ uri: TwoWayAjaxCalls.List })
        .then((response: AxiosResponse): void => {
            const decisions: TwoWayCommunicationsBlock[] = (
                response.data.data.body.list as TwoWayCommunicationsBlock[]
            ).filter((item) => item.claimId === currentClaim().id);
            const view: ClaimPopup = claimView();
            view.applyDecisions(decisions).buildDetails();
            view.contentBuilder().stashContent();
            ajaxContentIsReady.value = true;
            applyViewContent();
        });
};
</script>

<template>
    <div class="app-claims-page">
        <app-claim-details-overview v-if="viewIsReady" :content="content">
            <template v-if="!ajaxContentIsReady" #ajax-loader>
                <app-content-loader class="margin-bottom"></app-content-loader>
            </template>
        </app-claim-details-overview>
    </div>
</template>

<style lang="scss" scoped>
.app-claims-page {
    width: 100%;

    .margin-bottom {
        margin-bottom: 20px;
    }
}
</style>
