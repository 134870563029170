<script lang="ts">
import { defineComponent, ref, Ref, nextTick } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import Form from '@/Assets/Libraries/Form/Form';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { useScroll } from '@/Composables/Scroll';
import VueEvent from '@/Classes/VueEventClass';
import CssClass from '@/Enums/CssClassEnum';
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { useAxios } from '@/Composables/Axios';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { useDefine } from '@/Composables/Define';
import { useTransforms } from '@/Composables/Transforms';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import { useRouter, Router } from 'vue-router';
import RouteStepperStep from '@/Components/Routing/RouteStepper/RouteStepperStepInterface';
import GuardsService from '@/Apps/PayByLink/Pay/Services/GuardsService';
import { Subscription } from 'rxjs';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import { DateTimeImmutable } from '@/Interfaces/date.time.immutable.interface';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const router: Router = useRouter();
        const { validResponse } = useDefine();

        const CurrentStep: number = 1;
        const Facility: string = 'pay-by-link-pay';
        const InsuranceTermSeparator: string = ' - ';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const submitEnabled: Ref<boolean> = ref(false);
        const paymentTerm: Ref<string> = ref('');
        const insuranceTerm: Ref<string> = ref('');
        const offerValidityTerm: Ref<string> = ref('');
        const documentNumber: Ref<string> = ref('');
        const paymentAmount: Ref<number> = ref(0);
        const bankLinkId: Ref<number> = ref(0);
        const informationText: Ref<string | null> = ref(null);
        const bankLinkIdError: Ref<boolean> = ref(false);
        const showPaymentMethods: Ref<boolean> = ref(false);
        const cardNumberPart: Ref<string> = ref('');
        const paymentData: Ref<DynamicDictionary> = ref({});
        const viewIsReady: Ref<boolean> = ref(false);

        const cardDetailsPbl: Ref<HTMLDivElement | null> = ref(null);
        const steps: RouteStepperStep[] = (router.options.routes || []).map((routeConfig): RouteStepperStep => {
            return {
                routeConfig: routeConfig,
                label: routeConfig.meta?.title as string,
            };
        });

        function setupForm(): void {
            form.setReady();
            formIsReady.value = true;
        }

        function init(): void {
            applyData();
            setupForm();
        }

        function applyCardNumberPart(value: string): void {
            cardNumberPart.value = value;
        }

        function openPaymentMethods(): void {
            showPaymentMethods.value = true;
            useScroll().scrollToTop();
        }

        function choosePayment(event: VueEvent): void {
            const newBankLinkId: number = event.params.bankLinkId;
            const newInformationText: string = event.params.informationText;
            if (newBankLinkId > 0) {
                event.sender.siblings('.' + CssClass.Active).removeClass(CssClass.Active);
                event.sender.toggleClass(CssClass.Active);
                bankLinkId.value = newBankLinkId === bankLinkId.value ? 0 : newBankLinkId;
                informationText.value = newInformationText
                    ? btaBase.localized(String(newInformationText).toLowerCase(), '')
                    : null;
                bankLinkIdError.value = bankLinkId.value === 0;
                submitEnabled.value = !bankLinkIdError.value;
            }
        }

        function submitButton(): void {
            if (bankLinkIdError.value) {
                onSubmitShowBankPaymentError();
            } else {
                submitPayment();
            }
        }

        function isVisibleInformationalText(): boolean {
            return !bankLinkIdError.value && !!informationText.value;
        }

        function hasReference(): boolean {
            return String(window.location.search).split('?ref=').length === 2;
        }

        function onCardSubmit(): void {
            bankLinkId.value = RecurringPaymentBanklink.ByCountry[new AppCountry().iso()];
            submitPayment();
        }

        function applyData(): void {
            if (paymentData.value.insurancePeriod) {
                const insuranceTermParts: string[] = paymentData.value.insurancePeriod.split(InsuranceTermSeparator);
                insuranceTerm.value = [OneDate.long(insuranceTermParts[0]), OneDate.long(insuranceTermParts[1])].join(
                    InsuranceTermSeparator,
                );
            }
            const offerValidity: DateTimeImmutable = paymentData.value.offerValidity || paymentData.value.validTo;
            documentNumber.value = paymentData.value.documentNumber;
            paymentAmount.value = paymentData.value.paymentAmount;
            offerValidityTerm.value = OneDate.short(offerValidity.date);
            if (paymentData.value.paymentTerm) {
                paymentTerm.value = OneDate.short(paymentData.value.paymentTerm.date);
            }
        }

        function submitPayment(): void {
            const params: Record<string, Record<string, string | unknown>> = assemblePaymentData();
            form.submitAttempt().then((): void => {
                if (form.isValid()) {
                    nextTick((): void => {
                        btaBase.lockInput(true);
                        PopupService.getInstance().show(new OnePopup().withType().loadingWait);
                        useAxios()
                            .post(Url.Ajax.payByLink, params)
                            .then((value: AxiosResponse<DynamicDictionary>): void => {
                                if (validResponse(value) && value.data.data.status === 'OK') {
                                    btaBase.navigate(value.data.data.body.redirectUrl);
                                } else {
                                    PopupService.getInstance().hide();
                                    btaBase.error.show(ErrorType.Error, 'submitPayment', value);
                                }
                            })
                            .catch((reason: LimitedVariant): void => {
                                btaBase.lockInput(false);
                                PopupService.getInstance().hide();
                                btaBase.error.show(ErrorType.Error, 'submitPayment', reason as DynamicDictionary);
                            });
                    });
                } else {
                    useScroll().scrollInvalidFieldToView(form);
                }
            });
        }

        function assemblePaymentData(): Record<string, Record<string, string | unknown>> {
            const json: Record<string, Record<string, number | string>> = {
                data: {},
            };
            json.data.id = paymentData.value.id;
            json.data.bankLinkId = bankLinkId.value;

            return {
                data: {
                    json: JSON.stringify(json),
                    facility: Facility,
                    extra: null,
                    numberPart: cardNumberPart.value,
                },
            };
        }

        function onSubmitShowBankPaymentError(): void {
            const errorTitle: string = btaBase.localized('btar_error_payment_method_not_selected_title', '');
            const errorDescription: string = btaBase.localized(
                'btar_error_payment_method_not_selected_description',
                '',
            );
            const errorElement: HTMLElement = $('#banks-list').get(0) as HTMLElement;
            btaBase.popup
                .applyErrorTitle(errorTitle)
                .applyErrorDetails(errorDescription)
                .applyErrorElement(errorElement);
            btaBase.showPopup('error');
        }

        function addPaymentData(paymentDataSafeJson: string): void {
            paymentData.value = JSON.parse(useTransforms().transformedVueSafeString(paymentDataSafeJson));
            TransferStateService.getInstance().set('payByLink', paymentData.value);
        }

        function applyMtplOffer(json: string): void {
            TransferStateService.getInstance().set(
                'mtplOffer',
                JSON.parse(useTransforms().transformedVueSafeString(json)),
            );
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                submitEnabled,
                paymentTerm,
                insuranceTerm,
                offerValidityTerm,
                documentNumber,
                paymentAmount,
                bankLinkId,
                informationText,
                bankLinkIdError,
                paymentData,
                showPaymentMethods,
                cardNumberPart,
                setupForm,
                init,
                applyCardNumberPart,
                openPaymentMethods,
                choosePayment,
                submitButton,
                isVisibleInformationalText,
                hasReference,
                onCardSubmit,
                applyData,
                assemblePaymentData,
                onSubmitShowBankPaymentError,
                addPaymentData,
                applyMtplOffer,
                steps,
                viewIsReady,
            },
        };
    },
    mounted() {
        this.applyApp(this);
        this.initBtaBase();
        this.setStorageUsage(false);
        GuardsService.getInstance().init();
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.init();

        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.viewIsReady = true;
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
