<script setup lang="ts">
import Form from '@/Assets/Libraries/Form/Form';
import FormField from '@/Assets/Libraries/Form/FormField';
import Sanitizer from '@/Services/sanitizer.service';
import InputDateConfig from '@/Components/Inputs/InputDate/InputDateConfig';
import moment from 'moment';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { computed, ComputedRef, onMounted, PropType, ref, Ref, watch } from 'vue';
import InputDateLayout from '@/Components/Inputs/InputDate/InputDateLayout';
import Validation from '@/Services/validation.service';
import DateFieldConfig from '@/Components/Inputs/InputDate/DateFieldConfig';

interface ChildForm {
    day: string;
    month: string;
    year: string;
}

const props = defineProps({
    layout: { type: String, default: InputDateLayout.dayMonthYear },
    formField: { type: Object as PropType<FormField<Date | string>>, default: () => new FormField('') },
    label: { type: String, default: '' },
    delimiter: { type: String, default: '.' },
    validateBirthDate: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    infoMessage: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    allowInvalidValue: { type: Boolean, default: false },
});

const emit = defineEmits(['change']);

const MonthsToFormat: string[] = ['2', '3', '4', '5', '6', '7', '8', '9'];
const MonthMaxCheckDigit: string = '1';
const MonthMaxThresholds: string[] = ['0', '1', '2'];
const DaysToFormat: string[] = ['4', '5', '6', '7', '8', '9'];
const DayMaxCheckDigit: string = '3';
const DayMaxThresholds: string[] = ['0', '1'];
const FormatPrefix: string = '0';
const FormatThreshold: number = 1;
const DayValueMinLength: number = 2;
const MonthValueMinLength: number = 2;
const YearValueMinLength: number = 4;
const DayMaxValue: number = 31;
const MonthMaxValue: number = 12;
const YearMinValue: number = 1900;
const FocusThresholds: DynamicDictionary = {
    day: 2,
    month: 2,
    year: 4,
};

const form: Form<ChildForm> = new Form();

const config: ComputedRef<InputDateConfig> = computed(() => {
    return {
        day: {
            id: `${props.formField.name}-day-input`,
            name: 'day',
            placeholder: 'DD',
            autocomplete: 'off',
        },
        month: {
            id: `${props.formField.name}-month-input`,
            name: 'month',
            placeholder: 'MM',
            autocomplete: 'off',
        },
        year: {
            id: `${props.formField.name}-year-input`,
            name: 'year',
            placeholder: 'YYYY',
            autocomplete: 'off',
        },
    };
});

const currentLayout: ComputedRef<string> = computed(() => props.layout);
const dateFields: ComputedRef<(keyof InputDateConfig)[]> = computed(
    () => props.layout.split('-') as (keyof InputDateConfig)[],
);
const fieldId: ComputedRef<string> = computed(() => props.formField.name + '-date');
const userMadeInput: Ref<boolean> = ref(false);
const rootElement = ref<HTMLElement | null>(null);

watch(
    () => props.formField.value,
    () => {
        onFormFieldValueChange();
    },
);

onMounted(() => {
    setupForm();
    props.formField.onClear.subscribe(() => {
        form.fields().forEach((field: FormField) => {
            field.clear();
        });
    });
    props.formField.addValidators({
        isValidDate: (value: Date | string) => !value || isValidDate(),
    });
    onFormFieldValueChange();
});

function onFormFieldValueChange(): void {
    if (props.formField.isNotEmpty() && moment(props.formField.value).isValid()) {
        patchChildFields();
        applyFieldWidth();
    } else {
        resetFieldWidth();
    }
}

function onKeyUp(event: KeyboardEvent): void {
    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;
    const currentField: keyof ChildForm = eventTarget.dataset.type as keyof ChildForm;
    const currentFieldOrder: number = Number(eventTarget.dataset.order);
    if (event.key === 'Backspace' && eventTarget.selectionStart === 0) {
        focusNextField(currentField, currentFieldOrder, false);
    }
}

function onClick(event: Event): void {
    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;
    const firstFocusable: HTMLInputElement | null = rootElement.value?.querySelector('[data-order="0"]') ?? null;
    if (form.field(eventTarget.dataset.type as keyof ChildForm).value.length > 0) {
        eventTarget.select();
    } else {
        const formIsEmpty: boolean = form.fields().filter((field) => field.isEmpty()).length === form.fields().length;
        if (formIsEmpty && firstFocusable) {
            firstFocusable.focus();
        }
    }
}

function onInput(event: Event): void {
    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;
    const currentField: keyof ChildForm = eventTarget.dataset.type as keyof ChildForm;
    const currentFieldOrder: number = Number(eventTarget.dataset.order);
    userMadeInput.value = true;
    form.field(currentField).sanitize();
    autoFormat(currentField, false).then((): void => {
        if (form.field(currentField).value.length > 0) {
            eventTarget.style.width = calculatedWidth(eventTarget.value);
        } else {
            eventTarget.style.removeProperty('width');
        }
        focusNextField(currentField, currentFieldOrder);
        form.validate()
            .then((): void => {
                patchFormFieldValue();
            })
            .finally(() => {
                if (props.allowInvalidValue) {
                    patchFormFieldValue();
                }
            });
    });
}

function autoFormat(currentField: string, formatOnBlur: boolean): Promise<void> {
    return new Promise((resolve) => {
        switch (currentField) {
            case 'day':
                formatDay(formatOnBlur);
                break;
            case 'month':
                formatMonth(formatOnBlur);
                break;
            default:
                break;
        }
        resolve();
    });
}

function onBlur(event: FocusEvent): void {
    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;
    const currentField: keyof ChildForm = eventTarget.dataset.type as keyof ChildForm;
    autoFormat(currentField, true).then((): void => {
        if (form.field(currentField).value.length > 0) {
            eventTarget.style.width = calculatedWidth(eventTarget.value);
        } else {
            eventTarget.style.removeProperty('width');
        }
        patchFormFieldValue();
        if (!isFocusedChildField()) {
            if (userMadeInput.value) {
                props.formField.markAsDirty();
            }
            props.formField.markAsTouched();
        }
    });
}

function patchChildFields(): void {
    form.field('day').setValue(moment(props.formField.value).format('DD'));
    form.field('month').setValue(moment(props.formField.value).format('MM'));
    form.field('year').setValue(moment(props.formField.value).format('YYYY'));
    form.validate().then();
}

function calculatedWidth(value: string): string {
    const parentElementSize: number = parseFloat($('#' + fieldId.value).css('font-size'));
    const narrowMultiplier: number = 1;
    const wideMultiplier: number = 1.2;
    const characterMultiplier: number = parentElementSize / 2;
    const width: number = value
        .split('')
        .map((character: string): number =>
            character === '1' ? characterMultiplier * narrowMultiplier : characterMultiplier * wideMultiplier,
        )
        .reduce((previousValue, currentValue): number => currentValue + previousValue, 0);

    return width + 'px';
}

function applyFieldWidth(): void {
    const elementsToFormat: HTMLInputElement[] = Array.from(rootElement.value?.querySelectorAll('[data-order]') ?? []);
    elementsToFormat.forEach((element: HTMLInputElement): void => {
        if (form.field(element.dataset.type as keyof ChildForm).value.length > 0) {
            element.style.width = calculatedWidth(form.field(element.dataset.type as keyof ChildForm).value);
        }
    });
}

function resetFieldWidth(): void {
    const elementsToFormat: HTMLInputElement[] = Array.from(rootElement.value?.querySelectorAll('[data-order]') ?? []);
    elementsToFormat.forEach((element: HTMLInputElement): void => {
        element.style.removeProperty('width');
    });
}

function patchFormFieldValue(): void {
    if (childFormIsValid()) {
        props.formField.setValue(valueFromChildFields());
        emit('change');
    } else {
        if (props.allowInvalidValue) {
            props.formField.setValue('invalid');
            emit('change');
        } else {
            props.formField.setValue('');
        }
    }
}

function childFormIsValid(): boolean {
    return form.isValid();
}

function valueFromChildFields(): Date {
    const dateMoment: string = [form.field('day').value, form.field('month').value, form.field('year').value].join('.');

    return moment(dateMoment, 'DD.MM.YYYY').toDate();
}

function formatDay(formatOnBlur: boolean = false): void {
    const currentValue: string = form.field('day').value;
    if (currentValue.length === FormatThreshold) {
        if (DaysToFormat.includes(currentValue)) {
            form.field('day').patch(FormatPrefix + currentValue);
        } else {
            if (formatOnBlur) {
                const prefix: string = currentValue === '0' ? '' : '0';
                const suffix: string = currentValue === '0' ? '1' : '';
                form.field('day').patch(prefix + currentValue + suffix);
            }
        }
    }
    if (currentValue.length > FormatThreshold) {
        const firstDigit: string = currentValue.substring(0, 1);
        const secondDigit: string = currentValue.substring(1);
        if (firstDigit === DayMaxCheckDigit && !DayMaxThresholds.includes(secondDigit)) {
            form.field('day').patch(String(DayMaxValue));
        }
    }
}

function formatMonth(formatOnBlur: boolean = false): void {
    const currentValue: string = form.field('month').value;
    if (currentValue.length === FormatThreshold) {
        if (MonthsToFormat.includes(currentValue)) {
            form.field('month').patch(FormatPrefix + currentValue);
        } else {
            if (formatOnBlur) {
                const prefix: string = currentValue === '0' ? '' : '0';
                const suffix: string = currentValue === '0' ? '1' : '';
                form.field('month').patch(prefix + currentValue + suffix);
            }
        }
    }
    if (currentValue.length > FormatThreshold) {
        const firstDigit: string = currentValue.substring(0, 1);
        const secondDigit: string = currentValue.substring(1);
        if (firstDigit === MonthMaxCheckDigit && !MonthMaxThresholds.includes(secondDigit)) {
            form.field('month').patch(String(MonthMaxValue));
        }
    }
}

function focusNextField(currentField: keyof ChildForm, currentFieldOrder: number, focusForwards: boolean = true): void {
    const focusableElements: HTMLInputElement[] = Array.from(rootElement.value?.querySelectorAll('[data-order]') ?? []);
    const nextElementToFocus: HTMLElement = focusForwards
        ? focusableElements.filter(
              (element: HTMLInputElement): boolean => Number(element.getAttribute('data-order')) > currentFieldOrder,
          )[0]
        : focusableElements
              .reverse()
              .filter(
                  (element: HTMLInputElement): boolean =>
                      Number(element.getAttribute('data-order')) < currentFieldOrder,
              )[0];
    if (nextElementToFocus) {
        if (focusForwards) {
            if (form.field(currentField).value.length === FocusThresholds[currentField]) {
                triggerFocusOnElement($(nextElementToFocus));
            }
        } else {
            triggerFocusOnElement($(nextElementToFocus));
        }
    }
}

function isFocusedChildField(): boolean {
    return (
        Boolean(document.activeElement) &&
        Object.values(config.value).some(
            (inputConfig: DateFieldConfig): boolean => inputConfig.id === document.activeElement?.id,
        )
    );
}

function isValidDate(): boolean {
    let isValid: boolean = true;
    if (props.formField.value) {
        const isDateValid: boolean = moment(props.formField.value).isValid();
        isValid = props.validateBirthDate
            ? isDateValid && moment(props.formField.value).isSameOrBefore(moment(), 'day')
            : isDateValid;
    }
    return isValid;
}

function setupForm(): void {
    form.addField(new FormField('day', '', dayValidators(), Sanitizer.cleanDay));
    form.addField(new FormField('month', '', monthValidators(), Sanitizer.cleanDay));
    form.addField(new FormField('year', '', yearValidators(), Sanitizer.cleanYear));
    form.setReady();
}

function dayValidators(): object {
    return {
        isValidDay: (value: string) => !value || (value.length === DayValueMinLength && Number(value) <= DayMaxValue),
        required: Validation.required,
    };
}

function monthValidators(): object {
    return {
        isValidMonth: (value: string) =>
            !value || (value.length === MonthValueMinLength && Number(value) <= MonthMaxValue),
        required: Validation.required,
    };
}

function yearValidators(): object {
    return {
        isValidYear: (value: string) =>
            !value || (value.length === YearValueMinLength && Number(value) >= YearMinValue),
        required: Validation.required,
    };
}

function triggerFocusOnElement(element: JQuery): void {
    element.trigger('focus');
    element.select();
}
</script>
<template>
    <div
        :id="formField.name"
        ref="rootElement"
        class="input input-date"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : formField.value"
    >
        <div v-if="label" class="label informative">
            <label :for="formField.name"> {{ label }}&nbsp;<span v-if="required" class="asterisk">&#42;</span> </label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper">
            <div v-for="(field, index) in dateFields" :key="index" class="date-field-container cursor-text">
                <input
                    :id="config[field].id"
                    v-model="form.field(config[field].name as keyof ChildForm).value"
                    type="text"
                    :class="[config[field].name, currentLayout]"
                    :name="config[field].name"
                    :disabled="disabled"
                    :placeholder="config[field].placeholder"
                    :autocomplete="config[field].autocomplete"
                    :data-type="config[field].name"
                    :data-order="index"
                    @keyup="onKeyUp"
                    @click="onClick"
                    @change="patchFormFieldValue"
                    @input="onInput"
                    @blur="onBlur"
                />
                <span v-if="index < dateFields.length - 1" class="delimiter">{{ delimiter }}</span>
            </div>
        </div>
        <div v-if="formField.classes().error" class="error-container">
            <slot name="error"></slot>
        </div>
        <div v-if="infoMessage !== ''" class="feedback-info" v-html="infoMessage"></div>
    </div>
</template>
<style lang="scss" scoped>
.input-date {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    .wrapper {
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border: 1px solid var(--black-200);
        background-color: var(--white);
        border-radius: 3px;

        &:hover {
            @include input-hover;
        }

        &:focus-within {
            @include input-focus;
        }

        .date-field-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            &.cursor-text {
                cursor: text;
            }

            .delimiter {
                height: 30px;
                display: flex;
                align-items: center;
                color: var(--black-500);
            }

            input {
                height: 30px;
                border: none;
                background: none;
                padding: 0;
                text-align: center;

                &.day,
                &.month {
                    width: 2.5ch;
                }

                &::placeholder {
                    text-align: center;
                }

                &.year {
                    width: 4ch;
                }

                &:focus {
                    outline: none;
                }
            }

            &:hover {
                border-color: var(--system-color-error-default);
            }

            &:focus {
                border-color: transparent;
            }
        }
    }

    &.error {
        .wrapper {
            border-color: var(--system-color-error-default);
            background-color: var(--red-50);

            &:focus-within {
                border-color: transparent;
                outline-color: var(--system-color-error-default);
                background-color: var(--white);
            }
        }
    }

    &.disabled {
        .wrapper {
            border-color: transparent;
        }
    }

    .feedback-info {
        font-size: var(--font-size-nano);
        font-weight: 500;
        color: var(--text-color-subtlest);
        margin-top: var(--size-pico);
    }

    .error-container {
        margin-top: var(--size-femto);

        &:empty {
            display: none;
        }
    }

    @include respond-above('sm') {
        width: 160px;
        margin-bottom: 0;
    }
}
</style>
