<script setup lang="ts">
import {
    computed,
    markRaw,
    onMounted,
    reactive,
    Ref,
    ref,
    shallowReactive,
    ShallowUnwrapRef,
    UnwrapNestedRefs,
} from 'vue';
import Form from '@/Assets/Libraries/Form/Form';
import Validation from '@/Services/validation.service';
import { useTranslate } from '@/Composables/Translate';
import Option from '@/Apps/ClaimsTravel/Interfaces/Option';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { FormNames as LuggageFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Luggage/FormNames';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import { FieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Luggage/FieldNames';
import FormField from '@/Assets/Libraries/Form/FormField';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import { useClaimsTravelHtml } from '@/Apps/ClaimsTravel/Composables/ClaimsTravelHtml';
import LuggageFormFields from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelFormFields';
import { Router, useRouter } from 'vue-router';
import { useDefine } from '@/Composables/Define';
import {
    DayInstitutionInformedFields,
    HaveConfirmationIssuedDelayedFields,
    HaveConfirmationIssuedStolenFields,
    InfoAboutLuggageFields,
    ListOfEssentialsFields,
    ListOfEssentialsPurchasedFields,
    LuggageForms,
    WhatWasDamagedFields,
    WhoWasSupervisingFields,
} from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentDetails/LuggageForms';
import ClaimsTravelObject from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelObject';
import AppSvg from '@/Components/Other/Svg/Svg.vue';
import AppButtonPrimary from '@/Components/Buttons/ButtonPrimary/ButtonPrimary.vue';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppWhiteboard from '@/Components/Containers/Whiteboard/Whiteboard.vue';
import AppNotification from '@/Components/Widgets/Notification/Notification.vue';
import AppInputRadioSelector from '@/Components/Inputs/InputRadioSelector/InputRadioSelector.vue';
import AppRepeatable from '@/Components/Other/Repeatable/Repeatable.vue';
import AppTravelClaimRepeatable from '@/Apps/ClaimsTravel/Components/TravelClaimRepeatable.vue';
import {
    RepeatableLuggageForm,
    RepeatableLuggageItem,
    RepeatableLuggageStoredItem,
} from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentDetails/RepeatableLuggage';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import Sanitizer from '@/Services/sanitizer.service';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import AppInputDateWithCalendar from '@/Components/Inputs/InputDateWithCalendar/InputDateWithCalendar.vue';
import moment from 'moment';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import {
    RepeatableEssentialsForm,
    RepeatableEssentialsItem,
    RepeatableEssentialsStoredItem,
} from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentDetails/RepeatableEssentials';
import { FormHelperParams, useFormHelper } from '@/Composables/FormHelper';
import { useScroll } from '@/Composables/Scroll';
import GuardsService from '@/Apps/ClaimsTravel/Services/GuardsService';
import ClaimsTravelStepUid from '@/Apps/ClaimsTravel/Enums/ClaimsTravelStepUid';

type FormNames = `${LuggageFormNames}`;

const router: Router = useRouter();
const { translateForType, applyTranslationType, type, translate } = useTranslate();
const { isSet } = useDefine();
const formHelper: FormHelperParams = useFormHelper();

const claimsTravelService: ClaimsTravelService = ClaimsTravelService.getInstance();

const Step: number = 2;
const MaxItemsLuggage: number = 5;
const MaxItemsEssentials: number = 5;

const forms: ShallowUnwrapRef<{ [FormKey in keyof LuggageForms]: Form<LuggageForms[FormKey]> }> = shallowReactive(
    {},
) as ShallowUnwrapRef<{
    [FormKey in keyof LuggageForms]: Form<LuggageForms[FormKey]>;
}>;

const inputOptions: UnwrapNestedRefs<Record<FormNames, Option>> = reactive({}) as UnwrapNestedRefs<
    Record<FormNames, Option>
>;

const luggage: Ref<RepeatableLuggageItem[]> = ref([]);
const essentialsStolen: Ref<RepeatableEssentialsItem[]> = ref([]);
const essentialsDelayed: Ref<RepeatableEssentialsItem[]> = ref([]);

const isDamaged: Ref<boolean> = computed(() => {
    return claimsTravelService.fields.whatHappened?.whatHappened.selected === 'damaged';
});

const isStolen: Ref<boolean> = computed(() => {
    return claimsTravelService.fields.whatHappened?.whatHappened.selected === 'stolen';
});

const isDelayed: Ref<boolean> = computed(() => {
    return claimsTravelService.fields.whatHappened?.whatHappened.selected === 'delayed';
});

const selectedPolicy: Ref<ClaimsTravelObject> = computed(() => {
    return claimsTravelService.storageFields.allInsuredObjects.find(
        (object) => object.id === claimsTravelService.fields.policy?.policy.object,
    )!;
});

const whatWasDamagedIsVisible: Ref<boolean> = computed(() => {
    return (
        isDamaged.value &&
        forms[LuggageFormNames.WhatWasDamaged]?.isReady() &&
        claimsTravelService.whatWasDamagedEnabled
    );
});

const infoAboutLuggageIsVisible: Ref<boolean> = computed(() => {
    let result: boolean = true;
    if (isDamaged.value) {
        if (claimsTravelService.whatWasDamagedEnabled) {
            result = isPanelVisible(LuggageFormNames.InfoAboutLuggage);
        } else {
            result = forms[LuggageFormNames.InfoAboutLuggage]?.isReady();
        }
    } else {
        result = false;
    }

    return result;
});

const isVisibleLuggageItemsInfo: Ref<boolean> = computed(() => {
    return forms[LuggageFormNames.InfoAboutLuggage].field(FieldNames.InfoAboutLuggage).value === 'no';
});

const isVisibleHaveConfirmationIssuedDelayedInfo: Ref<boolean> = computed(() => {
    return (
        forms[LuggageFormNames.HaveConfirmationIssuedDelayed].field(FieldNames.HaveConfirmationIssuedDelayed).value ===
        'no'
    );
});

const isNotVisibleHaveConfirmationIssuedDelayedInfo: Ref<boolean> = computed(() => {
    return (
        forms[LuggageFormNames.HaveConfirmationIssuedDelayed].field(FieldNames.HaveConfirmationIssuedDelayed).value ===
        'yes'
    );
});

const isVisibleLuggageItemsRepeatable: Ref<boolean> = computed(() => {
    return ['yes', 'no'].includes(forms[LuggageFormNames.InfoAboutLuggage].field(FieldNames.InfoAboutLuggage).value);
});

const whoWasSupervisingIsVisible: Ref<boolean> = computed(() => {
    let result: boolean = false;
    if (isStolen.value) {
        result = forms[LuggageFormNames.WhoWasSupervising]?.isReady();
    }

    return result;
});

const isUnderCarriersSupervision: Ref<boolean> = computed(() => {
    return (
        forms.whoWasSupervising &&
        forms.whoWasSupervising.field(FieldNames.WhoWasSupervising).value === 'supervising_carrier'
    );
});

const isUnderUserSupervision: Ref<boolean> = computed(() => {
    return (
        forms.whoWasSupervising &&
        forms.whoWasSupervising.field(FieldNames.WhoWasSupervising).value === 'supervising_user'
    );
});

const haveConfirmationIssuedStolenIsVisible: Ref<boolean> = computed(() => {
    let result: boolean = false;
    if (isStolen.value && isUnderCarriersSupervision.value) {
        result = isPanelVisible(LuggageFormNames.HaveConfirmationIssuedStolen);
    }

    return result;
});

const dayInstitutionInformedIsVisible: Ref<boolean> = computed(() => {
    let result: boolean = false;
    if (isStolen.value && isUnderUserSupervision.value) {
        result = isPanelVisible(LuggageFormNames.HaveConfirmationIssuedStolen);
    }

    return result;
});

const listOfEssentialsStolenIsVisible: Ref<boolean> = computed(() => {
    let result: boolean = false;
    if (isStolen.value && isUnderUserSupervision.value) {
        result = isPanelVisible(LuggageFormNames.ListOfEssentialsStolen);
    }

    return result;
});

const listOfEssentialsDelayedIsVisible: Ref<boolean> = computed(() => {
    let result: boolean = false;
    if (isDelayed.value) {
        result = isPanelVisible(LuggageFormNames.ListOfEssentialsDelayed);
    }

    return result;
});

const isVisibleHaveConfirmationIssuedStolen: Ref<boolean> = computed(() => {
    return (
        isUnderCarriersSupervision.value &&
        forms.haveConfirmationIssuedStolen.field(FieldNames.HaveConfirmationIssuedStolen).value === 'no'
    );
});

const isVisibleConfirmationIssuedSum: Ref<boolean> = computed(() => {
    return (
        isUnderCarriersSupervision.value &&
        forms.haveConfirmationIssuedStolen.field(FieldNames.HaveConfirmationIssuedStolen).value === 'yes'
    );
});

const haveConfirmationIssuedDelayedIsVisible: Ref<boolean> = computed(() => {
    let result: boolean = false;
    if (isDelayed.value) {
        result = forms[LuggageFormNames.HaveConfirmationIssuedDelayed]?.isReady();
    }

    return result;
});

const calendarMinDate: Ref<Date> = computed(() => {
    const minDateYears: number = 5;
    return moment().subtract(minDateYears, 'years').toDate();
});

const calendarMaxDate: Ref<Date> = computed(() => {
    return moment().toDate();
});

function isProceedVisible(form: FormNames): boolean {
    const next = findNextFormName(form);

    return next !== undefined ? !inputOptions[next].visible : true;
}

function proceedToNextForm(formName: FormNames, nextFormName: FormNames | null = null): void {
    const nextPanel = nextFormName ? nextFormName : findNextFormName(formName);
    if (nextPanel) {
        inputOptions[nextPanel].visible = true;
        claimsTravelService.storageFields.formVisibility[nextPanel] = true;
        useClaimsTravelHtml().scrollToPanel(nextPanel);
    }
}

function scrollToLastForm(): void {
    const lastForm = Object.keys(inputOptions).findLast((form) => inputOptions[form as FormNames].visible);
    const firstFormName: string = claimsTravelService.whatWasDamagedEnabled
        ? LuggageFormNames.WhatWasDamaged
        : LuggageFormNames.InfoAboutLuggage;
    useClaimsTravelHtml().scrollToPanel(lastForm ? lastForm : firstFormName);
}

function clearForms(): void {
    Object.keys(forms).forEach((form) => {
        claimsTravelService.storageFields.formVisibility[form as keyof LuggageFormFields] = false;
        clearForm(form as FormNames);
    });
}

function findNextFormName(currentForm: FormNames): FormNames | undefined {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);

    return Object.keys(forms).at(currentFormIndex + 1) as FormNames | undefined;
}

function isPanelVisible(panel: FormNames): boolean {
    return inputOptions[panel] && inputOptions[panel].visible;
}

function clearFormsAhead(currentForm: FormNames): void {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);
    const nextFormKeys: FormNames[] = Object.keys(forms).slice(
        currentFormIndex + 1,
        Object.keys(forms).length,
    ) as FormNames[];
    nextFormKeys.forEach((formName: FormNames) => {
        clearForm(formName);
        inputOptions[formName].visible = false;
        claimsTravelService.storageFields.formVisibility[formName] = false;
    });
}

function markFormsAsReady(): void {
    Object.values(forms).forEach((form) => form.setReady());
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

function storeForm(formName: FormNames): void {
    return claimsTravelService.storeForm(formName as keyof LuggageFormFields, forms[formName]);
}

function onFormInputChange(formName: FormNames): void {
    clearFormsAhead(formName);
    storeForm(formName);
}

function onTodayClick(): void {
    if (forms.dayInstitutionInformed) {
        forms.dayInstitutionInformed.field(FieldNames.DayInstitutionInformed).patch({
            startDate: moment().toDate().toString(),
            endDate: '',
        });
    }
}

function onYesterdayClick(): void {
    if (forms.dayInstitutionInformed) {
        forms.dayInstitutionInformed.field(FieldNames.DayInstitutionInformed).patch({
            startDate: moment().subtract(1, 'days').toDate().toString(),
            endDate: '',
        });
    }
}

function calendarDaysButtonsParams(stringId: string): ButtonWithCallbackParams {
    return {
        title: translated(stringId),
        mode: 'badge',
        textColor: ButtonTextColor.White,
        textColorHover: ButtonTextColor.Black,
        backgroundColor: ButtonBackground.Black,
        backgroundColorHover: ButtonBackground.White,
    };
}

function onRemoveLuggageItem(item: RepeatableLuggageItem): void {
    item.form.destroy();
}

function createItemFactoryLuggageItem(id: string): RepeatableLuggageItem {
    const form: Form = new Form(id, { useValidationV2: true });
    form.addField(
        new FormField('height').addValidators({ required: Validation.required }).addSanitizer(Sanitizer.cleanNumber),
    );
    form.addField(
        new FormField('width').addValidators({ required: Validation.required }).addSanitizer(Sanitizer.cleanNumber),
    );
    form.addField(new FormField('brand').addValidators({ required: Validation.required }));
    form.addField(
        new FormField('year').addValidators({ required: Validation.required }).addSanitizer(Sanitizer.cleanYear),
    );
    form.addField(
        new FormField('price').addValidators({ required: Validation.required }).addSanitizer(Sanitizer.cleanNumber),
    );
    form.setReady();

    return { id: id, form: markRaw(form) };
}

function onRemoveEssentials(item: RepeatableEssentialsItem): void {
    item.form.destroy();
}

function createItemFactoryEssentialItem(id: string): RepeatableEssentialsItem {
    const form: Form = new Form(id, { useValidationV2: true });
    form.addField(new FormField('what').addValidators({ required: Validation.required }));
    form.addField(
        new FormField('amount').addValidators({ required: Validation.required }).addSanitizer(Sanitizer.cleanNumber),
    );
    form.setReady();

    return { id: id, form: markRaw(form) };
}

function restoreIterableValues(): void {
    if (claimsTravelService.fields.infoAboutLuggage && claimsTravelService.fields.infoAboutLuggage.luggageItem) {
        luggage.value = claimsTravelService.fields.infoAboutLuggage.luggageItem.map(
            (storedItem: RepeatableLuggageStoredItem): RepeatableLuggageItem => {
                const item: RepeatableLuggageItem = createItemFactoryLuggageItem(storedItem.formName);
                item.form.restoreValues({
                    height: storedItem.height,
                    width: storedItem.width,
                    brand: storedItem.brand,
                    year: storedItem.year,
                    price: storedItem.price,
                });

                return item;
            },
        );
    }
    if (
        claimsTravelService.fields.listOfEssentialsStolen &&
        claimsTravelService.fields.listOfEssentialsStolen.listOfEssentialsStolen
    ) {
        essentialsStolen.value = claimsTravelService.fields.listOfEssentialsStolen.listOfEssentialsStolen.map(
            (storedItem: RepeatableEssentialsStoredItem): RepeatableEssentialsItem => {
                const item: RepeatableEssentialsItem = createItemFactoryEssentialItem(storedItem.formName);
                item.form.restoreValues({
                    what: storedItem.what,
                    amount: storedItem.amount,
                });

                return item;
            },
        );
    }
    if (
        claimsTravelService.fields.listOfEssentialsDelayed &&
        claimsTravelService.fields.listOfEssentialsDelayed.listOfEssentialsDelayed
    ) {
        essentialsDelayed.value = claimsTravelService.fields.listOfEssentialsDelayed.listOfEssentialsDelayed.map(
            (storedItem: RepeatableEssentialsStoredItem): RepeatableEssentialsItem => {
                const item: RepeatableEssentialsItem = createItemFactoryEssentialItem(storedItem.formName);
                item.form.restoreValues({
                    what: storedItem.what,
                    amount: storedItem.amount,
                });

                return item;
            },
        );
    }
}

function storeValues(): void {
    if (claimsTravelService.fields.infoAboutLuggage) {
        claimsTravelService.fields.infoAboutLuggage.luggageItem = luggage.value.map(
            (item: RepeatableLuggageItem): RepeatableLuggageStoredItem => {
                return {
                    formName: item.form.name,
                    height: item.form.field('height').value,
                    width: item.form.field('width').value,
                    brand: item.form.field('brand').value,
                    year: item.form.field('year').value,
                    price: item.form.field('price').value,
                };
            },
        );
    }
    if (claimsTravelService.fields.listOfEssentialsStolen) {
        claimsTravelService.fields.listOfEssentialsStolen.listOfEssentialsStolen = essentialsStolen.value.map(
            (item: RepeatableEssentialsItem): RepeatableEssentialsStoredItem => {
                return {
                    formName: item.form.name,
                    what: item.form.field('what').value,
                    amount: item.form.field('amount').value,
                };
            },
        );
    }
    if (claimsTravelService.fields.listOfEssentialsDelayed) {
        claimsTravelService.fields.listOfEssentialsDelayed.listOfEssentialsDelayed = essentialsDelayed.value.map(
            (item: RepeatableEssentialsItem): RepeatableEssentialsStoredItem => {
                return {
                    formName: item.form.name,
                    what: item.form.field('what').value,
                    amount: item.form.field('amount').value,
                };
            },
        );
    }
}

function preparePanels(): void {
    Object.values(LuggageFormNames).forEach((panelName: FormNames) => {
        inputOptions[panelName] = reactive(
            new (class implements Option {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = claimsTravelService.storageFields.formVisibility[panelName] ?? false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            })(),
        );
    });
    buildWhatWasDamaged();
    buildInfoAboutLuggage();
    buildWhoWasSupervising();
    buildHaveConfirmationIssued();
}

function buildWhatWasDamaged(): void {
    if (whatWasDamagedIsVisible.value) {
        claimsTravelService.whatWasDamaged.forEach((damagedPart: any) => {
            inputOptions[LuggageFormNames.WhatWasDamaged].options.push(
                new InputOptionBuilder().setValue(damagedPart).setName(translated(damagedPart)).build(),
            );
        });
    }
}

function buildInfoAboutLuggage(): void {
    inputOptions[LuggageFormNames.InfoAboutLuggage].options = yesNoInputOptions();
}

function buildWhoWasSupervising(): void {
    ['supervising_carrier', 'supervising_user'].forEach((item: string) => {
        inputOptions[LuggageFormNames.WhoWasSupervising].options.push(
            new InputOptionBuilder().setValue(item).setName(translated(item)).build(),
        );
    });
}

function buildHaveConfirmationIssued(): void {
    inputOptions[LuggageFormNames.HaveConfirmationIssuedStolen].options = yesNoInputOptions();
    inputOptions[LuggageFormNames.HaveConfirmationIssuedDelayed].options = yesNoInputOptions();
}

function yesNoInputOptions(): InputOption[] {
    return [
        new InputOptionBuilder().setValue('yes').setName(translated('yes')).build(),
        new InputOptionBuilder().setValue('no').setName(translated('no')).build(),
    ];
}

function setupForms(): void {
    setupDamagedForms();
    setupStolenForms();
    setupDelayedForms();
}

function setupDamagedForms(): void {
    const whatWasDamagedValidator = {
        required: (value: { selected?: string }) => isDamaged.value && isSet(value?.selected) && value.selected !== '',
    };
    const infoAboutLuggageValidator = {
        required: (value: string) => {
            return isDamaged.value && Validation.required(value);
        },
    };
    const whatWasDamagedForm: Form<WhatWasDamagedFields> = new Form({ useValidationV2: true });
    whatWasDamagedForm.addField(new FormField(FieldNames.WhatWasDamaged, '', whatWasDamagedValidator));
    forms[LuggageFormNames.WhatWasDamaged] = whatWasDamagedForm;
    const infoAboutLuggage: Form<InfoAboutLuggageFields> = new Form({ useValidationV2: true });
    infoAboutLuggage.addField(new FormField(FieldNames.InfoAboutLuggage, '', infoAboutLuggageValidator));
    infoAboutLuggage.addField(new FormField(FieldNames.LuggageItem, []));
    forms[LuggageFormNames.InfoAboutLuggage] = infoAboutLuggage;
}

function setupStolenForms(): void {
    const stolenRequiredValidator = {
        required: (value: string) => {
            return isStolen.value && Validation.required(value);
        },
    };
    const confirmationIssuedSumValidator = {
        required: (value: string) => {
            return (isStolen.value && Validation.required(value)) || isVisibleHaveConfirmationIssuedStolen.value;
        },
    };
    const whoWasSupervisingForm: Form<WhoWasSupervisingFields> = new Form({ useValidationV2: true });
    whoWasSupervisingForm.addField(new FormField(FieldNames.WhoWasSupervising, '', stolenRequiredValidator));
    forms[LuggageFormNames.WhoWasSupervising] = whoWasSupervisingForm;
    const haveConfirmationIssuedForm: Form<HaveConfirmationIssuedStolenFields> = new Form({ useValidationV2: true });
    haveConfirmationIssuedForm.addField(
        new FormField(FieldNames.HaveConfirmationIssuedStolen, '', stolenRequiredValidator),
    );
    haveConfirmationIssuedForm.addField(
        new FormField(FieldNames.ConfirmationIssuedSum, '', confirmationIssuedSumValidator, Sanitizer.cleanNumber),
    );
    forms[LuggageFormNames.HaveConfirmationIssuedStolen] = haveConfirmationIssuedForm;
    const dayInstitutionInformedFields: Form<DayInstitutionInformedFields> = new Form({ useValidationV2: true });
    dayInstitutionInformedFields.addField(
        new FormField(FieldNames.DayInstitutionInformed, '', stolenRequiredValidator),
    );
    forms[LuggageFormNames.DayInstitutionInformed] = dayInstitutionInformedFields;
    const listOfEssentialsStolenFields: Form<ListOfEssentialsFields> = new Form({ useValidationV2: true });
    listOfEssentialsStolenFields.addField(new FormField(FieldNames.ListOfEssentialsStolen, []));
    forms[LuggageFormNames.ListOfEssentialsStolen] = listOfEssentialsStolenFields;
}

function setupDelayedForms(): void {
    const delayedRequiredValidator = {
        required: (value: string) => {
            return isDelayed.value && Validation.required(value);
        },
    };
    const hoursCompensationValidator = {
        required: (value: string) => {
            return isVisibleHaveConfirmationIssuedDelayedInfo.value
                ? true
                : isDelayed.value && Validation.required(value);
        },
    };
    const haveConfirmationIssuedDelayedFields: Form<HaveConfirmationIssuedDelayedFields> = new Form({
        useValidationV2: true,
    });
    haveConfirmationIssuedDelayedFields.addField(
        new FormField(FieldNames.HaveConfirmationIssuedDelayed, '', delayedRequiredValidator),
    );
    haveConfirmationIssuedDelayedFields.addField(
        new FormField(FieldNames.HowManyHoursLuggageDelayed, '', hoursCompensationValidator),
    );
    haveConfirmationIssuedDelayedFields.addField(
        new FormField(FieldNames.AmountOfCompensationByCarrier, '', hoursCompensationValidator, Sanitizer.cleanNumber),
    );
    forms[LuggageFormNames.HaveConfirmationIssuedDelayed] = haveConfirmationIssuedDelayedFields;
    const listOfEssentialsPurchasedFields: Form<ListOfEssentialsPurchasedFields> = new Form({ useValidationV2: true });
    listOfEssentialsPurchasedFields.addField(new FormField(FieldNames.ListOfEssentialsDelayed, ''));
    forms[LuggageFormNames.ListOfEssentialsDelayed] = listOfEssentialsPurchasedFields;
}

function clearForm(formName: FormNames): void {
    const form:
        | Form<WhatWasDamagedFields>
        | Form<InfoAboutLuggageFields>
        | Form<WhoWasSupervisingFields>
        | Form<HaveConfirmationIssuedStolenFields>
        | Form<DayInstitutionInformedFields>
        | Form<ListOfEssentialsFields>
        | Form<HaveConfirmationIssuedDelayedFields>
        | Form<ListOfEssentialsPurchasedFields> = forms[formName];
    switch (formName) {
        case LuggageFormNames.WhatWasDamaged: {
            const whatWasDamagedForm = form as Form<WhatWasDamagedFields>;
            whatWasDamagedForm.field(FieldNames.WhatWasDamaged).setValue({ selected: '', extra: '' });
            break;
        }
        case LuggageFormNames.InfoAboutLuggage: {
            const infoAboutLuggage = form as Form<InfoAboutLuggageFields>;
            infoAboutLuggage.field(FieldNames.InfoAboutLuggage).setValue('');
            infoAboutLuggage.field(FieldNames.LuggageItem).setValue([]);
            break;
        }
        case LuggageFormNames.WhoWasSupervising: {
            const whoWasSupervising = form as Form<WhoWasSupervisingFields>;
            whoWasSupervising.field(FieldNames.WhoWasSupervising).setValue('');
            break;
        }
        case LuggageFormNames.HaveConfirmationIssuedStolen: {
            const haveConfirmationIssued = form as Form<HaveConfirmationIssuedStolenFields>;
            haveConfirmationIssued.field(FieldNames.HaveConfirmationIssuedStolen).setValue('');
            haveConfirmationIssued.field(FieldNames.ConfirmationIssuedSum).setValue('');
            break;
        }
        case LuggageFormNames.DayInstitutionInformed: {
            const dayInstitutionInformedFields = form as Form<DayInstitutionInformedFields>;
            dayInstitutionInformedFields
                .field(FieldNames.DayInstitutionInformed)
                .setValue({ startDate: '', endDate: '' });
            break;
        }
        case LuggageFormNames.ListOfEssentialsStolen: {
            const listOfEssentialsFields = form as Form<ListOfEssentialsFields>;
            listOfEssentialsFields.field(FieldNames.ListOfEssentialsStolen).setValue([]);
            break;
        }
        case LuggageFormNames.HaveConfirmationIssuedDelayed: {
            const haveConfirmationIssuedDelayed = form as Form<HaveConfirmationIssuedDelayedFields>;
            haveConfirmationIssuedDelayed.field(FieldNames.HaveConfirmationIssuedDelayed).setValue('');
            haveConfirmationIssuedDelayed.field(FieldNames.HowManyHoursLuggageDelayed).setValue('');
            haveConfirmationIssuedDelayed.field(FieldNames.AmountOfCompensationByCarrier).setValue('');
            break;
        }
        case LuggageFormNames.ListOfEssentialsDelayed: {
            const listOfEssentialsPurchasedFields = form as Form<ListOfEssentialsPurchasedFields>;
            listOfEssentialsPurchasedFields.field(FieldNames.ListOfEssentialsDelayed).setValue([]);
            break;
        }
        default:
    }
    storeForm(formName);
}

function onProceedClick(panel: FormNames): void {
    storeValues();
    const form = forms[panel];
    switch (panel) {
        case LuggageFormNames.InfoAboutLuggage:
            onSubmitDamaged();
            break;
        case LuggageFormNames.ListOfEssentialsStolen:
            onSubmitStolen();
            break;
        case LuggageFormNames.ListOfEssentialsDelayed:
            onSubmitDelayed();
            break;
        default:
            form.submitAttempt().then(() => {
                if (form.isValid()) {
                    if (panel === (LuggageFormNames.HaveConfirmationIssuedStolen as FormNames)) {
                        submit();
                    } else {
                        proceedToNextForm(panel);
                    }
                } else {
                    useScroll().scrollAnyInvalidFieldToView();
                }
            });
    }
}

function onSubmitDamaged(): void {
    const luggageForms: Form<RepeatableLuggageForm>[] = luggage.value.map(
        (item: RepeatableLuggageItem): Form<RepeatableLuggageForm> => item.form,
    );
    formHelper.submitAttemptMultiple(luggageForms).then(() => {
        if (formHelper.isMultipleFormsValid(luggageForms)) {
            submit();
        } else {
            useScroll().scrollAnyInvalidFieldToView();
        }
    });
}

function onSubmitStolen(): void {
    const essentialsForms: Form<RepeatableEssentialsForm>[] = essentialsStolen.value.map(
        (item: RepeatableEssentialsItem): Form<RepeatableEssentialsForm> => item.form,
    );
    formHelper.submitAttemptMultiple(essentialsForms).then(() => {
        if (formHelper.isMultipleFormsValid(essentialsForms)) {
            submit();
        } else {
            useScroll().scrollAnyInvalidFieldToView();
        }
    });
}

function onSubmitDelayed(): void {
    const essentialsForms: Form<RepeatableEssentialsForm>[] = essentialsDelayed.value.map(
        (item: RepeatableEssentialsItem): Form<RepeatableEssentialsForm> => item.form,
    );
    formHelper.submitAttemptMultiple(essentialsForms).then(() => {
        if (formHelper.isMultipleFormsValid(essentialsForms)) {
            submit();
        } else {
            useScroll().scrollAnyInvalidFieldToView();
        }
    });
}

function submit(): void {
    GuardsService.getInstance().applyStepValidity(Step, true);
    router.push({ name: ClaimsTravelStepUid.UploadFiles });
}

onMounted(() => {
    applyTranslationType(ClaimsTravelService.translationType);
    setupForms();
    claimsTravelService.restoreForms(forms);
    restoreIterableValues();
    markFormsAsReady();
    preparePanels();
});

defineExpose({
    clearForms,
    scrollToLastForm,
});
</script>

<template>
    <app-custom-form
        v-if="whatWasDamagedIsVisible"
        :form="forms[LuggageFormNames.WhatWasDamaged]"
        class="form"
        @change="storeForm(LuggageFormNames.WhatWasDamaged)"
    >
        <app-whiteboard
            :data-type="'whiteboard-0'"
            :data-scroll="LuggageFormNames.WhatWasDamaged"
            :title="translated('what_was_damaged')"
        >
            <div class="inputs row">
                <div class="input-block">
                    <app-options-list
                        :options="inputOptions[LuggageFormNames.WhatWasDamaged].options"
                        :form-field="forms[LuggageFormNames.WhatWasDamaged].field(FieldNames.WhatWasDamaged)"
                        :required="true"
                        :show-error-borders="false"
                        @change="onFormInputChange(LuggageFormNames.WhatWasDamaged)"
                    >
                    </app-options-list>
                </div>
            </div>
            <app-button-primary
                v-if="isProceedVisible(LuggageFormNames.WhatWasDamaged)"
                @click="onProceedClick(LuggageFormNames.WhatWasDamaged)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="infoAboutLuggageIsVisible"
        :form="forms[LuggageFormNames.InfoAboutLuggage]"
        class="form"
        @change="storeForm(LuggageFormNames.InfoAboutLuggage)"
    >
        <app-whiteboard
            :data-type="'whiteboard-1'"
            :data-scroll="LuggageFormNames.InfoAboutLuggage"
            :title="translated('enter_info_about_luggage')"
            :title-size="'medium'"
        >
            <div class="inputs row">
                <div class="input-block">
                    <h5 class="title title-offset">{{ translated('carriers_info') }}</h5>
                    <app-input-radio-selector
                        class="column-span-8 input-horizontal"
                        :form-field="forms[LuggageFormNames.InfoAboutLuggage].field(FieldNames.InfoAboutLuggage)"
                        :options="inputOptions[LuggageFormNames.InfoAboutLuggage].options"
                        :type="'with-circle'"
                        @change="onFormInputChange(LuggageFormNames.InfoAboutLuggage)"
                    >
                    </app-input-radio-selector>
                </div>
                <div v-if="isVisibleLuggageItemsInfo" class="luggage-notification">
                    <app-notification
                        :variant="'error'"
                        :with-title="false"
                        :with-close="false"
                        :body="translated('luggage_notification')"
                    ></app-notification>
                </div>
                <div :class="{ 'visually-hidden': !isVisibleLuggageItemsRepeatable }" class="input-block block-offset">
                    <h5 class="margin-bottom">{{ translated('provide_luggage_details') }}</h5>
                    <app-repeatable
                        v-model:items="luggage"
                        class="luggage-item-container"
                        :min="1"
                        :max="MaxItemsLuggage"
                        :factory="createItemFactoryLuggageItem"
                        @remove="onRemoveLuggageItem"
                        @change="onFormInputChange(LuggageFormNames.InfoAboutLuggage)"
                    >
                        <template #default="{ item, index, repeatable }">
                            <app-travel-claim-repeatable
                                :title="'Item #' + (index + 1)"
                                :add-button-text="translated('add_more_item')"
                                :remove-button-text="translated('remove_item')"
                                :item="item"
                                :repeatable="repeatable"
                            >
                                <app-custom-form class="item-form" :form="item.form">
                                    <div class="luggage-item-dimensions">
                                        <app-input-text
                                            class="luggage-item"
                                            :form-field="item.form.field('height')"
                                            :label="translated('height')"
                                            :placeholder="translated('height_placeholder')"
                                            :inside-label="translated('cm')"
                                            :inside-label-class="'clean'"
                                            @change="onFormInputChange(LuggageFormNames.InfoAboutLuggage)"
                                        ></app-input-text>
                                        <app-input-text
                                            class="luggage-item"
                                            :form-field="item.form.field('width')"
                                            :label="translated('width')"
                                            :placeholder="translated('width_placeholder')"
                                            :inside-label="translated('cm')"
                                            :inside-label-class="'clean'"
                                            @change="onFormInputChange(LuggageFormNames.InfoAboutLuggage)"
                                        ></app-input-text>
                                    </div>
                                    <div class="luggage-item-details">
                                        <app-input-text
                                            class="luggage-item"
                                            :form-field="item.form.field('brand')"
                                            :label="translated('brand')"
                                            :placeholder="translated('brand_placeholder')"
                                            @change="onFormInputChange(LuggageFormNames.InfoAboutLuggage)"
                                        ></app-input-text>
                                        <app-input-text
                                            class="luggage-item"
                                            :form-field="item.form.field('year')"
                                            :label="translated('year')"
                                            :placeholder="translated('year_placeholder')"
                                            @change="onFormInputChange(LuggageFormNames.InfoAboutLuggage)"
                                        ></app-input-text>
                                        <app-input-text
                                            class="luggage-item"
                                            :form-field="item.form.field('price')"
                                            :label="translated('price')"
                                            :placeholder="translated('price_placeholder')"
                                            :inside-label="translated('eur')"
                                            :inside-label-class="'clean'"
                                            @change="onFormInputChange(LuggageFormNames.InfoAboutLuggage)"
                                        ></app-input-text>
                                    </div>
                                </app-custom-form>
                            </app-travel-claim-repeatable>
                        </template>
                    </app-repeatable>
                </div>
            </div>
            <app-button-primary
                v-if="isProceedVisible(LuggageFormNames.InfoAboutLuggage)"
                @click="onProceedClick(LuggageFormNames.InfoAboutLuggage)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>

    <app-custom-form
        v-if="whoWasSupervisingIsVisible"
        :form="forms[LuggageFormNames.WhoWasSupervising]"
        class="form"
        @change="storeForm(LuggageFormNames.WhoWasSupervising)"
    >
        <app-whiteboard
            :data-type="'whiteboard-0'"
            :data-scroll="LuggageFormNames.WhoWasSupervising"
            :title="translated('who_was_supervising')"
        >
            <div class="inputs row">
                <div class="input-block">
                    <app-input-radio-selector
                        class="column-span-8 who-was-supervising"
                        :form-field="forms[LuggageFormNames.WhoWasSupervising].field(FieldNames.WhoWasSupervising)"
                        :options="inputOptions[LuggageFormNames.WhoWasSupervising].options"
                        :type="'with-circle'"
                        @change="onFormInputChange(LuggageFormNames.WhoWasSupervising)"
                    >
                    </app-input-radio-selector>
                </div>
            </div>
            <app-button-primary
                v-if="isProceedVisible(LuggageFormNames.WhoWasSupervising)"
                @click="onProceedClick(LuggageFormNames.WhoWasSupervising)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="haveConfirmationIssuedStolenIsVisible"
        :form="forms[LuggageFormNames.HaveConfirmationIssuedStolen]"
        class="form"
        @change="storeForm(LuggageFormNames.HaveConfirmationIssuedStolen)"
    >
        <app-whiteboard
            :data-type="'whiteboard-1'"
            :data-scroll="LuggageFormNames.HaveConfirmationIssuedStolen"
            :title="translated('have_confirmation_issued')"
        >
            <div class="inputs row">
                <div class="input-block">
                    <app-input-radio-selector
                        class="column-span-8 input-horizontal"
                        :form-field="
                            forms[LuggageFormNames.HaveConfirmationIssuedStolen].field(
                                FieldNames.HaveConfirmationIssuedStolen,
                            )
                        "
                        :options="inputOptions[LuggageFormNames.HaveConfirmationIssuedStolen].options"
                        :type="'with-circle'"
                        @change="onFormInputChange(LuggageFormNames.HaveConfirmationIssuedStolen)"
                    >
                    </app-input-radio-selector>
                </div>
            </div>
            <div v-if="isVisibleHaveConfirmationIssuedStolen" class="luggage-notification">
                <app-notification
                    :variant="'error'"
                    :with-title="false"
                    :with-close="false"
                    :body="translated('have_confirmation_issued_notification')"
                ></app-notification>
            </div>
            <div v-if="isVisibleConfirmationIssuedSum" class="inputs row">
                <div class="input-block block-offset">
                    <h5 class="margin-bottom">{{ translated('amount_of_carrier_compensation') }}</h5>
                    <app-input-text
                        class="issued-sum"
                        :form-field="
                            forms[LuggageFormNames.HaveConfirmationIssuedStolen].field(FieldNames.ConfirmationIssuedSum)
                        "
                        :placeholder="translated('amount_of_carrier_compensation_placeholder')"
                        :inside-label="translated('eur')"
                        :inside-label-class="'clean'"
                        @change="onFormInputChange(LuggageFormNames.HaveConfirmationIssuedStolen)"
                    ></app-input-text>
                </div>
            </div>
            <app-button-primary
                v-if="isProceedVisible(LuggageFormNames.HaveConfirmationIssuedStolen)"
                @click="onProceedClick(LuggageFormNames.HaveConfirmationIssuedStolen)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="dayInstitutionInformedIsVisible"
        :form="forms[LuggageFormNames.DayInstitutionInformed]"
        class="form"
        @change="storeForm(LuggageFormNames.DayInstitutionInformed)"
    >
        <app-whiteboard
            :data-type="'whiteboard-2'"
            :data-scroll="LuggageFormNames.DayInstitutionInformed"
            :title="translated('the_date_you_informed')"
        >
            <div class="inputs row">
                <div class="input-block">
                    <div class="calendar-block-container">
                        <app-input-date-with-calendar
                            class="calendar"
                            :number-of-calendars="1"
                            :number-of-days="364"
                            :min-date="calendarMinDate"
                            :max-date="calendarMaxDate"
                            :return-raw="false"
                            :date-visual-empty-marker="translated('select_event_date')"
                            :mobile-button-text="translated('select_date')"
                            :form-field="
                                forms[LuggageFormNames.DayInstitutionInformed].field(FieldNames.DayInstitutionInformed)
                            "
                            @change="onFormInputChange(LuggageFormNames.DayInstitutionInformed)"
                        >
                        </app-input-date-with-calendar>
                        <span class="date-buttons">
                            <button
                                class="button outside calendar-date-button-big"
                                data-type="button-today"
                                @click="onTodayClick"
                            >
                                {{ translated('today') }}
                            </button>
                            <button
                                class="button outside calendar-date-button-big"
                                data-type="button-yesterday"
                                @click="onYesterdayClick"
                            >
                                {{ translated('yesterday') }}
                            </button>
                            <app-button-with-callback
                                class="calendar-date-button-small"
                                v-bind="calendarDaysButtonsParams('today')"
                                @button-callback-click="onTodayClick"
                            >
                            </app-button-with-callback>
                            <app-button-with-callback
                                class="calendar-date-button-small"
                                v-bind="calendarDaysButtonsParams('yesterday')"
                                @button-callback-click="onYesterdayClick"
                            >
                            </app-button-with-callback>
                        </span>
                    </div>
                </div>
            </div>
            <app-button-primary
                v-if="isProceedVisible(LuggageFormNames.DayInstitutionInformed)"
                @click="onProceedClick(LuggageFormNames.DayInstitutionInformed)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="listOfEssentialsStolenIsVisible"
        :form="forms[LuggageFormNames.ListOfEssentialsStolen]"
        class="form"
        @change="storeForm(LuggageFormNames.ListOfEssentialsStolen)"
    >
        <app-whiteboard
            :data-type="'whiteboard-3'"
            :data-scroll="LuggageFormNames.ListOfEssentialsStolen"
            :title="translated('list_the_essentials')"
        >
            <div class="inputs row">
                <div class="input-block">
                    <app-repeatable
                        v-model:items="essentialsStolen"
                        class="essentials-item-container"
                        :min="1"
                        :max="MaxItemsEssentials"
                        :factory="createItemFactoryEssentialItem"
                        @remove="onRemoveEssentials"
                        @change="onFormInputChange(LuggageFormNames.ListOfEssentialsStolen)"
                    >
                        <template #default="{ item, index, repeatable }">
                            <app-travel-claim-repeatable
                                :title="'Item #' + (index + 1)"
                                :add-button-text="translated('add_more_item')"
                                :remove-button-text="translated('remove_item')"
                                :item="item"
                                :repeatable="repeatable"
                            >
                                <app-custom-form class="item-form" :form="item.form">
                                    <div class="essential-item-dimensions">
                                        <app-input-text
                                            class="essential-item"
                                            :form-field="item.form.field('what')"
                                            :label="translated('what')"
                                            :placeholder="translated('what_placeholder')"
                                            @change="onFormInputChange(LuggageFormNames.ListOfEssentialsStolen)"
                                        ></app-input-text>
                                        <app-input-text
                                            class="essential-item"
                                            :form-field="item.form.field('amount')"
                                            :label="translated('amount')"
                                            :placeholder="translated('amount_placeholder')"
                                            :inside-label="translated('eur')"
                                            :inside-label-class="'clean'"
                                            @change="onFormInputChange(LuggageFormNames.ListOfEssentialsStolen)"
                                        ></app-input-text>
                                    </div>
                                </app-custom-form>
                            </app-travel-claim-repeatable>
                        </template>
                    </app-repeatable>
                </div>
            </div>
            <app-button-primary
                v-if="isProceedVisible(LuggageFormNames.ListOfEssentialsStolen)"
                @click="onProceedClick(LuggageFormNames.ListOfEssentialsStolen)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>

    <app-custom-form
        v-if="haveConfirmationIssuedDelayedIsVisible"
        :form="forms[LuggageFormNames.HaveConfirmationIssuedDelayed]"
        class="form"
        @change="storeForm(LuggageFormNames.HaveConfirmationIssuedDelayed)"
    >
        <app-whiteboard
            :data-type="'whiteboard-0'"
            :data-scroll="LuggageFormNames.HaveConfirmationIssuedDelayed"
            :title="translated('dou_you_have_confirmation_delayed')"
        >
            <div class="inputs row">
                <div class="input-block">
                    <app-input-radio-selector
                        class="column-span-8 input-horizontal"
                        :form-field="
                            forms[LuggageFormNames.HaveConfirmationIssuedDelayed].field(
                                FieldNames.HaveConfirmationIssuedDelayed,
                            )
                        "
                        :options="inputOptions[LuggageFormNames.HaveConfirmationIssuedDelayed].options"
                        :type="'with-circle'"
                        @change="onFormInputChange(LuggageFormNames.HaveConfirmationIssuedDelayed)"
                    >
                    </app-input-radio-selector>
                </div>
                <div v-if="isVisibleHaveConfirmationIssuedDelayedInfo" class="luggage-notification">
                    <app-notification
                        :variant="'error'"
                        :with-title="false"
                        :with-close="false"
                        :body="translated('luggage_notification_delayed')"
                    ></app-notification>
                </div>
                <div v-if="isNotVisibleHaveConfirmationIssuedDelayedInfo" class="input-block">
                    <div class="input-block block-offset hours-compensation">
                        <app-input-text
                            class="hours-item"
                            :form-field="
                                forms[LuggageFormNames.HaveConfirmationIssuedDelayed].field(
                                    FieldNames.HowManyHoursLuggageDelayed,
                                )
                            "
                            :label="translated('how_many_hours')"
                            :placeholder="translated('how_many_hours_placeholder')"
                            :inside-label="translated('hrs')"
                            :inside-label-class="'clean'"
                            @change="onFormInputChange(LuggageFormNames.HaveConfirmationIssuedDelayed)"
                        ></app-input-text>
                        <app-input-text
                            class="sum-item"
                            :form-field="
                                forms[LuggageFormNames.HaveConfirmationIssuedDelayed].field(
                                    FieldNames.AmountOfCompensationByCarrier,
                                )
                            "
                            :label="translated('amount_of_compensation')"
                            :placeholder="translated('amount_of_compensation_placeholder')"
                            :inside-label="translated('eur')"
                            :inside-label-class="'clean'"
                            @change="onFormInputChange(LuggageFormNames.HaveConfirmationIssuedDelayed)"
                        ></app-input-text>
                    </div>
                </div>
            </div>
            <app-button-primary
                v-if="isProceedVisible(LuggageFormNames.HaveConfirmationIssuedDelayed)"
                @click="onProceedClick(LuggageFormNames.HaveConfirmationIssuedDelayed)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="listOfEssentialsDelayedIsVisible"
        :form="forms[LuggageFormNames.ListOfEssentialsDelayed]"
        class="form"
        @change="storeForm(LuggageFormNames.ListOfEssentialsDelayed)"
    >
        <app-whiteboard
            :data-type="'whiteboard-3'"
            :data-scroll="LuggageFormNames.ListOfEssentialsDelayed"
            :title="translated('list_the_essentials')"
        >
            <div class="inputs row">
                <div class="input-block">
                    <app-repeatable
                        v-model:items="essentialsDelayed"
                        class="essentials-item-container"
                        :min="1"
                        :max="MaxItemsEssentials"
                        :factory="createItemFactoryEssentialItem"
                        @remove="onRemoveEssentials"
                        @change="onFormInputChange(LuggageFormNames.ListOfEssentialsDelayed)"
                    >
                        <template #default="{ item, index, repeatable }">
                            <app-travel-claim-repeatable
                                :title="'Item #' + (index + 1)"
                                :add-button-text="translated('add_more_item')"
                                :remove-button-text="translated('remove_item')"
                                :item="item"
                                :repeatable="repeatable"
                            >
                                <app-custom-form class="item-form" :form="item.form">
                                    <div class="essential-item-dimensions">
                                        <app-input-text
                                            class="essential-item"
                                            :form-field="item.form.field('what')"
                                            :label="translated('what')"
                                            :placeholder="translated('what_placeholder')"
                                            @change="onFormInputChange(LuggageFormNames.ListOfEssentialsDelayed)"
                                        ></app-input-text>
                                        <app-input-text
                                            class="essential-item"
                                            :form-field="item.form.field('amount')"
                                            :label="translated('amount')"
                                            :placeholder="translated('amount_placeholder')"
                                            :inside-label="translated('eur')"
                                            :inside-label-class="'clean'"
                                            @change="onFormInputChange(LuggageFormNames.ListOfEssentialsDelayed)"
                                        ></app-input-text>
                                    </div>
                                </app-custom-form>
                            </app-travel-claim-repeatable>
                        </template>
                    </app-repeatable>
                </div>
            </div>
            <app-button-primary
                v-if="isProceedVisible(LuggageFormNames.ListOfEssentialsDelayed)"
                @click="onProceedClick(LuggageFormNames.ListOfEssentialsDelayed)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
</template>

<style scoped lang="scss">
.form {
    .input-block {
        .title-offset {
            margin-bottom: var(--size-small);
        }

        .options-list {
            :deep(.options-list-option) {
                min-height: 64px;

                .options-list-panel {
                    min-height: 64px;
                    padding: 13px 22px 13px 64px;

                    &::before {
                        margin-left: var(--size-small);
                    }

                    .checkbox-icon {
                        left: 25px;
                    }
                }
            }
        }

        .essentials-item-container,
        .luggage-item-container {
            .luggage-item-dimensions,
            .essential-item-dimensions,
            .luggage-item-details {
                .luggage-item,
                .essential-item {
                    :deep(.label) {
                        label {
                            font-weight: 600;
                        }
                    }
                }
            }

            .luggage-item-dimensions,
            .essential-item-dimensions {
                display: flex;
                justify-content: space-between;
                gap: var(--size-small);
                margin-top: var(--size-small);

                .luggage-item,
                .essential-item {
                    flex: 1;
                }
            }

            .luggage-item-details {
                display: flex;
                justify-content: space-between;
                gap: var(--size-small);
                margin-top: var(--size-small);
                margin-bottom: var(--size-small);

                @include respond-below('sm') {
                    flex-direction: column;
                }

                .luggage-item {
                    flex: 1;
                }
            }

            .repeatable-item {
                border-top: 1px solid var(--component-color-border-default);

                &:first-child {
                    border-top: none;
                }

                :deep(.action-buttons:not(:empty)) {
                    margin-top: var(--size-small);
                }
            }
        }

        .who-was-supervising {
            :deep(.grid-utility) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .issued-sum {
            width: 50%;
            margin-top: var(--size-small);

            @include respond-below('sm') {
                width: 100%;
            }
        }

        .calendar-block-container {
            display: flex;
            justify-content: space-between;

            @include respond-below('sm') {
                flex-direction: column;
                gap: var(--size-small);
            }

            .date-buttons {
                .button-with-callback {
                    background-color: var(--system-color-success-dark);
                }
            }
        }

        .input-date-with-calendar {
            margin-right: var(--size-small);
            flex: 1;

            @include respond-below('sm') {
                margin-right: 0;
            }
        }

        .date-buttons {
            display: flex;

            .button:not(:last-child) {
                margin-right: var(--size-small);
            }

            .calendar-date-button-big {
                display: flex;
                @include respond-below('sm') {
                    display: none;
                }
            }

            .calendar-date-button-small {
                display: none;
                height: 32px;
                padding: var(--size-femto) var(--size-tiny);
                font-size: var(--font-size-nano);

                @include respond-below('sm') {
                    display: block;
                }
            }

            @include respond-below('sm') {
                gap: var(--size-nano);
            }
        }

        .hours-compensation {
            display: flex;
            justify-content: space-between;
            gap: var(--size-small);

            @include respond-below('sm') {
                flex-direction: column;
            }

            .hours-item,
            .sum-item {
                flex: 1;

                :deep(.label) {
                    font-size: var(--font-size-small);
                    font-weight: 700;
                    text-align: left;
                }
            }

            .sum-item {
                @include respond-below('sm') {
                    margin-top: var(--size-small);
                }
            }
        }
    }

    .luggage-notification {
        margin-top: var(--size-small);
    }

    .block-offset {
        margin-top: var(--size-big);
    }

    .one-button {
        margin-top: var(--size-big);
    }

    .input-horizontal {
        width: 50%;

        :deep(.grid-utility) {
            .radio-button {
                grid-column: span 6;
                min-height: 64px;
            }
        }

        @include respond-below('sm') {
            width: 100%;

            :deep(.grid-utility) {
                display: grid;
                grid-template-columns: repeat(12, 1fr);
            }
        }
    }
}
</style>
