<script lang="ts">
import { computed, defineComponent, ref, Ref, nextTick, watch } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import Form from '@/Assets/Libraries/Form/Form';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { Subscription } from 'rxjs';
import { useDefine } from '@/Composables/Define';
import { OneDashboardInterface, useOneDashboard } from '@/Pages/OneDashboard/Composables/OneDashboard';
import { useAxios } from '@/Composables/Axios';
import { useTranslate } from '@/Composables/Translate';
import FormField from '@/Assets/Libraries/Form/FormField';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import PopupType from '@/Enums/PopupTypeEnum';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import Url from '@/Enums/UrlEnum';
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import { useNavigate } from '@/Composables/Navigate';
import ErrorType from '@/Enums/ErrorTypeEnum';
import UserCredentials from '@/Interfaces/user.credentials.interface';
import StringDictionary from '@/Interfaces/string.dictionary.interface';
import Value from '@/Assets/Libraries/Form/Value';
import RequestService from '@/Services/request.service';
import { AxiosResponse } from 'axios';
import Validation from '@/Services/validation.service';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const oneDashboard: OneDashboardInterface = useOneDashboard();
        const CurrentStep: number = 1;
        const Facility: string = 'dashboard';
        const ConsentsGlue: string = ':';
        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const addressMode: Ref<string> = ref('');
        const genderOptions: Ref<InputOption[]> = ref([]);
        const contactLanguageEnabled: Ref<boolean> = ref(false);
        const contactLanguageOptions: Ref<InputOption[]> = ref([]);
        const genderEnabled: Ref<boolean> = ref(false);
        const hasSubscriptions: Ref<boolean> = ref(false);
        const cardNumberParts: Ref<string[]> = ref([]);
        const updateCardMessage: Ref<DynamicDictionary> = ref({
            type: '',
            message: '',
        });
        const genderIsActive: Ref<boolean> = computed(() => {
            return genderEnabled.value;
        });
        const subscriptionsEnabled: Ref<boolean> = computed(() => {
            return hasSubscriptions.value;
        });
        const isMobileDevice: Ref<boolean> = computed(() => {
            return btaBase.isMobile.value;
        });
        const cardUpdateStatusMessage: Ref<DynamicDictionary> = computed(() => {
            return updateCardMessage.value;
        });
        const agreedHealthConsent: Ref<boolean> = computed(() => {
            return btaBase.user.userAgreedPersonalProcessing();
        });
        const userHasActiveHealthPolicy: Ref<boolean> = computed(() => {
            return btaBase.user.hasActiveHealthPolicy;
        });

        watch(
            () => form.field('address').value,
            (): void => {
                addressMode.value = 'apartment';
            },
        );

        function onAppReady(): void {
            setupForm().then((): void => {
                applyValues();
                btaBase.setOffersCount();
            });
        }

        function setupForm(): Promise<void> {
            return new Promise((resolve) => {
                if (genderIsActive.value) {
                    form.addField(new FormField('gender'));
                }
                form.addField(new FormField('name'));
                form.addField(new FormField('personalCode'));
                form.addField(new FormField('clientNumber'));
                form.addField(new FormField('email', '', 'required'));
                form.addField(new FormField('phoneWithCountry', {}));
                form.addField(new FormField('address'));
                form.addField(new FormField('healthConsent'));
                form.addField(new FormField('account'));
                form.addField(new FormField('marketingConsents'));
                if (subscriptionsEnabled.value) {
                    cardNumberParts.value.forEach((numberPart: string): void => {
                        form.addField(new FormField('subscriptionCard' + numberPart));
                    });
                }
                if (contactLanguageEnabled.value) {
                    form.addField(new FormField('contactLanguage'));
                }
                form.setReady();
                formIsReady.value = true;
                resolve();
            });
        }

        function applyContactLanguageStatus(value: boolean): void {
            contactLanguageEnabled.value = value;
        }

        function applyGenderStatus(value: boolean): void {
            genderEnabled.value = value;
        }

        function applyCardNumberParts(newCardNumberParts: string[]): void {
            cardNumberParts.value = newCardNumberParts;
        }

        function applySubscriptionsEnabled(newHasSubscriptions: boolean): void {
            hasSubscriptions.value = newHasSubscriptions;
        }

        function updateDetailsButtonIsClasses(): string {
            return form.isValid() ? '' : 'disabled';
        }

        function propertyLabel(): string {
            return useTranslate().translate('btar_property_address');
        }

        function addressLabel(): string {
            return useTranslate().translate('btar_apartment');
        }

        function formattedHealthConsentExpiration(title: string): string {
            let returnValue: string = title;
            if (btaBase.user.userAgreedPersonalProcessing()) {
                returnValue +=
                    ' (' +
                    useTranslate().translate('btar_health_agree_terms_valid_until') +
                    ': ' +
                    OneDate.short(btaBase.user.userPersonalProcessingExpirationDate()) +
                    ')';
            }

            return returnValue;
        }

        function onHealthConsentClick(value: boolean): void {
            if (!btaBase.user.userAgreedPersonalProcessing() || btaBase.user.userAgreedPersonalProcessing()) {
                btaBase.popup
                    .applyCallbackModel(healthAgreeCallback)
                    .applyOnCloseCallback(healthAgreeCloseCallback, null);
                if (value) {
                    btaBase.popup.showPopup(PopupType.HealthPolicyAgree);
                }
            }
        }

        function submitCardDetailsUpdate(cardNumberPart: string): void {
            nextTick((): void => {
                btaBase.lockInput(true);
                PopupService.getInstance().show(new OnePopup().withType().loading);
            });
            useAxios()
                .post(Url.Ajax.dashboardChangeCard, {
                    numberPart: cardNumberPart,
                    banklinkId: RecurringPaymentBanklink.ByCountry[new AppCountry().iso()],
                })
                .then((value: DynamicDictionary): void => {
                    if (useDefine().validResponse(value) && value.data.data.status === 'OK') {
                        useNavigate().navigate(Url.SubscriptionApi.recurringRegisterWithoutPayment);
                    } else {
                        PopupService.getInstance().hide();
                        btaBase.lockInput(false);
                        btaBase.error.show(ErrorType.Error, 'submitCardDetailsUpdate::', value);
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    PopupService.getInstance().hide();
                    btaBase.lockInput(false);
                    btaBase.error.show(ErrorType.Error, 'submitCardDetailsUpdate::', reason);
                });
        }

        function applyValues(): void {
            const currentUser: UserCredentials = btaBase.user.current;
            applyGenderValues();
            form.field('name').patch(currentUser.name);
            form.field('account').patch(currentUser.bank);
            form.field('personalCode').patch(currentUser.personCode);
            form.field('clientNumber').patch(currentUser.clientNumber);
            form.field('email').patch(currentUser.email);
            const phoneWithCountry: DynamicDictionary = {
                iso: currentUser.code,
                country: currentUser.phoneCode,
                phone: currentUser.phone,
            };
            form.field('phoneWithCountry').patch(phoneWithCountry);
            form.field('address').patch({
                addressCode: currentUser.addressCode,
                label: currentUser.address,
                value: '',
                additional: currentUser.addressExtra,
                postCode: currentUser.postCode,
            });
            applyMarketingConsentValues();
            applyHealthConsentValues();
            applyContactLanguageValues();
        }

        function applyGenderValues(): void {
            if (genderOptions.value.length === 0 && genderIsActive.value) {
                genderOptions.value.push({
                    name: useTranslate().translate('btar_gender_male'),
                    value: String(useTranslate().translate('btar_gender_male')).toLowerCase(),
                    icon: '',
                });
                genderOptions.value.push({
                    name: useTranslate().translate('btar_gender_female'),
                    value: String(useTranslate().translate('btar_gender_female')).toLowerCase(),
                    icon: '',
                });
                form.addField(new FormField('gender', btaBase.user.current.gender));
            }
        }

        function applyContactLanguageValues(): void {
            if (contactLanguageOptions.value.length === 0 && contactLanguageEnabled.value) {
                switch (true) {
                    case new AppCountry().isLV():
                        contactLanguageOptions.value.push({
                            name: oneDashboard.translated('one_dashboard_communication_language_lv'),
                            value: 'LV',
                        });
                        contactLanguageOptions.value.push({
                            name: oneDashboard.translated('one_dashboard_communication_language_ru'),
                            value: 'RU',
                        });
                        break;
                    case new AppCountry().isEE():
                        contactLanguageOptions.value.push({
                            name: oneDashboard.translated('one_dashboard_communication_language_ee'),
                            value: 'EE',
                        });
                        contactLanguageOptions.value.push({
                            name: oneDashboard.translated('one_dashboard_communication_language_ru'),
                            value: 'RU',
                        });
                        break;
                    case new AppCountry().isLT():
                        contactLanguageOptions.value.push({
                            name: oneDashboard.translated('one_dashboard_communication_language_lt'),
                            value: 'LT',
                        });
                        break;
                    default:
                        break;
                }
                contactLanguageOptions.value.push({
                    name: oneDashboard.translated('one_dashboard_communication_language_en'),
                    value: 'EN',
                });
                const contactLanguage: string =
                    btaBase.user.current.contactLanguage !== ''
                        ? btaBase.user.current.contactLanguage
                        : new AppCountry().iso();
                form.field('contactLanguage').patch(contactLanguage);
            }
        }

        function applyMarketingConsentValues(): void {
            const hasAgreedToMarketing: boolean = btaBase.user.current.receiveMarketing;
            if (hasAgreedToMarketing) {
                form.field('marketingConsents').patch({
                    customText: {},
                    selected: btaBase.user.current.marketingConsents.join(ConsentsGlue),
                });
            }
        }

        function applyHealthConsentValues(): void {
            const value: boolean = btaBase.user.userAgreedPersonalProcessing();
            form.field('healthConsent').patch(value);
        }

        function updateDetails(): void {
            form.touch();
            if (form.isValid()) {
                if (genderIsActive.value) {
                    btaBase.user.current.gender = form.field('gender').value;
                }
                const email: string = form.field('email').value;
                if (btaBase.user.current.email !== email) {
                    btaBase.user.current.email = email;
                }
                const newMobileAndFixedPhone: { phone: string; isFixedPhone: boolean } = mobileAndFixedPhone();
                btaBase.user.current.isFixedPhone = newMobileAndFixedPhone.isFixedPhone;
                const phone: string = newMobileAndFixedPhone.phone;
                if (btaBase.user.current.phone !== phone) {
                    btaBase.user.current.phone = phone;
                }
                const phoneCountry: string = form.field('phoneWithCountry').value.country;
                if (btaBase.user.current.phoneCode !== phoneCountry) {
                    btaBase.user.current.phoneCode = phoneCountry;
                }
                btaBase.user.current.code = form.field('phoneWithCountry').value.code;
                const addressField: FormField = form.field('address');
                const address: StringDictionary = addressField.value;
                if (!addressField.isEmpty()) {
                    if (address.label !== '') {
                        if (btaBase.user.current.address !== address.label) {
                            btaBase.user.current.address = address.detailed;
                            btaBase.user.current.addressCode = address.addressCode;
                        }
                        if (address.apartment !== '') {
                            btaBase.user.current.addressExtra = address.apartment;
                        }
                        if (address.postCode !== '') {
                            btaBase.user.current.postCode = address.postCode;
                        }
                    } else {
                        btaBase.user.current.address = address.detailed;
                        btaBase.user.current.addressCode = address.addressCode;
                        btaBase.user.current.addressCountry = parseInt(address.countryId, 10);
                        btaBase.user.current.postCode = address.postCode;
                    }
                }
                const account: string = form.field('account').value;
                if (btaBase.user.current.bank !== account) {
                    btaBase.user.current.bankTemp = account;
                }
                btaBase.user.current.receiveMarketing = marketingConsents().length > 0;
                btaBase.user.current.marketingConsents = marketingConsents();
                if (contactLanguageEnabled.value) {
                    btaBase.user.current.contactLanguage = form.field('contactLanguage').value;
                }
                submitUserUpdate();
            }
        }

        function marketingConsents(): string[] {
            const selectedConsents: string = form.field('marketingConsents').value.selected;

            return new Value(selectedConsents).isNotEmpty() ? selectedConsents.split(ConsentsGlue) : [];
        }

        function submitUserUpdate(): void {
            if (btaBase.inputIsLocked.value) {
                return;
            }
            btaBase.lockInput(true);
            PopupService.getInstance().show(new OnePopup().withType().loading);

            const params: DynamicDictionary = btaBase.user.assembleUser();
            RequestService.getInstance()
                .post({ uri: Url.Ajax.oneDashboardUpdateUser, content: params })
                .then((value: AxiosResponse): void => {
                    if (typeof value.data.errors !== 'undefined') {
                        btaBase.lockInput(false);
                        PopupService.getInstance().hide();
                        btaBase.error.show(ErrorType.Error, 'submitUserUpdate', value.data.errors.code);
                    } else {
                        window.location.reload();
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    PopupService.getInstance().hide();
                    btaBase.lockInput(false);
                    btaBase.error.show(ErrorType.Error, 'submitUserUpdate', reason);
                });
        }

        function mobileAndFixedPhone(): { phone: string; isFixedPhone: boolean } {
            const phone: string = form.field('phoneWithCountry').value.phone;
            const isFixedPhone: boolean = Validation.isValidFixedPhone(phone, btaBase.settings.localeIso());

            return { phone: phone, isFixedPhone: isFixedPhone };
        }

        function healthAgreeCallback(): void {
            const params: DynamicDictionary = {};
            const headers: DynamicDictionary = {};
            btaBase.lockInput(true);
            PopupService.getInstance().show(new OnePopup().withType().loading);
            useAxios()
                .post(Url.Ajax.agreeToHealthTerms, params, {
                    headers: headers,
                })
                .then((value: DynamicDictionary): void => {
                    const data: DynamicDictionary = value.data.data.consent;
                    if (data.error === false) {
                        btaBase.user.current.agreedPersonalProcessing = data.consent.agreedPersonalProcessing;
                        btaBase.user.current.personalProcessingExpirationDate =
                            data.consent.personalProcessingExpirationDate;
                    }
                    btaBase.lockInput(false);
                    PopupService.getInstance().hide();
                })
                .catch((reason: DynamicDictionary): void => {
                    btaBase.lockInput(false);
                    PopupService.getInstance().hide();
                    btaBase.error.show(ErrorType.Error, 'healthConsent', reason);
                });
        }

        function healthAgreeCloseCallback(): void {
            form.field('healthConsent').patch(false);
            btaBase.lockInput(false);
            btaBase.showPopup(PopupType.None);
        }

        function onAddressLockChange(addressIsLocked: boolean): void {
            if (addressIsLocked) {
                addressMode.value = '';
                $('.apartment').hide();
            } else {
                addressMode.value = 'apartment';
                $('.apartment').show();
            }
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                genderOptions,
                contactLanguageEnabled,
                contactLanguageOptions,
                genderEnabled,
                hasSubscriptions,
                cardNumberParts,
                updateCardMessage,
                oneDashboard,
                onAppReady,
                setupForm,
                applyContactLanguageStatus,
                applyGenderStatus,
                applyCardNumberParts,
                submitUserUpdate,
                healthAgreeCloseCallback,
                applySubscriptionsEnabled,
                updateDetailsButtonIsClasses,
                propertyLabel,
                mobileAndFixedPhone,
                healthAgreeCallback,
                addressLabel,
                formattedHealthConsentExpiration,
                onHealthConsentClick,
                submitCardDetailsUpdate,
                marketingConsents,
                onAddressLockChange,
                updateDetails,
                genderIsActive,
                subscriptionsEnabled,
                isMobileDevice,
                cardUpdateStatusMessage,
                agreedHealthConsent,
                userHasActiveHealthPolicy,
                addressMode,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.oneDashboard.init();
        const subscription: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.onAppReady();
            subscription.unsubscribe();
        });
    },
});
</script>
