import AppOptionsList from '@/Components/Options/OptionsList/OptionsList.vue';
import AppOptionsSmartList from '@/Components/Options/OptionsSmartList/OptionsSmartList.vue';
import TravelOptionRentalCarSecurity from '@/Components/Lists/AdditionalOptionsList/Components/TravelOptionRentalCarSecurity.vue';
import MovablePropertyOption from '@/Components/Lists/AdditionalOptionsList/Components/MovablePropertyOption.vue';
import MtplOfferOption from '@/Components/Lists/AdditionalOptionsList/Components/MtplOfferOption.vue';
import GroupedRadioListSingle from '@/Components/Options/GroupedRadioListSingle/GroupedRadioListSingle.vue';

import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppOptionsList: AppOptionsList,
    AppOptionsSmartList: AppOptionsSmartList,
    AppTravelOptionRentalCarSecurity: TravelOptionRentalCarSecurity,
    AppMovablePropertyOption: MovablePropertyOption,
    AppMtplOfferOption: MtplOfferOption,
    AppGroupedRadioListSingle: GroupedRadioListSingle,
} as Components;
