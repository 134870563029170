<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { useDefine } from '@/Composables/Define';
import { Router, useRouter } from 'vue-router';
import OneBaseService from '@/Services/OneBaseService';
import RequestService from '@/Services/request.service';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Services/TwoWayCommunicationService';
import { computed, getCurrentInstance, nextTick, onMounted, ref, Ref, watch } from 'vue';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import TwoWayFormFields from '@/Apps/TwoWayCommunication/Classes/TwoWayFormFields';
import TwoWayGuardsService from '@/Apps/TwoWayCommunication/Services/TwoWayGuardsService';
import TwoWaySupportType from '@/Apps/TwoWayCommunication/Enums/TwoWaySupportTypeEnum';
import TwoWaySteps from '@/Apps/TwoWayCommunication/Enums/TwoWayStepsEnum';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonsWithCallbackBuilder from '@/Components/Buttons/ButtonWithCallback/Builders/ButtonsWithCallbackBuilder';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import PopupService from '@/Services/custom.popup.service';
import { AxiosResponse } from 'axios';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import SpaUserStorage from '@/Services/SpaUserStorageService';
import Agreement from '@/Components/Policies/PolicyBlock/Interfaces/AgreementInterface';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import Url from '@/Enums/UrlEnum';
import UserClaim from '@/Interfaces/user.claim.interface';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import { ClaimStatus } from '@/Components/Claims/ClaimBlock/Enums/ClaimStatus.enum';
import InsurancesContentTab from '@/Pages/OneDashboard/Insurances/Enums/InsurancesContentTabEnum';
import Claim from '@/Components/Claims/ClaimBlock/Interfaces/ClaimInterface';
import TwoWayAjaxCalls from '@/Apps/TwoWayCommunication/Enums/TwoWayAjaxCallsEnum';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import ResultMessage from '@/Components/Other/ResultMessage/Services/ResultMessageService';

const { translateForType, translate, applyTranslationType, type } = useTranslate();
const { validResponse } = useDefine();
const router: Router = useRouter();

const requestService: RequestService = RequestService.getInstance();
const twoWayCommunicationService: TwoWayCommunication = TwoWayCommunication.getInstance();
const spaUserStorage: SpaUserStorage = SpaUserStorage.getInstance();

const Step: number = 2;
const AllClaims: string = 'all_claims';
const UploaderId: string = 'communications';
const AgreementTypes: string[] = [
    InsurancesContentTab.Active,
    InsurancesContentTab.Terminated,
    InsurancesContentTab.Transport,
    InsurancesContentTab.Property,
    InsurancesContentTab.Health,
    InsurancesContentTab.Accident,
    InsurancesContentTab.Travel,
    InsurancesContentTab.Other,
];

const claimsTabs: Ref<InputOption[]> = ref([]);
const policiesTabs: Ref<InputOption[]> = ref([]);
const isFetchInProgress: Ref<boolean> = ref(false);
const tabsBlockIsVisible: Ref<boolean> = ref(false);

const hasSelectedClaim: Ref<boolean> = computed(() => {
    return twoWayCommunicationService.storageFields.selectedClaim !== null;
});

const hasSelectedPolicy: Ref<boolean> = computed(() => {
    return twoWayCommunicationService.storageFields.selectedPolicy !== null;
});

const isReplyOrAddNew: Ref<boolean> = computed(() => {
    return twoWayCommunicationService.storageFields.isReply || twoWayCommunicationService.storageFields.isAddNewMessage;
});

const isVisibleSupportType: Ref<boolean> = computed(() => {
    return twoWayCommunicationService.storageFields.supportType === TwoWaySupportType.None && !isReplyOrAddNew.value;
});

const isCaseSupportType: Ref<boolean> = computed(() => {
    return (
        twoWayCommunicationService.storageFields.supportType === TwoWaySupportType.ExistingClaim ||
        twoWayCommunicationService.storageFields.supportType === TwoWaySupportType.ExistingPolicy
    );
});

const caseSupportIsClaim: Ref<boolean> = computed(() => {
    return twoWayCommunicationService.storageFields.supportType === TwoWaySupportType.ExistingClaim;
});

const caseSupportIsPolicy: Ref<boolean> = computed(() => {
    return twoWayCommunicationService.storageFields.supportType === TwoWaySupportType.ExistingPolicy;
});

const isCaseSupportTypeNew: Ref<boolean> = computed(() => {
    return twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.New;
});

const tabs: Ref<InputOption[]> = computed(() => {
    return caseSupportIsClaim.value ? claimsTabs.value : policiesTabs.value;
});

const isVisibleCaseSupport: Ref<boolean> = computed(() => {
    return (
        isCaseSupportType.value &&
        twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.None &&
        !isReplyOrAddNew.value
    );
});

const isVisibleSelectClaim: Ref<boolean> = computed(() => {
    return (
        isCaseSupportType.value &&
        twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.ExistingClaim &&
        !hasSelectedClaim.value &&
        !isReplyOrAddNew.value
    );
});

const isVisibleSelectPolicy: Ref<boolean> = computed(() => {
    return (
        isCaseSupportType.value &&
        twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.ExistingPolicy &&
        !hasSelectedPolicy.value &&
        !isReplyOrAddNew.value
    );
});

const isVisibleMessage: Ref<boolean> = computed(() => {
    return (
        twoWayCommunicationService.storageFields.supportType === TwoWaySupportType.Other ||
        (isCaseSupportType.value &&
            twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.ExistingPolicy &&
            hasSelectedPolicy.value) ||
        (isCaseSupportType.value &&
            twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.ExistingClaim &&
            hasSelectedClaim.value) ||
        isReplyOrAddNew.value ||
        twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.New
    );
});

const headerClass: Ref<string> = computed(() => {
    const result: string[] = ['full-width'];
    if (isVisibleMessage.value) {
        result.push('block-small');
    } else {
        result.push(isVisibleSelectClaim.value || isVisibleSelectPolicy.value ? 'block-medium' : 'block-tiny');
    }

    return result.join(' ');
});

const flowTitle: Ref<string> = computed(() => {
    let result: string = '';
    if (twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.None) {
        switch (twoWayCommunicationService.storageFields.supportType) {
            case TwoWaySupportType.None:
                result = translated('communication_subject_title');
                break;
            case TwoWaySupportType.ExistingClaim:
                result = translated('communication_claim_support_type_title');
                break;
            case TwoWaySupportType.ExistingPolicy:
                result = translated('communication_policy_support_type_title');
                break;
            case TwoWaySupportType.Other:
                result = translated('communication_new_message_title');
                break;
            case TwoWaySupportType.Reply:
                result = translated('communication_reply_title');
                break;
            default:
        }
    } else {
        switch (twoWayCommunicationService.storageFields.supportType) {
            case TwoWaySupportType.ExistingClaim:
                if (!hasSelectedClaim.value) {
                    if (isVisibleSelectClaim.value) {
                        result = translated('communication_claim_case_support_title');
                    } else {
                        result = translated('communication_claim_new_support_title');
                    }
                } else {
                    result = translated('communication_claim_case_support_message');
                }
                break;
            case TwoWaySupportType.ExistingPolicy:
                if (!hasSelectedPolicy.value) {
                    if (isVisibleSelectPolicy.value) {
                        result = translated('communication_policy_case_support_title');
                    } else {
                        result = translated('communication_policy_new_support_title');
                    }
                } else {
                    result = translated('communication_policy_case_support_message');
                }
                break;
            default:
        }
    }

    return result;
});

const flowDescription: Ref<string> = computed(() => {
    let result: string = '';
    if (twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.None) {
        switch (twoWayCommunicationService.storageFields.supportType) {
            case TwoWaySupportType.None:
                result = translated('communication_new_message_description');
                break;
            case TwoWaySupportType.ExistingClaim:
                result = translated('communication_claim_support_type_description');
                break;
            case TwoWaySupportType.ExistingPolicy:
                result = translated('communication_policy_support_type_description');
                break;
            case TwoWaySupportType.Other:
                result = translated('communication_new_message_description');
                break;
            case TwoWaySupportType.Reply:
                result = translated('communication_reply_description');
                break;
            default:
        }
    } else {
        switch (twoWayCommunicationService.storageFields.supportType) {
            case TwoWaySupportType.ExistingClaim:
                if (!hasSelectedClaim.value) {
                    if (isVisibleSelectClaim.value) {
                        result = translated('communication_my_existing_claim_description');
                    } else {
                        result = translated('communication_my_existing_claim_new_message_description');
                    }
                } else {
                    result = translated('communication_my_existing_claim_selected_message_description');
                }
                break;
            case TwoWaySupportType.ExistingPolicy:
                if (!hasSelectedPolicy.value) {
                    if (isVisibleSelectPolicy.value) {
                        result = translated('communication_my_existing_policy_description');
                    } else {
                        result = translated('communication_my_existing_policy_new_message_description');
                    }
                } else {
                    result = translated('communication_my_existing_policy_selected_message_description');
                }
                break;
            default:
        }
    }

    return result;
});

const filteredClaims: Ref<Claim[]> = computed(() => {
    let result: Claim[] = [];
    if (String(twoWayCommunicationService.storageFields.claimsList) !== '') {
        result = twoWayCommunicationService.storageFields.claimsList.filter((claim: Claim): boolean => {
            const selectedType: string = spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).value;
            return (
                selectedType === AllClaims ||
                claim.policyType === (spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).value as string)
            );
        });
    }

    return result;
});

const filteredPolicies: Ref<Agreement[]> = computed(() => {
    let result: Agreement[] = [];
    if (String(twoWayCommunicationService.storageFields.policyList) !== '') {
        result = twoWayCommunicationService.storageFields.policyList.filter((policy: Agreement): boolean => {
            const selectedType: string = spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).value;
            return policy.tabType === (selectedType as string);
        });
    }

    return result;
});

const canProceedToNextStep: Ref<boolean> = computed(() => {
    return true;
});

const claimCaseLabel: Ref<string> = computed(() => {
    let result: string = '';
    if (hasSelectedClaim.value) {
        result = translated('claim_no');
    }

    return result;
});

const policyCaseLabel: Ref<string> = computed(() => {
    let result: string = '*';
    if (hasSelectedPolicy.value) {
        result = translated('policy_no');
    }

    return result;
});

const caseLabel: Ref<string> = computed(() => {
    return caseSupportIsClaim.value ? claimCaseLabel.value : policyCaseLabel.value;
});

const selectedCaseNumber: Ref<string> = computed(() => {
    let result: string;
    switch (true) {
        case caseSupportIsClaim.value && !isCaseSupportTypeNew.value:
            result = twoWayCommunicationService.storageFields.selectedClaim!.claimNumber;
            break;
        case caseSupportIsPolicy.value && !isCaseSupportTypeNew.value:
            result = twoWayCommunicationService.storageFields.selectedPolicy!.agreementNumber;
            break;
        default:
            result = '';
    }

    return result;
});

watch(
    () => isVisibleSupportType.value,
    (value: boolean, oldValue: boolean) => {
        if (value !== oldValue && value) {
            scrollToTop();
        }
    },
);

watch(
    () => isVisibleCaseSupport.value,
    (value: boolean, oldValue: boolean) => {
        if (value !== oldValue && value) {
            scrollToTop();
        }
    },
);

watch(
    () => isVisibleSelectClaim.value,
    (value: boolean, oldValue: boolean) => {
        if (value !== oldValue && value) {
            scrollToTop();
            fetchClaimsList().then(() => {
                buildClaimsTabs();
                if (hasNavigationPayload()) {
                    twoWayCommunicationService.storageFields.selectedClaim =
                        twoWayCommunicationService.storageFields.claimsList.find((claim) => {
                            return router.currentRoute.value.query.id === claim.claimNumber;
                        }) ?? null;
                    router.replace({ query: {} });
                }
            });
        }
    },
);

watch(
    () => isVisibleSelectPolicy.value,
    (value: boolean, oldValue: boolean) => {
        if (value !== oldValue && value) {
            scrollToTop();
            fetchPoliciesList().then(() => {
                buildPoliciesTabs();
            });
        }
    },
);

watch(
    () => isVisibleMessage.value,
    (value: boolean, oldValue: boolean) => {
        if (value !== oldValue && value) {
            scrollToTop();
        }
    },
);

function setupForm(): void {
    spaUserStorage.setupForm();
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

function onSupportTypeClick(supportType: string): void {
    twoWayCommunicationService.storageFields.supportType = supportType;
}

function onCaseSupportClick(caseSupportType: string): void {
    twoWayCommunicationService.storageFields.caseSupportType = caseSupportType;
}

function onBackClick(): void {
    resetMessage();
    if (isReplyOrAddNew.value) {
        twoWayCommunicationService.storageFields.addOrReplyType = '';
        twoWayCommunicationService.storageFields.newMessageResponseId = '';
        router.replace({ path: twoWayCommunicationService.storageFields.communicationId });
    } else if (twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.None) {
        switch (twoWayCommunicationService.storageFields.supportType) {
            case TwoWaySupportType.None:
                twoWayCommunicationService.storageFields.caseSupportType = TwoWaySupportType.None;
                router.push({ name: TwoWaySteps.Communication });
                break;
            case TwoWaySupportType.ExistingClaim:
            case TwoWaySupportType.ExistingPolicy:
            case TwoWaySupportType.Other:
                twoWayCommunicationService.storageFields.supportType = TwoWaySupportType.None;
                scrollToTop();
                break;
            default:
        }
    } else {
        switch (twoWayCommunicationService.storageFields.caseSupportType) {
            case TwoWaySupportType.ExistingClaim:
                if (isCaseSupportType.value && hasSelectedClaim.value) {
                    twoWayCommunicationService.storageFields.selectedClaim = null;
                } else {
                    twoWayCommunicationService.storageFields.caseSupportType = TwoWaySupportType.None;
                    spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).patch('');
                }
                spaUserStorage.saveUpdatedStorage();
                scrollToTop();
                break;
            case TwoWaySupportType.ExistingPolicy:
                if (isCaseSupportType.value && hasSelectedPolicy.value) {
                    twoWayCommunicationService.storageFields.selectedPolicy = null;
                } else {
                    twoWayCommunicationService.storageFields.caseSupportType = TwoWaySupportType.None;
                    spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).patch('');
                }
                spaUserStorage.saveUpdatedStorage();
                scrollToTop();
                break;
            case TwoWaySupportType.New:
                twoWayCommunicationService.storageFields.caseSupportType = TwoWaySupportType.None;
                break;
            default:
        }
    }
}

function resetMessage(): void {
    spaUserStorage.form.field(TwoWayFormFields.Message).patch('');
    spaUserStorage.form.field(TwoWayFormFields.Message).markAsUntouched();
}

function scrollToTop(): void {
    const scrollSpeed: number = 300;
    nextTick((): void => {
        $('html,body').animate({ scrollTop: 0 }, scrollSpeed);
    });
}

function fetchClaimsList(): Promise<void> {
    twoWayCommunicationService.storageFields.claimsList = [];
    PopupService.getInstance().show(new OnePopup().withType().loading);
    isFetchInProgress.value = true;
    tabsBlockIsVisible.value = false;

    return requestService
        .get({
            uri: Url.Ajax.oneDashboardGetClaims,
            content: {},
            withCache: true,
        })
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            if (validResponse(response)) {
                const activeClaims: Claim[] = addActiveClaims(response.data.data.body.active);
                const resolvedClaims: Claim[] = addResolvedClaims(response.data.data.body.resolved);
                twoWayCommunicationService.storageFields.claimsList = activeClaims.concat(resolvedClaims);
            }
        })
        .finally((): void => {
            PopupService.getInstance().hide().then();
            isFetchInProgress.value = false;
        });
}

function addActiveClaims(value: UserClaim[]): Claim[] {
    return value.map((claimData: UserClaim): Claim => {
        return {
            id: claimData.Id,
            type: '',
            claimType: '',
            claimNumber: claimData.ClaimCaseNo,
            claimId: claimData.Uuid,
            policyId: claimData.PolicyId,
            policyNumber: claimData.PolicyNumber,
            policyType: claimData.PolicyType,
            submitDate: OneDate.short(claimData.ApplicationDate),
            status: ClaimStatus.InProgress,
            title: claimData.NotificationTypeText,
            attachments: [],
        };
    });
}

function addResolvedClaims(value: UserClaim[]): Claim[] {
    return value.map((claimData: UserClaim): Claim => {
        return {
            id: claimData.Id,
            type: '',
            claimType: '',
            claimNumber: claimData.ClaimCaseNo,
            claimId: claimData.Uuid,
            policyId: claimData.PolicyId,
            policyNumber: claimData.PolicyNumber ?? '',
            policyType: claimData.PolicyType,
            submitDate: OneDate.short(claimData.ApplicationDate),
            status: ClaimStatus.Approved,
            title: claimData.NotificationTypeText,
            attachments: [],
            sumApproved: claimData.SumApproved,
            compensationDate: OneDate.short(claimData.EventDate),
            closeDate: OneDate.short(claimData.ClaimCaseCloseDate) ?? '',
        };
    });
}

function buildClaimsTabs(): void {
    claimsTabs.value = [];
    claimsTabs.value.push(new InputOptionBuilder().setValue(AllClaims).setName(translate(AllClaims)).build());
    if (
        twoWayCommunicationService.storageFields.claimsList &&
        twoWayCommunicationService.storageFields.claimsList.length > 0
    ) {
        twoWayCommunicationService.storageFields.claimsList.forEach((claim: Claim) => {
            if (
                !claimsTabs.value.find((tab: InputOption) => {
                    return tab.value === claim.policyType;
                })
            ) {
                claimsTabs.value.push(new InputOptionBuilder().setValue(claim.policyType).setName(claim.title).build());
            }
        });
    }
    if (
        twoWayCommunicationService.storageFields.claimsList &&
        twoWayCommunicationService.storageFields.claimsList.length > 0
    ) {
        const formValue: LimitedVariant = spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).value;
        if (
            !claimsTabs.value.find((option: InputOption) => {
                return option.value === formValue;
            })
        ) {
            spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).patch(AllClaims);
        }
    }

    //INFO: set only [All claims] for now and wait for BTA decision about them
    spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).patch(AllClaims);
    tabsBlockIsVisible.value = false;
}

function fetchPoliciesList(): Promise<void> {
    twoWayCommunicationService.storageFields.policyList = [];
    PopupService.getInstance().show(new OnePopup().withType().loading);
    isFetchInProgress.value = true;
    tabsBlockIsVisible.value = false;

    return requestService
        .get({
            uri: Url.Ajax.oneDashboardInsurances,
            content: {},
            withCache: true,
        })
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            if (validResponse(response)) {
                buildInsurancesTabsContent(response.data.data.body).then(() => {
                    buildPoliciesTabs();
                });
            }
        })
        .finally((): void => {
            PopupService.getInstance().hide().then();
            isFetchInProgress.value = false;
        });
}

const buildInsurancesTabsContent = (response: DynamicDictionary): Promise<void> => {
    return new Promise((resolve) => {
        AgreementTypes.forEach((agreementType: string) => {
            response[agreementType].forEach((agreement: Agreement) => {
                agreement.tabType = agreementType;
                twoWayCommunicationService.storageFields.policyList.push(agreement);
            });
        });
        resolve();
    });
};

function buildPoliciesTabs(): void {
    policiesTabs.value = [];
    AgreementTypes.forEach((agreementType: string) => {
        if (tabHasPolicies(agreementType)) {
            policiesTabs.value.push(
                new InputOptionBuilder()
                    .setValue(agreementType)
                    .setName(translateForType('one_dashboard_agreement_tabs_' + agreementType, 'dashboard'))
                    .build(),
            );
        }
    });
    if (
        twoWayCommunicationService.storageFields.policyList &&
        twoWayCommunicationService.storageFields.policyList.length > 0
    ) {
        const formValue: LimitedVariant = spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).value;
        if (
            !policiesTabs.value.find((option: InputOption) => {
                return option.value === formValue;
            })
        ) {
            selectFirstTabWithEntries();
        }
    }
    tabsBlockIsVisible.value = true;
}

function selectFirstTabWithEntries(): void {
    const element: string | undefined = AgreementTypes.find((agreementType: string) => {
        return tabHasPolicies(agreementType);
    });
    if (element) {
        spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).patch(element);
    }
}

function tabHasPolicies(tabType: string): boolean {
    let result: boolean = false;
    if (
        twoWayCommunicationService.storageFields.policyList &&
        twoWayCommunicationService.storageFields.policyList.length > 0
    ) {
        twoWayCommunicationService.storageFields.policyList.forEach((agreement: Agreement) => {
            if (agreement.tabType === tabType) {
                result = true;
            }
        });
    }

    return result;
}

function caseBlockButtons(data: Agreement | Claim): ButtonWithCallbackParams[] {
    return ButtonsWithCallbackBuilder.getInstance()
        .withTitle(caseSupportIsClaim.value ? translated('select_claim') : translated('select_policy'))
        .withBackgroundColor(ButtonBackground.White)
        .withBackgroundColorHover(ButtonBackground.Transparent)
        .withTextColor(ButtonTextColor.Black)
        .withCallback((params: DynamicDictionary): void => {
            if (caseSupportIsClaim.value) {
                twoWayCommunicationService.storageFields.selectedClaim = params.data;
            } else {
                twoWayCommunicationService.storageFields.selectedPolicy = params.data;
            }
        })
        .withCallbackParams({ data: data })
        .build();
}

function onDeleteFileClick(id: string): void {}

function onMessageChange(): void {
    spaUserStorage.storeForm();
}

function onSendMessage(): void {
    PopupService.getInstance().show(new OnePopup().withType().loading);
    let caseSupportId: string = '';
    let caseType: string = TwoWaySupportType.Other;
    if (isCaseSupportType.value) {
        if (caseSupportIsClaim.value) {
            caseSupportId = selectedClaim();
            caseType = TwoWaySupportType.ExistingClaim;
        } else {
            caseSupportId = selectedPolicy();
            caseType = TwoWaySupportType.ExistingPolicy;
        }
    } else if (isReplyOrAddNew.value) {
        caseType = twoWayCommunicationService.storageFields.addOrReplyType;
    }
    const params: DynamicDictionary = {
        body: spaUserStorage.form.field(TwoWayFormFields.Message).value,
        objectId: caseSupportId,
        sourceMessageId: twoWayCommunicationService.communicationSourceId(),
        type: caseType,
    };
    requestService
        .post({
            uri: TwoWayAjaxCalls.Create,
            content: params,
        })
        .then((response: AxiosResponse) => {
            if (validResponse(response)) {
                twoWayCommunicationService.storageFields.newMessageResponseId = response.data.data.body.id;
                PopupService.getInstance()
                    .hide()
                    .then(() => {
                        showSuccessMessage();
                    });
            } else {
                Error.getInstance().show(ErrorType.Error, 'onSendMessage', 'btar_error_common');
                Error.log(ErrorType.Error, 'TwoWayCommunicationNew::onSendMessage', response.data.errors, true);
            }
        })
        .catch((reason: DynamicDictionary): void => {
            Error.getInstance().show(ErrorType.Error, 'onSendMessage', reason);
        });
}

function selectedClaim(): string {
    return useDefine().isSet(twoWayCommunicationService.storageFields.selectedClaim)
        ? twoWayCommunicationService.storageFields.selectedClaim!.id
        : '';
}

function selectedPolicy(): string {
    return useDefine().isSet(twoWayCommunicationService.storageFields.selectedPolicy)
        ? twoWayCommunicationService.storageFields.selectedPolicy!.id
        : '';
}

function showSuccessMessage(): void {
    const resultMessage: ResultMessage = ResultMessage.getInstance();
    resultMessage
        .withTitle(translated('message_submitted_title'))
        .withDescription(translated('message_submitted_description'))
        .withModalMode(true)
        .withButtons(
            ButtonsWithCallbackBuilder.getInstance()
                .newButton()
                .withTitle(translated('go_to_my_communications'))
                .withTextColor(ButtonTextColor.Black)
                .withTextColorHover(ButtonTextColor.White)
                .withBackgroundColor(ButtonBackground.Transparent)
                .withBackgroundColorHover(ButtonBackground.Transparent)
                .withCallback((params: DynamicDictionary): void => {
                    resetMessage();
                    spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).patch('');
                    twoWayCommunicationService.clearAll();
                    ResultMessage.getInstance().hide();
                    router.push({ name: TwoWaySteps.Communication });
                })
                .build(),
        )
        .show();
}

function hasNavigationPayload(): boolean {
    return Object.keys(router.currentRoute.value.query).length > 0;
}

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    applyTranslationType('communications');
    spaUserStorage.init(TwoWayFormFields, twoWayCommunicationService.storageFields);
    setupForm();
    buildClaimsTabs();
    buildPoliciesTabs();
    TwoWayGuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value);
    nextTick(() => {
        if (hasNavigationPayload()) {
            onSupportTypeClick(router.currentRoute.value.query.supportType as string);
            onCaseSupportClick(router.currentRoute.value.query.caseSupportType as string);
        }
    });
});

defineExpose({
    twoWayCommunicationService,
    spaUserStorage,
});
</script>

<template>
    <div class="two-way-communication full-width horizontal-spacing horizontal-spacing-desktop">
        <div class="wrapper full-width">
            <app-custom-form
                v-if="spaUserStorage.isReady()"
                :form="spaUserStorage.newForm('two-way-new')"
                class="form full-width"
                @change="spaUserStorage.storeForm()"
            >
                <div class="new-communication full-width">
                    <div class="communication-header">
                        <button data-v-ff6cde2a="" class="back back-margin" :class="headerClass" @click="onBackClick()">
                            <img data-v-ff6cde2a="" src="images/one/arrow-left.svg" alt="back" />
                            <span data-v-ff6cde2a="">{{ translated('back') }}</span>
                        </button>
                        <div class="title" :class="headerClass">{{ flowTitle }}</div>
                        <div class="sub-title" :class="headerClass">{{ flowDescription }}</div>
                    </div>
                    <div class="content">
                        <div v-if="isVisibleSupportType" class="panels-container support-type" :class="headerClass">
                            <div class="panel" @click="onSupportTypeClick(TwoWaySupportType.ExistingClaim)">
                                {{ translated('claim_case_support') }}
                                <img width="14" height="14" src="images/one/communications/arrow-right.svg" />
                            </div>
                            <div class="panel" @click="onSupportTypeClick(TwoWaySupportType.ExistingPolicy)">
                                {{ translated('policy_support') }}
                                <img width="14" height="14" src="images/one/communications/arrow-right.svg" />
                            </div>
                            <div class="panel" @click="onSupportTypeClick(TwoWaySupportType.Other)">
                                {{ translated('other_questions') }}
                                <img width="14" height="14" src="images/one/communications/arrow-right.svg" />
                            </div>
                        </div>
                        <div
                            v-if="isVisibleCaseSupport"
                            class="panels-container claim-case-support"
                            :class="headerClass"
                        >
                            <div
                                v-if="caseSupportIsClaim"
                                class="panel"
                                @click="onCaseSupportClick(TwoWaySupportType.ExistingClaim)"
                            >
                                {{ translated('select_my_existing_claim') }}
                                <img width="14" height="14" src="images/one/communications/arrow-right.svg" />
                            </div>
                            <div
                                v-if="!caseSupportIsClaim"
                                class="panel"
                                @click="onCaseSupportClick(TwoWaySupportType.ExistingPolicy)"
                            >
                                {{ translated('select_my_existing_policy') }}
                                <img width="14" height="14" src="images/one/communications/arrow-right.svg" />
                            </div>
                            <div class="panel" @click="onCaseSupportClick(TwoWaySupportType.New)">
                                {{ translated('start_new_communication') }}
                                <img width="14" height="14" src="images/one/communications/arrow-right.svg" />
                            </div>
                        </div>
                        <div
                            v-if="isVisibleSelectClaim || isVisibleSelectPolicy"
                            class="my-existing-case"
                            :class="headerClass"
                        >
                            <div class="block-medium">
                                <div v-if="tabsBlockIsVisible" class="tabs">
                                    <app-input-radio
                                        :form-field="spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs)"
                                        :options="tabs"
                                        :type="'clear'"
                                    >
                                    </app-input-radio>
                                </div>
                                <template v-if="caseSupportIsClaim">
                                    <app-claim-block
                                        v-for="(claim, index) in filteredClaims"
                                        :key="index"
                                        :item="claim"
                                        :buttons="caseBlockButtons(claim)"
                                        :translation-type="'dashboard'"
                                    >
                                    </app-claim-block>
                                </template>
                                <template v-if="caseSupportIsPolicy">
                                    <app-policy-block
                                        v-for="(policy, index) in filteredPolicies"
                                        :key="index"
                                        :item="policy"
                                        :buttons="caseBlockButtons(policy)"
                                        :translation-type="'dashboard'"
                                    >
                                    </app-policy-block>
                                </template>
                            </div>
                        </div>
                        <div v-if="isVisibleMessage" class="new-message" :class="headerClass">
                            <app-new-message
                                :form-field="spaUserStorage.form.field(TwoWayFormFields.Message)"
                                :case-number="selectedCaseNumber"
                                :case-number-label="caseLabel"
                                :translation-type="type()"
                                :uploader-id="UploaderId"
                                @delete-file="onDeleteFileClick"
                                @change="onMessageChange"
                                @send-message="onSendMessage"
                            ></app-new-message>
                        </div>
                    </div>
                </div>
            </app-custom-form>
        </div>
    </div>
</template>

<style scoped lang="scss">
.new-communication {
    .content {
        margin-bottom: 90px;

        .my-existing-case {
            .tabs {
                overflow-x: auto;
                min-height: 50px;
                margin-bottom: var(--size-tiny);

                :deep(.input-radio) {
                    .buttons {
                        .button {
                            white-space: nowrap;
                        }
                    }
                }
            }

            .input-radio {
                margin-bottom: var(--size-normal);

                :deep(.button-badge) {
                    .button {
                        color: var(--text-color-subtlest);
                        font-size: var(--font-size-nano);
                        font-weight: 600;
                        margin-right: var(--size-femto);

                        &.active {
                            background-color: var(--brand-black);
                            color: var(--button-color-text-primary-default);
                        }
                    }
                }
            }

            :deep(.claims-block) {
                margin-bottom: var(--size-nano);

                .buttons {
                    .button-with-callback {
                        span {
                            font-weight: 600;
                        }
                    }
                }
            }

            :deep(.policy-block) {
                padding: var(--size-small);
                margin-bottom: var(--size-nano);

                @include respond-above('lg') {
                    padding: 0 var(--size-normal);
                }

                .insurance-type {
                    width: 180px;
                }

                .wrapper {
                    @include respond-above('lg') {
                        padding-left: var(--size-big);
                    }

                    .insured-item {
                        .additional-info {
                            @include respond-above('lg') {
                                margin: 10px 0;
                            }
                        }
                    }
                }

                .button-container {
                    .button-with-callback {
                        @include respond-above('sm') {
                            width: 111px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .panels-container {
            .panel {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 64px;
                background-color: var(--background-base);
                border-radius: var(--size-pico);
                margin-bottom: var(--size-nano);
                padding: var(--size-small);
                cursor: pointer;
                font-weight: 600;
            }
        }

        :deep(.new-message) {
            .attach-files {
                .compact-file-uploader {
                    .content {
                        padding: 0;
                    }
                }
            }
        }
    }
}
</style>
