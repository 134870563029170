<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import OneBaseService from '@/Services/OneBaseService';
import {
    computed,
    ComputedRef,
    getCurrentInstance,
    onMounted,
    reactive,
    ref,
    Ref,
    shallowReactive,
    ShallowUnwrapRef,
    UnwrapNestedRefs,
    watch,
} from 'vue';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import GuardsService from '@/Apps/ClaimsTravel/Services/GuardsService';
import { useClaimsTravelHtml } from '@/Apps/ClaimsTravel/Composables/ClaimsTravelHtml';
import Form from '@/Assets/Libraries/Form/Form';
import { FieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentData/FieldNames';
import { InsuredObjectRequestTypes } from '@/Apps/ClaimsTravel/Enums/InsuredObjectRequestTypes';
import {
    AccidentDateFields,
    AccidentTypeFields,
    Forms,
    PolicyFields,
    SufferingPersonFields,
    SufferingPersonOtherFields,
} from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentData/Forms';
import moment from 'moment/moment';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import AppInputCountry from '@/Components/Inputs/InputCountry/InputCountry.vue';
import AppInputRadioOverlayed from '@/Components/Inputs/InputRadioOverlayed/InputRadioOverlayed.vue';
import AppInputTime from '@/Components/Inputs/InputTime/InputTime.vue';
import AppInputBirthDate from '@/Components/Inputs/InputBirthDate/InputBirthDate.vue';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppInputDateWithCalendar from '@/Components/Inputs/InputDateWithCalendar/InputDateWithCalendar.vue';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import Option from '@/Apps/ClaimsTravel/Interfaces/Option';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import AppOptionsList from '@/Components/Options/OptionsList/OptionsList.vue';
import AppInputDate from '@/Components/Inputs/InputDate/InputDate.vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import InputDateLayout from '@/Components/Inputs/InputDate/InputDateLayout';
import AppInsuredObjectsBlocks from '@/Components/Lists/InsuredObjectsBlocks/InsuredObjectsBlocks.vue';
import AppOptionsSmartList from '@/Components/Options/OptionsSmartList/OptionsSmartList.vue';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import ErrorType from '@/Enums/ErrorTypeEnum';
import OneBase from '@/Interfaces/OneBaseInterface';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { useAxios } from '@/Composables/Axios';
import { AxiosResponse } from 'axios';
import { ClaimsTravelAjaxRoutes } from '@/Apps/ClaimsTravel/Enums/ClaimsTravelAjaxRoutes';
import { useDefine } from '@/Composables/Define';
import ClaimsTravelObject from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelObject';
import UserCredentials from '@/Interfaces/user.credentials.interface';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import OptionsSmartCustom from '@/Components/Options/OptionsSmartList/Interfaces/OptionsSmartCustomInterface';
import OptionsSmartTypes from '@/Components/Options/OptionsSmartList/Enums/OptionsSmartTypesEnum';
import OptionsSmartCustomSelectState from '@/Components/Options/OptionsSmartList/Interfaces/OptionsSmartCustomSelectStateInterface';
import OptionSmartColor from '@/Components/Options/OptionsSmartList/Enums/OptionSmartColorEnum';
import ClaimsTravelObjectRisk from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelObjectRisk';
import PersonCodeValidator from '@/Validators/PersonCodeValidator';
import { FormNames as AccidentDataForms } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentData/FormNames';
import FormField from '@/Assets/Libraries/Form/FormField';
import ClaimsTravelStepUid from '@/Apps/ClaimsTravel/Enums/ClaimsTravelStepUid';
import Validation from '@/Services/validation.service';
import ClaimsTravelFormFields from '@/Apps/ClaimsTravel/Classes/ClaimsTravelFormFields';
import ClaimPage from '@/Components/Other/Page/ClaimPage.vue';
import AppWhiteboard from '@/Components/Containers/Whiteboard/Whiteboard.vue';
import AppSvg from '@/Components/Other/Svg/Svg.vue';
import AppButtonPrimary from '@/Components/Buttons/ButtonPrimary/ButtonPrimary.vue';

type FormNames = `${AccidentDataForms}`;

const { translateForType, applyTranslationType, type, translate } = useTranslate();
const { scrollToPanel } = useClaimsTravelHtml();
const router: Router = useRouter();
const { isSet } = useDefine();

const claimsTravelService: ClaimsTravelService = ClaimsTravelService.getInstance();
const base: OneBase = OneBaseService.getInstance();

const Step: number = 1;
const forms: ShallowUnwrapRef<{ [FormKey in keyof Forms]: Form<Forms[FormKey]> }> = shallowReactive(
    {},
) as ShallowUnwrapRef<{
    [FormKey in keyof Forms]: Form<Forms[FormKey]>;
}>;

const insuredObjects: Ref<InputOption[]> = ref([]);
const inputOptions: UnwrapNestedRefs<Record<FormNames, Option>> = reactive({}) as UnwrapNestedRefs<
    Record<FormNames, Option>
>;
const isSearchInProgress: Ref<boolean> = ref(false);
const externalErrorTextMessage: Ref<string> = ref('');
const accidentTypeFormSubmitted: Ref<boolean> = ref(false);
const allowResetOnResidentChange: Ref<boolean> = ref(false);

const accidentEventDateTime: ComputedRef<string> = computed(() => {
    const accidentDateForm: Form<AccidentDateFields> = forms[AccidentDataForms.AccidentDate];

    return OneDate.irisTimestamp(
        accidentDateForm?.field(FieldNames.AccidentDate).value.startDate?.substring(0, 10) +
            ' ' +
            accidentDateForm?.field(FieldNames.AccidentTime).value,
    );
});

const calendarMinDate: Ref<Date> = computed(() => {
    const minDateYears: number = 5;
    return moment().subtract(minDateYears, 'years').toDate();
});

const calendarMaxDate: Ref<Date> = computed(() => {
    return moment().toDate();
});

const dateLayout: Ref<string> = computed(() => {
    return new AppCountry().isLT() ? InputDateLayout.yearMonthDay : InputDateLayout.dayMonthYear;
});

const isSufferingPersonBirthDateInputVisible: Ref<boolean> = computed(() => {
    return (
        forms[AccidentDataForms.SufferingPersonOther]?.field(FieldNames.SufferingPersonOtherIsResident).value ===
            false || isAnonymousPersonCode.value
    );
});

const isAnonymousPersonCode: Ref<boolean> = computed(() => {
    return new PersonCodeValidator().isValidPersonCodeWithoutDate(
        forms[AccidentDataForms.SufferingPersonOther]?.field(FieldNames.SufferingPersonOtherPersonCode).value,
        new AppCountry().iso(),
    );
});

const userInputOptionsFromPolicies: Ref<InputOption[]> = computed(() => {
    return claimsTravelService.storageFields.allInsuredObjects
        .filter((object) => object.objectAttribute.valueid !== base.user.current.personId)
        .map((object) => {
            return {
                id: object.objectAttribute.valueid,
                name: object.name
                    .split(' ')
                    .slice(0, -1)
                    .map((name) => name.charAt(0) + name.slice(1).toLowerCase())
                    .join(' '),
            };
        })
        .reduce<{
            ids: Set<string>;
            uniqueObjects: { id: string; name: string }[];
        }>(
            (accumulator, object) => {
                if (!accumulator.ids.has(object.id)) {
                    accumulator.ids.add(object.id);
                    accumulator.uniqueObjects.push(object);
                }
                return accumulator;
            },
            { ids: new Set(), uniqueObjects: [] },
        )
        .uniqueObjects.map((object, index) => {
            const inputOptionBuilder = new InputOptionBuilder().setValue(object.id).setName(object.name);
            if (index === 0) {
                inputOptionBuilder.setSeparatorText(translated('submitting_claim_about_from_policies'));
            }

            return inputOptionBuilder.build();
        });
});

const selectedPolicy: Ref<ClaimsTravelObject | undefined> = computed(() => {
    return claimsTravelService.storageFields.personInsuredObjects.find(
        (object) => object.id === forms[AccidentDataForms.Policy].field(FieldNames.Policy).value?.object,
    );
});

const selectedPolicyRisks: Ref<ClaimsTravelObjectRisk[]> = computed(() => {
    return selectedPolicy.value?.risks ?? [];
});

const canProceedToNextStep: Ref<boolean> = computed(() => {
    return accidentTypeFormSubmitted.value;
});

const isResident: Ref<boolean> = computed(() => {
    return (
        forms[AccidentDataForms.SufferingPersonOther] &&
        forms[AccidentDataForms.SufferingPersonOther].field(FieldNames.SufferingPersonOtherIsResident).value
    );
});

watch(
    () => isResident.value,
    () => {
        if (allowResetOnResidentChange.value) {
            forms[AccidentDataForms.SufferingPersonOther].field(FieldNames.SufferingPersonOtherName).setValue('');
            forms[AccidentDataForms.SufferingPersonOther].field(FieldNames.SufferingPersonOtherSurname).setValue('');
            forms[AccidentDataForms.SufferingPersonOther].field(FieldNames.SufferingPersonOtherPersonCode).setValue('');
            forms[AccidentDataForms.SufferingPersonOther].field(FieldNames.SufferingPersonOtherBirthDate).setValue('');
        } else {
            allowResetOnResidentChange.value = true;
        }
    },
);

function isPanelVisible(panel: FormNames): boolean {
    return inputOptions[panel].visible;
}

function clearFormsAhead(currentForm: FormNames): void {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);
    const nextFormKeys: FormNames[] = Object.keys(forms).slice(
        currentFormIndex + 1,
        Object.keys(forms).length,
    ) as FormNames[];
    nextFormKeys.forEach((formName: FormNames) => {
        clearForm(formName);
        inputOptions[formName].visible = false;
        claimsTravelService.storageFields.formVisibility[formName] = false;
    });
}

function clearForm(formName: FormNames): void {
    const form:
        | Form<AccidentDateFields>
        | Form<SufferingPersonFields>
        | Form<SufferingPersonOtherFields>
        | Form<PolicyFields>
        | Form<AccidentTypeFields> = forms[formName];
    switch (formName) {
        case AccidentDataForms.AccidentDate: {
            const accidentDateForm = form as Form<AccidentDateFields>;
            accidentDateForm.field(FieldNames.AccidentDate).setValue({ startDate: '', endDate: '' });
            accidentDateForm.field(FieldNames.AccidentTime).setValue('');
            accidentDateForm.field(FieldNames.AccidentCountry).setValue({ iso: '', phoneCode: '', ic: '' });
            break;
        }
        case AccidentDataForms.SufferingPerson: {
            const sufferingPersonForm = form as Form<SufferingPersonFields>;
            sufferingPersonForm.field(FieldNames.SufferingPerson).setValue({ selected: '', extra: '' });
            inputOptions[AccidentDataForms.SufferingPerson].options = [];
            break;
        }
        case AccidentDataForms.SufferingPersonOther: {
            const sufferingPersonOtherForm = form as Form<SufferingPersonOtherFields>;
            sufferingPersonOtherForm.field(FieldNames.SufferingPersonOtherIsResident).setValue(true);
            sufferingPersonOtherForm.field(FieldNames.SufferingPersonOtherName).setValue('');
            sufferingPersonOtherForm.field(FieldNames.SufferingPersonOtherSurname).setValue('');
            sufferingPersonOtherForm.field(FieldNames.SufferingPersonOtherPersonCode).setValue('');
            sufferingPersonOtherForm.field(FieldNames.SufferingPersonOtherBirthDate).setValue('');
            insuredObjects.value = [];
            break;
        }
        case AccidentDataForms.Policy: {
            const policyForm = form as Form<PolicyFields>;
            policyForm.field(FieldNames.Policy).setValue({ object: '', finder: '' });
            inputOptions[AccidentDataForms.Policy].options = [];
            break;
        }
        case AccidentDataForms.AccidentType: {
            const accidentTypeForm = form as Form<AccidentTypeFields>;
            accidentTypeForm.field(FieldNames.AccidentType).setValue({ selected: '', extra: '' });
            inputOptions[AccidentDataForms.AccidentType].options = [];
            accidentTypeFormSubmitted.value = false;
            break;
        }
    }
    storeForm(formName);
}

function objectAlreadyStored(objectId: string): boolean {
    return claimsTravelService.storageFields.personInsuredObjects.some(
        (object: ClaimsTravelObject): boolean => object.id === objectId,
    );
}

function onFinderReceive(object: ClaimsTravelObject): void {
    if (!objectAlreadyStored(object.id)) {
        claimsTravelService.storageFields.personInsuredObjects.push(object);
    }
}

function descriptionTransformerCallback(item: InputOption): string[] {
    interface Custom {
        policy: string;
        validFrom: string;
        validTo: string;
    }

    const custom: Custom = item.custom as Custom;

    return [
        `${translateForType('policy_no', type())} ${custom.policy}`,
        `${translateForType('valid', type())} ` +
            `${OneDate.short(custom.validFrom)} — ` +
            OneDate.short(custom.validTo),
    ];
}

function setupForms(): void {
    const requiredValidator = { required: Validation.required };
    const requiredOptionValidator = {
        required: (value: { selected?: string }) => isSet(value?.selected) && value.selected !== '',
    };
    const captionValidator = { isValidCaption: Validation.isValidCaption };

    const accidentDateForm: Form<AccidentDateFields> = new Form({ useValidationV2: true });
    accidentDateForm.addField(new FormField(FieldNames.AccidentDate, '', requiredValidator));
    accidentDateForm.addField(new FormField(FieldNames.AccidentTime, '', requiredValidator));
    accidentDateForm.addField(new FormField(FieldNames.AccidentCountry, '', requiredValidator));
    forms[AccidentDataForms.AccidentDate] = accidentDateForm;

    const sufferingPersonForm: Form<SufferingPersonFields> = new Form({ useValidationV2: true });
    sufferingPersonForm.addField(new FormField(FieldNames.SufferingPerson, '', requiredOptionValidator));
    forms[AccidentDataForms.SufferingPerson] = sufferingPersonForm;

    const sufferingPersonOtherForm: Form<SufferingPersonOtherFields> = new Form({ useValidationV2: true });
    sufferingPersonOtherForm.addField(new FormField(FieldNames.SufferingPersonOtherIsResident));
    sufferingPersonOtherForm.field(FieldNames.SufferingPersonOtherIsResident).setValue(true);
    sufferingPersonOtherForm.addField(
        new FormField(FieldNames.SufferingPersonOtherName, '', {
            ...requiredValidator,
            ...captionValidator,
        }),
    );
    sufferingPersonOtherForm.addField(
        new FormField(FieldNames.SufferingPersonOtherSurname, '', {
            ...requiredValidator,
            ...captionValidator,
        }),
    );
    sufferingPersonOtherForm.addField(
        new FormField(FieldNames.SufferingPersonOtherPersonCode, '', {
            ...requiredValidator,
            isValidPersonCode: (value: string) => {
                return forms[AccidentDataForms.SufferingPersonOther].field(FieldNames.SufferingPersonOtherIsResident)
                    .value
                    ? Validation.isValidNaturalPersonCode(value)
                    : true;
            },
        }),
    );
    sufferingPersonOtherForm.addField(
        new FormField(FieldNames.SufferingPersonOtherBirthDate, '', {
            ...requiredValidator,
            isValidBirthDate: (value: string) => moment(value).isValid(),
        }),
    );
    sufferingPersonOtherForm.field(FieldNames.SufferingPersonOtherBirthDate).markAsHidden();
    sufferingPersonOtherForm.addField(
        new FormField(FieldNames.SufferingPersonOtherPolicyNumber, '', requiredValidator),
    );
    forms[AccidentDataForms.SufferingPersonOther] = sufferingPersonOtherForm;

    const policyForm: Form<PolicyFields> = new Form({ useValidationV2: true });
    policyForm.addField(new FormField(FieldNames.Policy, '', requiredValidator));
    forms[AccidentDataForms.Policy] = policyForm;

    const accidentTypeForm: Form<AccidentTypeFields> = new Form({ useValidationV2: true });
    accidentTypeForm.addField(new FormField(FieldNames.AccidentType, '', requiredOptionValidator));
    forms[AccidentDataForms.AccidentType] = accidentTypeForm;
}

function markFormsAsReady(): void {
    Object.values(forms).forEach((form) => form.setReady());
}

function calendarDaysButtonsParams(stringId: string): ButtonWithCallbackParams {
    return {
        title: translated(stringId),
        mode: 'badge',
        textColor: ButtonTextColor.White,
        textColorHover: ButtonTextColor.Black,
        backgroundColor: ButtonBackground.Black,
        backgroundColorHover: ButtonBackground.White,
    };
}

function onAccidentDatePanelProceedClick(): void {
    const form = forms[AccidentDataForms.AccidentDate];
    form.submitAttempt()
        .then(() => (form.isValid() ? fetchInsuredObjects(InsuredObjectRequestTypes.Person) : Promise.reject()))
        .then((objects) => {
            claimsTravelService.storageFields.allInsuredObjects = objects;
            setTimeout(() => {
                buildSufferingPersonOptions();
            });
            proceedToNextForm(AccidentDataForms.AccidentDate);
        });
}

function onPersonListPanelProceedClick(): void {
    const form = forms[AccidentDataForms.SufferingPerson];
    form.submitAttempt()
        .then(() => (form.isValid() ? Promise.resolve() : Promise.reject()))
        .then(() => {
            if (form.field(FieldNames.SufferingPerson).value.selected === 'other') {
                proceedToNextForm(AccidentDataForms.SufferingPerson, AccidentDataForms.SufferingPersonOther);
            } else if (form.field(FieldNames.SufferingPerson).value.selected === base.user.current.personId) {
                fetchInsuredObjects(InsuredObjectRequestTypes.Myself).then((objects) => {
                    claimsTravelService.storageFields.personInsuredObjects = objects;
                    buildInsuredObjects(objects);
                    proceedToNextForm(AccidentDataForms.SufferingPerson, AccidentDataForms.Policy);
                });
            } else {
                fetchInsuredObjects(InsuredObjectRequestTypes.Person).then((objects) => {
                    const personObjects: ClaimsTravelObject[] = objects.filter((object) => {
                        return object.objectAttribute.valueid === form.field(FieldNames.SufferingPerson).value.selected;
                    });
                    claimsTravelService.storageFields.personInsuredObjects = personObjects;
                    buildInsuredObjects(personObjects);
                    proceedToNextForm(AccidentDataForms.SufferingPerson, AccidentDataForms.Policy);
                });
            }
        });
}

function onOtherPanelProceedClick(): void {
    const form = forms[AccidentDataForms.SufferingPersonOther];
    form.submitAttempt()
        .then(() => {
            return form.isValid()
                ? Promise.all([
                      fetchInsuredObjects(InsuredObjectRequestTypes.Other),
                      fetchInsuredObjects(InsuredObjectRequestTypes.Search),
                  ])
                : Promise.reject();
        })
        .then(([otherRequestInsuredObjects, searchRequestInsuredObjects]) => {
            const mergedInsuredObjects = [...otherRequestInsuredObjects, ...searchRequestInsuredObjects];
            claimsTravelService.storageFields.personInsuredObjects = mergedInsuredObjects;
            buildInsuredObjects(mergedInsuredObjects);
            proceedToNextForm(AccidentDataForms.SufferingPersonOther);
        });
}

function onInsuredListProceedClick(): void {
    const form = forms[AccidentDataForms.Policy];
    form.submitAttempt().then(() => {
        if (form.isValid()) {
            buildAccidentTypeOptions();
            proceedToNextForm(AccidentDataForms.Policy);
        }
    });
}

function onAccidentTypeProceedClick(): void {
    const form = forms[AccidentDataForms.AccidentType];
    form.submitAttempt().then(() => {
        if (form.isValid()) {
            accidentTypeFormSubmitted.value = true;
            GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value);
            router.push({ name: ClaimsTravelStepUid.AccidentDetails });
        }
    });
}

function proceedToNextForm(formName: FormNames, nextFormName: FormNames | null = null): void {
    const nextPanel = nextFormName ? nextFormName : findNextFormName(formName);
    if (nextPanel) {
        inputOptions[nextPanel].visible = true;
        claimsTravelService.storageFields.formVisibility[nextPanel] = true;
        useClaimsTravelHtml().scrollToPanel(nextPanel);
    }
}

function scrollToLastForm(): void {
    const lastForm = Object.keys(inputOptions).findLast((form) => inputOptions[form as FormNames].visible);

    if (lastForm) {
        useClaimsTravelHtml().scrollToPanel(lastForm);
    }
}

function findNextFormName(currentForm: FormNames): FormNames | undefined {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);

    return Object.keys(forms).at(currentFormIndex + 1) as FormNames | undefined;
}

function accidentTypeRisks(accidentType: string): string[] {
    return claimsTravelService.travelTypes
        .filter((travelType) => travelType.travelType === accidentType)
        .map((travelType) => travelType.travelTypeRiskIc);
}

function injuryOptionCustom(accidentType: string): OptionsSmartCustom {
    const accidentTypeContainsTripCancellationRisk = accidentTypeRisks(accidentType).includes(
        claimsTravelService.tripCancellationRiskIc,
    );
    const isFuturePolicy = moment().isBefore(moment(selectedPolicy.value!.validFrom));
    const riskIsCovered: boolean = isFuturePolicy
        ? accidentTypeContainsTripCancellationRisk
        : selectedPolicyRisks.value.find((policyRisk) =>
              accidentTypeRisks(accidentType).includes(policyRisk.agrrisk),
          ) !== undefined;

    return new (class implements OptionsSmartCustom {
        public type: OptionsSmartTypes = riskIsCovered ? OptionsSmartTypes.Regular : OptionsSmartTypes.Info;
        public initialValue: string = translated('risk_not_covered_by_the_selected_policy');
        public selectState: OptionsSmartCustomSelectState = new (class implements OptionsSmartCustomSelectState {
            public borderColor?: OptionSmartColor | undefined = riskIsCovered
                ? OptionSmartColor.Green
                : OptionSmartColor.Red;
            public isInvalid: boolean = !riskIsCovered;
        })();
    })();
}

function buildAccidentTypeOptions(): void {
    inputOptions[AccidentDataForms.AccidentType].options = [
        ...new Set(claimsTravelService.travelTypes.map((travelType) => travelType.travelType)).values(),
    ].map((travelType) =>
        new InputOptionBuilder<string>()
            .setValue(travelType.toLowerCase())
            .setName(translated('accident_type_' + travelType.toLowerCase()))
            .setCustom(injuryOptionCustom(travelType))
            .setTipster(
                translated('accident_type_' + travelType.toLowerCase() + '_tooltip_title'),
                translated('accident_type_' + travelType.toLowerCase() + '_tooltip_description'),
            )
            .build(),
    );
}

function buildResidencyOptions(): void {
    inputOptions[AccidentDataForms.SufferingPersonOther].options = [
        new InputOptionBuilder<boolean>().setValue(true).setName(translated('resident')).build(),
        new InputOptionBuilder<boolean>().setValue(false).setName(translated('non_resident')).build(),
    ];
}

function buildSufferingPersonOptions(): void {
    const currentUser: UserCredentials = base.user.current;

    inputOptions[AccidentDataForms.SufferingPerson].options = [
        new InputOptionBuilder().setValue(currentUser.personId).setName(currentUser.name).build(),
        new InputOptionBuilder().setValue('other').setName(translated('suffering_person_other')).build(),
        ...userInputOptionsFromPolicies.value,
    ];
}

function finderOptionBuilder(object: DynamicDictionary): InputOption {
    const policy: ClaimsTravelObject | undefined = claimsTravelService.storageFields.personInsuredObjects.find(
        (data) => {
            return data.policyNumber === object.policy;
        },
    );
    const objectRiskIds = policy !== undefined ? policy.risks.map((risk) => risk.agrrisk) : [];
    const isFuturePolicy = moment().isBefore(moment(object.validFrom));
    const isObjectDisabled = isFuturePolicy && !objectRiskIds.includes(claimsTravelService.tripCancellationRiskIc);

    return new InputOptionBuilder()
        .setValue(object.id)
        .setName(object.name)
        .setCustom({
            type: object.type,
            policy: object.policy,
            validFrom: object.validFrom,
            validTo: object.validTo,
            errorMessage: isObjectDisabled ? translated('trip_cancellation_not_included_in_policy') : null,
            isFinderObject: object.isFinderObject,
        })
        .setDisabled(false)
        .build();
}

function insuredObjectsRequestParamsByType(requestType: InsuredObjectRequestTypes): Record<string, string> {
    const form = forms[AccidentDataForms.SufferingPersonOther];
    let params: Record<string, string> = {
        eventDate: accidentEventDateTime.value,
        type: requestType,
    };
    if (requestType === InsuredObjectRequestTypes.Other) {
        params = {
            ...params,
            insuredCode: form.field(FieldNames.SufferingPersonOtherPersonCode).value,
            agreementNumber: form.field(FieldNames.SufferingPersonOtherPolicyNumber).value,
        };
    } else if (requestType === InsuredObjectRequestTypes.Search) {
        params = {
            ...params,
            agreementNumber: form.field(FieldNames.SufferingPersonOtherPolicyNumber).value,
        };
    }

    return params;
}

function fetchInsuredObjects(requestType: InsuredObjectRequestTypes): Promise<ClaimsTravelObject[]> {
    PopupService.getInstance().show(new OnePopup().withType().loading);
    isSearchInProgress.value = true;
    const params: DynamicDictionary = insuredObjectsRequestParamsByType(requestType);

    return useAxios()
        .get(ClaimsTravelAjaxRoutes.InsuredObjects, { params })
        .then(
            (
                response: AxiosResponse<{ data?: { body?: { objects?: ClaimsTravelObject[] } } }>,
            ): ClaimsTravelObject[] => {
                if (isValidGetObjectDataResponse(response)) {
                    return response.data.data!.body!.objects!;
                } else {
                    throw response;
                }
            },
        )
        .catch((reason: LimitedVariant): never => {
            base.error.show(ErrorType.Error, 'fetchInsuredObjects', reason as DynamicDictionary);
            resetToFirstPanel();
            throw reason;
        })
        .finally((): void => {
            PopupService.getInstance().hide().then();
            isSearchInProgress.value = false;
        });
}

function isValidGetObjectDataResponse(response: DynamicDictionary): boolean {
    return isSet(response.data.data) && isSet(response.data.data.body) && isSet(response.data.data.body.objects);
}

function buildInsuredObjects(data: ClaimsTravelObject[]): void {
    insuredObjects.value = [];
    if (data.length > 0 && !isSet(data[0].error)) {
        data.forEach((object: ClaimsTravelObject): void => {
            const objectRiskIds = object.risks.map((risk) => risk.agrrisk);
            const isFuturePolicy = moment().isBefore(moment(object.validFrom));
            const isObjectDisabled =
                isFuturePolicy && !objectRiskIds.includes(claimsTravelService.tripCancellationRiskIc);

            insuredObjects.value.push(
                new InputOptionBuilder()
                    .setValue(object.id)
                    .setName(object.name)
                    .setCustom({
                        validFrom: object.validFrom,
                        validTo: object.validTo,
                        policy: object.policyNumber,
                        risks: object.risks,
                        insuranceTerms: object.insuranceTerms,
                        objectAttribute: object.objectAttribute,
                        errorMessage: isObjectDisabled ? translated('trip_cancellation_not_included_in_policy') : null,
                    })
                    .setDisabled(isObjectDisabled)
                    .build(),
            );
        });
    }
}

function preparePanels(): void {
    Object.values(AccidentDataForms).forEach((panelName: FormNames) => {
        inputOptions[panelName] = reactive(
            new (class implements Option {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = claimsTravelService.storageFields.formVisibility[panelName] ?? false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            })(),
        );
    });

    buildResidencyOptions();
    if (isPanelVisible(AccidentDataForms.SufferingPerson)) {
        buildSufferingPersonOptions();
    }
    if (isPanelVisible(AccidentDataForms.AccidentType)) {
        buildAccidentTypeOptions();
    }
    if (isPanelVisible(AccidentDataForms.Policy)) {
        buildInsuredObjects(claimsTravelService.storageFields.personInsuredObjects);
    }
}

function onInsuredReceiveError(): void {
    base.error.show(ErrorType.Error, 'app-insured-objects-blocks', 'btar_error_common');
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

function onTodayClick(): void {
    forms[AccidentDataForms.AccidentDate].field(FieldNames.AccidentDate).patch({
        startDate: moment().toDate().toString(),
        endDate: '',
    });
}

function onYesterdayClick(): void {
    forms[AccidentDataForms.AccidentDate].field(FieldNames.AccidentDate).patch({
        startDate: moment().subtract(1, 'days').toDate().toString(),
        endDate: '',
    });
}

function onFormInputChange(formName: FormNames): void {
    clearFormsAhead(formName);
    claimsTravelService.clearStepsAhead();
    storeForm(formName);
}

function resetToFirstPanel(): void {
    clearFormsAhead(AccidentDataForms.AccidentDate);
    scrollToPanel(AccidentDataForms.AccidentDate);
}

function storeForm(formName: FormNames): void {
    return claimsTravelService.storeForm(
        formName as keyof ClaimsTravelFormFields,
        forms[formName as keyof ClaimsTravelFormFields],
    );
}

function isActionsVisible(form: FormNames): boolean {
    const nextForms: FormNames[] = findFormsAfterCurrent(form);
    const visibleForms: FormNames[] = nextForms.filter((value) => inputOptions[value]?.visible);

    return !(nextForms.length > 0 && visibleForms.length > 0);
}

function findFormsAfterCurrent(currentForm: FormNames): FormNames[] {
    const currentFormIndex: number = Object.keys(forms).indexOf(currentForm);
    let data: FormNames[] = [];
    if (currentFormIndex >= 0) {
        data = Object.keys(forms).slice(currentFormIndex + 1) as FormNames[];
    }

    return data;
}

watch(isSufferingPersonBirthDateInputVisible, (value: boolean) => {
    value
        ? forms[AccidentDataForms.SufferingPersonOther]?.field(FieldNames.SufferingPersonOtherBirthDate).markAsVisible()
        : forms[AccidentDataForms.SufferingPersonOther]?.field(FieldNames.SufferingPersonOtherBirthDate).markAsHidden();
});

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    applyTranslationType('claims_travel');
    setupForms();
    claimsTravelService.restoreForms(forms);
    // setTimeout because of timing issue - form.isReady() starts returning true even though restoreForms has not properly restored all fields
    setTimeout(() => {
        markFormsAsReady();
        preparePanels();
        scrollToLastForm();
    });
    GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value);
});

defineExpose({
    claimsTravelService,
});
</script>

<template>
    <claim-page class="horizontal-spacing">
        <template #title>
            <h3>{{ translated('accident_data') }}</h3>
        </template>
        <app-custom-form
            v-if="forms[AccidentDataForms.AccidentDate]?.isReady()"
            :form="forms[AccidentDataForms.AccidentDate]"
            class="form"
        >
            <app-whiteboard
                :data-type="'whiteboard-0'"
                :data-scroll="AccidentDataForms.AccidentDate"
                :title="translated('when_event_happened')"
            >
                <div class="inputs row">
                    <div class="input-block">
                        <div class="calendar-block-container">
                            <app-input-date-with-calendar
                                class="calendar"
                                :disabled="isSearchInProgress"
                                :number-of-calendars="1"
                                :number-of-days="364"
                                :min-date="calendarMinDate"
                                :max-date="calendarMaxDate"
                                :return-raw="false"
                                :date-visual-empty-marker="translated('select_event_date')"
                                :mobile-button-text="translated('select_date')"
                                :form-field="forms[AccidentDataForms.AccidentDate].field(FieldNames.AccidentDate)"
                                @change="onFormInputChange(AccidentDataForms.AccidentDate)"
                            >
                            </app-input-date-with-calendar>
                            <span class="date-buttons" :class="{ disabled: isSearchInProgress }">
                                <button
                                    class="button outside calendar-date-button-big"
                                    data-type="button-today"
                                    @click="onTodayClick"
                                >
                                    {{ translated('today') }}
                                </button>
                                <button
                                    class="button outside calendar-date-button-big"
                                    data-type="button-yesterday"
                                    @click="onYesterdayClick"
                                >
                                    {{ translated('yesterday') }}
                                </button>
                                <app-button-with-callback
                                    class="calendar-date-button-small"
                                    v-bind="calendarDaysButtonsParams('today')"
                                    @button-callback-click="onTodayClick"
                                >
                                </app-button-with-callback>
                                <app-button-with-callback
                                    class="calendar-date-button-small"
                                    v-bind="calendarDaysButtonsParams('yesterday')"
                                    @button-callback-click="onYesterdayClick"
                                >
                                </app-button-with-callback>
                            </span>
                        </div>
                        <div class="input-block input-time-offset">
                            <h5 class="margin-bottom">{{ translated('what_time_did_it_happened') }}</h5>
                            <app-input-time
                                :mode="'split'"
                                :feedback-message="externalErrorTextMessage"
                                :form-field="forms[AccidentDataForms.AccidentDate].field(FieldNames.AccidentTime)"
                                @change="onFormInputChange(AccidentDataForms.AccidentDate)"
                            >
                            </app-input-time>
                        </div>
                        <div class="input-block input-time-offset">
                            <h5 class="margin-bottom">{{ translated('in_which_country_did_it_happened') }}</h5>
                            <app-input-country
                                class="input-country"
                                :include-default-country="true"
                                :patch-default-country="false"
                                :disable-error-text="true"
                                :form-field="forms[AccidentDataForms.AccidentDate].field(FieldNames.AccidentCountry)"
                                :placeholder="translate('btar_country_placeholder')"
                                @change="onFormInputChange(AccidentDataForms.AccidentDate)"
                            >
                            </app-input-country>
                        </div>
                    </div>
                </div>
                <app-button-primary
                    v-if="isActionsVisible(AccidentDataForms.AccidentDate)"
                    @click="onAccidentDatePanelProceedClick"
                >
                    <template #end>
                        <app-svg src="images/one/arrow-right.svg"></app-svg>
                    </template>
                    {{ translated('proceed') }}
                </app-button-primary>
            </app-whiteboard>
        </app-custom-form>
        <app-custom-form
            v-if="
                forms[AccidentDataForms.SufferingPerson]?.isReady() && isPanelVisible(AccidentDataForms.SufferingPerson)
            "
            :form="forms[AccidentDataForms.SufferingPerson]"
            class="form"
        >
            <app-whiteboard
                v-if="isPanelVisible(AccidentDataForms.SufferingPerson)"
                :data-type="'whiteboard-1'"
                :data-scroll="AccidentDataForms.SufferingPerson"
                :title="translated('submitting_claim_about')"
            >
                <div class="inputs row">
                    <div class="input-block">
                        <app-options-list
                            v-if="inputOptions[AccidentDataForms.SufferingPerson]"
                            :options="inputOptions[AccidentDataForms.SufferingPerson].options"
                            :form-field="forms[AccidentDataForms.SufferingPerson].field(FieldNames.SufferingPerson)"
                            :show-error-borders="false"
                            :type="'radio'"
                            :option-class="'filled'"
                            @change="onFormInputChange(AccidentDataForms.SufferingPerson)"
                        >
                        </app-options-list>
                    </div>
                </div>
                <app-button-primary
                    v-if="isActionsVisible(AccidentDataForms.SufferingPerson)"
                    @click="onPersonListPanelProceedClick"
                >
                    <template #end>
                        <app-svg src="images/one/arrow-right.svg"></app-svg>
                    </template>
                    {{ translated('proceed') }}
                </app-button-primary>
            </app-whiteboard>
        </app-custom-form>
        <app-custom-form
            v-if="
                forms[AccidentDataForms.SufferingPersonOther]?.isReady() &&
                isPanelVisible(AccidentDataForms.SufferingPersonOther)
            "
            :form="forms[AccidentDataForms.SufferingPersonOther]"
            class="form"
        >
            <app-whiteboard
                v-if="isPanelVisible(AccidentDataForms.SufferingPersonOther)"
                :data-type="'whiteboard-2'"
                :data-scroll="AccidentDataForms.SufferingPersonOther"
                :title="translated('other_person_details')"
            >
                <div class="inputs row">
                    <div class="input-block">
                        <div class="person-buttons">
                            <app-input-radio-overlayed
                                :form-field="
                                    forms[AccidentDataForms.SufferingPersonOther].field(
                                        FieldNames.SufferingPersonOtherIsResident,
                                    )
                                "
                                :options="inputOptions[AccidentDataForms.SufferingPersonOther].options"
                                @change="onFormInputChange(AccidentDataForms.SufferingPersonOther)"
                            ></app-input-radio-overlayed>
                        </div>
                        <div class="person-block" :class="{ shorten: isSufferingPersonBirthDateInputVisible }">
                            <app-input-text
                                :label="translated('name')"
                                :placeholder="translated('enter_name')"
                                :form-field="
                                    forms[AccidentDataForms.SufferingPersonOther].field(
                                        FieldNames.SufferingPersonOtherName,
                                    )
                                "
                                @change="onFormInputChange(AccidentDataForms.SufferingPersonOther)"
                            ></app-input-text>
                            <app-input-text
                                :label="translated('surname')"
                                :placeholder="translated('enter_surname')"
                                :form-field="
                                    forms[AccidentDataForms.SufferingPersonOther].field(
                                        FieldNames.SufferingPersonOtherSurname,
                                    )
                                "
                                @change="onFormInputChange(AccidentDataForms.SufferingPersonOther)"
                            ></app-input-text>
                            <app-input-text
                                :label="translated('person_code')"
                                :placeholder="translated('enter_person_code')"
                                :form-field="
                                    forms[AccidentDataForms.SufferingPersonOther].field(
                                        FieldNames.SufferingPersonOtherPersonCode,
                                    )
                                "
                                @change="onFormInputChange(AccidentDataForms.SufferingPersonOther)"
                            ></app-input-text>
                            <app-input-birth-date
                                v-if="
                                    forms[AccidentDataForms.SufferingPersonOther].field(
                                        FieldNames.SufferingPersonOtherBirthDate,
                                    ).isVisible
                                "
                                class="input-full input-date"
                                :label="translated('birth_date')"
                                :placeholder="translated('enter_birth_date')"
                                :delimiter="'-'"
                                :info-message="translated('date_format')"
                                :layout="dateLayout"
                                :validate-birth-date="true"
                                :form-field="
                                    forms[AccidentDataForms.SufferingPersonOther].field(
                                        FieldNames.SufferingPersonOtherBirthDate,
                                    )
                                "
                                @change="onFormInputChange(AccidentDataForms.SufferingPersonOther)"
                            ></app-input-birth-date>
                        </div>
                    </div>
                </div>
                <div class="inputs row claimed-policy">
                    <div class="input-block">
                        <h5 class="margin-bottom">{{ translated('suffering_person_claimed_policy_number') }}</h5>
                        <app-input-text
                            class="full-width"
                            :label="translated('policy_number')"
                            :placeholder="translated('enter_policy_number')"
                            :form-field="
                                forms[AccidentDataForms.SufferingPersonOther].field(
                                    FieldNames.SufferingPersonOtherPolicyNumber,
                                )
                            "
                            @change="onFormInputChange(AccidentDataForms.SufferingPersonOther)"
                        ></app-input-text>
                    </div>
                </div>
                <app-button-primary
                    v-if="isActionsVisible(AccidentDataForms.SufferingPersonOther)"
                    @click="onOtherPanelProceedClick"
                >
                    <template #end>
                        <app-svg src="images/one/arrow-right.svg"></app-svg>
                    </template>
                    {{ translated('proceed') }}
                </app-button-primary>
            </app-whiteboard>
        </app-custom-form>
        <app-custom-form
            v-if="forms[AccidentDataForms.Policy]?.isReady() && isPanelVisible(AccidentDataForms.Policy)"
            :form="forms[AccidentDataForms.Policy]"
            class="form"
        >
            <app-whiteboard
                v-if="isPanelVisible(AccidentDataForms.Policy)"
                class="whiteboard"
                :data-type="'whiteboard-3'"
                :data-scroll="AccidentDataForms.Policy"
                :title="translated('select_policy_to_claim')"
            >
                <div class="inputs row">
                    <div class="input-block">
                        <app-insured-objects-blocks
                            :icon="'travel'"
                            :object-type="type()"
                            :description-transformer-callback="descriptionTransformerCallback"
                            :translation-type="type()"
                            :without-branch-value="false"
                            :options="insuredObjects"
                            :form-field="forms[AccidentDataForms.Policy].field(FieldNames.Policy)"
                            :event-date="accidentEventDateTime"
                            :fetch-url="ClaimsTravelAjaxRoutes.InsuredObject"
                            :finder-callback="onFinderReceive"
                            :finder-object-builder-callback="new AppCountry().isLV() ? finderOptionBuilder : undefined"
                            :search-by="'policy-number'"
                            :fetch-params="insuredObjectsRequestParamsByType(InsuredObjectRequestTypes.Search)"
                            :automatically-select-the-only-option="true"
                            :without-holder-code-field="true"
                            :force-show-finder="true"
                            :mode="'with-finder'"
                            @error="onInsuredReceiveError"
                            @change="onFormInputChange(AccidentDataForms.Policy)"
                        >
                        </app-insured-objects-blocks>
                    </div>
                </div>
                <app-button-primary
                    v-if="isActionsVisible(AccidentDataForms.Policy)"
                    @click="onInsuredListProceedClick"
                >
                    <template #end>
                        <app-svg src="images/one/arrow-right.svg"></app-svg>
                    </template>
                    {{ translated('proceed') }}
                </app-button-primary>
            </app-whiteboard>
        </app-custom-form>
        <app-custom-form
            v-if="forms[AccidentDataForms.AccidentType]?.isReady() && isPanelVisible(AccidentDataForms.AccidentType)"
            :form="forms[AccidentDataForms.AccidentType]"
            class="form"
        >
            <app-whiteboard
                v-if="isPanelVisible(AccidentDataForms.AccidentType)"
                class="whiteboard"
                :data-type="'whiteboard-4'"
                :data-scroll="AccidentDataForms.AccidentType"
                :title="translated('select_accident_type')"
            >
                <div class="inputs row">
                    <div class="input-block">
                        <app-options-smart-list
                            :options="inputOptions[AccidentDataForms.AccidentType].options"
                            :type="'radio'"
                            :option-class="'filled'"
                            :show-error-borders="false"
                            :skip-options-change-form-reset="true"
                            :form-field="forms[AccidentDataForms.AccidentType].field(FieldNames.AccidentType)"
                            @change="onFormInputChange(AccidentDataForms.AccidentType)"
                        >
                        </app-options-smart-list>
                    </div>
                </div>
                <app-button-primary
                    v-if="isActionsVisible(AccidentDataForms.AccidentType)"
                    @click="onAccidentTypeProceedClick"
                >
                    <template #end>
                        <app-svg src="images/one/arrow-right.svg"></app-svg>
                    </template>
                    {{ translated('proceed') }}
                </app-button-primary>
            </app-whiteboard>
        </app-custom-form>
    </claim-page>
</template>

<style scoped lang="scss">
.calendar-block-container {
    display: flex;
    justify-content: space-between;

    @include respond-below('sm') {
        flex-direction: column;
        gap: var(--size-small);
    }

    .date-buttons {
        .button-with-callback {
            background-color: var(--system-color-success-dark);
        }
    }
}

.input-time-offset {
    margin-top: var(--size-big);

    :deep(.input-time) {
        .feedback {
            margin-top: 4px;
            line-height: var(--size-tiny);
        }
    }
}

:deep(.split-wrapper) {
    .input:not(:last-child) {
        margin-right: 0;
    }

    .input-text {
        .label.informative {
            margin-bottom: 0;
        }
    }
}

.protocol-number {
    margin-top: var(--size-big);

    :deep(.protocol-number-input) {
        margin-bottom: var(--size-pico);
        width: 100%;
    }

    .remark {
        position: relative;
        display: flex;
        font-size: var(--font-size-nano);
        color: var(--black-500);

        .img {
            position: relative;
            margin-right: var(--size-pico);
        }
    }
}

.form {
    .guilty-vehicle-number {
        width: 100%;
    }

    .claimed-policy {
        margin-top: var(--size-medium);

        :deep(.input) {
            .label p,
            .label label {
                color: var(--text-color-default);
                font-weight: 600;
            }
        }
    }

    .button {
        padding: 0 var(--size-medium);

        .icon-right {
            margin-left: var(--size-nano);
        }
    }

    .button-with-callback {
        height: 52px;
    }

    .one-button {
        margin-top: var(--size-big);
    }

    .margin-bottom {
        margin-bottom: var(--size-small);
    }

    .input-date-with-calendar {
        margin-right: var(--size-small);
        flex: 1;

        @include respond-below('sm') {
            margin-right: 0;
        }
    }

    .input-country {
        width: 100%;

        @include respond-above('sm') {
            width: 430px;
        }
    }

    .date-buttons {
        display: flex;

        .button:not(:last-child) {
            margin-right: var(--size-small);
        }

        .calendar-date-button-big {
            display: flex;
            @include respond-below('sm') {
                display: none;
            }
        }

        .calendar-date-button-small {
            display: none;
            height: 32px;
            padding: var(--size-femto) var(--size-tiny);
            font-size: var(--font-size-nano);

            @include respond-below('sm') {
                display: block;
            }
        }

        @include respond-below('sm') {
            gap: var(--size-nano);
        }
    }

    .person-buttons {
        display: flex;
        flex-direction: column;
        gap: var(--size-small);
        margin-bottom: var(--size-big);

        :deep(.input-radio-overlayed) {
            .buttons {
                .overlay-button {
                    padding: var(--size-tiny);
                    width: 50%;
                }
            }
        }

        @include respond-below('sm') {
            margin-bottom: var(--size-tiny);
        }
    }

    .country {
        width: 100%;
    }

    .person-block {
        margin-top: var(--size-small);
        display: flex;
        gap: var(--size-small);
        flex-wrap: wrap;

        > .input {
            flex: 1;
            width: 50%;
        }

        &.shorten {
            > .input,
            .input-date {
                max-width: 100%;
                flex-direction: column;

                @include respond-above('sm') {
                    max-width: calc(50% - 10px);
                    flex-basis: 50%;
                    flex-grow: initial;
                    flex-shrink: initial;
                }
            }
        }

        .input {
            flex: 1;

            @include respond-below('sm') {
                width: 100%;
                flex: 0 0 100%;
            }
        }

        .input-full {
            flex-basis: 100%;
        }

        :deep(.input-date) {
            .wrapper {
                justify-content: flex-start;
                min-height: 52px;
                padding: 0 30px 0 20px;
            }
        }

        :deep(.input) {
            .label p,
            .label label {
                color: var(--text-color-default);
                font-weight: 600;
            }
        }
    }
}
</style>
