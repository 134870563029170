<script setup lang="ts">
import { Router, useRouter } from 'vue-router';
import {
    computed,
    markRaw,
    onMounted,
    reactive,
    ref,
    Ref,
    shallowReactive,
    ShallowUnwrapRef,
    UnwrapNestedRefs,
} from 'vue';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import { FormNames as AccidentDetailsFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Cancellation/FormNames';
import { FieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Cancellation/FieldNames';
import Form from '@/Assets/Libraries/Form/Form';
import Option from '@/Apps/ClaimsTravel/Interfaces/Option';
import {
    TravelCancellationForms,
    WhatCancellationHappenedFields,
    HowTripWasPlannedFields,
    OtherInsuredFields,
    IndemnificationFields,
} from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentDetails/TravelCancellationForms';
import { useDefine } from '@/Composables/Define';
import { useTranslate } from '@/Composables/Translate';
import FormField from '@/Assets/Libraries/Form/FormField';
import Validation from '@/Services/validation.service';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import { useClaimsTravelHtml } from '@/Apps/ClaimsTravel/Composables/ClaimsTravelHtml';
import ClaimsTravelFormFields from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelFormFields';
import AppButtonPrimary from '@/Components/Buttons/ButtonPrimary/ButtonPrimary.vue';
import AppSvg from '@/Components/Other/Svg/Svg.vue';
import AppWhiteboard from '@/Components/Containers/Whiteboard/Whiteboard.vue';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppOptionsList from '@/Components/Options/OptionsList/OptionsList.vue';
import AppInputRadioSelector from '@/Components/Inputs/InputRadioSelector/InputRadioSelector.vue';
import AppOptionsSmartList from '@/Components/Options/OptionsSmartList/OptionsSmartList.vue';
import ClaimsTravelObject from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelObject';
import AppRepeatable from '@/Components/Other/Repeatable/Repeatable.vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppTravelClaimRepeatable from '@/Apps/ClaimsTravel/Components/TravelClaimRepeatable.vue';
import {
    RepeatableNameItem,
    RepeatableNameStoredItem,
} from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentDetails/RepeatableName';
import Value from '@/Assets/Libraries/Form/Value';
import Sanitizer from '@/Services/sanitizer.service';
import GuardsService from '@/Apps/ClaimsTravel/Services/GuardsService';
import OneBaseService from '@/Services/OneBaseService';
import ClaimsTravelStepUid from '@/Apps/ClaimsTravel/Enums/ClaimsTravelStepUid';

type FormNames = `${AccidentDetailsFormNames}`;

const router: Router = useRouter();
const { translateForType, applyTranslationType, type, translate } = useTranslate();
const { isSet } = useDefine();

const claimsTravelService: ClaimsTravelService = ClaimsTravelService.getInstance();

const Step: number = 2;
const MaxPersons: number = 5;

const forms: ShallowUnwrapRef<{ [FormKey in keyof TravelCancellationForms]: Form<TravelCancellationForms[FormKey]> }> =
    shallowReactive({}) as ShallowUnwrapRef<{
        [FormKey in keyof TravelCancellationForms]: Form<TravelCancellationForms[FormKey]>;
    }>;

const inputOptions: UnwrapNestedRefs<Record<FormNames, Option>> = reactive({}) as UnwrapNestedRefs<
    Record<FormNames, Option>
>;

const otherPersonsList: Ref<InputOption[]> = ref([]);

const persons: Ref<RepeatableNameItem[]> = ref([]);

const isVisibleOtherPersonsList: Ref<boolean> = computed(() => {
    return forms[AccidentDetailsFormNames.OtherInsured].field(FieldNames.OtherInsured).value === 'yes';
});

const isVisibleIndemnifications: Ref<boolean> = computed(() => {
    return forms[AccidentDetailsFormNames.Indemnification].field(FieldNames.Indemnification).value === 'yes';
});

const isOtherPersonForSubmit: Ref<boolean> = computed(() => {
    const isOther: boolean =
        claimsTravelService.fields!.sufferingPerson!.sufferingPerson.selected !==
            OneBaseService.getInstance().currentUser.value.personId && otherInsuredPersonsList().length > 0;

    return isOther || (!isOther && otherPersonsList.value.length === 0);
});

const otherPersonsTitle: Ref<string> = computed(() => {
    return isOtherPersonForSubmit.value ? translated('provide_persons_details') : translated('select_that_persons');
});

const selectedPolicy: Ref<ClaimsTravelObject> = computed(() => {
    return claimsTravelService.storageFields.personInsuredObjects.find(
        (object) => object.id === claimsTravelService.fields.policy?.policy.object,
    )!;
});

function isProceedVisible(form: FormNames): boolean {
    const next = findNextFormName(form);

    return next !== undefined ? !inputOptions[next].visible : true;
}

function proceedToNextForm(formName: FormNames, nextFormName: FormNames | null = null): void {
    const nextPanel = nextFormName ? nextFormName : findNextFormName(formName);
    if (nextPanel) {
        inputOptions[nextPanel].visible = true;
        claimsTravelService.storageFields.formVisibility[nextPanel] = true;
        useClaimsTravelHtml().scrollToPanel(nextPanel);
    }
}

function scrollToLastForm(): void {
    const lastForm = Object.keys(inputOptions).findLast((form) => inputOptions[form as FormNames].visible);
    const firstFormName: string = AccidentDetailsFormNames.WhatCancellationHappened;
    useClaimsTravelHtml().scrollToPanel(lastForm ? lastForm : firstFormName);
}

function clearForms(): void {
    Object.keys(forms).forEach((form) => {
        claimsTravelService.storageFields.formVisibility[form as keyof ClaimsTravelFormFields] = false;
        clearForm(form as FormNames);
    });
}

function findNextFormName(currentForm: FormNames): FormNames | undefined {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);

    return Object.keys(forms).at(currentFormIndex + 1) as FormNames | undefined;
}

function isPanelVisible(panel: FormNames): boolean {
    return inputOptions[panel] && inputOptions[panel].visible;
}

function clearFormsAhead(currentForm: FormNames): void {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);
    const nextFormKeys: FormNames[] = Object.keys(forms).slice(
        currentFormIndex + 1,
        Object.keys(forms).length,
    ) as FormNames[];
    nextFormKeys.forEach((formName: FormNames) => {
        clearForm(formName);
        inputOptions[formName].visible = false;
        claimsTravelService.storageFields.formVisibility[formName] = false;
    });
}

function markFormsAsReady(): void {
    Object.values(forms).forEach((form) => form.setReady());
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

function storeForm(formName: FormNames): void {
    return claimsTravelService.storeForm(formName as keyof ClaimsTravelFormFields, forms[formName]);
}

function onFormInputChange(formName: FormNames): void {
    clearFormsAhead(formName);
    storeForm(formName);
}

function onRemove(item: RepeatableNameItem): void {
    item.form.destroy();
}

function otherInsuredPersonsList(): ClaimsTravelObject[] {
    return claimsTravelService.storageFields.allInsuredObjects.filter((object: ClaimsTravelObject) => {
        return (
            object.policyNumber === selectedPolicy.value.policyNumber &&
            object.objectAttribute.valueid !== selectedPolicy.value.objectAttribute.valueid
        );
    });
}

function restoreIterableValues(): void {
    if (claimsTravelService.fields.otherInsured && claimsTravelService.fields.otherInsured.otherPerson) {
        persons.value = claimsTravelService.fields.otherInsured.otherPerson.map(
            (storedItem: RepeatableNameStoredItem): RepeatableNameItem => {
                const item: RepeatableNameItem = createItemFactory(storedItem.formName);
                item.form.restoreValues({
                    name: storedItem.name,
                });

                return item;
            },
        );
    }
}

function storeValues(): void {
    claimsTravelService.fields.otherInsured!.otherPerson = persons.value.map(
        (person: RepeatableNameItem): RepeatableNameStoredItem => {
            return {
                formName: person.form.name,
                name: person.form.field('name').value,
            };
        },
    );
}

function createItemFactory(id: string): RepeatableNameItem {
    const form: Form = new Form(id, { useValidationV2: true });
    form.addField(new FormField('name').addValidators({ required: Validation.required }));
    form.setReady();

    return { id: id, form: markRaw(form) };
}

function preparePanels(): void {
    Object.values(AccidentDetailsFormNames).forEach((panelName: FormNames) => {
        inputOptions[panelName] = reactive(
            new (class implements Option {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = claimsTravelService.storageFields.formVisibility[panelName] ?? false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            })(),
        );
    });
    buildWhatCancellationHappenedOptions();
    buildHowTripWasPlanned();
    buildOtherInsured();
    buildOtherPersons();
    buildIndemnification();
}

function buildWhatCancellationHappenedOptions(): void {
    claimsTravelService.tripInterruptionReasons.forEach((reason: any) => {
        inputOptions[AccidentDetailsFormNames.WhatCancellationHappened].options.push(
            new InputOptionBuilder().setValue(reason).setName(translated(reason)).build(),
        );
    });
}

function buildHowTripWasPlanned(): void {
    inputOptions[AccidentDetailsFormNames.HowTripWasPlanned].options = [
        new InputOptionBuilder().setValue('travel_agency').setName(translated('travel_agency')).build(),
        new InputOptionBuilder().setValue('independently').setName(translated('independently')).build(),
    ];
}

function buildOtherInsured(): void {
    inputOptions[AccidentDetailsFormNames.OtherInsured].options = yesNoInputOptions();
}

function buildOtherPersons(): void {
    const claimsTravelObject: ClaimsTravelObject[] = otherInsuredPersonsList();
    claimsTravelObject.forEach((object: ClaimsTravelObject) => {
        otherPersonsList.value.push(new InputOptionBuilder().setValue(object.id).setName(object.name).build());
    });
}

function buildIndemnification(): void {
    inputOptions[AccidentDetailsFormNames.Indemnification].options = yesNoInputOptions();
}

function yesNoInputOptions(): InputOption[] {
    return [
        new InputOptionBuilder().setValue('yes').setName(translated('yes')).build(),
        new InputOptionBuilder().setValue('no').setName(translated('no')).build(),
    ];
}

function setupForms(): void {
    const requiredValidator = { required: Validation.required };
    const requiredOptionValidator = {
        required: (value: { selected?: string }) => isSet(value?.selected) && value.selected !== '',
    };
    const otherInsuredPersonValidator = {
        required: (value: { selected?: string }) => {
            let result: boolean = true;
            if (isVisibleOtherPersonsList.value) {
                if (!isOtherPersonForSubmit.value) {
                    result = value.selected !== '';
                }
            }

            return result;
        },
    };
    const otherPersonValidator = {
        required: (value: string[]) => {
            let result: boolean = true;
            if (isVisibleOtherPersonsList.value) {
                claimsTravelService.fields.otherInsured!.otherPerson.forEach((name: RepeatableNameStoredItem) => {
                    if (name.name === '') {
                        result = false;
                    }
                });
            }

            return result;
        },
    };
    const indemnificationSumValidator = {
        required: (value: string) => {
            let result: boolean = true;
            if (isVisibleIndemnifications.value) {
                result = new Value(value).isNotEmpty();
            }

            return result;
        },
    };
    const whatCancellationHappenedForm: Form<WhatCancellationHappenedFields> = new Form({ useValidationV2: true });
    whatCancellationHappenedForm.addField(
        new FormField(FieldNames.WhatCancellationHappened, '', requiredOptionValidator),
    );
    forms[AccidentDetailsFormNames.WhatCancellationHappened] = whatCancellationHappenedForm;
    const howTripWasPlanned: Form<HowTripWasPlannedFields> = new Form({ useValidationV2: true });
    howTripWasPlanned.addField(new FormField(FieldNames.HowTripWasPlanned, '', requiredOptionValidator));
    forms[AccidentDetailsFormNames.HowTripWasPlanned] = howTripWasPlanned;
    const otherInsuredForm: Form<OtherInsuredFields> = new Form({ useValidationV2: true });
    otherInsuredForm.addField(new FormField(FieldNames.OtherInsured, '', requiredValidator));
    otherInsuredForm.addField(new FormField(FieldNames.OtherInsuredPerson, '', otherInsuredPersonValidator));
    otherInsuredForm.addField(new FormField(FieldNames.OtherPerson, [], otherPersonValidator));
    forms[AccidentDetailsFormNames.OtherInsured] = otherInsuredForm;
    const indemnification: Form<IndemnificationFields> = new Form({ useValidationV2: true });
    indemnification.addField(new FormField(FieldNames.Indemnification, '', requiredValidator));
    indemnification.addField(
        new FormField(FieldNames.IndemnificationSum, '', indemnificationSumValidator, Sanitizer.cleanNumber),
    );
    indemnification.addField(
        new FormField(FieldNames.LossesSum, '', indemnificationSumValidator, Sanitizer.cleanNumber),
    );
    forms[AccidentDetailsFormNames.Indemnification] = indemnification;
}

function clearForm(formName: FormNames): void {
    const form:
        | Form<WhatCancellationHappenedFields>
        | Form<HowTripWasPlannedFields>
        | Form<OtherInsuredFields>
        | Form<IndemnificationFields> = forms[formName];
    switch (formName) {
        case AccidentDetailsFormNames.WhatCancellationHappened: {
            const accidentDateForm = form as Form<WhatCancellationHappenedFields>;
            accidentDateForm.field(FieldNames.WhatCancellationHappened).setValue({ selected: '', extra: '' });
            break;
        }
        case AccidentDetailsFormNames.HowTripWasPlanned: {
            const accidentDateForm = form as Form<HowTripWasPlannedFields>;
            accidentDateForm.field(FieldNames.HowTripWasPlanned).setValue({ selected: '', extra: '' });
            break;
        }
        case AccidentDetailsFormNames.OtherInsured: {
            const otherInsuredFieldsForm = form as Form<OtherInsuredFields>;
            otherInsuredFieldsForm.field(FieldNames.OtherInsured).setValue('');
            otherInsuredFieldsForm.field(FieldNames.OtherInsuredPerson).setValue({ selected: '', extra: '' });
            otherInsuredFieldsForm.field(FieldNames.OtherPerson).setValue([]);
            break;
        }
        case AccidentDetailsFormNames.Indemnification: {
            const indemnificationForm = form as Form<IndemnificationFields>;
            indemnificationForm.field(FieldNames.Indemnification).setValue('');
            indemnificationForm.field(FieldNames.IndemnificationSum).setValue('');
            indemnificationForm.field(FieldNames.LossesSum).setValue('');
            break;
        }
        default:
    }
    storeForm(formName);
}

function onProceedClick(panel: FormNames): void {
    storeValues();
    const form = forms[panel];
    form.submitAttempt().then(() => {
        if (form.isValid()) {
            if (panel === (AccidentDetailsFormNames.Indemnification as FormNames)) {
                GuardsService.getInstance().applyStepValidity(Step, true);
                router.push({ name: ClaimsTravelStepUid.UploadFiles });
            } else {
                proceedToNextForm(panel);
            }
        }
    });
}

onMounted(() => {
    applyTranslationType(ClaimsTravelService.translationType);
    setupForms();
    claimsTravelService.restoreForms(forms);
    restoreIterableValues();
    markFormsAsReady();
    preparePanels();
});

defineExpose({
    clearForms,
    scrollToLastForm,
});
</script>

<template>
    <app-custom-form
        v-if="forms[AccidentDetailsFormNames.WhatCancellationHappened]?.isReady()"
        :form="forms[AccidentDetailsFormNames.WhatCancellationHappened]"
        class="form"
        @change="storeForm(AccidentDetailsFormNames.WhatCancellationHappened)"
    >
        <app-whiteboard
            :data-type="'whiteboard-0'"
            :data-scroll="AccidentDetailsFormNames.WhatCancellationHappened"
            :title="translated('cancellation_reason')"
        >
            <div class="inputs row">
                <div class="input-block">
                    <app-options-smart-list
                        :options="inputOptions[AccidentDetailsFormNames.WhatCancellationHappened].options"
                        :type="'radio'"
                        :option-class="'filled'"
                        :show-error-borders="false"
                        :skip-options-change-form-reset="true"
                        :form-field="
                            forms[AccidentDetailsFormNames.WhatCancellationHappened].field(
                                FieldNames.WhatCancellationHappened,
                            )
                        "
                        @change="onFormInputChange(AccidentDetailsFormNames.WhatCancellationHappened)"
                    >
                    </app-options-smart-list>
                </div>
            </div>
            <app-button-primary
                v-if="isProceedVisible(AccidentDetailsFormNames.WhatCancellationHappened)"
                @click="onProceedClick(AccidentDetailsFormNames.WhatCancellationHappened)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="isPanelVisible(AccidentDetailsFormNames.HowTripWasPlanned)"
        :form="forms[AccidentDetailsFormNames.HowTripWasPlanned]"
        class="form"
        @change="storeForm(AccidentDetailsFormNames.HowTripWasPlanned)"
    >
        <app-whiteboard
            :data-type="'whiteboard-1'"
            :data-scroll="AccidentDetailsFormNames.HowTripWasPlanned"
            :title="translated('how_trip_was_planned')"
        >
            <div class="inputs row">
                <div class="input-block">
                    <app-options-smart-list
                        :options="inputOptions[AccidentDetailsFormNames.HowTripWasPlanned].options"
                        :type="'radio'"
                        :option-class="'filled'"
                        :show-error-borders="false"
                        :skip-options-change-form-reset="true"
                        :form-field="
                            forms[AccidentDetailsFormNames.HowTripWasPlanned].field(FieldNames.HowTripWasPlanned)
                        "
                        @change="onFormInputChange(AccidentDetailsFormNames.HowTripWasPlanned)"
                    >
                    </app-options-smart-list>
                </div>
            </div>
            <app-button-primary
                v-if="isProceedVisible(AccidentDetailsFormNames.HowTripWasPlanned)"
                @click="onProceedClick(AccidentDetailsFormNames.HowTripWasPlanned)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="isPanelVisible(AccidentDetailsFormNames.OtherInsured)"
        :form="forms[AccidentDetailsFormNames.OtherInsured]"
        class="form"
        @change="storeForm(AccidentDetailsFormNames.OtherInsured)"
    >
        <app-whiteboard
            :data-type="'whiteboard-2'"
            :data-scroll="AccidentDetailsFormNames.OtherInsured"
            :title="translated('was_there_any_other_insured')"
        >
            <div class="inputs row">
                <div class="input-block">
                    <app-input-radio-selector
                        class="column-span-8 input-horizontal"
                        :form-field="forms[AccidentDetailsFormNames.OtherInsured].field(FieldNames.OtherInsured)"
                        :options="inputOptions[AccidentDetailsFormNames.OtherInsured].options"
                        :type="'with-circle'"
                        @change="onFormInputChange(AccidentDetailsFormNames.OtherInsured)"
                    >
                    </app-input-radio-selector>
                </div>
            </div>
            <div v-if="isVisibleOtherPersonsList" class="inputs row">
                <div class="input-block block-offset">
                    <h5 class="margin-bottom">{{ otherPersonsTitle }}</h5>
                </div>
                <app-options-list
                    v-if="!isOtherPersonForSubmit"
                    class="other-persons-list"
                    :options="otherPersonsList"
                    :form-field="forms[AccidentDetailsFormNames.OtherInsured].field(FieldNames.OtherInsuredPerson)"
                    :required="true"
                    :show-error-borders="false"
                    @change="onFormInputChange(AccidentDetailsFormNames.OtherInsured)"
                >
                </app-options-list>
                <app-repeatable
                    v-if="isOtherPersonForSubmit"
                    v-model:items="persons"
                    class="other-persons-list"
                    :min="1"
                    :max="MaxPersons"
                    :factory="createItemFactory"
                    @remove="onRemove"
                    @change="onFormInputChange(AccidentDetailsFormNames.OtherInsured)"
                >
                    <template #default="{ item, index, repeatable }">
                        <app-travel-claim-repeatable
                            :title="'Item #' + (index + 1)"
                            :add-button-text="translated('add_person')"
                            :remove-button-text="translated('remove_person')"
                            :item="item"
                            :repeatable="repeatable"
                        >
                            <app-custom-form class="item-form" :form="item.form">
                                <app-input-text
                                    class="other-insured-name"
                                    :form-field="item.form.field('name')"
                                    @change="onFormInputChange(AccidentDetailsFormNames.OtherInsured)"
                                ></app-input-text>
                            </app-custom-form>
                        </app-travel-claim-repeatable>
                    </template>
                </app-repeatable>
            </div>
            <app-button-primary
                v-if="isProceedVisible(AccidentDetailsFormNames.OtherInsured)"
                @click="onProceedClick(AccidentDetailsFormNames.OtherInsured)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="isPanelVisible(AccidentDetailsFormNames.Indemnification)"
        :form="forms[AccidentDetailsFormNames.Indemnification]"
        class="form"
        @change="storeForm(AccidentDetailsFormNames.Indemnification)"
    >
        <app-whiteboard
            :data-type="'whiteboard-3'"
            :data-scroll="AccidentDetailsFormNames.Indemnification"
            :title="translated('indemnification')"
        >
            <div class="inputs row">
                <div class="input-block">
                    <app-input-radio-selector
                        class="column-span-8 input-horizontal"
                        :form-field="forms[AccidentDetailsFormNames.Indemnification].field(FieldNames.Indemnification)"
                        :options="inputOptions[AccidentDetailsFormNames.Indemnification].options"
                        :type="'with-circle'"
                        @change="onFormInputChange(AccidentDetailsFormNames.Indemnification)"
                    >
                    </app-input-radio-selector>
                </div>
            </div>
            <div v-if="isVisibleIndemnifications" class="inputs row indemnification">
                <div class="input-block indemnification-container">
                    <app-input-text
                        class="what-sum"
                        :label="translated('indemnification_what_sum')"
                        :placeholder="translated('indemnification_enter_sum')"
                        :inside-label="translated('eur')"
                        :inside-label-class="'clean'"
                        :form-field="
                            forms[AccidentDetailsFormNames.Indemnification].field(FieldNames.IndemnificationSum)
                        "
                    ></app-input-text>
                    <app-input-text
                        :label="translated('indemnification_trip_losses')"
                        :placeholder="translated('indemnification_enter_sum')"
                        :inside-label="translated('eur')"
                        :inside-label-class="'clean'"
                        :form-field="forms[AccidentDetailsFormNames.Indemnification].field(FieldNames.LossesSum)"
                    ></app-input-text>
                </div>
            </div>
            <app-button-primary
                v-if="isProceedVisible(AccidentDetailsFormNames.Indemnification)"
                @click="onProceedClick(AccidentDetailsFormNames.Indemnification)"
            >
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
</template>

<style scoped lang="scss">
.form {
    .block-offset {
        margin-top: var(--size-big);

        :deep(.input-time) {
            .feedback {
                margin-top: var(--size-femto);
                line-height: var(--size-tiny);
            }
        }
    }

    .one-button {
        margin-top: var(--size-big);
    }

    .other-persons-list {
        margin-top: var(--size-small);

        .repeatable-item {
            width: 50%;
            border-bottom: 1px solid var(--component-color-border-default);

            &:last-child {
                border-bottom: none;
            }

            @include respond-below('sm') {
                width: 100%;
            }
        }

        .other-insured-name {
            width: 100%;
            padding-bottom: var(--size-small);
        }
    }

    .input-horizontal {
        width: 50%;

        :deep(.grid-utility) {
            .radio-button {
                grid-column: span 6;
                min-height: 64px;
            }
        }

        @include respond-below('sm') {
            width: 100%;

            :deep(.grid-utility) {
                display: grid;
                grid-template-columns: repeat(12, 1fr);
            }
        }
    }

    .indemnification {
        margin-top: var(--size-big);

        .indemnification-container {
            display: flex;
            justify-content: space-between;
            gap: var(--size-small);

            .input-text {
                width: 50%;

                :deep(.label) {
                    margin-bottom: var(--size-normal);
                    font-size: var(--font-size-small);
                    font-weight: 700;
                }
            }

            @include respond-below('sm') {
                gap: 0;
                flex-direction: column;

                .input-text {
                    width: 100%;
                    margin-bottom: var(--size-small);

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
</style>
