export enum FieldNames {
    WhatCancellationHappened = 'whatCancellationHappened',
    HowTripWasPlanned = 'howTripWasPlanned',
    OtherInsured = 'otherInsured',
    OtherInsuredPerson = 'otherInsuredPerson',
    OtherPerson = 'otherPerson',
    Indemnification = 'indemnification',
    IndemnificationSum = 'indemnificationSum',
    LossesSum = 'lossesSum',
}
