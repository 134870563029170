import { Ref, ref } from 'vue';
import StringDictionary from '@/Interfaces/string.dictionary.interface';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import { useTranslate } from '@/Composables/Translate';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import TransferStateService from '@/Core/ServerState/TransferStateService';

export const useOneDashboard = (): OneDashboardInterface => {
    const navigationButtons: Ref<InputOption[]> = ref([]);
    const { translateForType, applyTranslationType, type } = useTranslate();

    const init = (): void => {
        buildNavigationBelt();
        applyActiveTab();
    };

    const buildNavigationBelt = (): void => {
        if (hasStoredUrls()) {
            navigationButtons.value = [];
            const navigationUrls: StringDictionary = storedUrls();
            Object.keys(navigationUrls).forEach((value: string): void => {
                if (value !== '') {
                    navigationButtons.value.push(
                        new InputOptionBuilder()
                            .setName(translated(value.replaceAll('-', '_')))
                            .setValue(navigationUrls[value])
                            .setCustom({
                                active: currentActiveTab() === value,
                                counter: 0,
                                tab: value,
                            })
                            .build(),
                    );
                }
            });
        }
    };

    const storedUrls = (): StringDictionary => {
        return TransferStateService.getInstance().get('navigationUrls');
    };

    const currentActiveTab = (): string => {
        return TransferStateService.getInstance().get('activeTabUid');
    };

    const applyActiveTab = (): void => {
        navigationButtons.value.forEach((item: InputOption): void => {
            if (item.custom) {
                (item.custom as DynamicDictionary).active =
                    (item.custom as DynamicDictionary).tab === currentActiveTab();
            }
        });
    };

    const translated = (stringId: string): string => {
        applyTranslationType('dashboard');
        return translateForType(stringId, type());
    };

    const hasStoredUrls = (): boolean => {
        return !!storedUrls();
    };

    return {
        currentActiveTab,
        navigationButtons,
        buildNavigationBelt,
        applyActiveTab,
        translated,
        init,
        storedUrls,
    };
};

export interface OneDashboardInterface {
    currentActiveTab: () => string;
    navigationButtons: Ref<InputOption[]>;
    buildNavigationBelt: () => void;
    applyActiveTab: (tab: string) => void;
    translated: (stringId: string) => string;
    init: () => void;
    storedUrls: () => StringDictionary;
}
