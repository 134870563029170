<script setup lang="ts">
import { getCurrentInstance, onMounted, ref, Ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import PolicyDetailsPopup from '@/Pages/OneDashboard/Insurances/PolicyDetailsPopup';
import AppPolicyDetailsPaymentMethod from '@/Components/Policies/PolicyDetailsPaymentMethod/PolicyDetailsPaymentMethod.vue';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import RequestService from '@/Services/request.service';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import { useMobileApp } from '@/Apps/MobileApp/Composables/MobileApp';
import RecurringPaymentDetails from '@/Interfaces/recurring.payment.details.interface';
import PolicyDetailsPaymentMethodContent from '@/Components/Policies/PolicyDetailsPaymentMethod/Interfaces/PolicyDetailsPaymentMethodContentInterface';

const Tab: string = 'card';

const viewIsReady: Ref<boolean> = ref(false);
const ajaxContentIsReady: Ref<boolean> = ref(false);
const content: Ref<PolicyDetailsPaymentMethodContent> = ref({
    cardNumbers: '',
    cardStatus: '',
});

const { designation, ajaxAgreement, agreementView, hasStoredAgreement, viewContent, emitFallbackEvent } =
    useMobileApp();

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    if (hasStoredAgreement()) {
        prepareContent();
    } else {
        emitFallbackEvent(designation().agreement);
        ajaxContentIsReady.value = true;
    }
});

const prepareContent = (): void => {
    PopupService.getInstance().show(new OnePopup().withType().loading);
    const view: PolicyDetailsPopup = agreementView();
    view.buildDetails();
    view.contentBuilder().stashContent();
    viewIsReady.value = true;
    PopupService.getInstance().hide();
    prepareAdditionalContent();
};

const applyViewContent = (): void => {
    content.value = viewContent(Tab, designation().agreement) as PolicyDetailsPaymentMethodContent;
};

const prepareAdditionalContent = (): void => {
    RequestService.getInstance()
        .get({ uri: Url.MobileApp.card, content: ajaxAgreement() })
        .then((response: AxiosResponse): void => {
            const axiosResponse: DynamicDictionary = response.data.data.body;
            const view: PolicyDetailsPopup = agreementView();
            view.applyRecurringPayment(
                (axiosResponse.recurringPayment as RecurringPaymentDetails) ?? null,
            ).buildDetails();
            view.contentBuilder().stashContent();
            ajaxContentIsReady.value = true;
            applyViewContent();
        });
};
</script>

<template>
    <div class="app-payment-card-page">
        <app-content-loader v-if="viewIsReady && !ajaxContentIsReady"></app-content-loader>
        <app-policy-details-payment-method v-if="viewIsReady && ajaxContentIsReady" :content="content">
        </app-policy-details-payment-method>
    </div>
</template>

<style lang="scss" scoped>
.app-payment-card-page {
    width: 100%;
}
</style>
