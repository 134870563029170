<script setup lang="ts">
import moment, { Moment } from 'moment';
import { computed, ComputedRef, onMounted, PropType, reactive, Ref, ref, watch, UnwrapNestedRefs, nextTick } from 'vue';
import FormField from '@/Assets/Libraries/Form/FormField';
import { useDefine } from '@/Composables/Define';
import DateRange from '@/Interfaces/date.range.interface';
import Translations from '@/Services/translations.service';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import MomentBuilder from '@/Assets/Libraries/Date/Builders/MomentBuilder';
import CssClass from '@/Enums/CssClassEnum';
import OneBaseService from '@/Services/OneBaseService';
import PopupType from '@/Enums/PopupTypeEnum';
import Popup from '@/Services/popup.service';

const props = defineProps({
    activeDates: { type: Array as PropType<Array<Date>>, default: () => [] },
    dataStoreDisabled: { type: Boolean, default: false },
    dateVisualEmptyMarker: { type: String, default: '-----' },
    disabled: { type: Boolean, default: false },
    disabledDays: { type: Array as PropType<Array<Date>>, default: () => [] },
    endDate: { type: Date, default: () => moment().toDate() },
    formField: { type: Object as PropType<FormField<DateRange>>, default: () => new FormField('') },
    format: { type: String, default: '' },
    isRange: { type: Boolean, default: false },
    label: { type: String, default: '' },
    maxDate: { type: Date, default: null },
    minDate: { type: Date, default: () => moment().toDate() },
    mobileButtonText: { type: String, default: '' },
    numberOfCalendars: { type: Number, default: 2 },
    forcedRange: { type: Boolean, default: false },
    numberOfDays: { type: Number, default: 31 },
    placeholder: { type: String, default: '' },
    required: { type: Boolean, default: false },
    returnRaw: { type: Boolean, default: true },
    showBothDates: { type: Boolean, default: false },
    startDate: { type: Date, default: () => moment().toDate() },
    withIcon: { type: Boolean, default: true },
    openCallback: { type: Function, default: () => {} },
});
const emit = defineEmits(['change', 'input', 'click']);
const translations: Translations = Translations.getInstance();
const { isSet } = useDefine();
const container: string = 'calendar-' + String(Math.random()).replace('.', '');
const DateVisualSplitter: string = ' - ';
const rangedDefaultCalendars: number = 2;
const tempSelected: UnwrapNestedRefs<DateRange> = reactive({
    endDate: '',
    startDate: '',
});
const saveOnClose: Ref<boolean> = ref(true);
const fieldId: ComputedRef<string> = computed(() => props.formField.name + '-calendar');
const isVisibleMobileButton: Ref<boolean> = computed(() => tempSelected.startDate !== '');
const mobileButtonSelectDateText: Ref<string> = computed(() => {
    return props.mobileButtonText === ''
        ? translations.localized('confirm_popup_user_input_select_date')
        : props.mobileButtonText;
});
const startDate: Ref<Date> = ref(props.startDate);
const endDate: Ref<Date> = ref(props.endDate);
const datePreview: Ref<string | undefined> = ref('');
const mobileDateDuration: Ref<string> = ref('');
const calendarElement: Ref<HTMLElement | null> = ref(null);
const iconElement: Ref<HTMLElement | null> = ref(null);
const isOpened: Ref<boolean> = ref(false);
let datePicker: any;

watch(
    () => props.isRange,
    () => {
        updatePreviewDate();
    },
);

watch(
    () => props.formField.value,
    (value: DateRange, previousValue: DateRange | string) => {
        if (isSet(value) && !datesAreEqual(value, previousValue)) {
            select(value, false);
            emit('change', value, previousValue);
        }
    },
);

watch([() => tempSelected.startDate, () => tempSelected.endDate], () => {
    applyMobileDateDuration();
});

onMounted(() => {
    updatePreviewDate();
    props.formField.onChange.subscribe(() => {
        checkAndApplyDefaultDateValues();
        updatePreviewDate();
    });
});

function open(): void {
    if (!props.disabled) {
        initDatepicker();
        datePicker.refresh(configForRefresh());
        nextTick(() => datePicker.show());
        props.openCallback();
    } else {
        emit('click');
    }
}

function onIconFocus(): void {
    if (props.withIcon) {
        $(iconElement).addClass(CssClass.Active);
        $(iconElement).siblings('.frame').addClass(CssClass.Active);
        window.addEventListener('keydown', onIconKeydown);
    }
}

function onIconBlur(): void {
    if (props.withIcon) {
        $(iconElement).removeClass(CssClass.Active);
        $(iconElement).siblings('.frame').removeClass(CssClass.Active);
        window.removeEventListener('keydown', onIconKeydown);
    }
}

function onIconKeydown(e: any): void {
    if (!isOpened.value && (e.keyCode === 32 || e.keyCode === 13) && e.target === iconElement.value) {
        e.preventDefault();
        open();
    }
}

function close(save: boolean = false): void {
    if (isOpened.value) {
        saveOnClose.value = save;
    }
    datePicker.hide();
    isOpened.value = false;
}

function datesAreEqual(currentDate: any, previousDate: any): boolean {
    let result = true;
    if (currentDate instanceof Date && previousDate instanceof Date) {
        if (currentDate.toString() !== previousDate.toString()) {
            result = false;
        }
    } else {
        if (currentDate.startDate !== previousDate.startDate) {
            result = false;
        }
        if (currentDate.endDate !== previousDate.endDate) {
            result = false;
        }
    }

    return result;
}

function select(date: DateRange, withTouch: boolean = true): void {
    props.formField.setValue(transformedDates(date));
    if (withTouch) {
        props.formField.markAsTouched();
    }
    updatePreviewDate();
}

function configForRefresh(): object {
    const result: any = {};
    const dates: DateRange = transformedDates(props.formField.value);
    if (dates.startDate === '' && props.formField.value.startDate === undefined) {
        // @ts-ignore
        dates.startDate = props.returnRaw
            ? props.formField.value
            : // @ts-ignore
              props.formField.value === ''
              ? moment(new Date()).format()
              : // @ts-ignore
                moment(props.formField.value).format();
    }
    const selectedDate = moment(
        props.formField.isEmpty() ? new Date() : dates.startDate === '' ? new Date() : dates.startDate,
    ).toDate();
    result.startDate = startDate.value;
    result.active = !props.formField.isEmpty() ? [selectedDate] : props.activeDates;
    result.range = props.isRange;
    result.calendarCount = props.isRange && !props.forcedRange ? rangedDefaultCalendars : props.numberOfCalendars;

    return result;
}

function initDatepicker(): void {
    if (!datePicker) {
        let fixMobileSwipeBackwards: boolean = false;
        const oneDay: number = 24 * 60 * 60 * 1000;
        const firstDate: Date = new Date(props.minDate);
        const secondDate: Date = new Date();
        const diffDays: number = Math.round((firstDate.getTime() - secondDate.getTime()) / oneDay);
        if (diffDays < 0 && diffDays <= -1) {
            fixMobileSwipeBackwards = true;
        }
        datePicker = new Datepicker({
            item: $(calendarElement).first(),
            fixMobileSwipeBackwards: fixMobileSwipeBackwards,
            minDate: props.minDate,
            maxDate: props.maxDate || moment(props.minDate).add(props.numberOfDays, 'days').toDate(),
            range: props.isRange,
            calendarCount: props.numberOfCalendars,
            startDate: startDate.value,
            endDate: endDate.value,
            active: [],
            currentDate: moment().toDate(),
            texts: datePickerLanguageTexts(),
            container,
            onSelect: (date: string[]) => {
                if (isOpened.value) {
                    tempSelected.startDate = date[0];
                    if (date.length > 0) {
                        tempSelected.endDate = date[1];
                    }
                }
            },
            onShow: () => {
                isOpened.value = true;
                saveOnClose.value = true;
                const $dayActive = $(datePicker.container).find('.day.active');
                if ($dayActive.length > 0) {
                    $dayActive[0].focus();
                }
                Popup.getInstance().showPopup(PopupType.CustomPopup);
                OneBaseService.getInstance()!.changeBodyVerticalScrollState();
            },
            onHide: () => {
                if (isOpened.value) {
                    setTimeout(() => {
                        $('body')
                            .find('> div.' + container + ' > .calendar-block')
                            .remove();
                        isOpened.value = false;
                        Popup.getInstance().showPopup(PopupType.None);
                    });
                    if (tempSelected.startDate && saveOnClose.value) {
                        select(tempSelected);
                        props.formField.markAsDirty();
                    }
                    if (props.withIcon) {
                        iconElement.value?.focus();
                    }
                    props.formField.markAsTouched();
                }
            },
            disabledDays: props.disabledDays,
            configForRefresh: configForRefresh(),
        });
    }
    resetMobileDateDuration();
}

function updatePreviewDate(): void {
    const dates = transformedDates(props.formField.value);
    let dateText = formattedDate(dates.startDate);
    if (dates.endDate && props.isRange) {
        dateText += DateVisualSplitter + formattedDate(dates.endDate);
    }
    datePreview.value = dateText;
}

function applyMobileDateDuration(): void {
    const dateStart: Moment = new MomentBuilder().withInitialDate(tempSelected.startDate).withPageLocale().build();
    const dateEnd: Moment = new MomentBuilder().withInitialDate(tempSelected.endDate).withPageLocale().build();
    const differenceText = dateEnd.endOf('day').from(dateStart.startOf('day'), true);
    const differenceTextEn = differenceText.substr(2, 3);
    mobileDateDuration.value =
        dateEnd.diff(dateStart, 'days') === 0
            ? translations.language === 'en'
                ? '1 ' + differenceTextEn
                : '1 ' + differenceText
            : differenceText;
}

function resetMobileDateDuration() {
    mobileDateDuration.value = '';
}

function transformedDates(date: DateRange): DateRange {
    let dateStart = isValidDate(date.startDate)
        ? props.returnRaw
            ? date.startDate
            : moment(date.startDate).format()
        : '';
    const dateEnd = isValidDate(date.endDate) ? (props.returnRaw ? date.endDate : moment(date.endDate).format()) : '';
    if (date instanceof Date && isValidDate(date.toString())) {
        if (props.returnRaw) {
            dateStart = date.toString();
        } else {
            dateStart = moment(date.toString()).format();
        }
    }

    return {
        startDate: dateStart.toString(),
        endDate: dateEnd.toString(),
    };
}

function formattedDate(date: string): string {
    let result: string = props.dateVisualEmptyMarker;
    if (date !== '') {
        if (props.format !== '') {
            result = OneDate.custom(new Date(date), props.format);
        } else {
            result = OneDate.mediumLong(new Date(date));
        }
    }

    return result;
}

function isValidDate(string: string): boolean {
    return Date.parse(string) > 0;
}

function datePickerLanguageTexts(): object {
    return {
        months: [
            translations.localized('btar_calendar_january'),
            translations.localized('btar_calendar_february'),
            translations.localized('btar_calendar_march'),
            translations.localized('btar_calendar_april'),
            translations.localized('btar_calendar_may'),
            translations.localized('btar_calendar_june'),
            translations.localized('btar_calendar_july'),
            translations.localized('btar_calendar_august'),
            translations.localized('btar_calendar_september'),
            translations.localized('btar_calendar_october'),
            translations.localized('btar_calendar_november'),
            translations.localized('btar_calendar_december'),
        ],
        days: [
            translations.localized('btar_calendar_monday_abbreviation'),
            translations.localized('btar_calendar_tuesday_abbreviation'),
            translations.localized('btar_calendar_wednesday_abbreviation'),
            translations.localized('btar_calendar_thursday_abbreviation'),
            translations.localized('btar_calendar_friday_abbreviation'),
            translations.localized('btar_calendar_saturday_abbreviation'),
            translations.localized('btar_calendar_sunday_abbreviation'),
        ],
    };
}

function checkAndApplyDefaultDateValues(): void {
    const formStartDate = moment(props.formField.value.startDate);
    const formEndDate = moment(props.formField.value.endDate);
    if (formStartDate.isBefore(moment(props.minDate), 'day')) {
        // @ts-ignore
        props.formField.value.startDate = moment(props.minDate);
    }
    if (formEndDate.isBefore(moment(props.minDate), 'day')) {
        // @ts-ignore
        props.formField.value.endDate = moment(props.minDate).add(1, 'day');
    }
}
</script>
<template>
    <div
        :id="formField.name"
        class="input calendar input-date-with-calendar"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <label v-if="label" :for="fieldId" class="label hide-on-mobile">
            {{ label }}<span v-if="required">*</span>
        </label>
        <div class="wrapper" @click="open()">
            <input
                :id="fieldId"
                ref="calendar"
                v-model="formField.value"
                class="frame"
                readonly
                tabindex="-1"
                type="text"
                :placeholder="placeholder"
                @click.stop="open()"
            />
            <span class="localized">
                {{ datePreview }}
                <span class="custom"><slot name="custom"></slot></span>
            </span>
            <span class="additional"><slot></slot></span>
            <div
                v-if="withIcon"
                ref="icon"
                class="icon"
                tabindex="0"
                :class="{ 'cursor-pointer': !disabled }"
                @focus="onIconFocus"
                @blur="onIconBlur"
            >
                <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        opacity="0.48"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5 0C4.44772 0 4 0.447693 4 1V2H3C1.34314 2 0 3.34314 0 5V21C0 22.6569 1.34314 24 3 24H19C20.6569 24 22 22.6569 22 21V5C22 3.34314 20.6569 2 19 2H18V1C18 0.447693 17.5523 0 17 0C16.4477 0 16 0.447693 16 1V2H6V1C6 0.447693 5.55228 0 5 0ZM20 8V5C20 4.44769 19.5523 4 19 4H18V5C18 5.55231 17.5523 6 17 6C16.4477 6 16 5.55231 16 5V4H6V5C6 5.55231 5.55228 6 5 6C4.44772 6 4 5.55231 4 5V4H3C2.44772 4 2 4.44769 2 5V8H20ZM2 10V21C2 21.5523 2.44772 22 3 22H19C19.5523 22 20 21.5523 20 21V10H2Z"
                        fill="#9297A0"
                    ></path>
                </svg>
            </div>
        </div>
        <div v-if="formField.classes().error" class="error-container">
            <slot name="error"></slot>
        </div>
        <div class="popups" :class="{ hidden: !isOpened }">
            <aside class="single-popup simple calendar">
                <aside class="overlay"></aside>
                <div class="calendar-container desktop hidden" :class="container">
                    <aside class="info-block">
                        <button :id="formField.name + '-popup-button-close'" class="close" @click="close()">
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 1L13 13M13 1L1 13"
                                    stroke="#9297A0"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                            </svg>
                        </button>
                        <div class="date-range-info">
                            <div class="date"></div>
                            <div v-if="isRange" class="duration">{{ mobileDateDuration }}</div>
                        </div>
                        <button
                            v-if="isVisibleMobileButton"
                            :id="formField.name + '-mobileButton'"
                            class="button red travel-landing-travel-dates"
                            @click="close(true)"
                        >
                            {{ mobileButtonSelectDateText }}
                        </button>
                    </aside>
                </div>
            </aside>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-date-with-calendar {
    width: 100%;

    .wrapper {
        cursor: pointer;

        .additional {
            position: absolute;
        }

        > .icon.active {
            svg {
                path {
                    fill: var(--brand-teal);
                    opacity: 1;
                }
            }
        }

        > .frame.active {
            box-shadow: var(--box-shadow-basic);
        }

        .frame {
            cursor: pointer;
            position: absolute;

            &:hover {
                @include input-hover;
            }

            &:active,
            &:focus {
                @include input-focus;
            }

            &::selection {
                opacity: 0;
            }
        }

        .localized {
            align-self: center;
            position: relative;
            margin: 14px;
            pointer-events: none;
        }
    }

    .error-container {
        margin-top: var(--size-femto);

        &:empty {
            display: none;
        }
    }
}

.travel-insurance {
    .travel-dates {
        .input-date-with-calendar {
            .hide-on-mobile {
                display: none;

                @include respond-above('sm') {
                    display: block;
                }
            }

            .wrapper {
                .localized {
                    padding: 0 50px 0 var(--size-small);
                }
            }
        }
    }
}

.input-date-with-calendar.error {
    .wrapper {
        .frame {
            border-color: var(--brand-red);
            outline-color: var(--brand-red);
        }
    }
}

.input-date-with-calendar.disabled {
    .wrapper {
        cursor: default;

        &::before {
            background-color: var(--component-color-background-disabled);
        }

        input {
            visibility: hidden;
        }

        .localized {
            color: var(--black-400);
        }

        .icon {
            pointer-events: none;
        }
    }
}
</style>
