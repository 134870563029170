<script setup lang="ts">
import { useStepper } from '@/Composables/Stepper';
import AppBackButton from '@/Components/Routing/BackButton/BackButton.vue';

const stepper = useStepper();
</script>
<template>
    <div class="layout">
        <div class="stepper-content">
            <slot name="stepper"></slot>
        </div>
        <div class="layout-container">
            <aside>
                <app-back-button id="to-previous-step" class="to-previous-step" />
            </aside>
            <div class="route-content">
                <router-view v-slot="{ Component }">
                    <transition :name="'fade'" mode="out-in">
                        <component :is="Component" />
                    </transition>
                </router-view>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .stepper-content {
        top: 0;
        width: 100%;
        z-index: 4;
        position: sticky;

        // Landing page
        &:empty + .layout-container {
            margin: 0;
            display: flex;
        }
    }

    .layout-container {
        display: flex;
        flex-direction: column;
        gap: var(--size-nano) 5px;
        width: 100%;
        align-items: start;
        margin: var(--size-small) 0 var(--size-big);
        max-width: 960px;

        @include respond-above('lg') {
            display: grid;
            grid-template-columns: 1fr;
            margin: 90px 0 80px;
        }

        .to-previous-step {
            font-weight: 600;
            grid-column: 1;
        }

        .route-content {
            align-self: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            flex-shrink: 1;
            width: 100%;
        }

        &.layout-full-width {
            max-width: 100%;
        }
    }
}
</style>
<style lang="scss">
body > div > main:not(.step-1) .module.product-app {
    flex: 1 0;

    &.spacing {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.spacing-top {
        padding-top: 0;
    }

    &.spacing-bottom {
        padding-bottom: 0;
    }

    &.spacing-double {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.spacing-double-top {
        padding-top: 0;
    }

    &.spacing-double-bottom {
        padding-bottom: 0;
    }
}
</style>
