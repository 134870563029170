import { reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import { validate, ValidationResult } from 'vee-validate';
import { Subject } from 'rxjs';
import Error from '@/Services/error.service';
import Value from '@/Assets/Libraries/Form/Value';
import ErrorType from '@/Enums/ErrorTypeEnum';
import type FormFieldStates from '@/Assets/Libraries/Form/FormFieldStates';
import FormConfig from '@/Assets/Libraries/Form/FormConfig';

export type SanitizerCallback<T = any> = (value: T) => T;
export type ValidatorCallback = (value: any) => boolean;

interface Validator {
    name: string;
    isValid: ValidatorCallback;
}

interface FieldInnerStates {
    valid: boolean;
    touched: boolean;
    dirty: boolean;
    hidden: boolean;
    attempted: boolean;
    restored: boolean;
    notRestored: boolean;
}

export default class FormField<ValueType = any> {
    /** @description Triggers every time field value was changed. */
    public onChange: Subject<ValueType> = new Subject();
    /** @description Triggers every time field state was changed. */
    public onStateChange: Subject<void> = new Subject();
    public onSubmitAttempt: Subject<void> = new Subject();
    /**
     * @description Triggers every time `patch()` method was called.
     */
    public onPatch: Subject<ValueType> = new Subject();
    public onValueSet: Subject<ValueType> = new Subject();
    public onTouch: Subject<void> = new Subject();
    public onClear: Subject<void> = new Subject();
    public onRestore: Subject<ValueType> = new Subject();
    public onDestroy: Subject<void> = new Subject();
    private localConfig: Required<FormConfig> = {
        errorOnlyAfterOnSubmit: false,
        useValidationV2: false,
    };
    private localName: Ref<string> = ref('');
    private localValue: Ref<ValueType> = ref('') as Ref<ValueType>;
    private localStates: UnwrapNestedRefs<FieldInnerStates> = reactive({
        valid: false,
        touched: false,
        dirty: false,
        attempted: false,
        hidden: false,
        restored: false,
        notRestored: false,
    });
    private sanitizer?: SanitizerCallback<ValueType>;
    private localValidators: Ref<string> = ref('');
    private localCustomValidators: Ref<Validator[]> = ref([]);
    private localErrors: Ref<string[]> = ref([]);

    public constructor(
        name: string,
        value?: ValueType,
        validators?: string | Record<string, any>,
        sanitizer?: SanitizerCallback<ValueType>,
    ) {
        this.localName.value = name;
        if (sanitizer !== undefined) {
            this.sanitizer = sanitizer;
        }
        if (validators !== undefined) {
            this.addValidators(validators);
        }
        if (value !== undefined) {
            this.setValue(value);
        } else {
            this.validate().then();
        }
    }

    public mount(fieldConfig: Required<FormConfig>): void {
        this.localConfig = fieldConfig;
    }

    public destroy(): void {
        this.onDestroy.next();
    }

    public config(): FormConfig {
        return this.localConfig;
    }

    public classes(): FormFieldStates {
        return {
            error: this.errorState(),
            success: this.successState(),
            valid: this.isValid,
            invalid: this.isInvalid,
            touched: this.isTouched,
            untouched: this.isUntouched,
            dirty: this.isDirty,
            pristine: this.isPristine,
            hidden: this.isHidden,
            visible: this.isVisible,
            attempted: this.isAttempted,
        };
    }

    public get name(): string {
        return this.localName.value;
    }

    /**
     * @deprecated Should avoid using name setter. Name should only be set in constructor.
     */
    public set name(name: string) {
        this.localName.value = name;
    }

    public get value(): ValueType {
        return this.localValue.value;
    }

    /**
     * @deprecated This setter is for `v-model` use only. Use `setValue()` instead.
     */
    public set value(value: ValueType) {
        this.setValue(value);
    }

    public get isValid(): boolean {
        return this.localStates.valid;
    }

    public get isInvalid(): boolean {
        return !this.isValid;
    }

    public get isTouched(): boolean {
        return this.localStates.touched;
    }

    public get isUntouched(): boolean {
        return !this.isTouched;
    }

    public get isDirty(): boolean {
        return this.localStates.dirty;
    }

    public get isPristine(): boolean {
        return !this.isDirty;
    }

    public get isHidden(): boolean {
        return this.localStates.hidden;
    }

    public get isVisible(): boolean {
        return !this.isHidden;
    }

    public get isAttempted(): boolean {
        return this.localStates.attempted;
    }

    public get isRestored(): boolean {
        return this.localStates.restored;
    }

    public get isNotRestored(): boolean {
        return this.localStates.notRestored;
    }

    public get restoreProcessIsFinished(): boolean {
        return this.localStates.restored || this.localStates.notRestored;
    }

    /**
     * @deprecated Use `markAsTouched()` instead.
     */
    public touch(): FormField<ValueType> {
        this.markAsTouched();
        return this;
    }

    public setValue(value: ValueType): void;

    /**
     * @deprecated Use `setValue()` without `silent` parameter.
     */
    public setValue(value: ValueType, silent: boolean): void;

    public setValue(value: ValueType, silent: boolean = false): void {
        const valueBeforeEncoded: string = JSON.stringify(this.value);
        this.localValue.value = value; // hack to make change detection work for value getter in html input elements
        this.localValue.value = this.sanitizer ? this.sanitizer(value) : value;
        if (!silent) {
            this.validate().then((): void => {
                const valueAfterEncoded: string = JSON.stringify(this.value);
                if (valueAfterEncoded !== valueBeforeEncoded) {
                    this.onChange.next(this.value);
                }
                this.onValueSet.next(this.value);
            });
        }
    }

    /**
     * @description This method intended to programmatically simulate the same behavior as a user after editing a field, for example, can be used to restore values from storage.
     */
    public patch(value: ValueType): void {
        this.setValue(value);
        this.markAsTouched();
        this.markAsDirty();
        this.onPatch.next(this.value);
    }

    public markAsTouched(): void {
        this.changeState('touched', true);
        this.onTouch.next();
    }

    public markAsUntouched(): void {
        this.changeState('touched', false);
    }

    public markAsDirty(): void {
        this.changeState('dirty', true);
    }

    public markAsPristine(): void {
        this.changeState('dirty', false);
    }

    public markAsHidden(): void {
        this.changeState('hidden', true);
    }

    public markAsVisible(): void {
        this.changeState('hidden', false);
    }

    public markAsAttempted(): void {
        this.changeState('attempted', true);
        this.onSubmitAttempt.next();
    }

    public markAsNotAttempted(): void {
        this.changeState('attempted', false);
    }

    public markAsNotRestored(): void {
        this.changeState('notRestored', true);
        this.changeState('restored', false);
    }

    public markAsRestored(): void {
        this.changeState('restored', true);
        this.changeState('notRestored', false);
        this.onRestore.next(this.value);
    }

    public markAsFresh(): void {
        this.changeState('restored', false);
        this.onRestore.next(this.value);
    }

    public isEmpty(): boolean {
        return new Value(this.value).isEmpty();
    }

    public isNotEmpty(): boolean {
        return !this.isEmpty();
    }

    public addValidators(validators: string | Record<string, any>): FormField<ValueType> {
        this.sortAndAppendValidators(validators);
        this.validate().then();

        return this;
    }

    public addSanitizer(sanitizer: SanitizerCallback<ValueType>): FormField<ValueType> {
        this.sanitizer = sanitizer;
        this.sanitize();

        return this;
    }

    /**
     * @description If possible use `setValue()` which will also sanitize it.
     */
    public sanitize(): FormField<ValueType> {
        this.setValue(this.value);

        return this;
    }

    public async validate(): Promise<void> {
        this.localErrors.value = [];
        try {
            const validationResult: ValidationResult = await validate(this.value, this.localValidators.value);
            const isValidAllCustomValidators: boolean = await this.isValidAllCustomValidators();
            Object.keys(validationResult.errors)
                .filter((name: string): boolean => !this.localErrors.value.includes(name))
                .forEach((name: string): void => {
                    this.localErrors.value.push(name);
                });
            if (validationResult.valid && isValidAllCustomValidators) {
                this.markAsValid();
            } else {
                this.markAsInvalid();
            }
        } catch (error: any) {
            Error.log(ErrorType.Error, 'FormField::validate()', error);
            this.markAsInvalid();
        }
    }

    public async clear(): Promise<void> {
        this.setValue(this.clearValue(this.value) as ValueType);
        this.markAsUntouched();
        this.markAsPristine();
        this.onClear.next();
        return this.validate();
    }

    public errors(): string[] {
        return this.localErrors.value;
    }

    public hasError(validatorName: string): boolean {
        return this.localErrors.value.includes(validatorName);
    }

    public hasAnyOfErrors(validatorNames: string[]): boolean {
        return validatorNames.some((name: string): boolean => this.localErrors.value.includes(name));
    }

    public clearCustomValidators(): void {
        this.localCustomValidators.value = [];
    }

    public clearValidators(): void {
        this.localValidators.value = '';
    }

    public clearSanitizer(): void {
        this.sanitizer = undefined;
    }

    private markAsValid(): void {
        this.changeState('valid', true);
    }

    private markAsInvalid(): void {
        this.changeState('valid', false);
    }

    private errorState(): boolean {
        let result: boolean;
        if (this.localConfig.useValidationV2) {
            result = this.localConfig.errorOnlyAfterOnSubmit
                ? this.isInvalid && this.isAttempted
                : this.isInvalid && ((this.isTouched && this.isDirty) || this.isAttempted);
        } else {
            result = this.localConfig.errorOnlyAfterOnSubmit
                ? this.isInvalid && this.isAttempted
                : this.isInvalid && this.isTouched;
        }
        return result;
    }

    private successState(): boolean {
        return this.localConfig.useValidationV2
            ? this.isValid && this.isNotEmpty() && ((this.isTouched && this.isDirty) || this.isAttempted)
            : false;
    }

    private changeState(stateName: keyof FieldInnerStates, value: boolean): void {
        const stateBefore: boolean = this.localStates[stateName];
        this.localStates[stateName] = value;
        if (stateBefore !== this.localStates[stateName]) {
            this.onStateChange.next();
        }
    }

    private clearValue(value: any): object | string {
        let result: any = '';

        if (typeof value === 'object') {
            result = {};
            Object.keys(value).forEach(
                (propertyName: string) => (result[propertyName] = this.clearValue(value[propertyName])),
            );
        }

        return result;
    }

    private async isValidAllCustomValidators(): Promise<boolean> {
        return Promise.all(
            this.localCustomValidators.value.map((validator: Validator): boolean => {
                const isValid: boolean = validator.isValid(this.value);
                if (!isValid && !this.localErrors.value.includes(validator.name)) {
                    this.localErrors.value.push(validator.name);
                }
                return isValid;
            }),
        ).then((results: boolean[]): boolean => !results.some((result: boolean): boolean => !result));
    }

    private sortAndAppendValidators(validators: string | Record<string, string | ValidatorCallback>): void {
        if (typeof validators === 'string') {
            this.appendLocalValidator(validators);
        } else {
            Object.keys(validators).forEach((validatorName: string): void => {
                const validator: string | ValidatorCallback = validators[validatorName];
                if (typeof validators[validatorName] === 'string') {
                    this.appendLocalValidator(validator as string);
                }
                if ({}.toString.call(validator) === '[object Function]') {
                    this.appendLocalCustomValidator({
                        name: validatorName,
                        isValid: validator as ValidatorCallback,
                    });
                }
            });
        }
    }

    private appendLocalValidator(validator: string): void {
        this.localValidators.value = this.localValidators.value
            .split('|')
            .concat(validator.split('|'))
            .filter((currentValidator: string): boolean => currentValidator !== '')
            .join('|');
    }

    private appendLocalCustomValidator(validator: Validator): void {
        this.localCustomValidators.value = this.localCustomValidators.value.filter(
            (localValidator: Validator): boolean => localValidator.name !== validator.name,
        );
        this.localCustomValidators.value.push(validator);
    }
}
