<script setup lang="ts">
import { onMounted, PropType } from 'vue';
import AppPopup from '@/Components/Popups/Popup/Popup.vue';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import AppGroupedRadioListSingle from '@/Components/Options/GroupedRadioListSingle/GroupedRadioListSingle.vue';
import FormField from '@/Assets/Libraries/Form/FormField';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

const props = defineProps({
    formField: {
        type: Object as PropType<FormField<string>>,
        default: () => new FormField(''),
    },
    options: {
        type: Array as PropType<InputOption[]>,
        required: true,
    },
    showLabel: {
        type: Boolean,
        default: false,
    },
    labelText: {
        type: String,
        default: '',
    },
});

const emit = defineEmits(['update', 'close']);

const field: FormField<{ selected: string }> = new FormField('selected', { selected: props.formField.value });

function select(value: any): void {
    props.formField.setValue(value.selected);
    emit('update', value.selected);
    emit('close');
}
</script>

<template>
    <app-popup class="simple list" @close="$emit('close')">
        <div v-if="showLabel" class="title">{{ labelText }}</div>
        <div class="content-popup grid-utility grid-columns-1">
            <app-grouped-radio-list-single
                :form-field="field as unknown as FormField<DynamicDictionary>"
                :type="'radio'"
                :option-class="'filled'"
                :options="options"
                @change="select"
            />
        </div>
    </app-popup>
</template>

<style lang="scss" scoped>
.list.simple {
    :deep(.wrapper) {
        padding: var(--size-big) 0 0 var(--size-big);
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        border-radius: var(--size-normal);
        overflow: hidden;
        background: var(--background-light);

        .popup-close {
            top: var(--size-big);
            right: var(--size-big);
            z-index: 1;
        }

        .title {
            align-items: center;
            width: 100%;
            padding: 0 0 var(--size-big) 0;
            font-size: var(--font-size-big);
            font-weight: 700;
            position: relative;
            line-height: var(--line-height-basic);
            margin-bottom: 0;

            &::after {
                left: calc(var(--size-big) * -1);
                content: '';
                position: absolute;
                bottom: 0;
                width: calc(100% + 2 * var(--size-big));
                height: 1px;
                background: var(--black-100);
            }
        }

        .content-popup {
            padding: var(--size-big) 0;
            padding-right: var(--size-big);
            overflow-y: auto;
            scrollbar-width: thin;
        }
    }
}
</style>
