<script setup lang="ts">
import Sanitizer from '@/Services/sanitizer.service';
import { computed, ComputedRef, onMounted, ref, Ref, watch } from 'vue';
import { default as AppCountryLibrary } from '@/Assets/Libraries/App/AppCountry';
import Validation from '@/Services/validation.service';
import FormField from '@/Assets/Libraries/Form/FormField';
import Form from '@/Assets/Libraries/Form/Form';
import SettingsService from '@/Services/settings.service';
import PhoneField from '@/Interfaces/phone.field.interface';
import Translations from '@/Services/translations.service';
import AppInputCountry from '@/Components/Inputs/InputCountry/InputCountry.vue';
import { CountryComponentParams } from '@/Components/Inputs/InputCountry/CountryComponentParams';
import { useDefine } from '@/Composables/Define';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

const props = defineProps({
    placeholder: { type: String, default: () => '' },
    formField: { type: FormField<PhoneField>, default: () => new FormField('') },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    countryShowCustomPopup: { type: Boolean, default: true },
    mobileModeEnabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    allowEmpty: { type: Boolean, default: true },
});

const emit = defineEmits(['change', 'close']);

const form: Form<{ country: CountryComponentParams; phone: string; fixed: string; mobile: string }> = new Form();
const settings: SettingsService = SettingsService.getInstance();
const translations: Translations = Translations.getInstance();
const appCountry: AppCountryLibrary = new AppCountryLibrary();
const fieldId: ComputedRef<string> = computed(() => props.formField.name + '-phone');
const popupLabel: ComputedRef<string> = computed(() => translations.localized('btar_phone_country'));
const userMadeInput: Ref<boolean> = ref(false);

watch(
    () => props.formField.name,
    () => {
        init();
    },
);

watch(
    () => props.formField.value,
    (newValue: any, oldValue: any) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
            applyValuesToChildFields();
        }
    },
);

onMounted((): void => {
    setupForm();
    init();
});

function onCountryClose(): void {
    emit('close');
}

function onCountryChange(): void {
    props.formField.setValue({
        code: form.field('country').value.iso,
        country: form.field('country').value.phoneCode,
        phone: props.formField.value.phone,
        fixed: mobileAndFixedPhone().fixed,
        mobile: mobileAndFixedPhone().mobile,
    });
    emit('change');
}

function onPhoneChange(): void {
    if (useDefine().isSet(props.formField.value.code) && props.formField.value.code === '') {
        props.formField.value.code = form.field('country').value.iso;
        props.formField.value.country = form.field('country').value.phoneCode;
    }
    userMadeInput.value = true;
    props.formField.setValue({
        code: props.formField.value.code,
        country: props.formField.value.country,
        phone: form.field('phone').value,
        fixed: mobileAndFixedPhone().fixed,
        mobile: mobileAndFixedPhone().mobile,
    });
    emit('change');
}

function onBlur(): void {
    if (userMadeInput.value) {
        props.formField.markAsDirty();
    }
    props.formField.markAsTouched();
}

function init(): void {
    props.formField.addValidators({
        childrenAreValid: () => form.isValid(),
    });
    applyValuesToChildFields();
    props.formField.onChange.subscribe(() => {
        applyValuesToChildFields();
    });
}

function setupForm(): void {
    form.addField(new FormField('country'));
    form.addField(
        new FormField('phone')
            .addValidators({ isValidPhoneNumber: isValidPhoneNumber })
            .addSanitizer(Sanitizer.cleanPhone),
    );
    form.addField(new FormField('fixed'));
    form.addField(new FormField('mobile'));
    form.setReady();
}

function applyValuesToChildFields(): void {
    const value: PhoneField = props.formField.value;
    if (value.country !== '') {
        form.field('country').setValue({
            iso: value.iso || '',
            phoneCode: value.country || '',
            ic: value.code || '',
        });
    }
    form.field('phone').setValue(value.phone || '');
    form.field('fixed').setValue(mobileAndFixedPhone().fixed || '');
    form.field('mobile').setValue(mobileAndFixedPhone().mobile || ' ');
}

function mobileAndFixedPhone(): { mobile: string; fixed: string } {
    const country: string = appCountry.iso();
    const phone: string = props.formField.value.phone;
    let mobile: string = phone;
    let fixed: string = '';
    if (
        settings.value('USER_PHONE_CODE').toString() === props.formField.value.country &&
        Validation.isValidFixedPhone(phone, country) &&
        !Validation.isValidMobilePhone(phone, country)
    ) {
        fixed = phone;
        mobile = '';
    }

    return { mobile: mobile, fixed: fixed };
}

function isValidPhoneNumber(value: string): boolean {
    let isValid: boolean = true;
    const country: CountryComponentParams = form.field('country').value;
    if (value !== '' || !props.allowEmpty) {
        isValid = props.mobileModeEnabled
            ? Validation.isValidMobilePhone(value, country.iso)
            : Validation.isValidPhone(value, country.iso);
    }

    return isValid;
}
</script>

<template>
    <div
        v-if="form.isReady()"
        :id="formField.name"
        class="input input-phone-with-country with-flag"
        :class="formField.classes()"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div v-if="label" class="label">
            <label :for="fieldId"> {{ label }}&nbsp;<span v-if="props.required" class="asterisk">&#42;</span> </label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper">
            <app-input-country
                class="phone-code-selector number"
                :popup-label="popupLabel"
                :form-field="form.field('country')"
                :disabled="disabled"
                :data-store-disabled="true"
                :show-value="'phone-code'"
                :show-custom-popup="countryShowCustomPopup"
                :mobile-mode-enabled="mobileModeEnabled"
                @close="onCountryClose"
                @change="onCountryChange"
            ></app-input-country>
            <input
                :id="fieldId"
                v-model="form.field('phone').value"
                type="text"
                :disabled="disabled"
                :autocomplete="'tel-national'"
                :placeholder="placeholder"
                @input="onPhoneChange"
                @change="onPhoneChange"
                @blur="onBlur"
            />
            <div v-if="formField.classes().error" class="error-container">
                <slot name="error"></slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-phone-with-country {
    scroll-margin-top: 4em;

    .asterisk {
        font-size: var(--font-size-medium);
        color: var(--brand-red);
    }

    :deep(.input) {
        width: auto !important;
        margin-top: 0 !important;
        left: 0;
        top: 0;

        &::before {
            top: 0;
            bottom: 0;
        }
    }

    input::placeholder {
        font-size: var(--font-size-nano);

        @include respond-above('sm') {
            font-size: var(--font-size-tiny);
        }
    }

    > .wrapper {
        > .phone-code-selector {
            z-index: inherit;

            :deep(.button) {
                border: none;
                background: none;
                padding: 0 0 0 var(--size-small);
                margin: 0;

                .arrow-icon {
                    display: none;
                }

                .flag {
                    img {
                        vertical-align: initial;
                    }
                }
            }
        }

        .error-container {
            position: absolute;
            top: 56px;

            &:empty {
                display: none;
            }
        }
    }
}
</style>
