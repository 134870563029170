<script setup lang="ts">
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import Attachment from '@/Components/TwoWayCommunication/Message/Interfaces/Attachment';
import ButtonPlacement from '@/Components/TwoWayCommunication/Message/Interfaces/ButtonPlacementInterface';
import ButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import { computed, reactive, Ref, UnwrapNestedRefs } from 'vue';
import Icon from '@/Components/TwoWayCommunication/MessageCard/Icon.enum';
import { useTranslate } from '@/Composables/Translate';
import { Placement } from '@/Components/TwoWayCommunication/Message/Enums/PlacementEnum';
import { MessageAction } from '@/Components/TwoWayCommunication/Message/Enums/MessageActionEnum';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import RequestService from '@/Services/request.service';
import { AxiosResponse } from 'axios';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import TwoWayAjaxCalls from '@/Apps/TwoWayCommunication/Enums/TwoWayAjaxCallsEnum';
import { useStrings } from '@/Composables/Strings';

const props = defineProps<{
    id: string;
    index: number;
    title: string;
    secondaryTitle?: string;
    body: string;
    author: string;
    date: string;
    isNew: boolean;
    isBta: boolean;
    action?: MessageAction;
    attachments?: Attachment[];
}>();

const emit = defineEmits<{
    (e: 'click', id: string): void;
}>();

const messageBodyLayout: Ref<'vertical' | 'horizontal' | ''> = computed((): 'vertical' | 'horizontal' | '' => {
    return buttonParams.value ? (buttonParams.value.placement === Placement.Right ? 'horizontal' : 'vertical') : '';
});

const defaultButtonParams: Ref<ButtonWithCallbackParams> = computed((): ButtonWithCallbackParams => {
    return {
        title: useTranslate().translate('action_' + props.action),
    };
});

const buttonParams: Ref<(ButtonWithCallbackParams & ButtonPlacement) | null> = computed(
    (): (ButtonWithCallbackParams & ButtonPlacement) | null => {
        let params: (ButtonWithCallbackParams & ButtonPlacement) | null;
        switch (props.action) {
            case MessageAction.Reply:
                params = replyActionButtonParams.value;
                break;
            case MessageAction.View:
                params = viewActionButtonParams.value;
                break;
            case MessageAction.Add:
                params = addActionButtonParams.value;
                break;
            default:
                params = null;
        }

        return params
            ? {
                  ...defaultButtonParams.value,
                  ...params,
              }
            : null;
    },
);

const replyActionButtonParams: Ref<ButtonWithCallbackParams & ButtonPlacement> = computed(
    (): ButtonWithCallbackParams & ButtonPlacement => {
        return {
            textColor: ButtonTextColor.Black,
            backgroundColor: ButtonBackground.White,
            backgroundColorHover: ButtonBackground.White,
            icon: 'curved-arrow-left',
            iconColor: ButtonIconColor.Black,
            placement: Placement.Below,
        };
    },
);

const viewActionButtonParams: Ref<ButtonWithCallbackParams & ButtonPlacement> = computed(
    (): ButtonWithCallbackParams & ButtonPlacement => {
        return props.isNew
            ? {
                  textColor: ButtonTextColor.White,
                  backgroundColor: ButtonBackground.Red,
                  placement: Placement.Right,
              }
            : {
                  textColor: ButtonTextColor.Black,
                  backgroundColor: ButtonBackground.Transparent,
                  placement: Placement.Right,
                  backgroundColorHover: ButtonBackground.White,
                  textColorHover: ButtonTextColor.White,
              };
    },
);

const addActionButtonParams: Ref<ButtonWithCallbackParams & ButtonPlacement> = computed(
    (): ButtonWithCallbackParams & ButtonPlacement => {
        return {
            textColor: ButtonTextColor.Black,
            backgroundColor: ButtonBackground.Transparent,
            placement: Placement.Below,
            backgroundColorHover: ButtonBackground.White,
            textColorHover: ButtonTextColor.White,
            icon: 'edit',
            iconColor: ButtonIconColor.Black,
        };
    },
);

const hasAttachments: Ref<boolean> = computed(() => {
    let result: boolean = false;
    if (props.attachments) {
        result = props.attachments.length > 0;
    }

    return result;
});

const isVisibleButton: Ref<boolean> = computed(() => {
    let result: boolean = false;
    if (buttonParams.value) {
        switch (props.action) {
            case MessageAction.Reply:
            case MessageAction.Add:
                result = props.index === 0;
                break;
            case MessageAction.View:
                result = true;
                break;
            default:
        }
    }

    return result;
});

const combinedAttachments: UnwrapNestedRefs<{ count: number; name: string }> = reactive({
    count: props.attachments?.length ?? 0,
    name: useTranslate().translate('attached_files'),
});

function attachmentName(attachment: Attachment): string {
    return useStrings().fileNameWithNoExtension(attachment.name);
}

function attachmentExtension(attachment: Attachment): string {
    return String(useStrings().extractFileExtension(attachment.name)).toUpperCase();
}

function downloadAttachment(referenceId: string, name: string) {
    RequestService.getInstance()
        .get({
            uri: TwoWayAjaxCalls.Attachment,
            content: { referenceId: referenceId },
            withCache: false,
        })
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            const a: HTMLAnchorElement = document.createElement('a');
            a.href = response.data.data.body.fileBase64;
            a.download = name;
            a.click();
        })
        .catch((error) => {
            console.error('Error downloading attachment:', error);
        });
}

function onClick(): void {
    return emit('click', props.id);
}
</script>

<template>
    <div class="message" :data-id="id">
        <div class="messenger-container">
            <app-user-message-card :name="author" :date="date" :icon="isBta ? Icon.Bta : Icon.None" :with-badge="isNew">
            </app-user-message-card>
        </div>
        <div class="body" :class="messageBodyLayout">
            <div class="expand">
                <div>
                    <div class="title">{{ title }}</div>
                    <div class="body content-text" v-html="body"></div>
                    <div v-if="secondaryTitle" class="secondary-title">{{ secondaryTitle }}</div>
                </div>
                <ul v-if="hasAttachments" class="attachments">
                    <li v-for="(attachment, key) in attachments" :key="key" class="attachment">
                        <a
                            class="download"
                            download
                            @click="downloadAttachment(attachment.referenceId ?? '', attachment.name)"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                                    stroke="#E30613"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M7 10L12 15L17 10"
                                    stroke="#E30613"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M12 15V3"
                                    stroke="#E30613"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <span class="attachment-name">{{ attachmentName(attachment) }}</span>
                        </a>
                        <span class="additional-text">{{ attachmentExtension(attachment) }}</span>
                    </li>
                </ul>
            </div>
            <div v-if="isVisibleButton" class="button-container">
                <button-with-callback v-bind="buttonParams" @button-callback-click="onClick"></button-with-callback>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.message {
    background-color: var(--white);
    border-radius: var(--size-pico);
    display: flex;
    padding: var(--size-normal);
    gap: var(--size-medium);
    flex-direction: column;
    justify-content: space-between;

    @include respond-above('sm') {
        flex-direction: row;
        padding: var(--size-medium);
    }

    @include respond-below('sm') {
        gap: var(--size-tiny);
    }

    .messenger-container {
        display: flex;
        align-self: start;
        width: 100%;

        @include respond-above('sm') {
            min-width: 200px;
            max-width: 200px;
        }

        .user-message-card {
            @include respond-below('sm') {
                width: 100%;
            }
        }
    }

    .body {
        display: flex;
        flex: 1;

        .expand {
            flex: 1;

            .body {
                :deep(h1),
                :deep(h2),
                :deep(h3),
                :deep(h4),
                :deep(h5) {
                    margin-bottom: var(--size-medium);
                }

                :deep(p) {
                    margin-bottom: var(--size-tiny);
                    font-size: var(--font-size-nano);
                    color: var(--text-color-default);
                    font-weight: 500;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                :deep(span) {
                    display: block;
                    font-size: var(--font-size-nano);
                    color: var(--text-color-default);
                    font-weight: 500;
                }

                :deep(i) {
                    font-size: var(--font-size-nano);
                    font-weight: 500;
                }

                :deep(ul) {
                    font-size: var(--font-size-nano);
                    margin-top: var(--size-small);
                    margin-bottom: var(--size-small);
                    padding-left: var(--size-normal);
                    color: var(--text-color-default);

                    li {
                        margin-bottom: 10px;

                        &::before {
                            content: '\2022';
                            color: var(--brand-red);
                            font-weight: bold;
                            display: inline-block;
                            width: 1em;
                            margin-left: -1em;
                        }
                    }
                }

                :deep(table) {
                    display: block;
                    overflow-x: auto;
                    font-size: var(--font-size-femto);
                    margin-top: var(--size-small);
                    margin-bottom: var(--size-normal);

                    thead,
                    tbody {
                        tr {
                            border: 1px solid var(--black-100);

                            th,
                            td {
                                font-weight: 500;
                                padding: 8px;
                            }

                            th {
                                min-height: 38px;
                                background-color: var(--background-light);
                                color: var(--text-color-subtle);
                                line-height: 11px;
                                font-weight: 600;
                            }

                            th:nth-child(1),
                            th:nth-child(2),
                            th:nth-child(3),
                            td:nth-child(1),
                            td:nth-child(2),
                            td:nth-child(3) {
                                width: 80px;
                            }

                            th:nth-child(4),
                            td:nth-child(4) {
                                min-width: 280px;
                            }

                            th:nth-child(2),
                            th:nth-child(3),
                            td:nth-child(2),
                            td:nth-child(3) {
                                text-align: right;
                            }

                            td {
                                font-size: var(--font-size-pico);
                                line-height: 14.4px;
                                color: var(--text-color-default);
                            }
                        }
                    }
                }
            }
        }

        &.horizontal {
            flex-direction: column;
            gap: var(--size-medium);

            .button-container {
                align-self: center;

                :deep(.button-with-callback) {
                    span {
                        font-weight: 600;
                    }
                }
            }

            @include respond-above('sm') {
                flex-direction: row;
            }

            @include respond-below('sm') {
                gap: var(--size-tiny);
            }
        }

        &.vertical {
            flex-direction: column;

            .button-container {
                align-self: end;

                :deep(.button-with-callback) {
                    margin-top: var(--size-tiny);
                }
            }
        }

        .button-container {
            display: flex;
            padding: 0;
            width: 100%;

            @include respond-above('sm') {
                width: auto;
            }

            :deep(.button-with-callback) {
                span {
                    font-weight: 600;
                }
            }
        }

        > div:not(.button-container) {
            display: flex;
            flex-direction: column;

            > div:not([class]) {
                display: flex;
                gap: var(--size-nano);
                flex-direction: column;

                .title {
                    font-weight: 600;
                }

                .body {
                    display: block;

                    :deep(p) {
                        color: var(--text-color-default);
                        font-size: var(--font-size-nano);
                        line-height: var(--size-tiny);

                        @include respond-below('sm') {
                            max-width: 100%;
                        }
                    }

                    :deep(a) {
                        font-weight: 600;
                    }
                }

                .secondary-title {
                    font-weight: 600;
                    font-size: var(--font-size-nano);
                }
            }

            .attachments {
                padding: var(--size-tiny) 0 0 0;

                .attachment {
                    display: flex;
                    justify-content: space-between;
                    border-top: 1px solid var(--black-100);
                    padding: var(--size-nano) 0;

                    a {
                        width: 100%;
                        max-width: 75%;
                    }

                    a,
                    span {
                        display: flex;
                        align-items: center;

                        .attachment-name {
                            width: 100%;
                            padding-left: var(--size-tiny);
                            font-size: var(--font-size-nano);
                            font-weight: 500;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: inline-block;
                        }
                    }

                    .additional-text {
                        display: flex;
                        align-items: center;
                        font-size: var(--font-size-pico);
                        color: var(--text-color-subtlest);
                    }

                    &:last-of-type {
                        border-bottom: 1px solid var(--black-100);
                    }
                }

                .download {
                    cursor: pointer;

                    svg {
                        min-width: 24px;
                    }
                }
            }
        }
    }

    :deep(button) {
        min-width: 200px;
        width: 100%;
        margin: 0;
        height: 52px;

        @include respond-above('sm') {
            width: auto;
        }

        &.background-white:hover {
            border-color: var(--button-color-border-secondary-hover);
        }
    }
}
</style>
