<script setup lang="ts">
import { computed, ComputedRef } from 'vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppInputHint from '@/Components/Inputs/InputHint/InputHint.vue';
import AppInputError from '@/Components/Inputs/InputError/InputError.vue';
import FormField from '@/Assets/Libraries/Form/FormField';
import { useTranslate } from '@/Composables/Translate';

const props = defineProps({
    formField: { type: FormField<string>, default: () => new FormField('') },
    objectType: { type: String, default: '' },
    translationType: { type: String, default: '', required: true },
});

const { translateForType } = useTranslate();
const label: ComputedRef<string> = computed(
    (): string => `${translated(props.objectType)} ${translated('label_object_make_model')}`,
);
const placeholder: ComputedRef<string> = computed((): string => translated('make_model_placeholder'));
const hint: ComputedRef<string> = computed((): string => translated(props.objectType + '_example'));

function translated(key: string): string {
    return translateForType(key, props.translationType);
}
</script>

<template>
    <app-input-text :label="label" :form-field="formField" :placeholder="placeholder">
        <template #error>
            <app-input-error v-if="formField.isInvalid">{{ hint }}</app-input-error>
        </template>
        <template #hint>
            <app-input-hint>{{ hint }}</app-input-hint>
        </template>
    </app-input-text>
</template>
