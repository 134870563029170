export enum FormNames {
    WhatWasDamaged = 'whatWasDamaged',
    InfoAboutLuggage = 'infoAboutLuggage',

    WhoWasSupervising = 'whoWasSupervising',
    HaveConfirmationIssuedStolen = 'haveConfirmationIssuedStolen',
    DayInstitutionInformed = 'dayInstitutionInformed',
    ListOfEssentialsStolen = 'listOfEssentialsStolen',

    HaveConfirmationIssuedDelayed = 'haveConfirmationIssuedDelayed',
    ListOfEssentialsDelayed = 'listOfEssentialsDelayed',
}
