<script setup lang="ts">
import FormField from '@/Assets/Libraries/Form/FormField';
import { computed, ComputedRef, onMounted, watch } from 'vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import SanitizersMap from '@/Components/Inputs/InputIdentityNumber/Classes/SanitizersMap';
import Validation from '@/Services/validation.service';

const props = defineProps({
    placeholder: { type: String, default: () => '' },
    formField: { type: FormField<string>, default: () => new FormField('') },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    resident: { type: Boolean, default: false },
    country: { type: String, default: '' },
    disableDefaultCountry: { type: Boolean, default: false },
});

const emit = defineEmits(['change', 'input', 'blur']);

const currentCountry: ComputedRef<string> = computed((): string => {
    let result: string = props.country || (props.resident ? new AppCountry().iso() : '');
    if (result === '' && !props.disableDefaultCountry) {
        result = new AppCountry().iso();
    }

    return result;
});

watch(
    () => props.country || props.resident,
    () => {
        props.formField.sanitize();
    },
);

onMounted((): void => {
    props.formField.addValidators({
        validIdentityNumber: (value: string): boolean =>
            !value || Validation.isValidNaturalPersonCode(value, currentCountry.value),
    });
    props.formField.addSanitizer((value: string) => {
        return SanitizersMap.byCountryIso()[currentCountry.value]
            ? SanitizersMap.byCountryIso()[currentCountry.value](value)
            : SanitizersMap.default()(value);
    });
    if (props.formField.isNotEmpty()) {
        onChange(props.formField.value);
    }
});

function onInput(value: string): void {
    emit('input', value);
}

function onChange(value: string): void {
    emit('change', value);
}

function onBlur(value: string): void {
    emit('blur', value);
}
</script>

<template>
    <app-input-text
        :class="'input-identity-number'"
        :required="required"
        :form-field="formField"
        :label="label"
        :autocomplete="'disabled'"
        :disabled="disabled"
        :placeholder="placeholder"
        :data-store-disabled="dataStoreDisabled"
        @input="onInput"
        @change="onChange"
        @blur="onBlur"
    >
        <template #app-tooltipster>
            <slot name="app-tooltipster"></slot>
        </template>
    </app-input-text>
</template>

<style lang="scss" scoped>
.input-identity-number {
    scroll-margin-top: 4em;

    @include respond-below('md') {
        width: 100%;
    }
}
</style>
