<script setup lang="ts">
import { PropType, Ref, ref } from 'vue';
import { ReimbursableItem } from '@/Components/Other/CompensationTable/Interfaces/ReimbursableItem';
import { useTranslate } from '@/Composables/Translate';

const props = defineProps({
    description: { type: String, default: '' },
    translationType: { type: String, default: 'components' },
    injuries: { type: Array as PropType<ReimbursableItem[]>, default: () => [] },
    expenses: { type: Array as PropType<ReimbursableItem[]>, default: () => [] },
});

const visibleInjuryKey: Ref<number | null> = ref<number | null>(null);

function totalCompensation(): string {
    let totalExpenses: number = 0;
    [...props.injuries, ...props.expenses].map((data) => {
        totalExpenses += parseFloat(data.price);
    });

    return parseFloat(totalExpenses.toString()).toFixed(2);
}

function translated(key: string): string {
    return useTranslate().translateForType(key, props.translationType);
}

function showAdditionalInjuryInformation(key: number) {
    if (visibleInjuryKey.value === key) {
        visibleInjuryKey.value = null;
    } else {
        visibleInjuryKey.value = key;
    }
}
</script>

<template>
    <span v-if="description !== ''" v-html="description"></span>
    <div class="compensation-table">
        <div v-for="(value, key) in injuries" :key="key" class="injury">
            <div class="data">
                <span class="text"
                    >{{ value.name }}
                    <img
                        src="images/one/helpers/info-icon.svg"
                        alt="info-icon"
                        class="info-icon clickable"
                        @click="showAdditionalInjuryInformation(key)"
                    />
                </span>
                <span class="price">&euro; {{ parseFloat(value.price).toFixed(2) }}</span>
            </div>
            <template v-if="visibleInjuryKey === key">
                <div class="additional-info">
                    <p>{{ value.description }}</p>
                </div>
                <div class="download-document">
                    <a class="download-text" :href="value.downloadLink" target="_blank">
                        //TODO Think about href
                        <img
                            src="images/one/helpers/arrow-45-degrees-right-upper.svg"
                            alt="arrow-45-degrees-right-upper"
                            class="download-arrow"
                        />
                    </a>
                </div>
            </template>
        </div>
        <div v-for="(value, key) in expenses" :key="key" class="expense">
            <span class="text" v-html="translated(value.name)"></span>
            <span class="price">&euro; {{ parseFloat(value.price).toFixed(2) }}</span>
        </div>
        <div class="total">
            <span class="text" v-html="translated('total_compensation')"></span>
            <span class="price">&euro; {{ totalCompensation() }} </span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.compensation-table {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--component-color-border-default);
    border-radius: 8px;
    margin: 24px 0;

    .injury {
        flex-direction: column;
        gap: var(--size-small);

        .data {
            display: flex;
            flex-direction: row;
            width: 100%;

            .info-icon {
                margin-left: var(--size-nano);

                &.clickable {
                    cursor: pointer;
                }
            }
        }

        .additional-info {
            align-self: flex-start;
        }

        .download-document {
            cursor: pointer;
            width: min-content;
            white-space: nowrap;
            align-self: flex-start;

            .download-text {
                font-size: var(--font-size-nano);
                color: var(--brand-teal);
            }

            .download-arrow {
                margin-left: var(--size-pico);
            }
        }
    }

    .text {
        font-weight: 600;
        flex: 1;
    }

    .price {
        font-weight: 600;
        flex: 0 0 auto;
    }

    .total {
        .text {
            font-weight: 700;
            font-size: var(--font-size-small);
        }

        .price {
            font-weight: 700;
        }
    }
}

.compensation-table > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--component-color-border-default);
    padding: 20px;

    &:last-child {
        border-bottom: none;
        background-color: #5448c80a;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}
</style>
