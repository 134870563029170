import ClaimsTravelFormFields from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelFormFields';
import { FormNames as AccidentDataFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentData/FormNames';
import { FieldNames as AccidentDataFieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentData/FieldNames';
import { FormNames as AccidentDetailsFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/FormNames';
import { FieldNames as AccidentDetailsFieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/FieldNames';
import { FormNames as AccidentDetailsInjuryFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Injury/FormNames';
import { FieldNames as AccidentDetailsInjuryFieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Injury/FieldNames';
import { FormNames as AccidentDetailsCancellationFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Cancellation/FormNames';
import { FieldNames as AccidentDetailsCancellationFieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Cancellation/FieldNames';
import { FormNames as AccidentDetailsChangesFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/TravelChanges/FormNames';
import { FieldNames as AccidentDetailsChangesFieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/TravelChanges/FieldNames';
import { FormNames as AccidentDetailsLuggageFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Luggage/FormNames';
import { FieldNames as AccidentDetailsLuggageFieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Luggage/FieldNames';
import { FormNames as AccidentDetailsOtherFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Other/FormNames';
import { FieldNames as AccidentDetailsOtherFieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Other/FieldNames';
import { FormNames as RecipientDataFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/RecipientData/FormNames';
import { FieldNames as RecipientDataFieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/RecipientData/FieldNames';
import InsuredPerson from '@/Interfaces/insured.person.interface';
import { AccidentTypes } from '@/Apps/ClaimsTravel/Enums/AccidentTypes';
import { AccidentSubTypes } from '@/Apps/ClaimsTravel/Enums/AccidentSubTypes';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import OneBase from '@/Interfaces/OneBaseInterface';
import OneBaseService from '@/Services/OneBaseService';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import {
    BaseForms as AccidentDetailsBaseForms,
    Forms as AccidentDetailsForms,
} from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentDetails/Forms';

type AccidentDetailsSubFlowsFields = Omit<AccidentDetailsForms, keyof AccidentDetailsBaseForms>;
type OptionalPanels = AccidentDetailsSubFlowsFields &
    Pick<ClaimsTravelFormFields, RecipientDataFormNames.PossibleCompensation>;

export type FilledClaimsTravelFormFields = Omit<ClaimsTravelFormFields, keyof OptionalPanels> & Partial<OptionalPanels>;

export default class FormTransformerService {
    private static instance: FormTransformerService;
    private static claimType: string = 'travel';
    private static uploader: string = 'claims-travel';
    private static multiOptionDelimiter: string = '@#@';
    private btaBase: OneBase = OneBaseService.getInstance();
    private claimsTravelService: ClaimsTravelService = ClaimsTravelService.getInstance();

    public static getInstance(): FormTransformerService {
        if (!FormTransformerService.instance) {
            FormTransformerService.instance = new FormTransformerService();
        }
        return FormTransformerService.instance;
    }

    public transform(forms: FilledClaimsTravelFormFields): Record<string, any> {
        return {
            claimType: FormTransformerService.claimType,
            uploader: FormTransformerService.uploader,
            policyId: forms[AccidentDataFormNames.Policy][AccidentDataFieldNames.Policy].object,
            whenEventHappened: forms[AccidentDataFormNames.AccidentDate][
                AccidentDataFieldNames.AccidentDate
            ].startDate.substring(0, 10),
            whatTimeDidItHappened: forms[AccidentDataFormNames.AccidentDate][AccidentDataFieldNames.AccidentTime],
            accidentCountryIso: forms[AccidentDataFormNames.AccidentDate][AccidentDataFieldNames.AccidentCountry].iso,
            injuryType: forms[AccidentDataFormNames.AccidentType][AccidentDataFieldNames.AccidentType].selected,
            injurySubType:
                forms[AccidentDetailsFormNames.WhatHappened][AccidentDetailsFieldNames.WhatHappened].selected,
            accidentDescription: forms[AccidentDetailsFormNames.DescribeEvent][AccidentDetailsFieldNames.DescribeEvent],
            beneficiary: this.beneficiary(forms),
            applicant: this.applicant(),
            sufferer: this.sufferer(forms),
            ...(forms[RecipientDataFormNames.PossibleCompensation] &&
            forms[RecipientDataFormNames.PossibleCompensation][RecipientDataFieldNames.AgreeToCompensation]
                ?.selected !== ''
                ? {
                      automaticSettlement:
                          forms[RecipientDataFormNames.PossibleCompensation][
                              RecipientDataFieldNames.AgreeToCompensation
                          ].selected === 'agree',
                  }
                : {}),
            ...this.subFlowParams(forms),
        };
    }

    private subFlowParams(forms: FilledClaimsTravelFormFields): Record<string, any> {
        const params: Record<string, any> = {};
        const accidentType = forms[AccidentDataFormNames.AccidentType][AccidentDataFieldNames.AccidentType].selected;
        const accidentSubType =
            forms[AccidentDetailsFormNames.WhatHappened][AccidentDetailsFieldNames.WhatHappened].selected;

        if (accidentType === AccidentTypes.Injury) {
            params['hadEhicCard'] =
                forms[AccidentDetailsInjuryFormNames.EhicCard]![AccidentDetailsInjuryFieldNames.HadEhicCard];
            params['medicalExpenseAmount'] =
                forms[AccidentDetailsInjuryFormNames.EhicCard]![AccidentDetailsInjuryFieldNames.MedicalExpensesTotal];
            if (accidentSubType === AccidentSubTypes.Injury) {
                params['injuredBodyParts'] =
                    forms[AccidentDetailsInjuryFormNames.Trauma]![AccidentDetailsInjuryFieldNames.Trauma];
            }
        } else if (accidentType === AccidentTypes.TravelChanges) {
            if (accidentSubType === AccidentSubTypes.TransportChanges) {
                params['whatHappenedToTransport'] =
                    forms[AccidentDetailsChangesFormNames.WhatHappenedToTransport]![
                        AccidentDetailsChangesFieldNames.WhatHappenedToTransport
                    ].selected;
                params['hadTripCancellationConfirmation'] =
                    forms[AccidentDetailsChangesFormNames.HasCarriersTripCancellationConfirmation]![
                        AccidentDetailsChangesFieldNames.HasCarriersTripCancellationConfirmation
                    ].selected === 'yes';
                params['delayExpenseAmount'] =
                    forms[AccidentDetailsChangesFormNames.HasCarriersTripCancellationConfirmation]![
                        AccidentDetailsChangesFieldNames.TotalExpensesBecauseOfDelay
                    ];
                params['hoursDelayed'] =
                    forms[AccidentDetailsChangesFormNames.HasCarriersTripCancellationConfirmation]![
                        AccidentDetailsChangesFieldNames.HoursDelayed
                    ];
            } else {
                params['tripInterruptionReason'] =
                    forms[AccidentDetailsChangesFormNames.ReasonOfTripCancellation]![
                        AccidentDetailsChangesFieldNames.ReasonOfTripCancellation
                    ].selected;
                params['howWasTripPlanned'] =
                    forms[AccidentDetailsChangesFormNames.HowWasTripPlanned]![
                        AccidentDetailsChangesFieldNames.HowWasTripPlanned
                    ].selected;
                const hadOtherInsuredPersonsWhoDidNotGo =
                    forms[AccidentDetailsChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo]![
                        AccidentDetailsChangesFieldNames.HadOtherInsuredPersonsWhoDidNotGo
                    ].selected === 'yes';
                params['hadOtherInsuredPersonsWhoDidNotGo'] = hadOtherInsuredPersonsWhoDidNotGo;
                if (hadOtherInsuredPersonsWhoDidNotGo) {
                    if (this.isClaimAboutSelf(forms)) {
                        params['otherInsuredPersonsWhoDidNotGo'] =
                            forms[AccidentDetailsChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo]![
                                AccidentDetailsChangesFieldNames.OtherInsuredPersonsFromPolicy
                            ].selected?.split(FormTransformerService.multiOptionDelimiter) ?? [];
                    } else {
                        params['otherInsuredPersonsWhoDidNotGo'] = forms[
                            AccidentDetailsChangesFormNames.HadOtherInsuredPersonsWhoDidNotGo
                        ]![AccidentDetailsChangesFieldNames.OtherInsuredPersonsWhoDidNotGo].map((field) => field.name);
                    }
                }
                const hadIndemnificationOfLosses =
                    forms[AccidentDetailsChangesFormNames.HadIndemnificationOfLoses]![
                        AccidentDetailsChangesFieldNames.HadIndemnificationOfLoses
                    ].selected === 'yes';
                params['hadIndemnificationOfLosses'] = hadIndemnificationOfLosses;
                if (hadIndemnificationOfLosses) {
                    params['delaySumIndemnified'] =
                        forms[AccidentDetailsChangesFormNames.HadIndemnificationOfLoses]![
                            AccidentDetailsChangesFieldNames.SumIndemnified
                        ];
                    params['tripCancellationRelatedLosses'] =
                        forms[AccidentDetailsChangesFormNames.HadIndemnificationOfLoses]![
                            AccidentDetailsChangesFieldNames.TripCancellationRelatedLoses
                        ];
                }
            }
        } else if (accidentType === AccidentTypes.TravelCancellation) {
            params['tripInterruptionReason'] =
                forms[AccidentDetailsCancellationFormNames.WhatCancellationHappened]![
                    AccidentDetailsCancellationFieldNames.WhatCancellationHappened
                ].selected;
            params['howWasTripPlanned'] =
                forms[AccidentDetailsCancellationFormNames.HowTripWasPlanned]![
                    AccidentDetailsCancellationFieldNames.HowTripWasPlanned
                ].selected;
            const hadOtherInsuredPersonsWhoDidNotGo =
                forms[AccidentDetailsCancellationFormNames.OtherInsured]![
                    AccidentDetailsCancellationFieldNames.OtherInsured
                ] === 'yes';
            params['hadOtherInsuredPersonsWhoDidNotGo'] = hadOtherInsuredPersonsWhoDidNotGo;
            if (hadOtherInsuredPersonsWhoDidNotGo) {
                if (this.isClaimAboutSelf(forms)) {
                    params['otherInsuredPersonsWhoDidNotGo'] =
                        forms[AccidentDetailsCancellationFormNames.OtherInsured]![
                            AccidentDetailsCancellationFieldNames.OtherInsuredPerson
                        ].selected?.split(FormTransformerService.multiOptionDelimiter) ?? [];
                } else {
                    params['otherInsuredPersonsWhoDidNotGo'] = forms[
                        AccidentDetailsCancellationFormNames.OtherInsured
                    ]![AccidentDetailsCancellationFieldNames.OtherPerson].map((field) => field.name);
                }
            }
            const hadIndemnificationOfLosses =
                forms[AccidentDetailsCancellationFormNames.Indemnification]![
                    AccidentDetailsCancellationFieldNames.Indemnification
                ] === 'yes';
            params['hadIndemnificationOfLosses'] = hadIndemnificationOfLosses;
            if (hadIndemnificationOfLosses) {
                params['delaySumIndemnified'] =
                    forms[AccidentDetailsCancellationFormNames.Indemnification]![
                        AccidentDetailsCancellationFieldNames.IndemnificationSum
                    ];
                params['tripCancellationRelatedLosses'] =
                    forms[AccidentDetailsCancellationFormNames.Indemnification]![
                        AccidentDetailsCancellationFieldNames.LossesSum
                    ];
            }
        } else if (accidentType === AccidentTypes.Luggage) {
            if (accidentSubType === AccidentSubTypes.Damaged) {
                params['luggageWhatWasDamaged'] =
                    forms[AccidentDetailsLuggageFormNames.WhatWasDamaged]![
                        AccidentDetailsLuggageFieldNames.WhatWasDamaged
                    ].selected?.split(':') ?? [];
                params['carrierIssuedConfirmationDamaged'] =
                    forms[AccidentDetailsLuggageFormNames.HaveConfirmationIssuedStolen]![
                        AccidentDetailsLuggageFieldNames.HaveConfirmationIssuedStolen
                    ];
                params['luggageItems'] =
                    forms[AccidentDetailsLuggageFormNames.InfoAboutLuggage]![
                        AccidentDetailsLuggageFieldNames.LuggageItem
                    ];
            } else if (accidentSubType === AccidentSubTypes.Stolen) {
                params['whoWasSupervising'] =
                    forms[AccidentDetailsLuggageFormNames.WhoWasSupervising]![
                        AccidentDetailsLuggageFieldNames.WhoWasSupervising
                    ];
                params['carrierIssuedConfirmationStolen'] =
                    forms[AccidentDetailsLuggageFormNames.HaveConfirmationIssuedStolen]![
                        AccidentDetailsLuggageFieldNames.HaveConfirmationIssuedStolen
                    ] === 'yes';
                params['amountOfCarrierCompensation'] =
                    forms[AccidentDetailsLuggageFormNames.HaveConfirmationIssuedStolen]![
                        AccidentDetailsLuggageFieldNames.ConfirmationIssuedSum
                    ];
                params['dayInstitutionInformed'] =
                    forms[AccidentDetailsLuggageFormNames.DayInstitutionInformed]![
                        AccidentDetailsLuggageFieldNames.DayInstitutionInformed
                    ];
                params['essentialsStolen'] =
                    forms[AccidentDetailsLuggageFormNames.ListOfEssentialsStolen]![
                        AccidentDetailsLuggageFieldNames.ListOfEssentialsStolen
                    ];
            } else if (accidentSubType === AccidentSubTypes.Delayed) {
                params['issuedDelayConfirmation'] =
                    forms[AccidentDetailsLuggageFormNames.HaveConfirmationIssuedDelayed]![
                        AccidentDetailsLuggageFieldNames.HaveConfirmationIssuedDelayed
                    ] === 'yes';
                params['hoursDelayed'] =
                    forms[AccidentDetailsLuggageFormNames.HaveConfirmationIssuedDelayed]![
                        AccidentDetailsLuggageFieldNames.HowManyHoursLuggageDelayed
                    ];
                params['amountOfCarrierCompensation'] =
                    forms[AccidentDetailsLuggageFormNames.HaveConfirmationIssuedDelayed]![
                        AccidentDetailsLuggageFieldNames.AmountOfCompensationByCarrier
                    ];
                params['essentialsDelayed'] =
                    forms[AccidentDetailsLuggageFormNames.ListOfEssentialsDelayed]![
                        AccidentDetailsLuggageFieldNames.ListOfEssentialsDelayed
                    ];
            }
        } else {
            params['claimAmount'] =
                forms[AccidentDetailsOtherFormNames.ClaimAmount]![AccidentDetailsOtherFieldNames.ClaimAmount];
        }

        return params;
    }

    private beneficiary(forms: FilledClaimsTravelFormFields): InsuredPerson & { bankAccountCurrency: string } {
        return {
            identityNumber:
                forms[RecipientDataFormNames.RecipientData][RecipientDataFieldNames.PersonDetails].personCode,
            firstName: forms[RecipientDataFormNames.RecipientData][RecipientDataFieldNames.PersonDetails].name,
            lastName: forms[RecipientDataFormNames.RecipientData][RecipientDataFieldNames.PersonDetails].surname,
            isResident: forms[RecipientDataFormNames.RecipientData][RecipientDataFieldNames.PersonDetails].isResident,
            residenceCountryIso:
                forms[RecipientDataFormNames.RecipientData][RecipientDataFieldNames.PersonDetails].country.iso,
            birthDate: forms[RecipientDataFormNames.RecipientData][RecipientDataFieldNames.PersonDetails].birthDate,
            email: forms[RecipientDataFormNames.RecipientData][RecipientDataFieldNames.PersonContacts].email,
            phone: forms[RecipientDataFormNames.RecipientData][RecipientDataFieldNames.PersonContacts].phone.phone,
            phoneCode:
                forms[RecipientDataFormNames.RecipientData][RecipientDataFieldNames.PersonContacts].phone.country ?? '',
            bankAccount: forms[RecipientDataFormNames.RecipientData][RecipientDataFieldNames.PersonBankDetails].account,
            bankAccountCurrency:
                forms[RecipientDataFormNames.RecipientData][RecipientDataFieldNames.PersonBankDetails].currency,
            isLegal: false,
        };
    }

    private applicant(): InsuredPerson {
        return {
            identityNumber: this.btaBase.user.current.personCode,
            firstName: this.btaBase.user.current.firstname,
            lastName: this.btaBase.user.current.lastname,
            isResident: true,
            isLegal: false,
            residenceCountryIso: new AppCountry().iso(),
            birthDate: this.btaBase.user.current.birthDate,
            email: this.btaBase.user.current.email,
            phoneCode: this.btaBase.user.current.phoneCode,
            phone: this.btaBase.user.current.phone,
            bankAccount: this.btaBase.user.current.bank,
        };
    }

    private sufferer(forms: FilledClaimsTravelFormFields): InsuredPerson {
        let sufferingPerson: InsuredPerson;
        const selectedSufferingPerson =
            forms[AccidentDataFormNames.SufferingPerson][AccidentDataFieldNames.SufferingPerson].selected;

        if (this.isClaimAboutSelf(forms)) {
            sufferingPerson = this.applicant();
        } else if (selectedSufferingPerson === 'other') {
            sufferingPerson = {
                identityNumber:
                    forms[AccidentDataFormNames.SufferingPersonOther][
                        AccidentDataFieldNames.SufferingPersonOtherPersonCode
                    ],
                firstName:
                    forms[AccidentDataFormNames.SufferingPersonOther][AccidentDataFieldNames.SufferingPersonOtherName],
                lastName:
                    forms[AccidentDataFormNames.SufferingPersonOther][
                        AccidentDataFieldNames.SufferingPersonOtherSurname
                    ],
                isResident:
                    forms[AccidentDataFormNames.SufferingPersonOther][
                        AccidentDataFieldNames.SufferingPersonOtherIsResident
                    ],
                residenceCountryIso: forms[AccidentDataFormNames.SufferingPersonOther][
                    AccidentDataFieldNames.SufferingPersonOtherIsResident
                ]
                    ? new AppCountry().iso()
                    : '',
                birthDate:
                    forms[AccidentDataFormNames.SufferingPersonOther][
                        AccidentDataFieldNames.SufferingPersonOtherBirthDate
                    ],
                isLegal: false,
            };
        } else {
            const personFromPolicy = this.claimsTravelService.storageFields.personFromPolicy;

            if (personFromPolicy === null) {
                throw new Error('personFromPolicy is null');
            }

            sufferingPerson = {
                identityNumber: personFromPolicy.personCode,
                firstName: personFromPolicy.name,
                lastName: personFromPolicy.surname,
                isResident: personFromPolicy.isResident,
                residenceCountryIso: personFromPolicy.country.iso,
                birthDate: personFromPolicy.birthDate,
                isLegal: false,
            };
        }

        return sufferingPerson;
    }

    private isClaimAboutSelf(forms: FilledClaimsTravelFormFields): boolean {
        return (
            forms[AccidentDataFormNames.SufferingPerson][AccidentDataFieldNames.SufferingPerson].selected ===
            this.btaBase.user.current.personId
        );
    }
}
