<script lang="ts">
import {
    computed,
    defineComponent,
    reactive,
    Ref,
    ref,
    nextTick,
    UnwrapNestedRefs,
    ShallowUnwrapRef,
    shallowReactive,
} from 'vue';
import Form from '@/Assets/Libraries/Form/Form';
import OneModule from '@/Interfaces/OneModuleInterface';
import Popup from '@/Services/popup.service';
import Error from '@/Services/error.service';
import TimeRanges from '@/Enums/TimeRangesEnum';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import ErrorType from '@/Enums/ErrorTypeEnum';
import FormField from '@/Assets/Libraries/Form/FormField';
import CssClass from '@/Enums/CssClassEnum';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import VueEvent from '@/Classes/VueEventClass';
import Sanitizer from '@/Services/sanitizer.service';
import UserCredentials from '@/Interfaces/user.credentials.interface';
import DataLayer from '@/Services/data.layer.service';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { useDefine } from '@/Composables/Define';
import DateRange from '@/Interfaces/date.range.interface';
import PopupType from '@/Enums/PopupTypeEnum';
import { useTranslate } from '@/Composables/Translate';
import { AxiosResponse } from 'axios';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import Url from '@/Enums/UrlEnum';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { useScroll } from '@/Composables/Scroll';
import { useModule } from '@/Composables/Module';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import GetInTouchModuleService from '@/Modules/GetInTouch/Services/GetInTouchModuleService';
import Validation from '@/Services/validation.service';

interface FormAddress {
    address: string;
    apartment: string;
    detailedAddress: string;
}

export default defineComponent({
    setup() {
        const module: OneModule = useModule();

        const popup: Popup = Popup.getInstance();
        const error: Error = Error.getInstance();

        const request: AxiosParams = useAxios();

        const DisabledDays: number = 6;
        const CheckboxTypeNotRequired: string = 'not_required';
        const PhoneCodes: { [countryISO: string]: string } = {
            EE: '372',
            LT: '370',
            LV: '371',
        };
        const moduleName: Ref<string> = ref('');
        const checkboxAgree: Ref<boolean> = ref(false);
        const saveToDb: Ref<boolean> = ref(false);
        const discardEmptyValues: Ref<boolean> = ref(false);
        const subject: Ref<string> = ref('');
        const checkboxType: Ref<string> = ref('');
        const phoneRequired: Ref<boolean> = ref(true);
        const showCompanyFields: Ref<boolean> = ref(false);
        const minutesStep: Ref<number> = ref(1);
        const getInTouchForm: Form = new Form({ useValidationV2: true });
        const typeOptions: Ref<InputOption[]> = ref([]);
        const disabledDays: Ref<number[]> = ref([0, DisabledDays]);
        const minuteRange: Ref<number[]> = ref([0, TimeRanges.MaxMinutes]);
        const hourRange: Ref<number[]> = ref([0, TimeRanges.MaxHours]);

        let minTime: UnwrapNestedRefs<object> = reactive({ hours: 0, minutes: 0 });
        let maxTime: UnwrapNestedRefs<object> = reactive({ hours: 23, minutes: 59 });

        const typeInputOptions: Ref<InputOption[]> = computed(() => {
            return typeOptions.value;
        });

        const timeInputMinTime: Ref<object> = computed(() => {
            return minTime;
        });

        const timeInputMaxTime: Ref<object> = computed(() => {
            return maxTime;
        });

        const timeInputMinutesStep: Ref<number> = computed(() => {
            return minutesStep.value;
        });

        const calendarInputDisabledDays: Ref<number[]> = computed(() => {
            return disabledDays.value;
        });

        const timeInputMinuteRange: Ref<number[]> = computed(() => {
            if (minuteRange.value.length <= TimeRanges.MaxMinuteDigits) {
                const result: number[] = [];
                for (let i: number = minuteRange.value[0]; i <= minuteRange.value[1]; i += minutesStep.value) {
                    result.push(i);
                }
                return result;
            }

            return minuteRange.value;
        });

        const timeInputHourRange: Ref<number[]> = computed(() => {
            if (hourRange.value.length <= TimeRanges.MaxHourDigits) {
                const result: number[] = [];
                for (let i: number = hourRange.value[0]; i <= hourRange.value[1]; i++) {
                    result.push(i);
                }
                return result;
            }

            return hourRange.value;
        });

        const fullName: Ref<string> = computed(() => {
            return module.localUser().current.firstname + ' ' + module.localUser().current.lastname;
        });

        const hasDataProcessingFieldOrIsLV: Ref<boolean> = computed(() => {
            return (
                (module.hasCmsFields() && module.cmsFieldIsEnabled('dataProcessingAgree')) ||
                (!module.hasCmsFields() && new AppCountry().isLV())
            );
        });

        const showDataProcessingCheckbox: Ref<boolean> = computed(() => {
            //INFO:
            //  Some GetInTouch modules (for example Default) doesn`t use cms fields
            //  In these cases data processing field will be visible for LV country
            return !module.localUser().isLogged() && hasDataProcessingFieldOrIsLV.value;
        });

        const create = (newModuleName: string): void => {
            moduleName.value = newModuleName;
            prepareTransfers();
            module.onModuleReady.subscribe((): void => {
                nextTick((): void => {
                    module.applyModuleType();
                    setupGetInTouchForm();
                    if (module.localUser().isLogged()) {
                        patchGetInTouchFields();
                    }
                });
            });
        };

        const prepareTransfers = (): void => {
            const transfer: TransferStateService = TransferStateService.getInstance();
            module.cmsFields.value = transfer.get(moduleName.value + 'CmsFields');
            switch (moduleName.value) {
                case GetInTouchModuleService.getInTouchModuleContactless:
                    makeTypeInputOptions(transfer.get('typeInputOptions'));
                    makeTimeInputOptions(transfer.get('timeInputOptions'));
                    makeDateInputOptions(transfer.get('dateInputOptions'));
                    applySaveToDb(transfer.get('saveToDb'));
                    module.applyDataLayerEventType(transfer.get('dataLayerEventType'));
                    break;

                case GetInTouchModuleService.getInTouchModuleCampaign:
                    applySaveToDb(transfer.get('saveToDb'));
                    break;

                case GetInTouchModuleService.getInTouchModuleLottery:
                    applySubject(transfer.get('subject'));
                    applyDiscardEmptyValues(transfer.get('discardEmptyValues'));
                    break;

                case GetInTouchModuleService.getInTouchModuleLeadsCollection:
                    module.appendClearCmsFields(transfer.get('getInTouchModuleDefaultCmsFields'));
                    applySaveToDb(transfer.get('saveToDb'));
                    break;

                default:
            }
        };

        const contactsRequestCallback = (event: VueEvent): void => {
            if (event.params.callbackType) {
                getInTouchForm.field('callType').patch(event.params.callbackType);
            }
            useScroll().scrollTo(event);
        };

        const applyPhoneRequired = (isRequired: boolean): void => {
            phoneRequired.value = isRequired;
        };

        const applyShowCompanyFields = (isRequired: boolean): void => {
            showCompanyFields.value = isRequired;
        };

        const applySubject = (newSubject: string | undefined): void => {
            if (newSubject !== undefined) {
                subject.value = newSubject;
            }
        };

        const applyDiscardEmptyValues = (newDiscardEmptyValues: boolean | undefined): void => {
            if (newDiscardEmptyValues !== undefined) {
                discardEmptyValues.value = newDiscardEmptyValues;
            }
        };

        const prepareAndSubmit = (): void => {
            module
                .captcha()
                .executeCaptcha(formSubmit)
                .then()
                .catch((reason: string): void => {
                    Error.log(ErrorType.Error, 'handleFormSubmit', reason, true);
                });
        };

        const formSubmit = (token: string): void => {
            const params: Record<string, unknown> = emailParams();
            params['g-recaptcha-response'] = token;
            submit(params);
        };

        const applyCheckboxType = (type: string): void => {
            checkboxType.value = type;
            if (checkboxType.value === CheckboxTypeNotRequired) {
                getInTouchForm.addField(new FormField('checkboxAgree', checkboxAgree.value));
            } else {
                getInTouchForm.addField(new FormField('checkboxAgree', checkboxAgree.value, 'checkboxChecked'));
            }
        };

        const applySaveToDb = (newSaveToDb: boolean | undefined): void => {
            if (newSaveToDb !== undefined) {
                saveToDb.value = newSaveToDb as boolean;
            }
        };

        const getInTouchPhoneClasses = (): string => {
            let result: string = '';
            if (module.hasCmsFields() && !module.cmsFieldIsEnabled('phone')) {
                result = CssClass.Hidden;
            }

            return result;
        };

        const makeTypeInputOptions = (options: DynamicDictionary[]): void => {
            if (options && options.length > 0) {
                typeOptions.value = options.map((option: DynamicDictionary) => {
                    return new InputOptionBuilder().setValue(option.value).setName(option.name).build();
                });
            }
        };

        const makeTimeInputOptions = (element: DynamicDictionary): void => {
            if (element) {
                minTime = element.minTime;
                maxTime = element.maxTime;
                minutesStep.value = element.minutesStep;
                minuteRange.value = element.minuteRange;
                hourRange.value = element.hourRange;
            }
        };

        const makeDateInputOptions = (element: DynamicDictionary): void => {
            if (element) {
                disabledDays.value = element.disabledDays;
            }
        };

        const emailParams = (): Record<string, unknown> => {
            const body: { [param: string]: string } = {
                name: fieldValue('name'),
                surname: fieldValue('surname'),
                personCode: fieldValue('personCode'),
                ...addressFromForm(),
                imei: fieldValue('imei'),
                email: fieldValue('email'),
                phone: phoneNumberFromForm(),
                type: fieldValue('callType'),
                date: fieldValue('date'),
                time: fieldValue('time'),
                message: fieldValue('message'),
                policyNumber: fieldValue('policyNumber'),
                companyName: fieldValue('companyName'),
                sumOfEmployees: fieldValue('employeesCount'),
            };
            if (checkboxType.value === CheckboxTypeNotRequired) {
                body.agree = getInTouchForm.field('checkboxAgree').value;
            }
            if (getInTouchForm.exists('receiveMarketing')) {
                body.receiveMarketing = getInTouchForm.field('receiveMarketing').value;
            }
            if (discardEmptyValues.value) {
                Object.keys(body)
                    .filter((key) => body[key] === null || body[key] === '')
                    .forEach((key) => delete body[key]);
            }

            return {
                body: body,
                subject: subject.value,
                pathname: location.pathname,
                saveToDb: saveToDb.value,
                moduleType: moduleType(),
            };
        };

        const moduleType = (): string => {
            let result: string;
            switch (moduleName.value) {
                case GetInTouchModuleService.getInTouchModuleLeadsCollection:
                    result = 'leads';
                    break;

                default:
                    result = module.moduleType.value;
            }

            return result;
        };

        const fieldValue = (name: string): string => {
            return getInTouchForm.exists(name) ? getInTouchForm.field(name).value : '';
        };

        const pushDataLayer = () => {
            DataLayer.getInstance()
                .addRootParam('event', module.dataLayerEventType.value)
                .addRootParam('form', module.facility.value)
                .buildAndPush();
        };

        const phoneNumberFromForm = (): string => {
            let result: string = '';
            if ((module.hasCmsFields() && module.cmsFields.value.phone.enabled) || !module.hasCmsFields()) {
                const phoneFieldValue: DynamicDictionary = getInTouchForm.field('phone').value;
                const phoneCountry: LimitedVariant = phoneFieldValue.country;
                const appCountryISO: string = module.appCountry().iso;
                const isSet = useDefine().isSet;
                result =
                    '+' +
                    (isSet(phoneCountry)
                        ? phoneCountry
                        : isSet(PhoneCodes[appCountryISO])
                          ? PhoneCodes[appCountryISO]
                          : '') +
                    ' ' +
                    phoneFieldValue.phone;
            }

            return result;
        };

        const addressFromForm = (): FormAddress => {
            let result: FormAddress = { address: '', apartment: '', detailedAddress: '' };
            if (
                (module.hasCmsFields() && module.cmsFields.value.address && module.cmsFields.value.address.enabled) ||
                !module.hasCmsFields()
            ) {
                const addressValue: DynamicDictionary = getInTouchForm.field('address').value;
                const { addressName = '', postCode = '', apartment = '', detailed = '' } = addressValue || {};
                const fullAddress: string = [addressName, postCode].filter(Boolean).join(', ');

                result = {
                    address: fullAddress,
                    apartment: apartment,
                    detailedAddress: detailed,
                } as FormAddress;
            }

            return result;
        };

        const showFailure = (reason: DynamicDictionary): void => {
            popup.showPopup(PopupType.None);
            error.show(ErrorType.Error, 'Callback::' + module.facility, reason);
        };

        const showSuccess = (): void => {
            const popupInstance: Popup = Popup.getInstance();
            popupInstance.applyTextTitle(useTranslate().translate('btar_success'));
            popupInstance.applyTextDescription(useTranslate().translate('btar_message_send_successfully'));
            popupInstance.applyCallbackModel(() => module.captcha().resetCaptcha());
            PopupService.getInstance().show(new OnePopup().withType().info);
        };

        const submit = (params: Record<string, unknown>): void => {
            switch (moduleName.value) {
                case GetInTouchModuleService.getInTouchModuleLeadsCollection:
                    validateAndSubmit(params);
                    break;

                default:
                    submitForm(params);
            }
        };

        const setupGetInTouchForm = (): void => {
            if (module.hasCmsFields()) {
                const requiredValidator = { required: Validation.required };
                if (module.cmsFieldIsEnabled('surname')) {
                    getInTouchForm.addField(new FormField('surname', '', '', Sanitizer.cleanName));
                    if (module.cmsFields.value.name.required) {
                        getInTouchForm.field('surname').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('personCode')) {
                    getInTouchForm.addField(new FormField('personCode', '', '', Sanitizer.clearPersonCode));
                    if (module.cmsFields.value.personCode.required) {
                        getInTouchForm.field('personCode').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('address')) {
                    getInTouchForm.addField(new FormField('address', '', ''));
                    if (module.cmsFields.value.address.required) {
                        getInTouchForm.field('address').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('imei')) {
                    getInTouchForm.addField(new FormField('imei', '', '', Sanitizer.cleanIMEINumber));
                    if (module.cmsFields.value.imei.required) {
                        getInTouchForm.field('imei').addValidators({
                            required: Validation.required,
                            validImei: Validation.isValidIMEINumber,
                        });
                    }
                }
                if (module.cmsFieldIsEnabled('name')) {
                    getInTouchForm.addField(new FormField('name', '', '', Sanitizer.cleanName));
                    if (module.cmsFields.value.name.required) {
                        getInTouchForm.field('name').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('email')) {
                    getInTouchForm.addField(new FormField('email'));
                    if (module.cmsFields.value.email.required) {
                        getInTouchForm.field('email').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('phone')) {
                    getInTouchForm.addField(new FormField('phone', ''));
                    if (module.cmsFields.value.phone.required) {
                        getInTouchForm.field('phone').addValidators({ required: Validation.required });
                    }
                }
                if (module.cmsFieldIsEnabled('message')) {
                    getInTouchForm.addField(new FormField('message', ''));
                    if (module.cmsFields.value.message.required) {
                        getInTouchForm.field('message').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('policyNumber')) {
                    getInTouchForm.addField(new FormField('policyNumber', ''));
                    if (module.cmsFields.value.policyNumber.required) {
                        getInTouchForm.field('policyNumber').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('receiveMarketing')) {
                    getInTouchForm.addField(new FormField('receiveMarketing', false));
                    if (module.cmsFields.value.receiveMarketing.required) {
                        getInTouchForm.field('receiveMarketing').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('date')) {
                    getInTouchForm.addField(new FormField('date'));
                    if (module.cmsFields.value.date.required) {
                        getInTouchForm.field('date').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('time')) {
                    getInTouchForm.addField(new FormField('time'));
                    if (module.cmsFields.value.time.required) {
                        getInTouchForm.field('time').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('callType')) {
                    getInTouchForm.addField(new FormField('callType'));
                    if (module.cmsFields.value.callType.required) {
                        getInTouchForm.field('callType').addValidators(requiredValidator);
                    }
                    if (module.cmsFields.value.callType.value !== '') {
                        getInTouchForm.field('callType').patch(module.cmsFields.value.callType.value);
                    }
                }
                if (module.cmsFieldIsEnabled('readRegulations')) {
                    getInTouchForm.addField(new FormField('readRegulations', false));
                    if (module.cmsFields.value.readRegulations.required) {
                        getInTouchForm.field('readRegulations').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('companyName')) {
                    getInTouchForm.addField(new FormField('companyName'));
                    if (module.cmsFields.value.companyName.required) {
                        getInTouchForm.field('companyName').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('employeesCount')) {
                    getInTouchForm.addField(new FormField('employeesCount'));
                    if (module.cmsFields.value.employeesCount.required) {
                        getInTouchForm.field('employeesCount').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('dataProcessingAgree')) {
                    getInTouchForm.addField(new FormField('dataProcessingAgree', false));
                    if (module.cmsFields.value.dataProcessingAgree.required && showDataProcessingCheckbox.value) {
                        getInTouchForm.field('dataProcessingAgree').addValidators(requiredValidator);
                    }
                }
                if (module.cmsFieldIsEnabled('checkboxAgree')) {
                    getInTouchForm.addField(new FormField('checkboxAgree', false));
                    if (module.cmsFields.value.checkboxAgree.required) {
                        getInTouchForm.field('checkboxAgree').addValidators(requiredValidator);
                    }
                }
            }
            getInTouchForm.setReady();
        };

        const patchGetInTouchFields = (): void => {
            const countryISO: string = module.appCountry().iso;
            const userData: UserCredentials = module.localUser().current;
            if (module.localUser().isLogged()) {
                switch (moduleName.value) {
                    case GetInTouchModuleService.getInTouchModuleLeadsCollection:
                        getInTouchForm.field('name').patch(module.localUser().current.firstname);
                        getInTouchForm.field('surname').patch(module.localUser().current.lastname);
                        nextTick(() => {
                            getInTouchForm.field('personCode').patch(module.localUser().current.personCode);
                        });
                        break;

                    default:
                        getInTouchForm.field('name').patch(fullName.value);
                }
                getInTouchForm.field('email').patch(userData.email);
                getInTouchForm.field('phone').patch({
                    code: countryISO,
                    country: userData.phoneCode,
                    phone: userData.phone,
                });
            }
        };

        const resetForm = (): void => {
            getInTouchForm.clear().then();
            getInTouchForm.resetSubmitAttempt();
            patchGetInTouchFields();
            if (checkboxType.value) {
                getInTouchForm.field('checkboxAgree').value = checkboxAgree.value;
            }
            const emptyDate: DateRange = new (class implements DateRange {
                public startDate: string = '';
                public endDate: string = '';
            })();
            getInTouchForm.field('date').patch(emptyDate);
            getInTouchForm.field('time').clear();
        };

        const validateAndSubmit = (params: Record<string, unknown>): void => {
            getInTouchForm.submitAttempt().then(() => {
                if (getInTouchForm.isValid()) {
                    submitForm(params);
                } else {
                    useScroll().scrollInvalidFieldToView(getInTouchForm);
                }
            });
        };

        const submitForm = (params: Record<string, unknown>): void => {
            nextTick(() => {
                PopupService.getInstance().show(new OnePopup().withType().loading);
            });
            request
                .post(Url.Ajax.submitGetInTouch, params)
                .then((value: AxiosResponse<DynamicDictionary>): void => {
                    PopupService.getInstance().hide();
                    if (module.validResponse(value) && value.data.data.status === 'OK') {
                        resetForm();
                        showSuccess();
                        pushDataLayer();
                    } else {
                        throw value;
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    showFailure(reason);
                });
        };

        return {
            ...module,
            ...{
                create,
                getInTouchForm,
                checkboxAgree,
                saveToDb,
                discardEmptyValues,
                subject,
                typeInputOptions,
                timeInputMinuteRange,
                timeInputHourRange,
                fullName,
                hasDataProcessingFieldOrIsLV,
                showDataProcessingCheckbox,
                timeInputMinTime,
                timeInputMaxTime,
                timeInputMinutesStep,
                calendarInputDisabledDays,
                contactsRequestCallback,
                applyPhoneRequired,
                applyShowCompanyFields,
                applySubject,
                applyDiscardEmptyValues,
                getInTouchPhoneClasses,
                applySaveToDb,
                prepareAndSubmit,
                applyCheckboxType,
                makeTypeInputOptions,
                makeDateInputOptions,
                makeTimeInputOptions,
                formSubmit,
            },
        };
    },
});
</script>
