import { ActivePlus } from '@/Apps/ActivePlus/Interfaces/ActivePlusInterface';
export class CoveredPopupBuilder {
    private type: string = '';
    private title: string = '';
    private content: ActivePlus | ActivePlus[] = [];
    private onSelectClick!: Function;
    private translationType: string = '';

    public withCoveredType(type: string): CoveredPopupBuilder {
        this.type = type;

        return this;
    }

    public withCoveredTitle(title: string): CoveredPopupBuilder {
        this.title = title;

        return this;
    }

    public withContent(data: ActivePlus | ActivePlus[]): CoveredPopupBuilder {
        this.content = data;

        return this;
    }

    public withTranslationKey(key: string): CoveredPopupBuilder {
        this.translationType = key;

        return this;
    }

    public withOnSelectClick(callback: Function): CoveredPopupBuilder {
        this.onSelectClick = callback;

        return this;
    }

    public build(): CoveredPopupBuilder {
        return this;
    }
}
