<script setup lang="ts">
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { AxiosResponse, CancelTokenSource } from 'axios';
import { useTranslate } from '@/Composables/Translate';
import { computed, onMounted, ref, Ref } from 'vue';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import { useHtml } from '@/Composables/Html';
import { usePrice } from '@/Composables/Price';
import { useDefine } from '@/Composables/Define';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import Url from '@/Enums/UrlEnum';
import ErrorType from '@/Enums/ErrorTypeEnum';
import OneBaseService from '@/Services/OneBaseService';
import Attachment from '@/Components/TwoWayCommunication/Message/Interfaces/Attachment';
import ProcessInnerToaster from '@/Components/Tooltips/ProcessInnerToaster/Services/ProcessInnerToasterService';
import InnerToasterState from '@/Components/Tooltips/ProcessInnerToaster/Enums/InnerToasterStateEnum';
import RequestService from '@/Services/request.service';
import TwoWayAjaxCalls from '@/Apps/TwoWayCommunication/Enums/TwoWayAjaxCallsEnum';
import Decision from '@/Components/Claims/ClaimOverview/Interfaces/Decision';
import ButtonsWithCallbackBuilder from '@/Components/Buttons/ButtonWithCallback/Builders/ButtonsWithCallbackBuilder';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import { useNavigate } from '@/Composables/Navigate';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import TwoWayNavigationPayload from '@/Apps/TwoWayCommunication/Interfaces/TwoWayNavigationPayload';
import TwoWaySupportType from '@/Apps/TwoWayCommunication/Enums/TwoWaySupportTypeEnum';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Services/TwoWayCommunicationService';
import UrlBuilder from '@/Assets/Libraries/Url/UrlBuilder';

const request: AxiosParams = useAxios();
const { translateForType, translate } = useTranslate();
const { imgTag } = useHtml();
const { price } = usePrice();
const { isSet } = useDefine();

const props = defineProps({
    translationsType: { type: String, default: 'dashboard' },
    content: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

const fetchIsInProgress: Ref<boolean> = ref(false);
const cancelToken: Ref<CancelTokenSource | null> = ref(null);
const compact: Ref<HTMLDivElement | null> = ref(null);
const decisionIsOpened: Ref<boolean> = ref(false);

const isCompensationPaid: Ref<boolean> = computed(() => {
    return (
        isSet(props.content.detailsBlock.compensationSum) &&
        props.content.detailsBlock.compensationSum !== '0' &&
        props.content.detailsBlock.compensationSum !== ''
    );
});

const showCompensationDate: Ref<boolean> = computed(() => {
    /*INFO: hidden for now
      REASON:
        BTA: Please hide this field as the date is not correct here
        and we are not receiving this date by existing ws method "getClaimCaseList"
     */
    return false;
});

const addFilesButtonIsDisabled: Ref<boolean> = computed(() => {
    let filesIsEmpty: boolean = true;
    let uploadInProgress: boolean = true;
    const compactFileUploader: DynamicDictionary = compact.value as DynamicDictionary;
    if (compactFileUploader && compactFileUploader.filesUploader) {
        filesIsEmpty = compactFileUploader.filesUploader.filesCount === 0;
        uploadInProgress = compactFileUploader.filesUploader.uploadInProgress;
    }

    return filesIsEmpty || uploadInProgress;
});

const infoWithActionsButtons: Ref<ButtonWithCallbackParams[]> = ref([]);

function addAttachmentsToClaimCase(): void {
    const params: DynamicDictionary = {
        policyId: props.content.uploadBlock.policyId,
        policyNumber: props.content.uploadBlock.policyNumber,
        policyType: props.content.uploadBlock.policyType,
        claimNr: props.content.uploadBlock.claimNumber,
        claimId: props.content.uploadBlock.claimId,
        uploader: props.content.uploadBlock.claimNumber,
    };
    const headers: DynamicDictionary = {};
    OneBaseService.getInstance().lockInput(true);
    const compactFileUploader: DynamicDictionary = compact.value as DynamicDictionary;
    compactFileUploader.applyToasterState(InnerToasterState.Process);
    useAxios()
        .post(Url.Ajax.addOneAttachmentsToClaimCase, params, {
            headers: headers,
        })
        .then((): void => {
            if (compact.value) {
                compactFileUploader.filesUploader.clearUploads();
            }
        })
        .catch((reason: DynamicDictionary): void => {
            OneBaseService.getInstance().error.show(ErrorType.Error, 'addAttachmentsToClaimCase', reason);
        })
        .finally((): void => {
            if (compact.value) {
                compactFileUploader.applyToasterState(InnerToasterState.Complete);
            }
            OneBaseService.getInstance().lockInput(false);
            OneBaseService.getInstance().changeBodyVerticalScrollState();
        });
}

function translated(key: string, replacements?: DynamicDictionary): string {
    return translateForType(key, props.translationsType, replacements);
}

function decisionDate(decision: Decision): string {
    return [translated('claim_decision_date'), OneDate.long(decision.createdAt)].join(': ');
}

function attachmentExtension(attachment: Attachment): string {
    return attachment.type.toUpperCase();
}

function toggleDecisionBlock(decision: Decision): void {
    decision.opened = !decision.opened;
}

function downloadAttachment(referenceId: string, name: string) {
    RequestService.getInstance()
        .get({
            uri: TwoWayAjaxCalls.Attachment,
            content: { referenceId: referenceId },
            withCache: false,
        })
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            const a: HTMLAnchorElement = document.createElement('a');
            a.href = response.data.data.body.fileBase64;
            a.download = name;
            a.click();
        })
        .catch((error) => {
            console.error('Error downloading attachment:', error);
        });
}

function buildContacts(): void {
    infoWithActionsButtons.value = ButtonsWithCallbackBuilder.getInstance()
        .reset()
        .newButton()
        .withTitle(translated('new_communication'))
        .withIcon(ButtonIcon.Edit)
        .withBackgroundColor(ButtonBackground.Green)
        .withBackgroundColorHover(ButtonBackground.DarkGreen)
        .withCallback((): void => {
            const data: TwoWayNavigationPayload = {
                supportType: TwoWaySupportType.ExistingClaim,
                caseSupportType: TwoWaySupportType.ExistingClaim,
                id: props.content.uploadBlock.claimNumber,
            };
            const uri: string = new UrlBuilder()
                .withLanguage(useTranslate().language())
                .withUri(TransferStateService.getInstance().get('newCommunicationUri'))
                .withGetParams(data)
                .build();
            useNavigate().navigate(uri);
        })
        .build();
}

onMounted(() => {
    ProcessInnerToaster.getInstance()
        .applyProcessTitle(translated('uploading_documents'))
        .applyCompleteTitle(translated('documents_uploaded'));
    buildContacts();
});
</script>

<template>
    <div class="policy-details-overview">
        <div v-if="content.statusBlock.enabled" class="overview-block">
            <div class="header header-with-badges">
                <div class="title">{{ translated('claim_status') }}</div>
            </div>
            <app-progress-steps :step-names="content.statusBlock.progressSteps" :step="content.statusBlock.currentStep">
            </app-progress-steps>
        </div>
        <div class="overview-block">
            <div class="header header-with-badges">
                <div class="title">{{ translated('claim_details') }}</div>
            </div>
            <div class="details-block">
                <div v-if="content.detailsBlock.claimType" class="details-line">
                    <span class="left-part">{{ translated('claim_type') }}</span>
                    <span class="right-part">{{ content.detailsBlock.claimType }}</span>
                </div>
                <div class="details-line">
                    <span class="left-part">{{ translated('claim_case_number') }}</span>
                    <span class="right-part">{{ content.detailsBlock.claimNumber }}</span>
                </div>
                <div class="details-line">
                    <span class="left-part">{{ translated('submitted_at') }}</span>
                    <span class="right-part">{{ content.detailsBlock.submitDate }}</span>
                </div>
                <div v-if="content.detailsBlock.policyNumber" class="details-line">
                    <span class="left-part">{{ translated('policy_number') }}</span>
                    <span class="right-part">{{ content.detailsBlock.policyNumber }}</span>
                </div>
                <div v-if="isCompensationPaid" class="details-line">
                    <span class="left-part">{{ translated('claim_compensation_sum') }}</span>
                    <span class="right-part">{{ price(content.detailsBlock.compensationSum, false, 'EUR') }}</span>
                </div>
                <div v-if="isCompensationPaid && showCompensationDate" class="details-line">
                    <span class="left-part">{{ translated('claim_compensation_date') }}</span>
                    <span class="right-part">{{ OneDate.short(content.detailsBlock.compensationDate) }}</span>
                </div>
                <div v-if="content.detailsBlock.closeDate && !isCompensationPaid" class="details-line">
                    <span class="left-part">{{ translated('claim_close_date') }}</span>
                    <span class="right-part">{{ content.detailsBlock.closeDate }}</span>
                </div>
            </div>
        </div>
        <slot name="ajax-loader"></slot>
        <template v-if="content.decisionBlock.enabled">
            <div v-for="(decision, index) in content.decisionBlock.decisions" :key="index" class="overview-block">
                <div class="decision-title" :class="{ opened: decision.opened }" @click="toggleDecisionBlock(decision)">
                    <div class="title">
                        {{ decision.title }}
                        <img
                            src="images/one/components/claims/dropdown-toggle.svg"
                            alt="toggle"
                            width="24"
                            height="24"
                        />
                    </div>
                    <div class="date">
                        {{ decisionDate(decision) }}
                    </div>
                </div>
                <div class="decision-opener" :class="{ opened: decision.opened }">
                    <div class="decision-description" v-html="decision.description"></div>
                    <div v-if="decision.documents.length > 0" class="decision-documents">
                        <div v-if="decision.documentsTitle !== ''" class="title">
                            {{ decision.documentsTitle }}
                        </div>
                        <ul class="attachments">
                            <li v-for="(attachment, key) in decision.documents" :key="key" class="attachment">
                                <a
                                    class="download"
                                    download
                                    @click="downloadAttachment(attachment.referenceId ?? '', attachment.name)"
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                                            stroke="#1d1f23a3"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M7 10L12 15L17 10"
                                            stroke="#1d1f23a3"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M12 15V3"
                                            stroke="#1d1f23a3"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                    <span class="attachment-name">{{ attachment.name }}</span>
                                </a>
                                <span class="additional-text">{{ attachmentExtension(attachment) }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="content.contactsBlock.enabled">
            <app-info-with-actions
                class="overview-block"
                :title="translated('contact_bta')"
                :sub-title="translated('need_help')"
                :buttons="infoWithActionsButtons"
            ></app-info-with-actions>
        </template>
        <div v-if="content.uploadBlock.enabled" class="overview-block no-extra-padding">
            <app-compact-file-uploader
                ref="compact"
                :title="translated('upload_documents')"
                :subtitle="translated('submit_additional_documents')"
                :uploader="content.uploadBlock.claimNumber"
                :with-document-list="false"
                :upload-files-callback="addAttachmentsToClaimCase"
                :translation-type="'dashboard'"
            >
            </app-compact-file-uploader>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import 'resources/stylesheets/base/base';

.policy-details-overview {
    :deep(.info-with-actions) {
        max-width: 100%;

        .buttons {
            .button-with-callback {
                min-width: 100%;
            }
        }
    }

    .overview-block {
        width: 100%;
        padding: var(--size-small);
        border-radius: 16px;
        background-color: var(--white);
        box-shadow: none;
        margin-bottom: 20px;

        @include respond-above('sm') {
            padding: var(--size-medium);
        }

        .header {
            display: flex;
            align-items: center;
            margin-bottom: var(--size-small);

            &.header-with-border {
                padding-bottom: 30px;
                border-bottom: 1px solid var(--black-300);
            }

            &.small-margin {
                margin-bottom: 20px;
            }

            .title {
                font-size: var(--font-size-small);
                font-weight: 700;
                max-width: 50%;
            }
        }

        .details-block {
            .details-line {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                font-size: var(--font-size-nano);

                &.column {
                    flex-direction: column;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .left-part {
                    color: var(--text-color-subtle);
                }

                .right-part {
                    font-weight: 500;
                    text-align: right;
                    color: var(--text-color-default);
                }

                .red {
                    color: var(--brand-red);
                    font-size: var(--font-size-small);
                    align-self: baseline;
                }

                .tiny {
                    font-size: var(--font-size-tiny);
                    align-self: baseline;
                }
            }

            .grey-line {
                border-top: solid 1px var(--black-50);
                margin-bottom: 20px;

                &.extra-margins {
                    margin-top: var(--size-small);
                    margin-bottom: var(--size-big);
                }
            }
        }

        .decision-title {
            font-size: var(--font-size-small);
            font-weight: 700;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            gap: var(--size-pico);

            .title {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .date {
                font-size: var(--font-size-nano);
                font-weight: 500;
                color: var(--text-color-subtle);
                line-height: var(--line-height-basic);
            }

            img {
                transform: rotate(180deg);
                transition: transform 0.3s ease-in-out;
            }

            &.opened {
                margin-bottom: var(--size-medium);

                img {
                    transform: rotate(0deg);
                }
            }
        }

        .decision-opener {
            display: none;

            &.opened {
                display: block;
            }

            .decision-description {
                display: block;

                :deep(h1),
                :deep(h2),
                :deep(h3),
                :deep(h4),
                :deep(h5) {
                    margin-bottom: var(--size-medium);
                }

                :deep(p) {
                    margin-bottom: var(--size-tiny);
                    font-size: var(--font-size-nano);
                    color: var(--text-color-default);
                    font-weight: 500;

                    a {
                        @extend %link-styles;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                :deep(span) {
                    display: block;
                    font-size: var(--font-size-nano);
                    color: var(--text-color-default);
                    font-weight: 500;
                }

                :deep(ul) {
                    font-size: var(--font-size-nano);
                    margin-top: var(--size-small);
                    margin-bottom: var(--size-small);
                    padding-left: var(--size-normal);
                    color: var(--text-color-default);
                    font-weight: 500;

                    li {
                        margin-bottom: 10px;

                        &::before {
                            content: '\2022';
                            color: var(--brand-red);
                            font-weight: bold;
                            display: inline-block;
                            width: 1em;
                            margin-left: -1em;
                        }
                    }
                }

                :deep(i) {
                    font-size: var(--font-size-nano);
                    font-weight: 500;
                }

                :deep(table) {
                    display: block;
                    overflow-x: auto;
                    font-size: var(--font-size-femto);
                    margin-top: var(--size-small);
                    margin-bottom: var(--size-normal);

                    thead,
                    tbody {
                        tr {
                            border: 1px solid var(--black-100);

                            th,
                            td {
                                font-weight: 500;
                                padding: 8px;
                            }

                            th {
                                min-height: 38px;
                                background-color: var(--background-light);
                                color: var(--text-color-subtle);
                                line-height: 11px;
                                font-weight: 600;
                            }

                            th:nth-child(1),
                            th:nth-child(2),
                            th:nth-child(3),
                            td:nth-child(1),
                            td:nth-child(2),
                            td:nth-child(3) {
                                width: 80px;
                            }

                            th:nth-child(4),
                            td:nth-child(4) {
                                min-width: 280px;
                            }

                            th:nth-child(2),
                            th:nth-child(3),
                            td:nth-child(2),
                            td:nth-child(3) {
                                text-align: right;
                            }

                            td {
                                font-size: var(--font-size-pico);
                                line-height: 14.4px;
                                color: var(--text-color-default);
                            }
                        }
                    }
                }
            }

            .decision-documents {
                .title {
                    font-size: var(--font-size-small);
                    font-weight: 700;
                    padding-top: var(--size-normal);
                    margin-top: var(--size-small);
                    border-top: 1px solid var(--black-100);
                }

                .attachments {
                    padding: var(--size-tiny) 0 0 0;

                    .attachment {
                        display: flex;
                        justify-content: space-between;
                        border: 1px solid var(--black-100);
                        padding: var(--size-nano) var(--size-small);
                        border-radius: var(--size-pico);
                        margin-bottom: var(--size-pico);

                        a,
                        span {
                            display: flex;
                            align-items: center;

                            .attachment-name {
                                padding-left: var(--size-tiny);
                                font-size: var(--font-size-tiny);
                                font-weight: 600;
                            }
                        }

                        .additional-text {
                            display: flex;
                            align-items: center;
                            font-size: var(--font-size-pico);
                            color: var(--text-color-subtlest);
                        }

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }

                    &.not-downloadable {
                        padding-bottom: 0;

                        .attachment {
                            &:last-of-type {
                                border-bottom: none;
                            }

                            .attachment-name {
                                font-weight: 600;
                            }

                            .additional-text {
                                font-size: var(--font-size-nano);
                            }
                        }
                    }

                    .download {
                        cursor: pointer;
                        color: var(--text-color-default);

                        &:hover {
                            color: var(--brand-red);
                        }

                        svg {
                            min-width: 24px;
                        }
                    }
                }
            }
        }
    }

    .no-extra-padding {
        padding: 0;
    }
}
</style>
