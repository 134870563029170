<script setup lang="ts" generic="T extends { id: string }">
import { computed, ComputedRef, Ref, ref, getCurrentInstance, watch, PropType, onMounted, nextTick } from 'vue';
import * as uuid from 'uuid';

const props = defineProps({
    min: { type: Number, default: 1 },
    max: { type: Number, default: 99999 },
    lockFirstItem: { type: Boolean, default: false },
    factory: {
        type: Function as PropType<(id: string) => T>,
        default: (id: string): { id: string } => ({ id: id }),
    },
});

const emit = defineEmits(['add', 'remove']);

const items: Ref<T[]> = defineModel('items', { type: Array as PropType<T[]>, default: () => [] });

const isTransitionEnabled: Ref<boolean> = ref(false);
const instance: any = getCurrentInstance();
const canAddOrRemoveItems: ComputedRef<boolean> = computed((): boolean => props.min !== props.max);

watch(
    () => items.value,
    () => {
        if (items.value.length < props.min) {
            for (let i = items.value.length; i < props.min; i++) {
                addItem();
            }
        }
    },
    { immediate: true },
);

onMounted(() => {
    nextTick(() => {
        isTransitionEnabled.value = true;
    });
});

function createItem(): T {
    return props.factory(uuid.v4());
}

function addItem(): void {
    const item: T = createItem();
    items.value.push(item);
    emit('add', item);
}

function removeItem(itemToRemove: T): void {
    const index = items.value.findIndex((item: T) => item.id === itemToRemove.id);
    if (index !== -1) {
        items.value.splice(index, 1);
        emit('remove', itemToRemove);
    }
}

function isAddButtonVisible(item: T): boolean {
    const index: number = indexForItem(item);
    return canAddOrRemoveItems.value && index === items.value.length - 1 && index < props.max - 1;
}

function isRemoveButtonVisible(item: T): boolean {
    const index: number = indexForItem(item);
    return canAddOrRemoveItems.value && (props.lockFirstItem ? index > 0 : items.value.length > props.min);
}

function isFirstElement(item: T): boolean {
    return indexForItem(item) === 0;
}

function isLastElement(item: T): boolean {
    const index: number = indexForItem(item);
    return canAddOrRemoveItems.value && index === items.value.length - 1;
}

function indexForItem(searchItem: T): number {
    return items.value.findIndex((item: T): boolean => item.id === searchItem.id);
}

defineExpose({
    isAddButtonVisible,
    isRemoveButtonVisible,
    isFirstElement,
    isLastElement,
    addItem,
    removeItem,
    items,
});
</script>

<template>
    <div class="repeatable repeatable-container">
        <transition-group :name="isTransitionEnabled ? 'repeatable' : ''">
            <template v-for="(item, index) in items" :key="item.id">
                <slot name="default" :item="item" :index="index" :repeatable="instance"></slot>
            </template>
        </transition-group>
    </div>
</template>

<style lang="scss">
.repeatable-container {
    display: grid;
    gap: var(--size-small);
    width: 100%;

    > .repeatable-item {
        position: relative;
    }
}

.repeatable-move,
.repeatable-enter-active,
.repeatable-leave-active {
    animation-delay: 0.3s;
    transition: all 0.3s ease-in-out;
}

.repeatable-leave-to {
    opacity: 0;
    transform: translateX(50px);
}

.repeatable-enter-from {
    opacity: 0;
    transform: translateX(-50px);
}

.repeatable-leave-active {
    position: absolute;
}
</style>
