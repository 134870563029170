import { ref, Ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import ResultMessageContent from '@/Components/Other/ResultMessage/Interfaces/ResultMessageContentInterface';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ResultMessageType from '@/Components/Other/ResultMessage/Enums/ResultMessageTypeEnum';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import { useTranslate } from '@/Composables/Translate';
import { useScroll } from '@/Composables/Scroll';

export default class ResultMessage {
    private static instance: ResultMessage;
    private resultMessageContent?: ResultMessageContent;
    private isModalOpen: boolean = true;

    public isVisible: Ref<boolean> = ref(false);

    private constructor() {
        this.reset();
    }

    public static getInstance(): ResultMessage {
        if (!ResultMessage.instance) {
            ResultMessage.instance = new ResultMessage();
        }

        return ResultMessage.instance;
    }

    public reset(): this {
        this.isModalOpen = true;
        this.resultMessageContent = new (class implements ResultMessageContent {
            public type: string = ResultMessageType.Success;
            public title: string = '';
            public description: string = '';
            public buttons: ButtonWithCallbackParams[] = [];
        })();

        return this;
    }

    public content(): ResultMessageContent {
        return this.resultMessageContent as ResultMessageContent;
    }

    public isModal(): boolean {
        return this.isModalOpen;
    }

    public withModalMode(isModal: boolean): this {
        this.isModalOpen = isModal;

        return this;
    }

    public withTitle(title: string): this {
        this.resultMessageContent!.title = title;

        return this;
    }

    public withDescription(description: string): this {
        this.resultMessageContent!.description = description;

        return this;
    }

    public withButtons(buttons: ButtonWithCallbackParams[]): this {
        this.resultMessageContent!.buttons = buttons;

        return this;
    }

    public show(): void {
        useScroll().scrollToTopInstantly();
        this.checkButtonsAndApplyDefaultIfNeeded();
        this.isVisible.value = true;
        OneBaseService.getInstance().changeBodyVerticalScrollState();
    }

    public hide(): void {
        this.isVisible.value = false;
        OneBaseService.getInstance().changeBodyVerticalScrollState();
    }

    private checkButtonsAndApplyDefaultIfNeeded(): void {
        if (this.resultMessageContent!.buttons.length === 0) {
            this.resultMessageContent!.buttons = [
                new (class implements ButtonWithCallbackParams {
                    [key: string]: string | number | undefined | boolean | DynamicDictionary;

                    public textColor: string = ButtonTextColor.Black;
                    public textColorHover: string = ButtonTextColor.White;
                    public backgroundColor: string = ButtonBackground.White;
                    public backgroundColorHover: string = ButtonBackground.White;
                    public title: string = useTranslate().translate('btar_error_close');
                    public callback = (params: DynamicDictionary): void => {
                        ResultMessage.getInstance().hide();
                    };
                })(),
            ];
        }
    }
}
