<script setup lang="ts">
import { computed, onMounted, onUnmounted, PropType, Ref, watch } from 'vue';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import User from '@/Services/user.service';
import { useTranslate } from '@/Composables/Translate';
import FormField from '@/Assets/Libraries/Form/FormField';
import Form from '@/Assets/Libraries/Form/Form';
import PersonContacts from '@/Interfaces/PersonContactsInterface';
import PhoneField from '@/Interfaces/phone.field.interface';
import AppPhoneWithCountry from '@/Components/Inputs/PhoneWithCountry/PhoneWithCountry.vue';
import AppInputEmail from '@/Components/Inputs/InputEmail/InputEmail.vue';
import Validation from '@/Services/validation.service';
import SettingsService from '@/Services/settings.service';

const { translateForType } = useTranslate();

const props = defineProps({
    formField: {
        type: Object as PropType<FormField<PersonContacts>>,
        default: () => new FormField(''),
    },
    translationType: {
        type: String,
        default: () => 'components',
    },
});

const emit = defineEmits(['change']);

const user: User = User.getInstance();

const settingsService: SettingsService = SettingsService.getInstance();
const form: Form<PersonContacts> = new Form({ useValidationV2: true });
const formChanges = new Subject<void>();
let onSubmitAttemptSubscription: Subscription | undefined;
let formChangeSubscription: Subscription | undefined;
let patchSubscription: Subscription;

onMounted((): void => {
    setupForm();
    prepareFormField();
    setupSubscriptions();
    setDefaultValues();
    patchSubscription = props.formField.onPatch.subscribe(() => {
        defaultFields();
    });
});

onUnmounted((): void => {
    onSubmitAttemptSubscription?.unsubscribe();
    formChangeSubscription?.unsubscribe();
    patchSubscription?.unsubscribe();
});

function setupForm(): void {
    form.addField(new FormField('email', '', { required: Validation.required }));
    form.addField(new FormField('phone', '', { required: Validation.required }));
    form.setReady();
}

function prepareFormField(): void {
    props.formField.addValidators({
        childrenAreValid: () => form.isValid(),
    });
}

function setupSubscriptions(): void {
    onSubmitAttemptSubscription = props.formField.onSubmitAttempt.subscribe(() => {
        form.submitAttempt();
    });

    formChangeSubscription = formChanges.pipe(debounceTime(1)).subscribe(() => {
        props.formField.setValue({
            email: form.field('email').value,
            phone: form.field('phone').value,
        });
        props.formField.markAsTouched();
        emit('change', props.formField.value);
    });
}

function authenticatedFields(): void {
    form.field('email').setValue(user.current.email);
    form.field('phone').setValue({
        code: user.current.phoneCode,
        iso: user.current.code,
        country: user.current.addressCountry,
        phone: user.current.phone,
    } as PhoneField);
}

function defaultFields(): void {
    form.field('email').setValue('');
    form.field('phone').setValue({
        code: '',
        iso: settingsService.localeIso(),
        country: '',
        phone: '',
    } as PhoneField);
}

function restoredFields(): void {
    form.field('email').setValue(props.formField.value.email);
    form.field('phone').setValue(props.formField.value.phone);
}

function setDefaultValues(): void {
    if (!props.formField.isEmpty()) {
        restoredFields();
    } else if (user.isLogged()) {
        authenticatedFields();
    } else {
        defaultFields();
    }
}

function translated(key: string): string {
    return translateForType(key, props.translationType);
}

function applyValues(): void {
    formChanges.next();
}
</script>

<template>
    <div
        v-if="form.isReady()"
        :id="formField.name"
        class="grid-utility grid-columns-1 sm:grid-columns-12"
        :class="{ ...formField.classes() }"
        :data-store="formField.name"
        :data-store-value="JSON.stringify(formField.value)"
    >
        <app-input-email
            class="column-span-12 sm:column-span-6"
            :label="translated('email')"
            :placeholder="translated('email_placeholder')"
            :form-field="form.field('email')"
            :data-store-disabled="true"
            @change="applyValues"
        >
        </app-input-email>
        <app-phone-with-country
            class="column-span-12 sm:column-span-6"
            :label="translated('phone')"
            :placeholder="translated('phone_placeholder')"
            :disable-error-text="true"
            :mobile-mode-enabled="true"
            :form-field="form.field('phone')"
            :data-store-disabled="true"
            @change="applyValues"
        >
        </app-phone-with-country>
    </div>
</template>
