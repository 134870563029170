<script setup lang="ts">
import { computed, ComputedRef, getCurrentInstance, onMounted, Ref, ref } from 'vue';
import Storage from '@/Apps/ActivePlus/Services/Storage';
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import StepsGuard from '@/Apps/ActivePlus/Services/StepsGuard';
import OneBaseService from '@/Services/OneBaseService';
import AppPage from '@/Apps/ActivePlus/Components/Page.vue';
import { FormHelperParams, useFormHelper } from '@/Composables/FormHelper';
import AppWhiteboard from '@/Components/Containers/Whiteboard/Whiteboard.vue';
import AppDetailsList from '@/Components/Lists/DetailsList/DetailsList.vue';
import { DetailList } from '@/Interfaces/DetailListInterface';
import { DetailsListBuilder } from '@/Builders/DetailListBuilder';
import AppInputDateWithCalendar from '@/Components/Inputs/InputDateWithCalendar/InputDateWithCalendar.vue';
import moment from 'moment';
import Form from '@/Assets/Libraries/Form/Form';
import DateRange from '@/Interfaces/date.range.interface';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { useAxios } from '@/Composables/Axios';
import FormField from '@/Assets/Libraries/Form/FormField';
import Validation from '@/Services/validation.service';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import AppSvg from '@/Components/Other/Svg/Svg.vue';
import AppButtonSecondary from '@/Components/Buttons/ButtonSecondary/ButtonSecondary.vue';
import { Color } from '@/Components/Buttons/Button/ButtonEnums';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import PopupService from '@/Services/custom.popup.service';
import { ActivePlus } from '@/Apps/ActivePlus/Interfaces/ActivePlusInterface';
import CoveredPopupTypes from '@/Components/Popups/CoveredPopup/Enums/CoveredPopupTypes';
import { CoveredPopupBuilder } from '@/Apps/ActivePlus/Builders/CoveredPopupBuilder';
import AppPersonDetails from '@/Components/Widgets/PersonDetails/PersonDetails.vue';
import PersonDetails from '@/Interfaces/PersonDetailsInterface';
import AppPersonContacts from '@/Components/Widgets/PersonContacts/PersonContacts.vue';
import PersonContacts from '@/Interfaces/PersonContactsInterface';
import AppConsentsList from '@/Components/Lists/ConsentsList/ConsentsList.vue';
import OneBase from '@/Interfaces/OneBaseInterface';
import AppPaymentMethods from '@/Components/Widgets/PaymentMethods/PaymentMethods.vue';
import AppTermsAndAgreements from '@/Components/Widgets/TermsAndAgreements/TermsAndAgreements.vue';
import AppButtonPrimary from '@/Components/Buttons/ButtonPrimary/ButtonPrimary.vue';
import TermsAndAgreements from '@/Interfaces/TermsAndAgreementsInterface';
import TermsAndAgreementsTranslations from '@/Components/Widgets/TermsAndAgreements/Interfaces/TermsAndAgreementsTranslationsInterface';
import { useScroll } from '@/Composables/Scroll';
import { useDefine } from '@/Composables/Define';
import Url from '@/Enums/UrlEnum';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import { AxiosResponse } from 'axios';
import { useNavigate } from '@/Composables/Navigate';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';

const { translateForType } = useTranslate();
const router: Router = useRouter();
const formHelper: FormHelperParams = useFormHelper();
const base: OneBase = OneBaseService.getInstance();
const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
const bankLinkIdError: Ref<boolean> = ref(false);
const bankLinkInfo: Ref<string> = ref('');
const popupService: PopupService = PopupService.getInstance();
const form: Form<{
    policyStartDate: DateRange;
    policyHolder: PersonDetails;
    policyHolderContacts: PersonContacts;
    marketingConsents: DynamicDictionary;
    agreeTerms: boolean;
    banklinkId: number;
}> = new Form({ useValidationV2: true });
const storage: Storage = Storage.getInstance();
const TranslationType: string = 'velo_plus';
const isPageReady: Ref<boolean> = ref(false);
const policyStartMinDate: Date = moment().add(1, 'days').toDate();
const policyStartMaxDate: Date = moment().add(30, 'days').toDate();

const showMarketingConsents: Ref<boolean> = computed(() => {
    return !base.user.current.receiveMarketing;
});

const policyStartDate: Ref<string> = computed(() => {
    const result: Date = moment(form.field('policyStartDate').value.startDate).toDate();

    return OneDate.short(result);
});

const policyEndDate: Ref<string> = computed(() => {
    const startDate: Date = form.field('policyStartDate').value.startDate as unknown as Date;
    const result: Date = moment(startDate).add(1, 'year').subtract(1, 'day').toDate();

    return OneDate.short(result);
});

const insuredDetails: ComputedRef<DetailList<string[]>[]> = computed((): DetailList<string[]>[] => {
    return storage.fields.insuredPersons.map((person: any, index: number): DetailList<string[]> => {
        return new DetailsListBuilder<string[]>()
            .setLabel(
                translateForType('label_person_details', TranslationType, {
                    number: index + 1,
                }),
            )
            .setValue([`${person.person.name} ${person.person.surname}`, person.person.personCode])
            .build();
    });
});

const objectDetails: ComputedRef<DetailList<string[]>[]> = computed((): DetailList<string[]>[] => {
    return storage.fields.selectedObjects.map((object: any, index: number): DetailList<string[]> => {
        return new DetailsListBuilder<string[]>()
            .setLabel(
                translateForType('label_object_details', TranslationType, {
                    number: index + 1,
                }),
            )
            .setValue([translateForType(object.objectType.ic, TranslationType), object.model])
            .build();
    });
});

const insurancePeriodDetails: ComputedRef<DetailList[]> = computed((): DetailList[] => {
    return [
        new DetailsListBuilder()
            .setLabel(translateForType('caption_policy_period', TranslationType))
            .setValue([policyStartDate.value + ' - ' + policyEndDate.value])
            .build(),
    ];
});

const paymentDetails: ComputedRef<DetailList[]> = computed((): DetailList[] => {
    return [
        new DetailsListBuilder()
            .setLabel(translateForType('label_total_payment', TranslationType))
            .setValue([storage.selectedProduct().price, translateForType('label_price_period', TranslationType)])
            .setIsTotal(true)
            .setHighlight(true)
            .build(),
    ];
});

const totalPaymentDetails: ComputedRef<DetailList[]> = computed((): DetailList[] => {
    return [
        new DetailsListBuilder()
            .setLabel(translateForType('label_payment_due_now', TranslationType))
            .setValue([storage.selectedProduct().price, '€'])
            .setIsTotal(true)
            .setHighlight(true)
            .build(),
    ];
});

const providedPerson: Ref<PersonDetails> = computed(() => {
    return storage.fields.insuredPersons[0].person;
});

const termsTranslations: TermsAndAgreementsTranslations = {
    type: TranslationType,
    keys: {
        terms: 'description_terms_and_agreements',
        agreeTerms: 'checkbox_agree_terms',
    },
};

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    storage.updateRoute();
    StepsGuard.getInstance(storage).init();
    setupForm();
    storage.fetchProducts().then(() => {
        buildCoveredPopup();
        restoreValues();
        isPageReady.value = true;
    });
});

function setupForm(): void {
    form.addField(
        new FormField('policyStartDate', {
            startDate: policyStartMinDate,
            endDate: policyStartMaxDate,
        }).addValidators({
            required: Validation.required,
        }),
    );
    form.addField(new FormField('policyHolder').addValidators({ required: Validation.required }));
    form.addField(new FormField('policyHolderContacts').addValidators({ required: Validation.required }));
    form.addField(new FormField('marketingConsents'));
    form.addField(new FormField('agreeTerms').addValidators({ required: Validation.required }));
    form.addField(new FormField('banklinkId').addValidators({ required: Validation.required }));
    form.setReady();
}

function buildCoveredPopup(): void {
    const product: ActivePlus = storage.selectedProduct()!;
    coveredPopup
        .withCoveredTitle('title_summary_see_what_covered')
        .withTranslationKey(TranslationType)
        .withCoveredType(CoveredPopupTypes.CoveredPopupActivePlusSingle)
        .withContent(product);
}

function onCoveredClick(): void {
    popupService.show(new OnePopup().withType().oneCovered);
}

function onPaymentMethodClick(value: DynamicDictionary): void {
    bankLinkIdError.value = false;
    bankLinkInfo.value = value.bankLinkInfo;
    form.field('banklinkId').setValue(value.bankLinkId);
}

function storeValues(): void {
    storage.fields.policyStartDate = form.field('policyStartDate').value;
    storage.fields.policyHolder = form.field('policyHolder').value;
    storage.fields.policyHolderContacts = form.field('policyHolderContacts').value;
    storage.fields.marketingConsents = form.field('marketingConsents').value;
    storage.fields.agreeTerms = form.field('agreeTerms').value;
    storage.fields.banklinkId = form.field('banklinkId').value;
}

function restoreValues(): void {
    form.restoreValues({
        policyStartDate: storage.fields.policyStartDate,
        policyHolder: storage.fields.policyHolder,
        policyHolderContacts: storage.fields.policyHolderContacts,
        marketingConsents: storage.fields.marketingConsents,
        agreeTerms: storage.fields.agreeTerms,
        banklinkId: storage.fields.banklinkId,
    });
}

function onSubmitStep(): void {
    form.submitAttempt().then((): void => {
        if (form.isValid()) {
            storeValues();
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            useAxios()
                .post(Url.Ajax.activePlusPurchase, storage.fields)
                .then((response: AxiosResponse): void => {
                    if (useDefine().validResponse(response)) {
                        useNavigate().navigate(Url.PaymentsApi.paymentsPay);
                    } else {
                        PopupService.getInstance().hide();
                        base.error.show(ErrorType.Error, 'submitPayment', response);
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    PopupService.getInstance().hide();
                    Error.getInstance().show(
                        ErrorType.Error,
                        'ActivePlus insurance / ActivePlusSummaryAndPayment / onConfirmAndPay',
                        reason,
                    );
                });
        } else {
            useScroll().scrollInvalidFieldToView(form);
        }
    });
}

defineExpose({
    coveredPopup,
});
</script>

<template>
    <app-page>
        <template #title>{{ translateForType('title_policy_summary_and_payment', TranslationType) }}</template>
        <template #description>{{ translateForType('subtitle_review_info', TranslationType) }}</template>
        <app-custom-form
            v-if="isPageReady"
            :form="form"
            class="grid-utility grid-columns-1"
            @change-after-restore="storeValues"
        >
            <app-whiteboard :title="translateForType('label_insured_objects_and_persons', TranslationType)">
                <div class="grid-utility grid-columns-1">
                    <app-details-list class="column-span-1" :options="insuredDetails"></app-details-list>
                    <app-details-list class="column-span-1" :options="objectDetails"></app-details-list>
                    <app-button-secondary :expand="true" @click="onCoveredClick">
                        <template #start>
                            <app-svg
                                :color="Color.PrimaryAlternative"
                                src="/images/one/components/button-with-callback/covered.svg"
                            ></app-svg>
                        </template>
                        {{ translateForType('button_summary_see_what_covered', TranslationType) }}
                    </app-button-secondary>
                </div>
            </app-whiteboard>
            <app-whiteboard :title="translateForType('label_summary_insurance_period', TranslationType)">
                <div class="grid-utility grid-columns-1">
                    <app-input-date-with-calendar
                        class="policy-start-date"
                        :label="translateForType('caption_policy_start_date', TranslationType)"
                        :form-field="form.field('policyStartDate')"
                        :min-date="policyStartMinDate"
                        :max-date="policyStartMaxDate"
                        :number-of-calendars="1"
                    >
                    </app-input-date-with-calendar>
                    <app-details-list :options="insurancePeriodDetails"></app-details-list>
                </div>
            </app-whiteboard>
            <app-whiteboard :title="translateForType('title_payment_details', TranslationType)">
                <app-details-list :options="paymentDetails"></app-details-list>
            </app-whiteboard>
            <app-whiteboard :title="translateForType('title_summary_policy_holder', TranslationType)">
                <app-person-details
                    :form-field="form.field('policyHolder')"
                    :provided-person="providedPerson"
                    :translation-type="TranslationType"
                    provided-user-option-title="option_first_insured_person"
                    other-option-title="option_other_insured_person"
                    resident-option-title="option_resident_of_latvia"
                    non-resident-option-title="option_non_resident"
                    :show-provided-option="true"
                    :show-authenticated-option="true"
                    :dont-show-options-if-authenticated="true"
                >
                    <template #contacts>
                        <app-person-contacts :form-field="form.field('policyHolderContacts')"></app-person-contacts>
                    </template>
                </app-person-details>
            </app-whiteboard>
            <div class="grid-utility gap-huge">
                <app-whiteboard :title="translateForType('title_terms_and_agreements', TranslationType)">
                    <div class="grid-utility grid-columns-1">
                        <app-terms-and-agreements
                            :form-field="form.field('agreeTerms')"
                            :translations="termsTranslations"
                        >
                            <template #subscriptions>
                                <app-consents-list
                                    v-if="showMarketingConsents"
                                    class="column-span-12"
                                    :form-field="form.field('marketingConsents')"
                                />
                            </template>
                        </app-terms-and-agreements>
                    </div>
                </app-whiteboard>
                <app-payment-methods
                    :id="'banks-list'"
                    :error-state="bankLinkIdError"
                    @payment-method-click="onPaymentMethodClick($event)"
                >
                </app-payment-methods>
            </div>
            <app-whiteboard>
                <app-details-list :options="totalPaymentDetails"></app-details-list>
                <template #after-content>
                    <div class="grid-utility gap-small">
                        <app-button-primary :expand="true" @click="onSubmitStep()">
                            {{ translateForType('button_confirm_and_pay', TranslationType) }}
                        </app-button-primary>
                        <p class="info" v-html="bankLinkInfo"></p>
                    </div>
                </template>
            </app-whiteboard>
        </app-custom-form>
    </app-page>
</template>
<style scoped lang="scss">
.info {
    color: var(--text-color-subtlest);
    font: 500 14px/120%;

    a {
        text-decoration: underline;
        text-underline-offset: 2px;

        &:hover {
            text-decoration: none;
        }
    }
}
</style>
