<script setup lang="ts">
import { getCurrentInstance, onMounted, ref, Ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import PolicyDetailsPopup from '@/Pages/OneDashboard/Insurances/PolicyDetailsPopup';
import AppPolicyDetailsOverview from '@/Components/Policies/PolicyDetailsOverview/PolicyDetailsOverview.vue';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import RequestService from '@/Services/request.service';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import { useMobileApp } from '@/Apps/MobileApp/Composables/MobileApp';
import Invoice from '@/Components/Policies/PolicyBlock/Interfaces/InvoiceInterface';
import RecurringPaymentDetails from '@/Interfaces/recurring.payment.details.interface';

const Tab: string = 'overview';

const viewIsReady: Ref<boolean> = ref(false);
const ajaxContentIsReady: Ref<boolean> = ref(false);
const content: Ref<DynamicDictionary> = ref({});

const { designation, ajaxAgreement, agreementView, hasStoredAgreement, viewContent, emitFallbackEvent } =
    useMobileApp();

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    if (hasStoredAgreement()) {
        prepareContent();
    } else {
        emitFallbackEvent(designation().agreement);
    }
});

const prepareContent = (): void => {
    PopupService.getInstance().show(new OnePopup().withType().loading);
    const view: PolicyDetailsPopup = agreementView();
    view.buildDetails();
    view.contentBuilder().stashContent();
    applyViewContent();
    viewIsReady.value = true;
    PopupService.getInstance().hide();
    prepareAdditionalContent();
};

const applyViewContent = (): void => {
    content.value = viewContent(Tab, designation().agreement);
};

const prepareAdditionalContent = (): void => {
    RequestService.getInstance()
        .get({ uri: Url.MobileApp.overview, content: ajaxAgreement() })
        .then((response: AxiosResponse): void => {
            const axiosResponse: DynamicDictionary = response.data.data.body;
            const view: PolicyDetailsPopup = agreementView();
            view.applyPrintouts(axiosResponse.printouts as DynamicDictionary[])
                .applyUpcomingPrintouts(axiosResponse.upcomingPrintouts as DynamicDictionary[])
                .applyTerms(axiosResponse.terms as DynamicDictionary[])
                .applyInvoices(axiosResponse.invoices as Invoice[])
                .applyRecurringPayment((axiosResponse.recurringPayment as RecurringPaymentDetails) ?? null)
                .buildDetails();
            view.contentBuilder().stashContent();
            ajaxContentIsReady.value = true;
            applyViewContent();
        });
};
</script>

<template>
    <div class="app-agreements-page">
        <app-policy-details-overview v-if="viewIsReady" :content="content">
            <template v-if="!ajaxContentIsReady" #ajax-loader>
                <app-content-loader></app-content-loader>
            </template>
        </app-policy-details-overview>
    </div>
</template>

<style lang="scss" scoped>
.app-agreements-page {
    width: 100%;

    :deep(.actions-block),
    :deep(.payment-widget) {
        display: none;
    }
}
</style>
