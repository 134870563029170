<script setup lang="ts">
import { computed, onMounted, reactive, Ref, shallowReactive, ShallowUnwrapRef, UnwrapNestedRefs } from 'vue';
import Form from '@/Assets/Libraries/Form/Form';
import {
    EhicCardFields,
    InjuryForms,
    TraumaFields,
} from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentDetails/InjuryForms';
import Validation from '@/Services/validation.service';
import { TranslateReplaceParts, useTranslate } from '@/Composables/Translate';
import Option from '@/Apps/ClaimsTravel/Interfaces/Option';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { FormNames as InjuryFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Injury/FormNames';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import { FieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Injury/FieldNames';
import FormField from '@/Assets/Libraries/Form/FormField';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import { useClaimsTravelHtml } from '@/Apps/ClaimsTravel/Composables/ClaimsTravelHtml';
import ClaimsTravelFormFields from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelFormFields';
import ClaimsTravelObject from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelObject';
import { ClaimsTravelAjaxRoutes } from '@/Apps/ClaimsTravel/Enums/ClaimsTravelAjaxRoutes';
import Sanitizer from '@/Services/sanitizer.service';
import AppWhiteboard from '@/Components/Containers/Whiteboard/Whiteboard.vue';
import AppButtonPrimary from '@/Components/Buttons/ButtonPrimary/ButtonPrimary.vue';
import AppSvg from '@/Components/Other/Svg/Svg.vue';
import AppInputError from '@/Components/Inputs/InputError/InputError.vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppInputRadio from '@/Components/Inputs/InputRadio/InputRadio.vue';

const props = defineProps({
    subType: { type: String, required: true },
});

const emit = defineEmits<{
    (event: 'completed'): void;
}>();

const { translateForType, applyTranslationType, type, translate } = useTranslate();

const claimsTravelService: ClaimsTravelService = ClaimsTravelService.getInstance();

type FormNames = `${InjuryFormNames}`;

const InjurySubType = 'injury';
const HintIconHtml =
    '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M6.86001 3.07347L1.21335 12.5001C1.09693 12.7018 1.03533 12.9303 1.03467 13.1631C1.03402 13.396 1.09434 13.6249 1.20963 13.8272C1.32492 14.0294 1.49116 14.198 1.69182 14.316C1.89247 14.4341 2.12055 14.4976 2.35335 14.5001H13.6467C13.8795 14.4976 14.1076 14.4341 14.3082 14.316C14.5089 14.198 14.6751 14.0294 14.7904 13.8272C14.9057 13.6249 14.966 13.396 14.9654 13.1631C14.9647 12.9303 14.9031 12.7018 14.7867 12.5001L9.14001 3.07347C9.02117 2.87754 8.85383 2.71555 8.65414 2.60313C8.45446 2.4907 8.22917 2.43164 8.00001 2.43164C7.77086 2.43164 7.54557 2.4907 7.34588 2.60313C7.1462 2.71555 6.97886 2.87754 6.86001 3.07347V3.07347Z" stroke="#1D1F23" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M8 6.5V9.16667" stroke="#1D1F23" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M8 11.8335H8.00667" stroke="#1D1F23" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>' +
    translated('leave_field_empty_if_no_expenses');

const forms: ShallowUnwrapRef<{ [FormKey in keyof InjuryForms]: Form<InjuryForms[FormKey]> }> = shallowReactive(
    {},
) as ShallowUnwrapRef<{
    [FormKey in keyof InjuryForms]: Form<InjuryForms[FormKey]>;
}>;

const inputOptions: UnwrapNestedRefs<Record<FormNames, Option>> = reactive({}) as UnwrapNestedRefs<
    Record<FormNames, Option>
>;

const selectedPolicy: Ref<ClaimsTravelObject> = computed(() => {
    return claimsTravelService.storageFields.personInsuredObjects.find(
        (object) => object.id === claimsTravelService.fields.policy?.policy.object,
    )!;
});

const policyInsuranceTerms: Ref<string> = computed(() => {
    return selectedPolicy.value.insuranceTerms as string;
});

const riskIc: Ref<string> = computed(() => {
    return claimsTravelService.travelTypes.find(
        (travelType) =>
            travelType.travelSubtype.toLowerCase() === claimsTravelService.fields.whatHappened?.whatHappened.selected,
    )!.travelTypeRiskIc;
});

const selectedRiskLimit: Ref<number> = computed(() => {
    return Number(selectedPolicy.value.risks.find((risk) => risk.agrrisk === riskIc.value)!.limit!);
});

const isInjurySubTypeSelected: Ref<boolean> = computed(() => {
    return props.subType === InjurySubType;
});

function preparePanels(): void {
    Object.values(InjuryFormNames).forEach((panelName: FormNames) => {
        inputOptions[panelName] = reactive(
            new (class implements Option {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = claimsTravelService.storageFields.formVisibility[panelName] ?? false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            })(),
        );
    });

    applyFirstPanelVisibility();
    buildHadEhicCardOptions();
}

function applyFirstPanelVisibility(): void {
    const everyInputInvisible = Object.values(InjuryFormNames).every(
        (panelName: FormNames) => !inputOptions[panelName].visible,
    );

    if (everyInputInvisible) {
        inputOptions[
            isInjurySubTypeSelected.value
                ? (InjuryFormNames.Trauma as FormNames)
                : (InjuryFormNames.EhicCard as FormNames)
        ].visible = true;
    }
}

function buildHadEhicCardOptions(): void {
    inputOptions[InjuryFormNames.EhicCard].options = [
        new InputOptionBuilder().setValue(true).setName(translate('btar_yes')).build(),
        new InputOptionBuilder().setValue(false).setName(translate('btar_no')).build(),
    ];
}

function isPanelVisible(panel: FormNames): boolean {
    return inputOptions[panel].visible;
}

function setupForms(): void {
    const requiredValidator = { required: Validation.required };
    const requiredBooleanValidator = { required: (value: boolean | string): boolean => typeof value === 'boolean' };

    const traumaForm: Form<TraumaFields> = new Form({ useValidationV2: true });
    traumaForm.addField(new FormField(FieldNames.Trauma, '', requiredValidator));
    forms[InjuryFormNames.Trauma] = traumaForm;

    const ehicCardForm: Form<EhicCardFields> = new Form({ useValidationV2: true });
    ehicCardForm.addField(new FormField(FieldNames.HadEhicCard, '', requiredBooleanValidator));
    ehicCardForm.addField(
        new FormField(
            FieldNames.MedicalExpensesTotal,
            '',
            {
                inRange: (value: string) => value === '' || Validation.inRange(value, 0, selectedRiskLimit.value),
            },
            Sanitizer.cleanInputNumber,
        ),
    );
    forms[InjuryFormNames.EhicCard] = ehicCardForm;
}

function firstFormName(): string {
    return isInjurySubTypeSelected.value ? InjuryFormNames.Trauma : InjuryFormNames.EhicCard;
}

function scrollToLastForm(): void {
    const lastForm = Object.keys(inputOptions).findLast((form) => inputOptions[form as FormNames].visible);

    useClaimsTravelHtml().scrollToPanel(lastForm ? lastForm : firstFormName());
}

function clearForms(): void {
    Object.keys(forms).forEach((form) => {
        claimsTravelService.storageFields.formVisibility[form as keyof ClaimsTravelFormFields] = false;
        clearForm(form as FormNames);
    });
}

function clearFormsAhead(currentForm: FormNames): void {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);
    const nextFormKeys: FormNames[] = Object.keys(forms).slice(
        currentFormIndex + 1,
        Object.keys(forms).length,
    ) as FormNames[];
    nextFormKeys.forEach((formName: FormNames) => {
        clearForm(formName);
        inputOptions[formName].visible = false;
        claimsTravelService.storageFields.formVisibility[formName] = false;
    });
}

function clearForm(formName: FormNames): void {
    const form: Form<TraumaFields> | Form<EhicCardFields> = forms[formName];
    switch (formName) {
        case InjuryFormNames.Trauma: {
            const traumaForm = form as Form<TraumaFields>;
            traumaForm.field(FieldNames.Trauma).setValue([]);
            break;
        }
        case InjuryFormNames.EhicCard: {
            const ehicCardForm = form as Form<EhicCardFields>;
            ehicCardForm.field(FieldNames.HadEhicCard).setValue('');
            ehicCardForm.field(FieldNames.MedicalExpensesTotal).setValue('');
            break;
        }
    }

    storeForm(formName);
}

function onTraumaProceedClick(): void {
    const form = forms[InjuryFormNames.Trauma];
    form.submitAttempt().then(() => {
        if (form.isValid()) {
            proceedToNextForm(InjuryFormNames.Trauma);
        }
    });
}

function onEhicCardProceedClick(): void {
    const form = forms[InjuryFormNames.EhicCard];
    form.submitAttempt().then(() => {
        if (form.isValid()) {
            emit('completed');
        }
    });
}

function proceedToNextForm(formName: FormNames, nextFormName: FormNames | null = null): void {
    const nextPanel = nextFormName ? nextFormName : findNextFormName(formName);
    if (nextPanel) {
        inputOptions[nextPanel].visible = true;
        claimsTravelService.storageFields.formVisibility[nextPanel] = true;
        useClaimsTravelHtml().scrollToPanel(nextPanel);
    }
}

function findNextFormName(currentForm: FormNames): FormNames | undefined {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);

    return Object.keys(forms).at(currentFormIndex + 1) as FormNames | undefined;
}

function markFormsAsReady(): void {
    Object.values(forms).forEach((form) => form.setReady());
}

function translated(stringId: string, replaceParts?: TranslateReplaceParts): string {
    return translateForType(stringId, type(), replaceParts);
}

function storeForm(formName: FormNames): void {
    return claimsTravelService.storeForm(formName as keyof ClaimsTravelFormFields, forms[formName]);
}

function onFormInputChange(formName: FormNames): void {
    clearFormsAhead(formName);
}

function isActionsVisible(form: FormNames): boolean {
    const next: FormNames | undefined = findNextFormName(form);

    return next !== undefined ? !inputOptions[next].visible : false;
}

onMounted(() => {
    applyTranslationType(ClaimsTravelService.translationType);
    setupForms();
    claimsTravelService.restoreForms(forms);
    markFormsAsReady();
    preparePanels();
});

defineExpose({
    clearForms,
    scrollToLastForm,
});
</script>

<template>
    <app-custom-form
        v-if="forms[InjuryFormNames.Trauma]?.isReady() && isPanelVisible(InjuryFormNames.Trauma)"
        :form="forms[InjuryFormNames.Trauma]"
        class="form"
        @change="storeForm(InjuryFormNames.Trauma)"
    >
        <app-whiteboard
            class="whiteboard"
            :data-type="'whiteboard-2'"
            :data-scroll="InjuryFormNames.Trauma"
            :title="translated('select_damaged_body_parts')"
        >
            <div class="inputs row">
                <div class="input-block">
                    <app-human-body-parts
                        :form-field="forms[InjuryFormNames.Trauma].field(FieldNames.Trauma)"
                        :translation-type="type()"
                        :insurance-terms="policyInsuranceTerms"
                        :risk-ic="riskIc"
                        :fetch-url="ClaimsTravelAjaxRoutes.Injuries"
                        @change="onFormInputChange(InjuryFormNames.Trauma)"
                    ></app-human-body-parts>
                </div>
            </div>
            <app-button-primary v-if="isActionsVisible(InjuryFormNames.Trauma)" @click="onTraumaProceedClick()">
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
    <app-custom-form
        v-if="forms[InjuryFormNames.EhicCard]?.isReady() && isPanelVisible(InjuryFormNames.EhicCard)"
        :form="forms[InjuryFormNames.EhicCard]"
        class="form"
        @change="storeForm(InjuryFormNames.EhicCard)"
    >
        <app-whiteboard
            class="whiteboard"
            :data-type="'whiteboard-3'"
            :data-scroll="InjuryFormNames.EhicCard"
            :title="translated('did_you_have_ehic_card')"
        >
            <div class="inputs row">
                <div class="input-block">
                    <div class="input-radio-container mobile-full">
                        <app-input-radio
                            :form-field="forms[InjuryFormNames.EhicCard].field(FieldNames.HadEhicCard)"
                            :options="inputOptions[InjuryFormNames.EhicCard].options"
                            :type="'with-circle'"
                            @change="onFormInputChange(InjuryFormNames.EhicCard)"
                        ></app-input-radio>
                    </div>
                </div>
            </div>
            <div
                v-if="forms[InjuryFormNames.EhicCard].field(FieldNames.HadEhicCard).value !== ''"
                class="inputs row medical-expenses"
            >
                <div class="input-block">
                    <h5 class="title">{{ translated('total_medical_expenses') }}</h5>
                    <app-input-text
                        :placeholder="translated('total_medical_expenses_placeholder')"
                        :inside-label="'EUR'"
                        :inside-label-class="'clean'"
                        :hint-label="HintIconHtml"
                        :form-field="forms[InjuryFormNames.EhicCard].field(FieldNames.MedicalExpensesTotal)"
                        @change="onFormInputChange(InjuryFormNames.EhicCard)"
                    >
                        <template #error>
                            <app-input-error>
                                {{ translated('limit_error_out_of_range', { '%limit%': selectedRiskLimit }) }}
                            </app-input-error>
                        </template>
                    </app-input-text>
                </div>
            </div>
            <app-button-primary @click="onEhicCardProceedClick()">
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
</template>

<style scoped lang="scss">
.form {
    .button {
        padding: 0 var(--size-medium);

        .icon-right {
            margin-left: var(--size-nano);
        }
    }

    :deep(#medicalExpensesTotal.error .hint-label) {
        display: none;
    }

    :deep(#medicalExpensesTotal .hint-label) {
        position: static;
        margin-top: var(--size-pico);
    }

    :deep(#medicalExpensesTotal) {
        width: 50%;

        @include respond-below('sm') {
            width: 100%;
        }
    }

    .button-with-callback {
        height: 52px;
    }

    .input-radio-container {
        width: 50%;

        :deep(.input-radio .buttons) {
            gap: var(--size-small);

            .button {
                min-height: 64px;
            }
        }

        &.mobile-full {
            @include respond-below('sm') {
                width: 100%;
            }
        }
    }

    .medical-expenses {
        margin: var(--size-medium) 0 0 0;
    }

    :deep(.hint-label) {
        display: flex;
        gap: 10px;
        color: var(--text-color-subtlest);
        font-size: var(--font-size-nano);
        font-style: normal;
        align-items: center;
        font-weight: 500;
    }

    .one-button {
        margin-top: var(--size-big);
    }

    .title {
        margin-bottom: var(--size-small);
    }
}

:deep(.single-selector) {
    > .title {
        font-size: var(--font-size-tiny);
        margin-top: 2px !important;
    }
}
</style>
