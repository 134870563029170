<script setup lang="ts">
import { computed, ComputedRef, onMounted, PropType, ref, Ref, watch, nextTick } from 'vue';
import InputTagIcon from '@/Components/Inputs/InputText/InputTagIcon';
import FormField from '@/Assets/Libraries/Form/FormField';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

const props = defineProps({
    formField: { type: FormField<string>, default: () => new FormField('') },
    maxLength: { type: Number, default: 9999 },
    insideLabel: { type: String, default: '' },
    insideLabelClass: { type: String, default: '' },
    leftIcon: { type: Object as PropType<InputTagIcon>, default: null },
    rightIcon: { type: Object as PropType<InputTagIcon>, default: null },
    disabled: { type: Boolean, default: false },
    hidden: { type: Boolean, default: false },
    hintLabel: { type: String, default: '' },
    locked: { type: Boolean, default: false },
    isLockable: { type: Boolean, default: false },
    tabFocusesTooltipAfterField: { type: Boolean, default: false },
    labelUnder: { type: Boolean, default: false },
    onlyUpperCase: { type: Boolean, default: false },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    ariaLabel: { type: String, default: '' },
    autocomplete: { type: String, default: '' },
    dataStoreDisabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    description: { type: String, default: '' },
});

const emit = defineEmits([
    'keyup',
    'keypress',
    'blur',
    'change',
    'lock-change',
    'left-icon-click',
    'right-icon-click',
    'input',
]);

const isLocked: Ref<boolean> = ref(false);
const isDisabled: ComputedRef<boolean> = computed(() =>
    props.isLockable ? isLocked.value || props.disabled : props.disabled,
);
const fieldId: ComputedRef<string> = computed(() => props.formField.name + '-text');
const userMadeInput: Ref<boolean> = ref(false);
const input: Ref<HTMLInputElement | null> = ref(null);

watch(
    () => props.formField.value,
    (newValue, oldValue) => {
        if (!props.isLockable) {
            emit('change', newValue, oldValue);
        }
    },
);
watch(
    () => props.locked,
    (newValue) => {
        isLocked.value = newValue;
    },
);

watch(
    () => isLocked.value,
    (newValue) => {
        emit('lock-change', newValue);
    },
);

onMounted(() => {
    isLocked.value = props.locked;
    nextTick(() => {
        emit('lock-change', props.isLockable ? isLocked.value : false);
    });
});

function onInput(): void {
    userMadeInput.value = true;
    format();
    emit('input', props.formField.value);
}

function onKeyUp(): void {
    emit('keyup', props.formField.value);
}

function onKeyPress(): void {
    emit('keypress', props.formField.value);
}

function onChange(): void {
    emit('change', props.formField.value);
}

function onBlur(): void {
    if (userMadeInput.value) {
        props.formField.markAsDirty();
    }
    props.formField.markAsTouched();
    emit('blur', props.formField.value);
}

function enable(): void {
    isLocked.value = false;
    userMadeInput.value = true;
    props.formField.setValue('');
    props.formField.markAsUntouched();
    props.formField.markAsPristine();
    nextTick(() => {
        input.value?.focus();
    });
    emit('keypress', props.formField.value);
}

function lock(): void {
    isLocked.value = true;
}

function iconSrc(name: string): string {
    return name !== '' ? `images/one/components/input-text/${name}_black.svg` : '';
}

function onLeftIconClick(): void {
    emit('left-icon-click');
}

function onRightIconClick(): void {
    emit('right-icon-click');
}

function addLabelUnderInput(): boolean {
    return (
        (props.labelUnder && !props.tabFocusesTooltipAfterField) ||
        (!props.labelUnder && props.tabFocusesTooltipAfterField)
    );
}

function format(): void {
    if (props.onlyUpperCase) {
        (props as DynamicDictionary).formField.value = props.formField.value.toUpperCase();
    }
}

defineExpose({
    lock,
});
</script>

<template>
    <div
        :id="formField.name"
        class="input input-text"
        :class="{
            ...formField.classes(),
            'disabled': isDisabled,
            'hidden': hidden,
            'tab-focuses-tooltip-after-field': tabFocusesTooltipAfterField,
        }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : formField.value"
    >
        <div v-if="label && !addLabelUnderInput()" class="label informative">
            <label :for="fieldId" :name="'form-field-label'">
                {{ label }}&nbsp;<span v-if="required" class="asterisk">&#42;</span>
            </label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper" :class="{ 'padding-left': leftIcon, 'padding-right': rightIcon }">
            <button v-if="leftIcon" class="inside-icon left" :class="leftIcon.cssClass" @click="onLeftIconClick">
                <img class="icon" alt="" width="24" height="24" :src="iconSrc(leftIcon.name)" />
            </button>
            <input
                :id="fieldId"
                ref="input"
                v-model="formField.value"
                type="text"
                :name="formField.name"
                :disabled="isDisabled"
                :hidden="hidden"
                :class="{ 'with-inside-label': insideLabel !== '' }"
                :placeholder="placeholder"
                :maxlength="maxLength"
                :autocomplete="autocomplete"
                :aria-label="ariaLabel && !label ? ariaLabel : undefined"
                :data-type="'inputField'"
                @input="onInput"
                @change="onChange"
                @keyup="onKeyUp"
                @keypress="onKeyPress"
                @blur="onBlur"
            />
            <div v-if="insideLabel" class="inside-label" :class="insideLabelClass" v-html="insideLabel"></div>
            <div v-if="hintLabel" class="hint-label" v-html="hintLabel"></div>
            <div v-if="formField.classes().error" class="error-container">
                <slot name="error"></slot>
            </div>
            <div v-if="$slots.hint && !formField.classes().error" class="hint-container">
                <slot name="hint"></slot>
            </div>
            <div v-if="isLocked && isLockable" class="enabler">
                <svg
                    :id="formField.name + '-enable'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="enable"
                >
                    <g opacity="0.48">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.7071 1.29289C14.3166 0.902369 13.6834 0.902369 13.2929 1.29289L2.29289 12.2929C2.10536 12.4804 2 12.7348 2 13V17C2 17.5523 2.44772 18 3 18H7C7.26522 18 7.51957 17.8946 7.70711 17.7071L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289L14.7071 1.29289ZM4 16V13.4142L14 3.41421L16.5858 6L6.58579 16H4Z"
                            fill="#9297A0"
                        />
                        <path
                            d="M3 21C2.44772 21 2 21.4477 2 22C2 22.5523 2.44772 23 3 23H21C21.5523 23 22 22.5523 22 22C22 21.4477 21.5523 21 21 21H3Z"
                            fill="#9297A0"
                        />
                    </g>
                </svg>
            </div>
            <button v-if="rightIcon" class="inside-icon right" :class="rightIcon.cssClass" @click="onRightIconClick">
                <img class="icon" alt="" width="24" height="24" :src="iconSrc(rightIcon.name)" />
            </button>
        </div>
        <div v-if="label && addLabelUnderInput()" class="label informative">
            <label :for="fieldId"> {{ label }}&nbsp;<span v-if="required" class="asterisk">&#42;</span> </label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div v-if="!addLabelUnderInput() && props.description !== ''" class="description">
            <img class="icon" src="images/one/caution.svg" width="16" height="16" alt="" />
            {{ props.description }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-text {
    scroll-margin-top: 4em;

    &.hidden {
        display: none;
    }

    &.tab-focuses-tooltip-after-field {
        flex-direction: column-reverse;
    }

    .inside-label {
        height: 52px;
        width: 60px;
        justify-content: center;
        align-items: center;
        position: absolute;
        display: flex;
        top: 0;
        right: 0;

        &:not(.clean)::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: var(--black-200);
        }

        &.clean {
            color: var(--component-color-text-placeholder);
            font-weight: 500;
        }
    }

    .wrapper {
        &.padding-left {
            input {
                padding-left: 49px;
            }
        }

        &.padding-right {
            input {
                padding-right: 44px;
            }
        }

        .enabler {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;

            svg {
                width: 20px;
                height: 20px;

                &:hover {
                    path {
                        fill: var(--text-color-default);
                    }
                }
            }
        }

        .error-container,
        .hint-container {
            margin-top: var(--size-pico);

            &:empty {
                display: none;
            }
        }
    }

    .description {
        display: flex;
        align-items: center;
        margin-top: var(--size-pico);
        font-size: var(--font-size-nano);
        color: var(--text-color-subtlest);
        font-weight: 500;

        .icon {
            margin-right: var(--size-nano);
        }
    }
}

input.disabled {
    color: var(--component-color-text-disabled);
    border-color: transparent;
}

.with-inside-label {
    padding: 0 58px 0 20px;
}

.hint-label {
    color: var(--black-500);
    font-size: var(--font-size-pico);
    font-style: italic;
    position: absolute;
    bottom: -24px;
    left: 6px;
}

.inside-icon {
    position: absolute;
    height: 100%;

    &.left {
        left: 16px;
    }

    &.right {
        right: 16px;
    }

    &.black-to-grey {
        filter: var(--to-color-color-grey);

        &:hover {
            filter: none;
        }
    }

    &.black-to-green {
        filter: var(--to-color-green);

        &:hover {
            filter: none;
        }
    }

    &.black-to-red {
        filter: var(--to-color-red);

        &:hover {
            filter: none;
        }
    }

    &.input-reset {
        filter: var(--to-color-color-grey);
        width: 16px;
        padding-top: 6px;

        &:hover {
            filter: none;
        }
    }
}
</style>
