<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import OneBaseService from '@/Services/OneBaseService';
import {
    computed,
    getCurrentInstance,
    onMounted,
    reactive,
    ref,
    Ref,
    shallowReactive,
    ShallowUnwrapRef,
    UnwrapNestedRefs,
} from 'vue';
import SpaUserStorage from '@/Services/SpaUserStorageService';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import GuardsService from '@/Apps/ClaimsTravel/Services/GuardsService';
import { useClaimsTravelHtml } from '@/Apps/ClaimsTravel/Composables/ClaimsTravelHtml';
import ClaimPage from '@/Components/Other/Page/ClaimPage.vue';
import AppPersonDetails from '@/Components/Widgets/PersonDetails/PersonDetails.vue';
import Form from '@/Assets/Libraries/Form/Form';
import FormField from '@/Assets/Libraries/Form/FormField';
import {
    Forms,
    PossibleCompensationFields,
    RecipientDataFields,
} from '@/Apps/ClaimsTravel/Interfaces/Forms/RecipientData/Forms';
import { FormNames as RecipientDataForms } from '@/Apps/ClaimsTravel/Enums/Steps/RecipientData/FormNames';
import { FieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/RecipientData/FieldNames';
import OneBase from '@/Interfaces/OneBaseInterface';
import PersonDetails from '@/Interfaces/PersonDetailsInterface';
import AppWhiteboard from '@/Components/Containers/Whiteboard/Whiteboard.vue';
import AppPersonBankDetails from '@/Components/Widgets/PersonBankDetails/PersonBankDetails.vue';
import AppPersonContacts from '@/Components/Widgets/PersonContacts/PersonContacts.vue';
import AppInputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import AppNotification from '@/Components/Widgets/Notification/Notification.vue';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppOptionsSmartList from '@/Components/Options/OptionsSmartList/OptionsSmartList.vue';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import Option from '@/Apps/ClaimsTravel/Interfaces/Option';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import ClaimsTravelFormFields from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelFormFields';
import CompensationTable from '@/Components/Other/CompensationTable/CompensationTable.vue';
import { ReimbursableItem } from '@/Components/Other/CompensationTable/Interfaces/ReimbursableItem';
import { FieldNames as InjuryFieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Injury/FieldNames';
import { FieldNames as OtherFieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Other/FieldNames';
import { FieldNames as TravelChangesFieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/TravelChanges/FieldNames';
import { FieldNames as TravelCancellationFieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Cancellation/FieldNames';
import { useDefine } from '@/Composables/Define';
import { useAxios } from '@/Composables/Axios';
import { AxiosResponse } from 'axios';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { ClaimsTravelAjaxRoutes } from '@/Apps/ClaimsTravel/Enums/ClaimsTravelAjaxRoutes';
import ErrorType from '@/Enums/ErrorTypeEnum';
import PopupService from '@/Services/custom.popup.service';
import ClaimsTravelObject from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelObject';
import AppSvg from '@/Components/Other/Svg/Svg.vue';
import AppButtonPrimary from '@/Components/Buttons/ButtonPrimary/ButtonPrimary.vue';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import { SufferingPersonOtherFields } from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentData/Forms';
import UserCredentials from '@/Interfaces/user.credentials.interface';
import { useScroll } from '@/Composables/Scroll';
import FormTransformerService, {
    FilledClaimsTravelFormFields,
} from '@/Apps/ClaimsTravel/Services/FormTransformerService';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import { useNavigate } from '@/Composables/Navigate';
type FormNames = `${RecipientDataForms}`;

const { translateForType, applyTranslationType, type } = useTranslate();
const router: Router = useRouter();
const { scrollToPanel } = useClaimsTravelHtml();

const btaBase: OneBase = OneBaseService.getInstance();
const claimsTravelService: ClaimsTravelService = ClaimsTravelService.getInstance();
const spaUserStorage: SpaUserStorage = SpaUserStorage.getInstance();
const injuryData = ref([]);
const personFromPolicy = ref<PersonDetails>() as Ref<PersonDetails>;

const Step: number = 4;

const forms: ShallowUnwrapRef<{ [FormKey in keyof Forms]: Form<Forms[FormKey]> }> = shallowReactive(
    {},
) as ShallowUnwrapRef<{
    [FormKey in keyof Forms]: Form<Forms[FormKey]>;
}>;

const inputOptions: UnwrapNestedRefs<Record<FormNames, Option>> = reactive({}) as UnwrapNestedRefs<
    Record<FormNames, Option>
>;

const canProceedToNextStep: Ref<boolean> = computed(() => {
    return true;
});

const selectedPolicy: Ref<ClaimsTravelObject> = computed(() => {
    return claimsTravelService.storageFields.personInsuredObjects.find(
        (object) => object.id === claimsTravelService.fields.policy?.policy.object,
    )!;
});

const riskIc: Ref<string> = computed(() => {
    return claimsTravelService.travelTypes.find(
        (travelType) =>
            travelType.travelSubtype.toLowerCase() === claimsTravelService.fields.whatHappened?.whatHappened.selected,
    )!.travelTypeRiskIc;
});

const selectedRiskLimit: Ref<number> = computed(() => {
    return Number(selectedPolicy.value.risks.find((risk) => risk.agrrisk === riskIc.value)!.limit!);
});

const isInjuredPersonAuthenticatedUser: Ref<boolean> = computed(() => {
    return claimsTravelService.fields.sufferingPerson?.sufferingPerson.selected === btaBase.currentUser.value.personId;
});

const isAuthenticatedUser: Ref<boolean> = computed(() => {
    return forms[RecipientDataForms.RecipientData].field(FieldNames.PersonDetails).value.isAuthenticated;
});

const totalExpenses: Ref<number> = computed(() => {
    let total: number = 0;
    [...allInjuries(), ...allExpenses()].map((value) => {
        total += parseFloat(value.price);
    });

    return total;
});

const providedPerson: Ref<PersonDetails> = computed(() => {
    const selectedPerson: string = claimsTravelService.fields.sufferingPerson?.sufferingPerson.selected ?? '';
    let person: PersonDetails;

    switch (selectedPerson) {
        case 'other':
            person = otherPersonDetails();
            break;
        case btaBase.currentUser.value.personId:
            person = currentUserDetails();
            break;
        default:
            person = personFromPolicy.value;
    }

    return person;
});

function setupForms(): void {
    const recipientDateForm: Form<RecipientDataFields> = new Form({ useValidationV2: true });
    recipientDateForm.addField(new FormField(FieldNames.PersonDetails));
    recipientDateForm.addField(
        new FormField(FieldNames.PersonContacts, {
            email: '',
            phone: {
                code: '',
                iso: '',
                country: '',
                phone: '',
            },
        }),
    );
    recipientDateForm.addField(new FormField(FieldNames.PersonBankDetails));
    recipientDateForm.addField(new FormField(FieldNames.CheckboxTerms));
    recipientDateForm.addField(new FormField(FieldNames.CheckboxCertify));
    forms[RecipientDataForms.RecipientData] = recipientDateForm;

    const possibleCompensationForm: Form<PossibleCompensationFields> = new Form({ useValidationV2: true });
    possibleCompensationForm.addField(new FormField(FieldNames.AgreeToCompensation));
    forms[RecipientDataForms.PossibleCompensation] = possibleCompensationForm;
}

function preparePanels(): void {
    Object.values(RecipientDataForms).forEach((panelName: FormNames) => {
        inputOptions[panelName] = reactive(
            new (class implements Option {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = claimsTravelService.storageFields.formVisibility[panelName] ?? false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            })(),
        );
    });
    buildProposalOptions();
}

function onPersonDetailsProceed(): void {
    const form = forms[RecipientDataForms.RecipientData];
    form.submitAttempt().then(() => {
        if (form.isValid()) {
            if (totalExpenses.value <= TransferStateService.getInstance().get<number>('maxSettlementAmount')) {
                inputOptions[RecipientDataForms.PossibleCompensation].visible = true;
                useClaimsTravelHtml().scrollToPanel(RecipientDataForms.PossibleCompensation);
            } else {
                submitClaim();
            }
        } else {
            useScroll().scrollInvalidFieldToView(form);
        }
    });
}

function buildProposalOptions(): void {
    inputOptions[RecipientDataForms.PossibleCompensation].options = [
        new InputOptionBuilder<string>().setValue('agree').setName(translated('agree')).build(),
        new InputOptionBuilder<string>().setValue('disagree').setName(translated('disagree')).build(),
    ];
}

function isPanelVisible(panel: FormNames): boolean {
    return inputOptions[panel].visible;
}

function markFormsAsReady(): void {
    Object.values(forms).forEach((form) => form.setReady());
}

function storeForm(formName: FormNames): void {
    return claimsTravelService.storeForm(formName as keyof ClaimsTravelFormFields, forms[formName]);
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

function otherPersonDetails(): PersonDetails {
    const other: SufferingPersonOtherFields | undefined = claimsTravelService.fields.sufferingPersonOther;
    return {
        isAuthenticated: false,
        isPolicyHolder: false,
        isResident: other!.sufferingPersonOtherIsResident,
        name: other!.sufferingPersonOtherName,
        surname: other!.sufferingPersonOtherSurname,
        personCode: other!.sufferingPersonOtherPersonCode,
        country: {
            iso: '',
            phoneCode: '',
            ic: '',
        },
        birthDate: other?.sufferingPersonOtherBirthDate.toString() ?? '',
    };
}

function currentUserDetails(): PersonDetails {
    const user: UserCredentials = btaBase.currentUser.value;
    return {
        isAuthenticated: true,
        isPolicyHolder: true,
        isResident: user.resident,
        name: user.firstname,
        surname: user.lastname,
        personCode: user.personCode,
        country: {
            iso: user.code,
            phoneCode: user.personCode,
            ic: '100000000',
        },
        birthDate: user.birthDate ?? '',
    };
}

function fetchPersonFromPolicies(): void {
    const params: Record<string, string> = {
        personId: claimsTravelService.fields.sufferingPerson?.sufferingPerson.selected ?? '',
    };
    useAxios()
        .get(ClaimsTravelAjaxRoutes.PersonData, { params })
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            if (useDefine().validResponse(response)) {
                const data = response.data.data.body.personData;
                const person = {
                    isAuthenticated: false,
                    isPolicyHolder: false,
                    isResident: data.resident,
                    name: data.firstname,
                    surname: data.lastname,
                    personCode: data.personCode,
                    country: {
                        iso: data.resident ? new AppCountry().iso() : '',
                        phoneCode: data.phoneCode,
                        ic: '',
                    },
                    birthDate: data.birthDate,
                } as PersonDetails;
                claimsTravelService.storageFields.personFromPolicy = person;
                personFromPolicy.value = person;
            }
        })
        .catch((reason: LimitedVariant): void => {
            btaBase.error.show(ErrorType.Error, 'uploadCategories', reason as DynamicDictionary);
        })
        .finally((): void => {
            PopupService.getInstance().hide().then();
        });
}

function fetchInjuryData(): void {
    const injuryKeys: string[] = claimsTravelService.fields.trauma?.trauma.map((injury) => injury.name) || [];
    const params: Record<string, number | string | string[]> = {
        injuryKey: injuryKeys,
        riskIc: riskIc.value,
    };
    if (injuryKeys.length > 0) {
        useAxios()
            .get(ClaimsTravelAjaxRoutes.InjuryData, { params })
            .then((response: AxiosResponse<DynamicDictionary>): void => {
                if (useDefine().validResponse(response)) {
                    injuryData.value = response.data.data.body.injuryInfo;
                }
            })
            .catch((reason: LimitedVariant): void => {
                btaBase.error.show(ErrorType.Error, 'uploadCategories', reason as DynamicDictionary);
            })
            .finally((): void => {
                PopupService.getInstance().hide().then();
            });
    }
}

function allInjuries() {
    const result: ReimbursableItem[] = [];
    if (useDefine().isSet(claimsTravelService.fields.trauma?.trauma)) {
        claimsTravelService.fields.trauma?.trauma.forEach((trauma) => {
            const data: DynamicDictionary | undefined = specificInjuryData(trauma.name);
            if (data !== undefined) {
                result.push({
                    name: trauma.name,
                    price: calculateCompensation(selectedRiskLimit.value, data.percentage).toString(),
                    description: useTranslate().translateForType('info_text_compensation', 'claims_accident', {
                        '%insuranceterms%': data.terms_ic,
                        '%table%': data.table,
                        '%point%': data.point,
                        '%percent%': data.percentage,
                        '%limit%': selectedRiskLimit.value,
                        '%subriskText%': data.injury_key,
                    }),
                });
            }
        });
    }

    return result;
}

function allExpenses() {
    const expensesFields = [
        { [InjuryFieldNames.MedicalExpensesTotal]: claimsTravelService.fields.ehicCard?.medicalExpensesTotal },
        {
            [TravelChangesFieldNames.TotalExpensesBecauseOfDelay]:
                claimsTravelService.fields.hasCarriersTripCancellationConfirmation?.totalExpensesBecauseOfDelay,
        },
        {
            [TravelChangesFieldNames.TripCancellationRelatedLoses]:
                claimsTravelService.fields.hadIndemnificationOfLoses?.tripCancellationRelatedLoses,
        },
        { [TravelCancellationFieldNames.LossesSum]: claimsTravelService.fields.indemnification?.lossesSum },
        { [OtherFieldNames.ClaimAmount]: claimsTravelService.fields.claimAmount?.claimAmount },
    ];
    const data: ReimbursableItem[] = [];
    expensesFields.forEach((field) => {
        const [key, value] = Object.entries(field)[0];
        if (useDefine().isSet(value)) {
            data.push({
                name: key,
                price: value ?? '',
            });
        }
    });

    return data;
}

function clearPossibleCompensation(): void {
    const compensationForm: Form<PossibleCompensationFields> = forms[
        RecipientDataForms.PossibleCompensation
    ] as Form<PossibleCompensationFields>;
    compensationForm.field(FieldNames.AgreeToCompensation).setValue({ selected: '', extra: '' });
    if (inputOptions[RecipientDataForms.PossibleCompensation].visible) {
        useClaimsTravelHtml().scrollToPanel(RecipientDataForms.RecipientData);
    }
    inputOptions[RecipientDataForms.PossibleCompensation].visible = false;
}

function specificInjuryData(key: string): DynamicDictionary | undefined {
    return injuryData.value.find((injury: DynamicDictionary) => {
        return injury.injury_key === key;
    });
}

function calculateCompensation(insuredSum: number, percent: number): number {
    return (insuredSum * percent) / 100;
}

function submitClaim(): void {
    useAxios()
        .post(
            ClaimsTravelAjaxRoutes.Submit,
            FormTransformerService.getInstance().transform(claimsTravelService.fields as FilledClaimsTravelFormFields),
        )
        .then(() => useNavigate().navigate(claimsTravelService.thankYouStepUrl))
        .catch((error) => {
            btaBase.error.show(ErrorType.Error, 'submitClaim', error as DynamicDictionary);
        });
}

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    applyTranslationType('claims_travel');
    fetchInjuryData();
    if (!isInjuredPersonAuthenticatedUser.value) {
        fetchPersonFromPolicies();
    }
    setupForms();
    claimsTravelService.restoreForms(forms);
    setTimeout(() => {
        markFormsAsReady();
        preparePanels();
    });
    spaUserStorage.form.validate().then((): void => {
        GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value);
    });
});

defineExpose({ forms });
</script>

<template>
    <claim-page class="horizontal-spacing">
        <template #title>
            <h3>{{ translated('recipient_data') }}</h3>
        </template>
        <app-custom-form
            v-if="forms[RecipientDataForms.RecipientData]?.isReady()"
            :form="forms[RecipientDataForms.RecipientData]"
            class="form"
            @change="storeForm(RecipientDataForms.RecipientData)"
        >
            <app-whiteboard
                :data-type="'whiteboard-0'"
                :data-scroll="RecipientDataForms.RecipientData"
                :title="translated('when_will_receive_money')"
            >
                <app-person-details
                    v-if="forms[RecipientDataForms.RecipientData]?.isReady()"
                    :form-field="forms[RecipientDataForms.RecipientData].field(FieldNames.PersonDetails)"
                    :provided-person="providedPerson"
                    :translation-type="type()"
                    :show-authenticated-option="!isInjuredPersonAuthenticatedUser"
                    @change="clearPossibleCompensation()"
                >
                    <template #other-person-title>
                        <h2 class="heading-5">{{ translated('other_person_title') }}</h2>
                    </template>
                    <template #contacts>
                        <app-person-contacts
                            :form-field="forms[RecipientDataForms.RecipientData].field(FieldNames.PersonContacts)"
                            :authenticated="isAuthenticatedUser"
                            :translation-type="type()"
                            @change="clearPossibleCompensation()"
                        >
                        </app-person-contacts>
                    </template>
                    <template #above-bank>
                        <app-notification
                            :variant="'warning'"
                            :with-body="false"
                            :with-close="false"
                            :title="translated('warning_bank_account_indemnity_receiver')"
                        ></app-notification>
                    </template>
                    <template #bank>
                        <app-person-bank-details
                            :bank-account-currency="['EUR', 'USD']"
                            :form-field="forms[RecipientDataForms.RecipientData].field(FieldNames.PersonBankDetails)"
                            :authenticated="isAuthenticatedUser"
                            :translation-type="type()"
                            @change="clearPossibleCompensation()"
                        >
                        </app-person-bank-details>
                    </template>
                    <app-input-checkbox
                        class="column-span-12"
                        :data-store-disabled="true"
                        :form-field="forms[RecipientDataForms.RecipientData].field(FieldNames.CheckboxTerms)"
                        data-type="checkboxTerms"
                        ><span class="basic-text-formatted" v-html="translated('terms_and_conditions')"></span>{{}}
                    </app-input-checkbox>
                    <app-input-checkbox
                        v-if="
                            forms[RecipientDataForms.RecipientData].field(FieldNames.PersonDetails).value.isPolicyHolder
                        "
                        class="column-span-12"
                        :data-store-disabled="true"
                        :form-field="forms[RecipientDataForms.RecipientData].field(FieldNames.CheckboxCertify)"
                        data-type="checkboxCertify"
                        ><span class="basic-text-formatted" v-html="translated('authorized_for_compensation')"></span
                        >{{}}
                    </app-input-checkbox>
                </app-person-details>
                <app-button-primary
                    v-if="!isPanelVisible(RecipientDataForms.PossibleCompensation)"
                    @click="onPersonDetailsProceed()"
                >
                    <template #end>
                        <app-svg src="images/one/arrow-right.svg"></app-svg>
                    </template>
                    {{ translated('proceed') }}
                </app-button-primary>
            </app-whiteboard>
        </app-custom-form>
        <app-custom-form
            v-if="
                forms[RecipientDataForms.PossibleCompensation]?.isReady() &&
                isPanelVisible(RecipientDataForms.PossibleCompensation)
            "
            :form="forms[RecipientDataForms.PossibleCompensation]"
            class="form"
            @change="storeForm(RecipientDataForms.PossibleCompensation)"
        >
            <app-whiteboard
                :data-type="'whiteboard-1'"
                :data-scroll="RecipientDataForms.PossibleCompensation"
                :title="translated('possible_compensation')"
            >
                <compensation-table
                    :description="translated('possible_compensation_terms')"
                    :translation-type="type()"
                    :injuries="allInjuries()"
                    :expenses="allExpenses()"
                >
                </compensation-table>
                <div class="grid-utility">
                    <h5>{{ translated('agree_with_compensation') }}</h5>
                    <app-options-smart-list
                        :form-field="
                            forms[RecipientDataForms.PossibleCompensation].field(FieldNames.AgreeToCompensation)
                        "
                        :options="inputOptions[RecipientDataForms.PossibleCompensation].options"
                        :option-class="'filled'"
                        :type="'radio'"
                        class="row"
                        :required="true"
                    >
                    </app-options-smart-list>
                    <app-notification
                        :body="translated('info_about_application')"
                        :with-close="false"
                        :with-icon="true"
                        :with-title="false"
                    >
                    </app-notification>
                </div>
                <app-button-primary @click="submitClaim()">
                    <template #end>
                        <app-svg src="images/one/arrow-right.svg"></app-svg>
                    </template>
                    {{ translated('proceed') }}
                </app-button-primary>
            </app-whiteboard>
        </app-custom-form>
    </claim-page>
</template>

<style scoped lang="scss">
.one-button {
    margin-top: var(--size-big);
}

.row {
    display: flex;
    flex-direction: row;
    gap: var(--size-small);

    :deep(.options-smart-list-option) {
        margin-bottom: unset;
    }
}
</style>
