<script lang="ts">
import { defineComponent } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import DataLayerFacilities from '@/Enums/DataLayerFacilitiesEnum';
import VueEvent from '@/Classes/VueEventClass';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const CurrentStep: number = 1;
        const Facility: string = DataLayerFacilities.Contacts;

        function requestCallbackProxy(event: VueEvent): void {
            btaBase.getInTouchModuleContactless.contactsRequestCallback(event);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                requestCallbackProxy,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
    },
});
</script>
