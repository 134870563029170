import Router from '@/Core/Routing/Router';
import VueApp from '@/Core/Routing/VueApp';
import { App } from 'vue';
import AppComponent from '@/Apps/TwoWayCommunication/App.vue';
import GlobalDirectives from '@/Directives/Directives';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Pages/TwoWayCommunication.vue';
import TwoWayCommunicationNew from '@/Apps/TwoWayCommunication/Pages/TwoWayCommunicationNew.vue';
import TwoWayCommunicationSingle from '@/Apps/TwoWayCommunication/Pages/TwoWayCommunicationSingle.vue';
import SelectAccountDropdown from '@/Components/Widgets/SelectAccountDropdown/SelectAccountDropdown.vue';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/Routing/RouteStepper/RouteStepper.vue';
import CustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import LanguageSwitcher from '@/Components/Widgets/LanguageSwitcher/LanguageSwitcher.vue';
import Popup from '@/Components/Popups/Popup/Popup.vue';
import Tooltipster from '@/Components/Tooltips/Tooltipster/Tooltipster.vue';
import Drawer from '@/Components/Modals/Drawer/Drawer.vue';
import { ComponentPublicInstance } from 'vue';
import PersonListWithSearch from '@/Components/Lists/PersonListWithSearch/PersonListWithSearch.vue';
import CommunicationNotificator from '@/Components/TwoWayCommunication/CommunicationNotificator/CommunicationNotificator.vue';
import Message from '@/Components/TwoWayCommunication/Message/Message.vue';
import InfoWithActions from '@/Components/Widgets/InfoWithActions/InfoWithActions.vue';
import ButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import UserMessageCard from '@/Components/TwoWayCommunication/MessageCard/UserMessageCard.vue';
import Badge from '@/Components/Buttons/Badge/Badge.vue';
import InputRadio from '@/Components/Inputs/InputRadio/InputRadio.vue';
import ClaimBlock from '@/Components/Claims/ClaimBlock/ClaimBlock.vue';
import NewMessage from '@/Components/TwoWayCommunication/NewMessage/NewMessage.vue';
import InputTextarea from '@/Components/Inputs/InputTextarea/InputTextarea.vue';
import ContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import FilesUploader from '@/Components/Files/FilesUploader/FilesUploader.vue';
import CompactFileUploader from '@/Components/Files/CompactFileUploader/CompactFileUploader.vue';
import ProcessInnerToaster from '@/Components/Tooltips/ProcessInnerToaster/ProcessInnerToaster.vue';
import PolicyBlock from '@/Components/Policies/PolicyBlock/PolicyBlock.vue';
import ResultMessage from '@/Components/Other/ResultMessage/ResultMessage.vue';
import AppButtonsBeltWithDigits from '@/Components/Lists/ButtonsBeltWithDigits/ButtonsBeltWithDigits.vue';
import ClaimPage from '@/Components/Other/Page/ClaimPage.vue';
import CommonComponents from '@/Apps/CommonComponents';

const pages: Components = {
    TwoWayCommunication: TwoWayCommunication,
    TwoWayCommunicationNew: TwoWayCommunicationNew,
    TwoWayCommunicationSingle: TwoWayCommunicationSingle,
};

const components: Components = {
    AppCommunicationNotificator: CommunicationNotificator,
    AppSelectAccountDropdown: SelectAccountDropdown,
    AppPersonListWithSearch: PersonListWithSearch,
    AppCustomForm: CustomForm,
    AppLanguageSwitcher: LanguageSwitcher,
    AppPopup: Popup,
    AppTooltipster: Tooltipster,
    AppStepperLayout: StepperLayout,
    AppDrawer: Drawer,
    AppRouteStepper: RouteStepper,
    AppMessage: Message,
    AppInfoWithActions: InfoWithActions,
    AppButtonWithCallback: ButtonWithCallback,
    AppUserMessageCard: UserMessageCard,
    AppBadge: Badge,
    AppInputRadio: InputRadio,
    AppClaimBlock: ClaimBlock,
    AppPolicyBlock: PolicyBlock,
    AppNewMessage: NewMessage,
    AppInputTextarea: InputTextarea,
    AppCompactFileUploader: CompactFileUploader,
    AppFilesUploader: FilesUploader,
    AppProcessInnerToaster: ProcessInnerToaster,
    AppContentLoader: ContentLoader,
    AppResultMessage: ResultMessage,
    AppButtonsBeltWithDigits: AppButtonsBeltWithDigits,
    ClaimPage: ClaimPage,
};

export default class {
    public app(selector: string, routes: BackendRoute[]): ComponentPublicInstance {
        const app: App = new VueApp(AppComponent)
            .withComponents({ ...pages, ...components, ...(CommonComponents.components as Components) })
            .withDirectives(GlobalDirectives)
            .create();
        app.use(new Router(app, routes).create());

        return app.mount(selector);
    }
}
