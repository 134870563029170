<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, PropType, Ref, toRaw } from 'vue';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Validation from '@/Services/validation.service';
import User from '@/Services/user.service';
import Sanitizer from '@/Services/sanitizer.service';
import SettingsService from '@/Services/settings.service';
import { useTranslate } from '@/Composables/Translate';
import { useDefine } from '@/Composables/Define';
import FormField from '@/Assets/Libraries/Form/FormField';
import Form from '@/Assets/Libraries/Form/Form';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import moment from 'moment/moment';
import PersonDetails from '@/Interfaces/PersonDetailsInterface';
import Country from '@/Interfaces/country.interface';
import { CountryComponentParams } from '@/Components/Inputs/InputCountry/CountryComponentParams';
import AppInputIdentityNumber from '@/Components/Inputs/InputIdentityNumber/InputIdentityNumber.vue';
import AppInputCountry from '@/Components/Inputs/InputCountry/InputCountry.vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppInputBirthDate from '@/Components/Inputs/InputBirthDate/InputBirthDate.vue';
import AppInputRadioSelector from '@/Components/Inputs/InputRadioSelector/InputRadioSelector.vue';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import AppInputRadioOverlayed from '@/Components/Inputs/InputRadioOverlayed/InputRadioOverlayed.vue';
import PersonDataType from '@/Components/Widgets/PersonDetails/Enums/PersonDataType.enum';
import OtherPersonType from '@/Components/Widgets/PersonDetails/Enums/OtherPersonType.enum';
import DateFormat from '@/Enums/DateFormatEnum';

const { translateForType } = useTranslate();
const type: string = 'components';

const props = defineProps({
    formField: {
        type: Object as PropType<FormField<PersonDetails>>,
        default: () => new FormField(''),
    },
    translationType: {
        type: String,
        default: () => 'components',
    },
    providedPerson: {
        type: Object as PropType<PersonDetails> | undefined,
        required: false,
        default: undefined,
    },
    providedUserOptionTitle: {
        type: String,
        default: () => 'provided_person',
    },
    authenticatedOptionTitle: {
        type: String,
        default: () => 'authenticated_person',
    },
    otherOptionTitle: {
        type: String,
        default: () => 'other_person',
    },
    residentOptionTitle: {
        type: String,
        default: () => 'resident',
    },
    nonResidentOptionTitle: {
        type: String,
        default: () => 'non_resident',
    },
    showAuthenticatedOption: {
        type: Boolean,
        default: () => true,
    },
    showProvidedOption: {
        type: Boolean,
        default: () => true,
    },
    dontShowOptionsIfAuthenticated: {
        type: Boolean,
        default: () => false,
    },
});

const emit = defineEmits(['change']);

const user: User = User.getInstance();
const country: AppCountry = new AppCountry();
const settingsService: SettingsService = SettingsService.getInstance();

const form: Form<{
    personDataSelector: string;
    residentSelector: string;
    name: string;
    surname: string;
    personCode: string;
    birthDate: string;
    country: CountryComponentParams;
}> = new Form({ useValidationV2: true });
const formChanges = new Subject<void>();
let onSubmitAttemptSubscription: Subscription | undefined;
let formChangeSubscription: Subscription | undefined;
let patchSubscription: Subscription;

const selectedCountry: Ref<string> = computed(() => {
    return isPolicyHolder.value && props.providedPerson?.country?.iso
        ? props.providedPerson.country.iso
        : isResident.value
          ? country.iso()
          : form.field('country').value.iso;
});

const isPolicyHolder: Ref<boolean> = computed(() => {
    return form.field('personDataSelector').value === PersonDataType.Provided;
});

const isResident: Ref<boolean> = computed(() => {
    return form.field('residentSelector').value === OtherPersonType.Resident;
});

const isAuthenticated: Ref<boolean> = computed(() => {
    return form.field('personDataSelector').value === PersonDataType.Authenticated;
});

const isAnonymousNumber: Ref<boolean> = computed(() => {
    return isResident.value && Validation.isValidPersonCodeWithoutDate(form.field('personCode').value);
});

const isLogged: Ref<boolean> = computed(() => {
    return user.isLogged();
});

const needToShowBirthDate: Ref<boolean> = computed(() => {
    return (
        !isAuthenticated.value &&
        (isAnonymousNumber.value || (!isResident.value && !country.isResidentForOneCountries(selectedCountry.value)))
    );
});

const needToShowIsPolicyHolder: Ref<boolean> = computed(() => {
    return (!isLogged.value && props.providedPerson !== undefined) || props.showProvidedOption;
});

const needToShowAuthenticated: Ref<boolean> = computed(() => {
    return isLogged.value && props.showAuthenticatedOption;
});

const needToShowIsResident: Ref<boolean> = computed(() => {
    return !isAuthenticated.value && !isPolicyHolder.value;
});

const shouldShowSelector: Ref<boolean> = computed(() => {
    const hideOptions = props.dontShowOptionsIfAuthenticated && isLogged.value;
    const hasOptions = needToShowIsPolicyHolder.value || needToShowAuthenticated.value;

    return !hideOptions && hasOptions;
});

const needToShowCountry: Ref<boolean> = computed(() => {
    return (
        !isResident.value &&
        !isAuthenticated.value &&
        (!isPolicyHolder.value ||
            !props.providedPerson?.country?.iso ||
            !country.isResidentForOneCountries(props.providedPerson.country.iso))
    );
});

const lockUserFields: Ref<boolean> = computed(() => {
    return (isLogged.value && isAuthenticated.value) || isPolicyHolder.value;
});

const personsDataOptions: Ref<InputOption[]> = computed(() => {
    const result: InputOption[] = [];

    if (needToShowAuthenticated.value) {
        result.push(
            new InputOptionBuilder()
                .setValue(PersonDataType.Authenticated)
                .setName(translated(props.authenticatedOptionTitle))
                .build(),
        );
    }
    if (needToShowIsPolicyHolder.value) {
        result.push(
            new InputOptionBuilder()
                .setValue(PersonDataType.Provided)
                .setName(translated(props.providedUserOptionTitle))
                .build(),
        );
    }

    if (result.length) {
        result.push(
            new InputOptionBuilder().setValue(PersonDataType.Other).setName(translated(props.otherOptionTitle)).build(),
        );
    }

    return result;
});

const residentOption: Ref<InputOption[]> = computed(() => {
    const values: Array<{ value: string; name: string }> = [
        { value: OtherPersonType.Resident, name: translated(props.residentOptionTitle) },
        { value: OtherPersonType.NonResident, name: translated(props.nonResidentOptionTitle) },
    ];

    return values.map(({ value, name }) => new InputOptionBuilder().setValue(value).setName(name).build());
});

onMounted((): void => {
    setupForm();
    prepareFormField();
    setDefaultPersonDataSelector();
    onSubmitAttemptSubscription = props.formField.onSubmitAttempt.subscribe(() => {
        form.submitAttempt();
    });
    formChangeSubscription = formChanges.pipe(debounceTime(1)).subscribe(() => {
        props.formField.setValue({
            isAuthenticated: isAuthenticated.value,
            isPolicyHolder: isPolicyHolder.value,
            isResident: isResident.value,
            name: form.field('name').value || '',
            surname: form.field('surname').value || '',
            personCode: form.field('personCode').value || '',
            birthDate: form.field('birthDate').value || '',
            country: form.field('country').value || '',
        });
        props.formField.markAsTouched();
        emit('change', props.formField.value);
    });
    setDefaultValues();
    patchSubscription = props.formField.onPatch.subscribe(() => {
        restoredFields();
    });
});

onUnmounted((): void => {
    onSubmitAttemptSubscription?.unsubscribe();
    formChangeSubscription?.unsubscribe();
    patchSubscription?.unsubscribe();
});

function nameValidator(): object {
    return {
        nameIsValid: Validation.isValidCaption,
    };
}

function surnameValidator(): object {
    return {
        surnameIsValid: Validation.isValidCaption,
    };
}

function identityNumberValidator(): object {
    return {
        identityNumberIsValid: Validation.isValidCaption,
    };
}

function countryValidator(): object {
    return {
        countryIsValid: (value: string) => {
            let isValid = true;
            if (needToShowCountry.value) {
                const countryValue: Country = toRaw(value) as unknown as Country;
                isValid = useDefine().isSet(countryValue.iso) && countryValue.iso !== '';
            }
            return isValid;
        },
    };
}

function dateValidator(): object {
    return {
        countryIsValid: (value: string) => {
            let result: boolean = false;
            if (needToShowBirthDate.value) {
                const dateFromForm: Date | '' = value as Date | '';
                if (dateFromForm !== '') {
                    result = moment(dateFromForm).isValid();
                }
            } else {
                result = true;
            }
            return result;
        },
    };
}

function setupForm(): void {
    form.addField(new FormField('personDataSelector', ''));
    form.addField(new FormField('residentSelector', ''));
    form.addField(new FormField('name', '', nameValidator(), Sanitizer.cleanName));
    form.addField(new FormField('surname', '', surnameValidator(), Sanitizer.cleanName));
    form.addField(new FormField('personCode', '', identityNumberValidator()));
    form.addField(new FormField('birthDate', '', dateValidator()));
    form.addField(new FormField('country', '', countryValidator()));
    form.setReady();
}

function prepareFormField(): void {
    props.formField.addValidators({
        childrenAreValid: () => form.isValid(),
    });
}

function onPersonDataChange(): void {
    switch (form.field('personDataSelector').value) {
        case PersonDataType.Authenticated:
            authenticatedFields();
            break;
        case PersonDataType.Provided:
            sameAsPolicyHolderFields();
            break;
        case PersonDataType.Other:
            residentFields();
            break;
    }
    applyValues();
}

function setDefaultPersonDataSelector(): void {
    if (needToShowAuthenticated.value) {
        form.field('personDataSelector').setValue(PersonDataType.Authenticated);
    } else if (needToShowIsPolicyHolder.value && props.providedPerson) {
        form.field('personDataSelector').setValue(PersonDataType.Provided);
    } else {
        form.field('personDataSelector').setValue(PersonDataType.Other);
    }
}

function onResidentSelectorChange(): void {
    form.field('residentSelector').value === OtherPersonType.Resident ? residentFields() : nonResidentFields();
    applyValues();
}

function authenticatedFields(): void {
    form.field('personDataSelector').setValue(PersonDataType.Authenticated);
    form.field('residentSelector').setValue(OtherPersonType.Resident);
    form.field('name').setValue(user.current.firstname);
    form.field('surname').setValue(user.current.lastname);
    form.field('personCode').setValue(user.current.personCode);
    form.field('country').setValue({
        ic: '',
        iso: user.current.code,
        phoneCode: user.current.phoneCode,
    });
    form.field('birthDate').setValue(moment(user.current.birthDate, DateFormat.Default.Short).utc(true).toISOString());
}

function residentFields(): void {
    form.field('personDataSelector').setValue(PersonDataType.Other);
    form.field('residentSelector').setValue(OtherPersonType.Resident);
    form.field('name').clear();
    form.field('surname').clear();
    form.field('personCode').clear();
    form.field('country').setValue({
        ic: '',
        iso: settingsService.localeIso(),
        phoneCode: settingsService.phoneCode(),
    });
    form.field('birthDate').clear();
}

function sameAsPolicyHolderFields(): void {
    if (props.providedPerson) {
        form.field('personDataSelector').setValue(PersonDataType.Provided);
        form.field('residentSelector').setValue(
            props.providedPerson.isResident ? OtherPersonType.Resident : OtherPersonType.NonResident,
        );
        form.field('name').setValue(props.providedPerson.name);
        form.field('surname').setValue(props.providedPerson.surname);
        form.field('country').setValue({
            ic: props.providedPerson.country.ic,
            iso: props.providedPerson.country.iso,
            phoneCode: props.providedPerson.country.phoneCode,
        });
        form.field('birthDate').setValue(props.providedPerson.birthDate);
        nextTick(() => {
            form.field('personCode').setValue(props.providedPerson!.personCode);
        });
    }
}

function nonResidentFields(): void {
    form.field('personDataSelector').setValue(PersonDataType.Other);
    form.field('residentSelector').setValue(OtherPersonType.NonResident);
    form.field('name').clear();
    form.field('surname').clear();
    form.field('country').setValue({
        ic: '',
        iso: '',
        phoneCode: '',
    });
    form.field('birthDate').clear();
    form.field('personCode').clear();
}

function restoredFields(): void {
    let personDataType = PersonDataType.Other;

    if (props.formField.value.isAuthenticated) {
        personDataType = PersonDataType.Authenticated;
    } else if (props.formField.value.isPolicyHolder) {
        personDataType = PersonDataType.Provided;
    }

    form.field('personDataSelector').setValue(personDataType);
    form.field('residentSelector').setValue(
        props.formField.value.isResident ? OtherPersonType.Resident : OtherPersonType.NonResident,
    );
    form.field('name').setValue(props.formField.value.name);
    form.field('surname').setValue(props.formField.value.surname);
    form.field('personCode').setValue(props.formField.value.personCode);
    form.field('country').setValue(props.formField.value.country);
    form.field('birthDate').setValue(props.formField.value.birthDate);
}

function setDefaultValues(): void {
    let fieldsStrategy: () => void = residentFields;

    if (!props.formField.isEmpty()) {
        fieldsStrategy = restoredFields;
    } else if (needToShowAuthenticated.value) {
        fieldsStrategy = authenticatedFields;
    } else if (props.providedPerson) {
        fieldsStrategy = sameAsPolicyHolderFields;
    }

    fieldsStrategy();
}

function translated(key: string): string {
    return translateForType(key, props.translationType);
}

function translatedDefault(key: string): string {
    return translateForType(key, type);
}

function applyValues(): void {
    formChanges.next();
}
</script>

<template>
    <div
        v-if="form.isReady()"
        :id="formField.name"
        class="grid-utility grid-columns-1 sm:grid-columns-12"
        :class="{ ...formField.classes() }"
        :data-store="formField.name"
        :data-store-value="JSON.stringify(formField.value)"
    >
        <app-input-radio-selector
            v-if="shouldShowSelector"
            class="column-span-12"
            :form-field="form.field('personDataSelector')"
            :options="personsDataOptions"
            :type="'with-circle'"
            @change="onPersonDataChange"
        >
        </app-input-radio-selector>
        <div v-if="needToShowIsResident && $slots['other-person-title']" class="column-span-12">
            <slot name="other-person-title"></slot>
        </div>
        <app-input-radio-overlayed
            v-if="needToShowIsResident"
            class="column-span-12"
            :form-field="form.field('residentSelector')"
            :options="residentOption"
            :type="'with-circle'"
            @change="onResidentSelectorChange"
        >
        </app-input-radio-overlayed>
        <app-input-country
            v-if="needToShowCountry"
            class="column-span-12"
            :label="translatedDefault('country')"
            :disabled="lockUserFields"
            :include-default-country="false"
            :patch-default-country="false"
            :placeholder="translatedDefault('country_placeholder')"
            :form-field="form.field('country')"
            :data-store-disabled="true"
            @change="applyValues"
        >
        </app-input-country>
        <app-input-text
            class="column-span-12 sm:column-span-4"
            :label="translatedDefault('name')"
            :disabled="lockUserFields"
            :form-field="form.field('name')"
            :placeholder="translatedDefault('name_placeholder')"
            :autocomplete="'given-name'"
            :data-store-disabled="true"
            @change="applyValues"
        >
        </app-input-text>
        <app-input-text
            class="column-span-12 sm:column-span-4"
            :label="translatedDefault('surname')"
            :disabled="lockUserFields"
            :form-field="form.field('surname')"
            :placeholder="translatedDefault('surname_placeholder')"
            :autocomplete="'family-name'"
            :data-store-disabled="true"
            @change="applyValues"
        >
        </app-input-text>
        <app-input-identity-number
            class="column-span-12 sm:column-span-4"
            :disabled="lockUserFields"
            :form-field="form.field('personCode')"
            :country="selectedCountry"
            :resident="isResident"
            :label="translatedDefault('person_code')"
            :placeholder="translatedDefault('person_code_placeholder')"
            @change="applyValues"
        >
        </app-input-identity-number>
        <app-input-birth-date
            v-if="needToShowBirthDate"
            class="column-span-12"
            :label="translatedDefault('birth_date')"
            :disabled="lockUserFields"
            :delimiter="'.'"
            :allow-invalid-value="true"
            :form-field="form.field('birthDate')"
            :data-store-disabled="true"
            @change="applyValues"
        >
        </app-input-birth-date>
        <div
            v-if="$slots['contacts']"
            class="column-span-12 sm:column-span-12"
            :class="{ 'sm:column-span-12': !$slots['bank'] }"
        >
            <slot name="contacts"></slot>
        </div>
        <div
            v-if="$slots['above-bank'] && $slots['bank']"
            class="column-span-12 sm:column-span-12"
            :class="{ 'sm:column-span-12': !$slots['contacts'] }"
        >
            <slot name="above-bank"></slot>
        </div>
        <div
            v-if="$slots['bank']"
            class="column-span-12 sm:column-span-12"
            :class="{ 'sm:column-span-12': !$slots['contacts'] }"
        >
            <slot name="bank"></slot>
        </div>
        <slot></slot>
    </div>
</template>
