<script setup lang="ts">
import { onMounted, reactive, shallowReactive, ShallowUnwrapRef, UnwrapNestedRefs } from 'vue';
import Form from '@/Assets/Libraries/Form/Form';
import Validation from '@/Services/validation.service';
import { useTranslate } from '@/Composables/Translate';
import Option from '@/Apps/ClaimsTravel/Interfaces/Option';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppWhiteboard from '@/Components/Containers/Whiteboard/Whiteboard.vue';
import AppButtonPrimary from '@/Components/Buttons/ButtonPrimary/ButtonPrimary.vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppSvg from '@/Components/Other/Svg/Svg.vue';
import { Router, useRouter } from 'vue-router';
import FormField from '@/Assets/Libraries/Form/FormField';
import { useClaimsTravelHtml } from '@/Apps/ClaimsTravel/Composables/ClaimsTravelHtml';
import ClaimsTravelFormFields from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelFormFields';
import { OtherForms, ClaimAmountFields } from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentDetails/OtherForms';
import { FieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Other/FieldNames';
import { FormNames as OtherFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Other/FormNames';
import Sanitizer from '@/Services/sanitizer.service';
import GuardsService from '@/Apps/ClaimsTravel/Services/GuardsService';
import ClaimsTravelStepUid from '@/Apps/ClaimsTravel/Enums/ClaimsTravelStepUid';

const emit = defineEmits<{
    (event: 'completed'): void;
}>();

const { translateForType, applyTranslationType, type, translate } = useTranslate();
const router: Router = useRouter();

const claimsTravelService: ClaimsTravelService = ClaimsTravelService.getInstance();

type FormNames = `${OtherFormNames}`;
const Step: number = 2;

const forms: ShallowUnwrapRef<{ [FormKey in keyof OtherForms]: Form<OtherForms[FormKey]> }> = shallowReactive(
    {},
) as ShallowUnwrapRef<{
    [FormKey in keyof OtherForms]: Form<OtherForms[FormKey]>;
}>;
const inputOptions: UnwrapNestedRefs<Record<FormNames, Option>> = reactive({}) as UnwrapNestedRefs<
    Record<FormNames, Option>
>;

function preparePanels(): void {
    Object.values(OtherFormNames).forEach((panelName: FormNames) => {
        inputOptions[panelName] = reactive(
            new (class implements Option {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = claimsTravelService.storageFields.formVisibility[panelName] ?? false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            })(),
        );
    });
}

function setupForms(): void {
    const requiredValidator = { required: Validation.required };

    const claimAmountForm: Form<ClaimAmountFields> = new Form({ useValidationV2: true });
    claimAmountForm.addField(new FormField(FieldNames.ClaimAmount, '', requiredValidator, Sanitizer.cleanNumber));
    forms[OtherFormNames.ClaimAmount] = claimAmountForm;
}

function firstFormName(): string {
    return OtherFormNames.ClaimAmount;
}

function scrollToLastForm(): void {
    const lastForm = Object.keys(inputOptions).findLast((form) => inputOptions[form as FormNames].visible);

    useClaimsTravelHtml().scrollToPanel(lastForm ? lastForm : firstFormName());
}

function clearForms(): void {
    Object.keys(forms).forEach((form) => {
        claimsTravelService.storageFields.formVisibility[form as keyof ClaimsTravelFormFields] = false;
        clearForm(form as FormNames);
    });
}

function clearFormsAhead(currentForm: FormNames): void {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);
    const nextFormKeys: FormNames[] = Object.keys(forms).slice(
        currentFormIndex + 1,
        Object.keys(forms).length,
    ) as FormNames[];
    nextFormKeys.forEach((formName: FormNames) => {
        clearForm(formName);
        inputOptions[formName].visible = false;
        claimsTravelService.storageFields.formVisibility[formName] = false;
    });
}

function clearForm(formName: FormNames): void {
    const form: Form<ClaimAmountFields> = forms[formName];
    switch (formName) {
        case OtherFormNames.ClaimAmount: {
            const traumaForm = form as Form<ClaimAmountFields>;
            traumaForm.field(FieldNames.ClaimAmount).setValue('');
            break;
        }
    }

    storeForm(formName);
}

function markFormsAsReady(): void {
    Object.values(forms).forEach((form) => form.setReady());
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

function storeForm(formName: FormNames): void {
    return claimsTravelService.storeForm(formName as keyof ClaimsTravelFormFields, forms[formName]);
}

function onFormInputChange(formName: FormNames): void {
    clearFormsAhead(formName);
}

function proceedToNextStep(name: FormNames): void {
    const form = forms[name];
    form.submitAttempt().then(() => {
        if (form.isValid()) {
            GuardsService.getInstance().applyStepValidity(Step, true);
            router.push({ name: ClaimsTravelStepUid.UploadFiles });
        }
    });
}

onMounted(() => {
    applyTranslationType(ClaimsTravelService.translationType);
    setupForms();
    claimsTravelService.restoreForms(forms);
    markFormsAsReady();
    preparePanels();
});

defineExpose({
    clearForms,
    scrollToLastForm,
});
</script>

<template>
    <app-custom-form
        v-if="forms[OtherFormNames.ClaimAmount]?.isReady()"
        :form="forms[OtherFormNames.ClaimAmount]"
        class="form"
        @change="storeForm(OtherFormNames.ClaimAmount)"
    >
        <app-whiteboard
            :data-type="'whiteboard-2'"
            :data-scroll="OtherFormNames.ClaimAmount"
            :title="translated('claim_amount')"
        >
            <div class="inputs row">
                <div class="input-radio-container row">
                    <app-input-text
                        :inside-label="translated('eur')"
                        :inside-label-class="'clean'"
                        :placeholder="translated('enter_claim_amount')"
                        :form-field="forms[OtherFormNames.ClaimAmount].field(FieldNames.ClaimAmount)"
                        @change="onFormInputChange(OtherFormNames.ClaimAmount)"
                    >
                    </app-input-text>
                </div>
            </div>
            <app-button-primary @click="proceedToNextStep(OtherFormNames.ClaimAmount)">
                <template #end>
                    <app-svg src="images/one/arrow-right.svg"></app-svg>
                </template>
                {{ translated('proceed') }}
            </app-button-primary>
        </app-whiteboard>
    </app-custom-form>
</template>

<style scoped lang="scss">
.form {
    .button-with-callback {
        height: 52px;
    }

    .input-radio-container {
        width: 50%;
    }

    .medical-expenses {
        margin: var(--size-medium) 0;
    }

    :deep(.hint-label) {
        display: flex;
        gap: 10px;
        color: var(--text-color-subtlest);
        font-size: var(--font-size-nano);
        font-style: normal;
        align-items: center;
        font-weight: 500;
    }

    .one-button {
        margin-top: var(--size-big);
    }
}
</style>
