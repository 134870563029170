import AdditionalOptionsList from '@/Components/Lists/AdditionalOptionsList/AdditionalOptionsList.vue';
import ArticlesNavigator from '@/Components/Lists/ArticlesNavigator/ArticlesNavigator.vue';
import AppButtonsBeltWithDigits from '@/Components/Lists/ButtonsBeltWithDigits/ButtonsBeltWithDigits.vue';
import ConsentsList from '@/Components/Lists/ConsentsList/ConsentsList.vue';
import AppFieldsetList from '@/Components/Lists/FieldsetList/FieldsetList.vue';
import HealthCardsList from '@/Components/Lists/HealthCardsList/HealthCardsList.vue';
import AppImageInfo from '@/Components/Lists/ImageInfo/ImageInfo.vue';
import InsuredObjectsBlocks from '@/Components/Lists/InsuredObjectsBlocks/InsuredObjectsBlocks.vue';
import AppInsuredRisks from '@/Components/Lists/InsuredRisks/InsuredRisks.vue';
import LoadMoreList from '@/Components/Lists/LoadMoreList/LoadMoreList.vue';
import AppItemsListWithIcons from '@/Components/Lists/ItemsListWithIcons/ItemsListWithIcons.vue';
import PersonListWithSearch from '@/Components/Lists/PersonListWithSearch/PersonListWithSearch.vue';
import ObjectList from '@/Components/Lists/ObjectList/ObjectList.vue';
import AppRemotePaginator from '@/Components/Lists/RemotePaginator/RemotePaginator.vue';
import AppGrid from '@/Components/Lists/SmartGrid/SmartGrid.vue';
import AppPaginator from '@/Components/Lists/Paginator/Paginator.vue';
import DetailsList from '@/Components/Lists/DetailsList/DetailsList.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppAdditionalOptionsList: AdditionalOptionsList,
    AppArticlesNavigator: ArticlesNavigator,
    AppButtonsBeltWithDigits: AppButtonsBeltWithDigits,
    AppConsentsList: ConsentsList,
    AppFieldsetList: AppFieldsetList,
    AppHealthCardsList: HealthCardsList,
    AppImageInfo: AppImageInfo,
    AppInsuredObjectsBlocks: InsuredObjectsBlocks,
    AppInsuredRisks: AppInsuredRisks,
    AppLoadMoreList: LoadMoreList,
    AppItemsListWithIcons: AppItemsListWithIcons,
    AppPersonListWithSearch: PersonListWithSearch,
    AppObjectList: ObjectList,
    AppRemotePaginator: AppRemotePaginator,
    AppGrid: AppGrid,
    AppPaginator: AppPaginator,
    AppDetailsList: DetailsList,
} as Components;
