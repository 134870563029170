<script setup lang="ts">
import { onMounted, ref, Ref, getCurrentInstance } from 'vue';
import { Router, useRouter } from 'vue-router';
import Form from '@/Assets/Libraries/Form/Form';
import Storage from '@/Apps/ActivePlus/Services/Storage';
import StepsGuard from '@/Apps/ActivePlus/Services/StepsGuard';
import OneBaseService from '@/Services/OneBaseService';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import { useTranslate } from '@/Composables/Translate';
import PopupService from '@/Services/custom.popup.service';
import { CoveredPopupBuilder } from '@/Apps/ActivePlus/Builders/CoveredPopupBuilder';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { useDefine } from '@/Composables/Define';
import { useScroll } from '@/Composables/Scroll';
import { ActivePlus } from '@/Apps/ActivePlus/Interfaces/ActivePlusInterface';
import ProductBlockItemBuilder from '@/Components/Other/ProductBlock/Builders/ProductBlockItemBuilder';
import { CoverageRisk } from '@/Apps/ActivePlus/Interfaces/CoverageRiskInterface';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import AppProductBlock from '@/Components/Other/ProductBlock/ProductBlock.vue';
import ProductBlockItem from '@/Components/Other/ProductBlock/Interfaces/ProductBlockItemInterface';
import AppPage from '@/Apps/ActivePlus/Components/Page.vue';
import ErrorType from '@/Enums/ErrorTypeEnum';
import OneBase from '@/Interfaces/OneBaseInterface';
import { ErrorInterface, useError } from '@/Composables/Error';
import FormField from '@/Assets/Libraries/Form/FormField';

import { CoveragePlan } from '@/Apps/ActivePlus/Interfaces/CoveragePlanInterface';
import CoveredPopupTypes from '@/Components/Popups/CoveredPopup/Enums/CoveredPopupTypes';
import Steps from '@/Apps/ActivePlus/Enums/Steps';

const router: Router = useRouter();
const { translateForType } = useTranslate();
const { isSet, isLinkAnchor } = useDefine();
const { logError }: ErrorInterface = useError();

const form: Form<{ 'product-panel-blocks': unknown }> = new Form({ useValidationV2: true });
const storage: Storage = Storage.getInstance();
const popupService: PopupService = PopupService.getInstance();
const btaBase: OneBase = OneBaseService.getInstance();
const TranslationType: string = 'velo_plus';
const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
const productBlocks: Ref<ProductBlockItem[]> = ref([]);
const IcTranslationSuffix: string = '_main';
const selectedProgram: Ref<string> = ref('');
const selectedCoverage: Ref<number> = ref(0);

defineExpose({
    coveredPopup,
    submit,
});

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    StepsGuard.getInstance(storage).init();
    if (shouldReroute()) {
        reRoute();
    } else {
        storage.fetchProducts().then((): void => {
            setupForm();
            buildProductBlocks();
            buildCoveredPopup();
            useScroll().scrollToTop();
        });
    }
});

function setupForm(): void {
    form.addField(new FormField('product-panel-blocks'));
    form.setReady();
}

function buildProductBlocks(): void {
    storage.products.value.forEach((policy: ActivePlus): void => {
        const programIc: string = policy.id.toLowerCase();
        const productBlockItemBuilder: ProductBlockItemBuilder = new ProductBlockItemBuilder();
        const productName: string = translateForType('title_policy_plan_' + programIc, TranslationType);
        const price: number = policy.price;
        const badgeText: string = translateForType('label_popular_choice', TranslationType);
        const territoryText: string = translateForType(
            policy.params.insuranceTerritoryIc + IcTranslationSuffix,
            TranslationType,
        );
        const customModule: string = 'AppProductBlockInnerActivePlusPolicy';
        const selectButtonText: string = translateForType('button_select_' + programIc, TranslationType);
        const customButtonText: string = translateForType('button_see_what_covered_main', TranslationType);
        const featuredRisks: CoverageRisk[] = policy.coveragePlans
            .map((plan: CoveragePlan): CoverageRisk[] => plan.risks)
            .flat()
            .filter((risk: CoverageRisk): boolean => risk.isFeatured);
        const dynamicContent: DynamicDictionary = {
            risks: featuredRisks,
            territory: territoryText,
        };
        productBlockItemBuilder
            .withProductId(policy.id)
            .withProductName(productName)
            .withDiscountSum(price)
            .withDynamicContent(customModule, dynamicContent)
            .withButtonSelectTitle(selectButtonText)
            .withButtonCustomTitle(customButtonText);
        if (policy.params.withBadge) {
            productBlockItemBuilder.withBadgeText(badgeText);
        }
        if (policy.params.isDefaultOnMobile) {
            productBlockItemBuilder.asDefaultOnMobile();
        }
        productBlocks.value.push(productBlockItemBuilder.build());
    });
}

function buildCoveredPopup(): void {
    coveredPopup
        .withCoveredType(CoveredPopupTypes.CoveredPopupActivePlusMulti)
        .withTranslationKey(TranslationType)
        .withCoveredTitle('title_covered_compare')
        .withOnSelectClick(onSelectProductClick as Function)
        .withContent(storage.products.value);
}

function onSelectProductClick(programs: string[]): void {
    selectedProgram.value = programs[0];
    selectedCoverage.value =
        storage.products.value.find((program): boolean => program.id === selectedProgram.value)?.price ?? 0;
    btaBase.captcha
        .executeCaptcha(submit)
        .then()
        .catch((reason: string): void => {
            logError(ErrorType.Error, 'onSubmitStep', reason);
        });
}

function submit(): void {
    prepareSubmit();
    router.push({ name: Steps.InsuredObjects });
    popupService.hide();
}

function prepareSubmit(): void {
    storage.fields.programIc = selectedProgram.value;
    storage.fields.coverage = selectedCoverage.value;
}

function onShowCoveredPopupClick(): void {
    popupService.show(new OnePopup().withType().oneCovered);
}

function reRoute(): void {
    router.push(storage.store().currentRoute);
}

function shouldReroute(): boolean {
    const isCurrentRoute: boolean = storage.store().currentRoute !== '';
    const isStorageSelectedProgram: boolean = isSet(storage.fields.programIc);

    // WIP return isStorageSelectedProgram && isCurrentRoute && !isLinkAnchor(location.href);
    return false;
}
</script>

<template>
    <app-page class="page">
        <template #title>{{ translateForType('title_active_insurance', TranslationType) }}</template>
        <template #description>{{ translateForType('subtitle_description_compare', TranslationType) }}</template>
        <app-content-loader v-if="!form.isReady()"></app-content-loader>
        <app-custom-form v-if="form.isReady()" :form="form" class="form">
            <app-product-block
                :id="'activePlusProducts'"
                :form-field="form.field('product-panel-blocks')"
                :products="productBlocks"
                :product-sum-type="'yearly'"
                :selected-frame-hidden="true"
                :mini-plans-component="'AppActivePlusMini'"
                @selected="onSelectProductClick"
                @custom="onShowCoveredPopupClick"
            >
            </app-product-block>
        </app-custom-form>
    </app-page>
</template>

<style lang="scss" scoped>
.page {
    max-width: 100%;
    width: 100%;
}
</style>
