<script setup lang="ts">
import Form from '@/Assets/Libraries/Form/Form';
import { computed, getCurrentInstance, onMounted, ref, Ref } from 'vue';
import OneBase from '@/Interfaces/OneBaseInterface';
import OneBaseService from '@/Services/OneBaseService';
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonBorder from '@/Components/Buttons/ButtonWithCallback/Enums/button.border.enum';
import moment from 'moment/moment';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import FormField from '@/Assets/Libraries/Form/FormField';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import RentersService from '@/Apps/Renters/Services/RentersService';
import { useDefine } from '@/Composables/Define';
import { useScroll } from '@/Composables/Scroll';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import { Renters } from '@/Apps/Renters/Interfaces/RentersInterface';
import { usePrice } from '@/Composables/Price';
import User from '@/Services/user.service';
import UserCredentials from '@/Interfaces/user.credentials.interface';
import Validation from '@/Services/validation.service';
import Sanitizer from '@/Services/sanitizer.service';
import StepsGuard from '@/Apps/Renters/Services/StepsGuard';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import PopupService from '@/Services/custom.popup.service';
import { CoveredPopupBuilder } from '@/Apps/Renters/Builders/CoveredPopupBuilder';
import Validators from '@/Apps/Renters/Services/Validators';
import { useFormatter } from '@/Composables/Formatter';
import ExtractDataService from '@/Services/extract.data.service';
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import { useNavigate } from '@/Composables/Navigate';
import { CoverageRisk } from '@/Apps/Renters/Interfaces/CoverageRiskInterface';
import { useAxios } from '@/Composables/Axios';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Value from '@/Assets/Libraries/Form/Value';
import { CoveragePlan } from '@/Apps/Renters/Interfaces/CoveragePlanInterface';
import { useStrings } from '@/Composables/Strings';
import AppInputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppSubscribeAndPay from '@/Components/Other/SubscribeAndPay/SubscribeAndPay.vue';
import RentersRiskGroups from '@/Apps/Renters/Enums/RentersRiskGroups';
import RentersPrograms from '@/Apps/Renters/Enums/RentersPrograms';
import SettingsService from '@/Services/settings.service';
import Steps from '@/Apps/Renters/Enums/Steps';
import AppPopupOpener from '@/Components/Popups/PopupOpener/PopupOpener.vue';
import AppInputCountry from '@/Components/Inputs/InputCountry/InputCountry.vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppInputIdentityNumber from '@/Components/Inputs/InputIdentityNumber/InputIdentityNumber.vue';
import AppInputDate from '@/Components/Inputs/InputDate/InputDate.vue';
import AppInputEmail from '@/Components/Inputs/InputEmail/InputEmail.vue';
import AppPhoneWithCountry from '@/Components/Inputs/PhoneWithCountry/PhoneWithCountry.vue';
import AppInputDateWithCalendar from '@/Components/Inputs/InputDateWithCalendar/InputDateWithCalendar.vue';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import AppConsentsList from '@/Components/Lists/ConsentsList/ConsentsList.vue';
import DateRange from '@/Interfaces/date.range.interface';
import PhoneField from '@/Interfaces/phone.field.interface';
import { CountryComponentParams } from '@/Components/Inputs/InputCountry/CountryComponentParams';

const { translate, translateForType } = useTranslate();
const { sparse } = usePrice();
const { capitalize } = useStrings();
const router: Router = useRouter();
const { sparsePrice } = useFormatter();

const base: OneBase = OneBaseService.getInstance();
const user: User = User.getInstance();
const popupService: PopupService = PopupService.getInstance();
const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
const rentersService: RentersService = RentersService.getInstance();
const validators: Validators = Validators.getInstance();
const form: Form<{
    policyStartDate: DateRange;
    policyHolderSameAsInsured: boolean;
    policyHolderIsResident: boolean;
    policyHolderCountry: CountryComponentParams;
    policyHolderBirthDate: Date | string;
    marketingConsents: DynamicDictionary;
    policyHolderFirstName: string;
    policyHolderLastName: string;
    policyHolderIdentityNumber: string;
    policyHolderEmail: string;
    policyHolderPhone: PhoneField;
    agreeTerms: boolean;
}> = new Form({ useValidationV2: true });
const extractDataService: ExtractDataService = new ExtractDataService();
const policyStartMinDate: Date = moment().add(1, 'days').toDate();
const policyStartMaxDate: Date = moment().add(30, 'days').toDate();

const TranslationType: string = 'renters';
const Facility: string = 'renters-insurance';
const ConsentsGlue: string = ':';

const policyStartDate: Ref<string> = computed(() => {
    const result: Date = moment(form.field('policyStartDate').value.startDate).toDate();

    return OneDate.short(result);
});

const viewIsReady: Ref<boolean> = ref(false);
const canStoreValues: Ref<boolean> = ref(false);

const policyEndDate: Ref<string> = computed(() => {
    const startDate: Date = form.field('policyStartDate').value.startDate as unknown as Date;
    const result: Date = moment(startDate).add(1, 'year').subtract(1, 'day').toDate();

    return OneDate.short(result);
});

const showMarketingConsents: Ref<boolean> = computed(() => {
    return !base.user.current.receiveMarketing;
});

const policyHolderSameAsInsured: Ref<boolean> = computed(() => {
    return form.field('policyHolderSameAsInsured').value;
});

const isResident: Ref<boolean> = computed(() => {
    return form.field('policyHolderIsResident').value;
});

const country: Ref<string> = computed(() => {
    return isResident.value ? 'LV' : form.field('policyHolderCountry').value.iso;
});

const isAuthenticatedUser: Ref<boolean> = computed(() => {
    return user.isLogged();
});

const birthDateRequired: Ref<boolean> = computed(() => {
    return !policyHolderIsFromBalticCountries.value || policyHolderCodeIsWithoutDate.value;
});

const policyHolderIsFromBalticCountries: Ref<boolean> = computed(
    () => isResident.value || ['EE', 'LV', 'LT'].includes(form.field('policyHolderCountry').value.iso),
);

const insuredPersonIsResident: Ref<boolean> = computed(() => !!rentersService.fields.insuredIsResident);

const policyHolderCodeIsWithoutDate: Ref<boolean> = computed(() => {
    const identityNumberField: FormField = form.field('policyHolderIdentityNumber');
    const identityNumber: string = String(identityNumberField.value);

    return (
        identityNumberField.isNotEmpty() &&
        Validation.isValidPersonCodeWithoutDate(identityNumber) &&
        countryField().iso === 'LV'
    );
});

const insuredCodeIsWithoutDate: Ref<boolean> = computed(() => {
    const identityNumber: string = rentersService.fields.insuredIdentityNumber;

    return Validation.isValidPersonCodeWithoutDate(identityNumber);
});

const insuredBirthDateRequired: Ref<boolean> = computed(() => insuredCodeIsWithoutDate.value);

const insuredAddress: DynamicDictionary = rentersService.fields.insuredAddress as DynamicDictionary;
const insuredAddressValue: string = insuredAddress.addressName;
const insuredAddressApartmentValue: string =
    insuredAddress.apartment && insuredAddress.apartment !== ''
        ? insuredAddress.apartment
        : insuredAddress.detailed || '';
const insuredAddressDetailedLabel: string =
    insuredAddress.apartment && insuredAddress.apartment !== ''
        ? 'caption_insured_object_apartment'
        : 'caption_insured_object_detailed_address';

defineExpose({
    coveredPopup,
    form,
});

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    rentersService.updateRoute();
    setupForm();
    rentersService.fetchProducts().then((): void => {
        buildCoveredPopup();
        useScroll().scrollToTop();
        viewIsReady.value = true;
        patchDefaultValues().then(() => {
            restoreValues();
            if (isAuthenticatedUser.value) {
                patchUserFields();
            }
        });
    });
    validators.init(form);
    StepsGuard.getInstance(rentersService).init();
});

function setupForm(): void {
    form.addField(new FormField('policyStartDate').addValidators({ required: Validation.required }));
    form.addField(new FormField('policyHolderSameAsInsured'));
    form.addField(new FormField('policyHolderIsResident'));
    form.addField(
        new FormField('policyHolderCountry').addValidators({
            required: Validation.requiredIf((): boolean => !isAuthenticatedUser.value),
        }),
    );
    form.addField(
        new FormField('policyHolderBirthDate').addValidators({
            required: Validation.requiredIf((): boolean => !isAuthenticatedUser.value),
        }),
    );
    form.addField(new FormField('marketingConsents'));
    form.addField(
        new FormField('policyHolderFirstName')
            .addValidators({
                required: Validation.required,
                validCaption: Validation.isValidCaption,
            })
            .addSanitizer(Sanitizer.cleanUppercaseName),
    );
    form.addField(
        new FormField('policyHolderLastName')
            .addValidators({
                required: Validation.required,
                validCaption: Validation.isValidCaption,
            })
            .addSanitizer(Sanitizer.cleanUppercaseName),
    );
    form.addField(new FormField('policyHolderIdentityNumber').addValidators({ required: Validation.required }));
    form.addField(new FormField('policyHolderEmail').addValidators({ required: Validation.required }));
    form.addField(new FormField('policyHolderPhone').addValidators({ required: Validation.required }));
    form.addField(new FormField('agreeTerms').addValidators({ required: Validation.required }));
    form.setReady();
}

function buildCoveredPopup(): void {
    const product: Renters[] = [];
    product.push(rentersService.selectedProduct()!);
    coveredPopup
        .withCoveredTitle('see_what_covered_single')
        .withTranslationKey(TranslationType)
        .withCoveredType('AppCoveredPopupRentersSingle')
        .withCoveragePlanKey(coverageId())
        .additionalRisks(selectAdditionalRisks())
        .withMode(Steps.Summary)
        .withContent(product);
}

function selectAdditionalRisks(): string[] {
    const result: string[] = [];
    const additionalOptions: DynamicDictionary = rentersService.fields.additionalOptions;
    if (additionalOptions) {
        Object.entries(additionalOptions).forEach(([key, value]): void => {
            value && result.push(key);
        });
    }

    return result;
}

function patchDefaultValues(): Promise<void> {
    return new Promise<void>((resolve) => {
        form.field('policyStartDate').patch({
            startDate: minPolicyStartDate() as unknown as string,
            endDate: '',
        });
        if (isAuthenticatedUser.value) {
            patchMailAndPhone();
        } else {
            patchHolderAsInsured();
        }
        updatePolicyHolderBirthDate();
        resolve();
    });
}

function patchUserFields(): void {
    const currentUser: UserCredentials = base.user.current;
    form.field('policyHolderFirstName').patch(currentUser.firstname);
    form.field('policyHolderLastName').patch(currentUser.lastname);
    form.field('policyHolderIdentityNumber').patch(currentUser.personCode);
    storeValues();
}

function patchMailAndPhone(): void {
    const currentUser: UserCredentials = base.user.current;
    form.field('policyHolderEmail').patch(currentUser.email);
    form.field('policyHolderPhone').patch({
        code: currentUser.code,
        iso: currentUser.code,
        country: currentUser.phoneCode,
        phone: currentUser.phone,
    } as PhoneField);
    rentersService.fields.policyHolderPhone = form.field('policyHolderPhone').value;
    rentersService.fields.policyHolderEmail = form.field('policyHolderEmail').value;
}

function minPolicyStartDate(): Date {
    const offset: number = base.settings.movablePropertySettings().minPolicyStartDateOffset;

    return moment().add(offset, 'day').toDate();
}

function coverageId(): number {
    return Number(rentersService.fields.coverage);
}

function coverageSum(): number {
    return rentersService.selectedProduct().coveragePlans[coverageId()].insuredSum;
}

function productPrice(): number {
    return rentersService.selectedProduct().coveragePlans[coverageId()].price;
}

function onCoveredClick(): void {
    popupService.show(new OnePopup().withType().oneCovered);
}

function onConfirmAndPayClick(): void {
    form.submitAttempt().then((): void => {
        if (form.isValid()) {
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            useAxios()
                .post(Url.Ajax.rentersPurchase, content())
                .then((response: AxiosResponse): void => {
                    if (useDefine().validResponse(response)) {
                        redirectToBank();
                    } else {
                        PopupService.getInstance().hide();
                        base.error.show(ErrorType.Error, 'submitPayment', response);
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    PopupService.getInstance().hide();
                    Error.getInstance().show(
                        ErrorType.Error,
                        'Renters insurance / RentersSummaryAndPayment / onConfirmAndPay',
                        reason,
                    );
                });
        } else {
            useScroll().scrollInvalidFieldToView(form);
        }
    });
}

function content(): DynamicDictionary {
    const product: Renters = rentersService.selectedProduct();
    const bankLinkId: number = RecurringPaymentBanklink.ByCountry[new AppCountry().iso()];
    const selectedCoveragePlan: CoveragePlan | undefined = product.coveragePlans[coverageId()];
    return {
        termsIc: product.params.termsIc,
        deductibleIc: product.params.deductibleIc,
        sumInsured: selectedCoveragePlan.insuredSum,
        risks: transformedRisks(includedRisks()),
        policyStartDate: OneDate.iris(policyStartDate.value),
        address: rentersService.fields.insuredAddress,
        policyHolder: policyHolder(),
        insuredPerson: insuredPerson(),
        bankLinkId: bankLinkId,
        paymentFailRedirectUrl: router.currentRoute.value.path,
        facility: Facility,
        planId: rentersService.fields.programIc,
    };
}

function policyHolder(): DynamicDictionary {
    return {
        firstName: form.field('policyHolderFirstName').value,
        lastName: form.field('policyHolderLastName').value,
        identityNumber: form.field('policyHolderIdentityNumber').value,
        country: countryField().ic,
        birthDate: OneDate.iris(form.field('policyHolderBirthDate').value),
        email: form.field('policyHolderEmail').value,
        phone: form.field('policyHolderPhone').value,
        isResident:
            isAuthenticatedUser.value || policyHolderSameAsInsured.value
                ? authenticatedOrInsuredField()
                : isResident.value,
        marketingConsents: marketingConsents(),
    };
}

function insuredPerson(): DynamicDictionary {
    return {
        firstName: rentersService.fields.insuredFirstName,
        lastName: rentersService.fields.insuredLastName,
        identityNumber: rentersService.fields.insuredIdentityNumber,
        birthDate: rentersService.fields.insuredBirthDate,
        country: rentersService.fields.insuredCountry.ic,
        isResident: !!rentersService.fields.insuredIsResident,
    };
}

function authenticatedOrInsuredField(): boolean {
    return isAuthenticatedUser.value ? true : insuredPersonIsResident.value;
}

function countryField(): DynamicDictionary {
    return isAuthenticatedUser.value || isResident.value ? residentCountry() : form.field('policyHolderCountry').value;
}

function residentCountry(): DynamicDictionary {
    const settingsInstance: SettingsService = SettingsService.getInstance();
    return {
        ic: '100000000',
        iso: settingsInstance.localeIso(),
        phoneCode: settingsInstance.phoneCode(),
    };
}

function marketingConsents(): string[] {
    const selectedConsents: string = form.field('marketingConsents').value.selected;

    return new Value(selectedConsents).isNotEmpty() ? selectedConsents.split(ConsentsGlue) : [];
}

function redirectToBank(): void {
    useNavigate().navigate(Url.PaymentsApi.paymentsPay);
}

function scrollToElements(elements: JQuery): void {
    if (elements.length > 0) {
        elements[0].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center',
        });
    }
}

function updatePolicyHolderBirthDate(): void {
    const date: string | undefined = isAuthenticatedUser.value
        ? user.current.birthDate
        : extractedPolicyHolderBirthDate();
    if (date) {
        form.field('policyHolderBirthDate').patch(date);
    }
}

function extractedPolicyHolderBirthDate(): string | undefined {
    let extractedBirthDate: Date | undefined;
    if (policyHolderIsFromBalticCountries.value && !policyHolderCodeIsWithoutDate.value) {
        extractedBirthDate = extractDataService.birthDateFromPersonCode(
            form.field('policyHolderIdentityNumber').value,
            countryField().iso,
        );
    }

    return extractedBirthDate ? OneDate.iris(extractedBirthDate) : undefined;
}

function onSameAsInsuredClick(): void {
    if (policyHolderSameAsInsured.value) {
        patchHolderAsInsured();
    } else {
        patchHolderAsGuest();
    }
    updatePolicyHolderBirthDate();
    storeValues();
}

function patchHolderAsInsured(): void {
    form.field('policyHolderSameAsInsured').patch(true);
    form.field('policyHolderCountry').patch(rentersService.fields.insuredCountry as CountryComponentParams);
    form.field('policyHolderIsResident').patch(rentersService.fields.insuredIsResident);
    form.field('policyHolderFirstName').patch(rentersService.fields.insuredFirstName);
    form.field('policyHolderLastName').patch(rentersService.fields.insuredLastName);
    form.field('policyHolderIdentityNumber').patch(rentersService.fields.insuredIdentityNumber);
    form.field('policyHolderBirthDate').patch(rentersService.fields.insuredBirthDate);
    form.field('policyHolderPhone').setValue(
        rentersService.fields.policyHolderSameAsInsured
            ? ({
                  code: '',
                  iso: '',
                  country: new AppCountry().iso(),
                  phone: '',
              } as PhoneField)
            : ({
                  code: rentersService.fields.insuredCountry.ic,
                  iso: rentersService.fields.insuredCountry.iso,
                  country: rentersService.fields.insuredCountry.phoneCode,
                  phone: '',
              } as PhoneField),
    );
    updatePolicyHolderBirthDate();
    storeValues();
}

function patchHolderAsGuest(): void {
    form.field('policyHolderSameAsInsured').patch(false);
    form.field('policyHolderIsResident').patch(true);
    form.field('policyHolderFirstName').clear();
    form.field('policyHolderLastName').clear();
    form.field('policyHolderIdentityNumber').clear();
    form.field('policyHolderCountry').clear();
    form.field('policyHolderBirthDate').clear();
    form.field('policyHolderPhone').setValue({
        code: '',
        iso: '',
        country: new AppCountry().iso(),
        phone: '',
    } as PhoneField);
    form.markAsUntouched();
}

function restoreValues(): void {
    form.restoreValues({
        policyStartDate: rentersService.fields.policyStartDate,
        policyHolderSameAsInsured: rentersService.fields.policyHolderSameAsInsured,
        policyHolderIsResident: rentersService.fields.policyHolderIsResident,
        policyHolderCountry: policyHolderSameAsInsured.value
            ? rentersService.fields.insuredCountry
            : rentersService.fields.policyHolderCountry,
        policyHolderBirthDate: rentersService.fields.policyHolderBirthDate,
        marketingConsents: rentersService.fields.marketingConsents,
        policyHolderFirstName: rentersService.fields.policyHolderFirstName,
        policyHolderLastName: rentersService.fields.policyHolderLastName,
        policyHolderIdentityNumber: rentersService.fields.policyHolderIdentityNumber,
        policyHolderEmail: rentersService.fields.policyHolderEmail,
        policyHolderPhone: rentersService.fields.policyHolderPhone,
        agreeTerms: rentersService.fields.agreeTerms,
    });
    canStoreValues.value = true;
}

function storeValues(): void {
    if (canStoreValues.value) {
        rentersService.fields.policyStartDate = form.field('policyStartDate').value;
        rentersService.fields.policyHolderSameAsInsured = form.field('policyHolderSameAsInsured').value;
        rentersService.fields.policyHolderIsResident = form.field('policyHolderIsResident').value;
        rentersService.fields.policyHolderCountry = form.field('policyHolderCountry').value;
        rentersService.fields.policyHolderFirstName = form.field('policyHolderFirstName').value;
        rentersService.fields.policyHolderLastName = form.field('policyHolderLastName').value;
        rentersService.fields.policyHolderEmail = form.field('policyHolderEmail').value;
        rentersService.fields.policyHolderPhone = form.field('policyHolderPhone').value;
        rentersService.fields.policyHolderIdentityNumber = form.field('policyHolderIdentityNumber').value;
        rentersService.fields.policyHolderBirthDate = form.field('policyHolderBirthDate').value as string;
        rentersService.fields.marketingConsents = form.field('marketingConsents').value;
        rentersService.fields.agreeTerms = form.field('agreeTerms').value;
    }
}

function insuredNameSurname(): string {
    const firstName: string = rentersService.fields.insuredFirstName.toLowerCase();
    const lastName: string = rentersService.fields.insuredLastName.toLowerCase();

    return `${capitalize(firstName)} ${capitalize(lastName)}`;
}

function includedRiskIcs(): string[] {
    const options: DynamicDictionary | undefined = rentersService.fields.additionalOptions;
    let result: string[] = [];
    if (options) {
        result = Object.keys(options).filter((key) => options[key] === true);
    }

    return result;
}

function includedRisks(): CoverageRisk[] {
    return rentersService
        .selectedProduct()
        .risks.filter((item: CoverageRisk) => includedRiskIcs().includes(item.id) || item.isIncluded);
}

function transformedRisks(risks: CoverageRisk[]): DynamicDictionary[] {
    const result: DynamicDictionary[] = [];
    const deductibleIc: string = rentersService.selectedProduct()!.params.deductibleIc;
    risks.forEach((includedRisk: CoverageRisk): void => {
        const risk: DynamicDictionary = {};
        risk.ic = includedRisk.id;
        risk.isSelected = true;
        risk.sumInsured = includedRisk.insuredSum === 0 ? coverageSum() : includedRisk.insuredSum;
        risk.deductibleIc = deductibleIc;
        if (includedRisk.riskGroup === RentersRiskGroups.Liability) {
            const liabilityRisk: CoverageRisk | undefined = rentersService
                .selectedProduct()
                .risks.find((value) => value.id === 'LOSSES_CAUSED_TO_YOUR_NEIGHBOUR');
            const deductible: CoverageRisk | undefined = rentersService
                .selectedProduct()
                .risks.find((value) => value.id === 'DEDUCTIBLE' && value.riskGroup === RentersRiskGroups.Liability);
            risk.sumInsured = liabilityRisk?.insuredSum;
            risk.sumInsuredOneTime = liabilityRisk?.insuredSum;
            risk.deductible = deductible?.insuredSum;
        }
        result.push(risk);
    });

    return result;
}

function programTitle(): string {
    return rentersService.selectedProduct().id === RentersPrograms.Renter ? 'renter_details' : 'renters+_details';
}

function optionPrice(ic: string): number {
    let price: number = 0;
    const risk: CoverageRisk | undefined = rentersService.selectedProduct().risks.find((value) => value.id === ic);
    if (risk) {
        price = risk.price;
    }

    return price;
}

function optionPriceText(ic: string): string {
    const price: number = optionPrice(ic);

    return sparsePrice(price, false) + ' ' + translate('btar_policy_price_text');
}

function calculateTotalPrice(): number {
    const basePrice: number = rentersService.selectedProduct().coveragePlans[coverageId()].price || 0;
    const risksIncluded: string[] = includedRiskIcs();
    const additionalPrice: number = risksIncluded.reduce((total, ic) => total + optionPrice(ic), 0);

    return basePrice + additionalPrice;
}

function coveredButtonParams(): ButtonWithCallbackParams {
    return {
        title: translateForType('what_covered', TranslationType),
        textColor: ButtonTextColor.Black,
        backgroundColor: ButtonBackground.White,
        backgroundColorHover: ButtonBackground.White,
        icon: ButtonIcon.Covered,
        iconColor: ButtonIconColor.Green,
        borderColor: ButtonBorder.Pale,
    };
}
</script>

<template>
    <div v-if="form.isReady()" class="step-container content-text">
        <app-custom-form v-if="viewIsReady" :form="form" :form-sanitize-skip="true" class="form">
            <section class="header">
                <h2 class="title">{{ translateForType('summary_and_payment_title', TranslationType) }}</h2>
                <div class="description">
                    {{ translateForType('summary_and_payment_description', TranslationType) }}
                </div>
            </section>
            <div class="summary-form">
                <section id="whiteboard-0" class="whiteboard">
                    <div class="whiteboard-title">
                        {{ translateForType('summary_policy_details', TranslationType) }}
                    </div>
                    <ul class="list details">
                        <li class="item" data-type="list-0-item-0">
                            <span>{{ translateForType('summary_object', TranslationType) }}</span>
                            <span
                                v-if="rentersService.fields.programIc === RentersPrograms.RenterPlus"
                                class="item-name"
                                >{{ translateForType('summary_object_value', TranslationType) }}</span
                            >
                            <span v-else class="item-name">{{
                                translateForType('summary_object_value_necessary', TranslationType)
                            }}</span>
                        </li>
                        <li class="item" data-type="list-0-item-1">
                            <span>{{ translateForType('sum_insured', TranslationType) }}</span>
                            <span class="item-name">{{ sparsePrice(coverageSum(), true) }}&nbsp;&euro;</span>
                        </li>
                        <li class="item" data-type="list-0-item-2">
                            <span>{{ translateForType('summary_address', TranslationType) }}</span>
                            <span class="item-name">{{ insuredAddressValue }}</span>
                        </li>
                        <li v-if="insuredAddressApartmentValue !== ''" class="item" data-type="list-0-item-3">
                            <span>{{ translateForType(insuredAddressDetailedLabel, TranslationType) }}</span>
                            <span class="item-name">{{ insuredAddressApartmentValue }}</span>
                        </li>
                        <li class="item" data-type="list-0-item-4">
                            <span>{{ translateForType('insured_person', TranslationType) }}</span>
                            <span class="item-name">
                                {{ insuredNameSurname() }} <br />
                                {{ rentersService.fields.insuredIdentityNumber }}
                            </span>
                        </li>
                    </ul>
                    <app-button-with-callback v-bind="coveredButtonParams()" @button-callback-click="onCoveredClick">
                    </app-button-with-callback>
                </section>
                <section id="whiteboard-1" class="whiteboard">
                    <div class="whiteboard-title">{{ translateForType('insured_period', TranslationType) }}</div>
                    <div class="title">{{ translateForType('policy_start_date', TranslationType) }}</div>
                    <app-input-date-with-calendar
                        class="policy-start-date"
                        :form-field="form.field('policyStartDate')"
                        :min-date="policyStartMinDate"
                        :max-date="policyStartMaxDate"
                        :number-of-calendars="1"
                        @change="storeValues()"
                    >
                    </app-input-date-with-calendar>
                    <div class="list">
                        <div class="item">
                            <span>{{ translateForType('policy_period', TranslationType) }}</span>
                            <span class="item-name">{{ policyStartDate + ' - ' + policyEndDate }}</span>
                        </div>
                    </div>
                </section>
                <section id="whiteboard-2" class="whiteboard">
                    <div class="whiteboard-title">{{ translateForType('payment_details', TranslationType) }}</div>
                    <ul class="list details">
                        <li class="item" data-type="list-2-item-0">
                            <span>{{ translateForType(programTitle(), TranslationType) }}</span>
                            <span class="item-name">{{
                                productPrice() + ' ' + translate('btar_policy_price_text')
                            }}</span>
                        </li>
                        <li
                            v-for="(ic, index) in includedRiskIcs()"
                            :key="index"
                            class="item"
                            :data-type="'list-2-item-1-' + index"
                        >
                            <span>{{ translateForType(ic, TranslationType) }}</span>
                            <span class="item-name">{{ optionPriceText(ic) }}</span>
                        </li>
                        <li class="item" data-type="list-2-item-2">
                            <span>{{ translateForType('summary_number_of_payments', TranslationType) }}</span>
                            <span class="item-name">12</span>
                        </li>
                        <li class="item total inline" data-type="list-2-item-3">
                            <span class="item-name payment">{{
                                translateForType('total_payment', TranslationType)
                            }}</span>
                            <span class="item-total">{{
                                sparse(calculateTotalPrice(), false) + ' ' + translate('btar_policy_price_text')
                            }}</span>
                        </li>
                    </ul>
                </section>
                <section id="whiteboard-3" class="whiteboard">
                    <app-popup-opener
                        v-if="!isAuthenticatedUser"
                        class="login-opener"
                        :button-params="{ title: translateForType('bank_login', TranslationType) }"
                    >
                    </app-popup-opener>
                    <div class="whiteboard-title">{{ translateForType('policyholder_title', TranslationType) }}</div>
                    <app-input-checkbox
                        v-if="!isAuthenticatedUser"
                        :form-field="form.field('policyHolderSameAsInsured')"
                        @click="onSameAsInsuredClick"
                    >
                        {{ translateForType('same_as_insured', TranslationType) }}
                    </app-input-checkbox>
                    <app-input-checkbox
                        v-if="!form.field('policyHolderSameAsInsured').value && !isAuthenticatedUser"
                        :form-field="form.field('policyHolderIsResident')"
                        @click="storeValues()"
                    >
                        {{ translateForType('is_resident', TranslationType) }}
                    </app-input-checkbox>
                    <app-input-country
                        v-if="
                            (!policyHolderSameAsInsured || !insuredPersonIsResident) &&
                            !isResident &&
                            !isAuthenticatedUser
                        "
                        :disabled="policyHolderSameAsInsured"
                        :include-default-country="false"
                        :patch-default-country="false"
                        :disable-error-text="true"
                        :form-field="form.field('policyHolderCountry')"
                        :label="translate('btar_country')"
                        :placeholder="translate('btar_country_placeholder')"
                        @close="storeValues()"
                    >
                    </app-input-country>
                    <div class="credentials three-columns">
                        <app-input-text
                            :disabled="policyHolderSameAsInsured || isAuthenticatedUser"
                            :label="translate('btar_name')"
                            :form-field="form.field('policyHolderFirstName')"
                            :placeholder="translate('btar_first_name_placeholder')"
                            @input="storeValues()"
                        >
                        </app-input-text>
                        <app-input-text
                            :disabled="policyHolderSameAsInsured || isAuthenticatedUser"
                            :label="translate('btar_surname')"
                            :form-field="form.field('policyHolderLastName')"
                            :placeholder="translate('btar_last_name_placeholder')"
                            :autocomplete="'<?php echo AutocompleteAttributes::LastName; ?>'"
                            @input="storeValues()"
                        >
                        </app-input-text>
                        <app-input-identity-number
                            :disabled="policyHolderSameAsInsured || isAuthenticatedUser"
                            :form-field="form.field('policyHolderIdentityNumber')"
                            :country="country"
                            :resident="isResident"
                            :label="translate('btar_personal_code')"
                            :placeholder="translate('btar_identity_number_placeholder')"
                            @input="storeValues()"
                        >
                        </app-input-identity-number>
                    </div>
                    <div
                        v-if="
                            (policyHolderSameAsInsured && (insuredBirthDateRequired || birthDateRequired)) ||
                            (birthDateRequired && !isAuthenticatedUser && !policyHolderSameAsInsured)
                        "
                        class="birth-date"
                    >
                        <app-input-date
                            :disabled="policyHolderSameAsInsured"
                            :validate-birth-date="true"
                            :include-default-country="false"
                            :form-field="form.field('policyHolderBirthDate')"
                            :label="translate('btar_birth_date')"
                            @change="storeValues()"
                        >
                        </app-input-date>
                        <div class="date-hint">
                            {{ translateForType('day_month_year', TranslationType) }}
                        </div>
                    </div>
                    <div class="credentials three-columns" @change="storeValues()">
                        <app-input-email
                            :label="translate('btar_email')"
                            :form-field="form.field('policyHolderEmail')"
                            :placeholder="translate('btar_email_placeholder')"
                        >
                        </app-input-email>
                        <app-phone-with-country
                            :allow-empty="false"
                            :label="translate('btar_phone')"
                            :form-field="form.field('policyHolderPhone')"
                            :placeholder="translate('btar_phone_placeholder')"
                        >
                        </app-phone-with-country>
                    </div>
                </section>
                <section id="whiteboard-4" class="whiteboard" @input="storeValues()">
                    <div class="whiteboard-title">
                        {{ translateForType('summary_terms_and_agreements', TranslationType) }}
                    </div>
                    <div
                        class="purchase-terms"
                        v-html="translateForType('description_terms_and_agreements', TranslationType)"
                    ></div>
                    <hr class="line-break" />
                    <app-input-checkbox class="terms-checkbox" :form-field="form.field('agreeTerms')">
                        {{ translateForType('checkbox_agree_terms', TranslationType) }}
                    </app-input-checkbox>
                    <hr class="line-break" />
                    <app-consents-list v-if="showMarketingConsents" :form-field="form.field('marketingConsents')">
                    </app-consents-list>
                </section>
                <app-subscribe-and-pay
                    :amount="calculateTotalPrice()"
                    :callback="onConfirmAndPayClick"
                    :wrapper-classes="'whiteboard'"
                    :wrapper-id="'whiteboard-5'"
                ></app-subscribe-and-pay>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.step-container {
    width: 100%;

    .header {
        padding: 0 var(--size-small);

        @include respond-above('sm') {
            padding: 0 var(--size-big);
        }

        .title,
        .description {
            text-align: center;
        }

        .title {
            font-size: var(--font-size-big);
        }

        .description {
            margin-top: var(--size-tiny);
            color: var(--text-color-subtlest);
            font-size: var(--font-size-tiny);
        }
    }

    :deep(.subscribe-and-pay-heading) {
        font-size: var(--font-size-big);
        font-weight: 700;
        gap: var(--size-tiny);
        margin-top: calc(var(--size-big) - var(--size-normal));
        text-align: center;
        width: 100%;

        @include respond-above('xs') {
            padding-left: 20%;
            padding-right: 20%;
        }
    }

    .summary-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--size-small);
        margin-top: var(--size-big);
        padding: var(--size-small);

        @include respond-above('sm') {
            gap: var(--size-normal);
            align-items: center;
        }

        :deep(.whiteboard) {
            display: flex;
            padding: var(--size-small);
            flex-direction: column;
            align-items: flex-start;
            border-radius: var(--size-tiny);
            background: var(--component-color-background-base);
            gap: var(--size-small);
            width: 100%;
            max-width: 800px;

            @include respond-above('sm') {
                padding: var(--size-big);
            }
        }

        .whiteboard {
            .login-opener {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @include respond-above('sm') {
                    align-items: flex-end;
                    position: absolute;
                    max-width: 720px;
                }
            }

            .whiteboard-title {
                color: var(--text-color-default);
                font-size: var(--font-size-small);
                font-weight: 700;
                word-break: break-word;
            }

            .input-date-with-calendar {
                width: 100%;
            }

            .input-date {
                .wrapper {
                    height: 52px;
                    justify-content: left;
                    padding-left: var(--size-small);
                }
            }

            .purchase-terms {
                font-size: var(--font-size-nano);
            }

            .terms-checkbox {
                :deep(label) {
                    color: var(--text-color-default);
                    font-weight: 400;
                }
            }

            .button-with-callback {
                width: 100%;
                height: 52px;
                font-size: var(--font-size-tiny);
                font-weight: 600;
            }

            .line-break {
                width: 100%;
                height: 1px;
                background-color: var(--component-color-border-default);
            }

            .list {
                width: 100%;
                border-radius: var(--size-pico);
                border: 1px solid var(--black-100);
                display: flex;
                flex-direction: column;

                .item {
                    display: flex;
                    padding: var(--size-small);
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: var(--size-pico);
                    align-self: stretch;
                    font-size: var(--font-size-nano);

                    @include respond-above('sm') {
                        flex-direction: row;
                        justify-content: space-between;
                        padding: var(--size-small);
                    }

                    &.inline {
                        display: flex;
                        padding: var(--size-small);
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-start;
                        gap: var(--size-pico);
                        align-self: stretch;
                        font-size: var(--font-size-nano);
                        text-align: left;
                    }

                    &.total {
                        background: var(--system-color-success-light);
                    }

                    .payment {
                        max-width: 120px;

                        @include respond-above('sm') {
                            max-width: 100%;
                        }
                    }

                    .item-total {
                        font-size: var(--font-size-medium);
                        font-weight: 700;
                    }

                    .currency {
                        font-size: var(--font-size-tiny);
                        font-weight: 600;
                    }

                    .item-name {
                        font-weight: 600;

                        @include respond-above('sm') {
                            width: auto;
                            text-align: right;
                        }
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid var(--black-100);
                    }
                }
            }

            .input-country {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
            }

            .credentials {
                display: flex;
                width: 100%;
                justify-content: space-between;
                flex-direction: column;
                align-items: stretch;
                gap: inherit;

                @include respond-above('sm') {
                    flex-direction: row;
                    gap: var(--size-small);
                }

                &.three-columns {
                    .input {
                        @include respond-above('sm') {
                            width: 100%;
                        }
                    }
                }
            }

            .birth-date {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--size-nano);
                align-self: stretch;

                :deep(.input-date) {
                    width: 100%;

                    .wrapper {
                        padding: var(--size-small);
                        height: 52px;
                        justify-content: left;
                    }
                }

                .date-hint {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    align-self: stretch;
                    color: var(--text-color-subtlest);
                    font-feature-settings: 'liga' off;
                    font-size: var(--font-size-nano);
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    flex: 100;
                }
            }

            .payment {
                width: 100%;
            }

            .title {
                display: flex;
                justify-content: space-between;
                font-size: var(--font-size-small);
                font-weight: 600;
                width: 100%;
            }

            .title-logos {
                text-align: end;
                display: flex;
            }

            .title-logos-logo {
                padding: 0 0 0 var(--size-pico);
            }
        }
    }

    .full-width {
        width: 100%;
    }

    .half-children {
        .input {
            @include respond-above('sm') {
                width: 50%;
            }
        }
    }

    .flex {
        display: flex;
        gap: var(--size-small);

        &.column {
            flex-direction: column;
        }

        &.mobile-column {
            flex-direction: column;

            @include respond-above('sm') {
                flex-direction: row;
            }
        }
    }
}
</style>
