<script setup lang="ts">
import FormField from '@/Assets/Libraries/Form/FormField';
import { computed, ComputedRef, onMounted, PropType, ref, Ref, watch } from 'vue';
import Vehicles from '@/Services/vehicles.service';
import { Subscription } from 'rxjs';
import { Vehicle } from '@/Interfaces/Resources/vehicle.interface';
import Form from '@/Assets/Libraries/Form/Form';
import { useTranslate } from '@/Composables/Translate';
import { useStrings } from '@/Composables/Strings';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import ImageTagAttributes from '@/Interfaces/image.tag.attributes';
import { useHtml } from '@/Composables/Html';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import Sanitizer from '@/Services/sanitizer.service';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';

const props = defineProps({
    formField: { type: Object as PropType<FormField<Vehicle | undefined>>, default: () => new FormField('') },
    placeholder: { type: String, default: '' },
    label: { type: String, default: '' },
    required: { type: Boolean, default: false },
    vehicleTypeWeb: { type: String, default: '' },
    dataStoreDisabled: { type: Boolean, default: false },
});

const { translate } = useTranslate();
const { capitalize } = useStrings();

const listOverflowThreshold: number = 5;
const filterLengthThreshold: number = 15;

const filter: Ref<string> = ref('');
const dropdownIsOpen: Ref<boolean> = ref(false);

const showScroll: Ref<boolean> = computed((): boolean => filteredOptions.value.length > listOverflowThreshold);
const fieldId: ComputedRef<string> = computed(() => props.formField.name + '-text');

watch(
    () => props.vehicleTypeWeb,
    () => {
        setupVehicleOptions();
        selectCurrentOption();
    },
);

const vehiclesService: Vehicles = Vehicles.getInstance();
const form: Form = new Form();
let onExternalDataIsReadySubscription!: Subscription;
const vehicles: Ref<Vehicle[]> = ref([]);
const options: Ref<InputOption[]> = ref([]);

const filteredOptions: Ref<InputOption[]> = computed((): InputOption[] => {
    return filter.value
        ? options.value.filter(
              (option: InputOption): boolean =>
                  option.name.toLowerCase().startsWith(filter.value) ||
                  option.name.toLowerCase().includes(filter.value) ||
                  option.value === 'other',
          )
        : options.value;
});

onMounted((): void => {
    if (vehiclesService.ready) {
        init();
    } else {
        onExternalDataIsReadySubscription = vehiclesService.onExternalDataIsReady.subscribe((): void => {
            init();
        });
        onExternalDataIsReadySubscription.unsubscribe();
    }
});

function selectCurrentOption(): void {
    if (props.formField.value) {
        const currentOptionId: string = String(props.formField.value.id);
        const currentOption: InputOption | undefined = options.value.find(
            (option: InputOption): boolean => option.value === currentOptionId || option.value === 'other',
        );
        if (currentOption) {
            const selectedVehicle: Vehicle | undefined = vehicles.value.find(
                (vehicle: Vehicle): boolean => vehicle.id.toString() === currentOption.value,
            );
            if (selectedVehicle) {
                props.formField.patch(selectedVehicle);
                filter.value = selectedVehicle.model;
                form.field('filter').patch(selectedVehicle.model);
            }
        }
    }
}

function init(): void {
    setupForm();
    fetchVehicles();
    addOtherVehicle();
    setupVehicleOptions();
    selectCurrentOption();
}

function setupForm(): void {
    form.addField(new FormField('filter', '', '', vehicleMakeSanitizer));
}

function vehicleMakeSanitizer(value: string): string {
    return Sanitizer.cleanName(value).substring(0, filterLengthThreshold);
}

function fetchVehicles(): void {
    vehicles.value = JSON.parse(JSON.stringify(vehiclesService.vehicleModels()));
}

function setupVehicleOptions(): void {
    options.value = [];
    const source: Vehicle[] = props.vehicleTypeWeb
        ? vehicles.value.filter(
              (vehicle: Vehicle): boolean =>
                  vehicle.vehicleTypeWeb === '' || vehicle.vehicleTypeWeb.includes(props.vehicleTypeWeb),
          )
        : vehicles.value;
    source.forEach((vehicle: Vehicle): void => {
        options.value.push(
            new InputOptionBuilder().setValue(vehicle.id.toString()).setName(capitalized(vehicle.model)).build(),
        );
    });
}

function addOtherVehicle(): void {
    vehicles.value.push({
        id: 'other',
        model: translate('repair_partners_vehicle_make_other'),
        vehicleTypeWeb: '',
    } as Vehicle);
}

function capitalized(value: string): string {
    return value
        .split(' ')
        .map((word: string): string => capitalize(word))
        .join(' ');
}

function onInput(value: string): void {
    dropdownIsOpen.value = true;
    filter.value = String(value).toLowerCase();
    if (value === '') {
        props.formField.setValue(undefined);
    }
}

function onOptionClick(optionId: LimitedVariant): void {
    dropdownIsOpen.value = false;
    const selectedOption: Vehicle | undefined = vehicles.value.find(
        (vehicle: Vehicle): boolean => vehicle.id.toString() === optionId,
    );
    if (selectedOption) {
        props.formField.patch(selectedOption);
        filter.value = selectedOption.model;
        form.field('filter').patch(selectedOption.model);
    }
}

function onShowDropdownClick(): void {
    if (dropdownIsOpen.value) {
        selectCurrentOption();
    } else {
        filter.value = '';
    }
    dropdownIsOpen.value = !dropdownIsOpen.value;
}

function onCloseFromOutside(): void {
    dropdownIsOpen.value = false;
    selectCurrentOption();
}

function dropdownIcon(): string {
    return useHtml().imgTag(dropdownIconAttributes());
}

function dropdownIconAttributes(): ImageTagAttributes {
    return {
        class: '',
        src: 'images/one/dropdown-black.svg',
        width: 14,
        height: 8,
    };
}
</script>

<template>
    <div
        :id="formField.name"
        v-click-outside="onCloseFromOutside"
        class="input input-search-vehicle-make"
        :class="formField.classes()"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div v-if="label" class="label informative">
            <label :for="fieldId"> {{ label }}&nbsp;<span v-if="required" class="asterisk">&#42;</span> </label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="container">
            <button
                id="vehicle-make-dropdown-open"
                class="show-dropdown"
                :class="{ flip: dropdownIsOpen }"
                @click="onShowDropdownClick()"
                v-html="dropdownIcon()"
            ></button>
            <app-input-text
                :form-field="form.field('filter')"
                :placeholder="placeholder"
                @input="onInput($event)"
            ></app-input-text>
            <ul v-show="dropdownIsOpen" class="options-list" :class="{ 'with-scroll': showScroll }">
                <li v-for="(option, index) in filteredOptions" :key="index" class="item">
                    <button
                        :id="'vehicle-option-selector-' + index"
                        class="item-button"
                        @click="onOptionClick(option.value)"
                    >
                        {{ option.name }}
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-search-vehicle-make {
    width: 100%;
    scroll-margin-top: 4em;

    label {
        color: var(--text-color-default);
    }

    .container {
        position: relative;

        .input-text {
            width: 100%;
        }

        .options-list {
            border: 1px solid var(--black-200);
            border-top: none;
            position: absolute;
            width: 100%;
            background: var(--white);
            z-index: 2;
            box-shadow: var(--box-shadow-basic);
            animation: show 0.5s;

            .item {
                scroll-snap-align: start;

                &:not(:last-child) {
                    border-bottom: 1px solid var(--black-200);
                }

                .item-button {
                    font-size: var(--font-size-tiny);
                    font-weight: bold;
                    color: var(--text-color-subtlest);
                    padding-left: var(--size-small);
                    width: 100%;
                    min-height: 52px;
                    text-align: left;

                    &:hover {
                        color: var(--brand-red);
                    }
                }
            }

            &.with-scroll {
                height: 260px;
                scroll-snap-type: y proximity;
                overflow-y: scroll;
                scrollbar-color: var(--color-grey-non-transparent);
                scrollbar-width: thin;

                &::-webkit-scrollbar-thumb {
                    background-color: var(--color-grey-non-transparent);
                }
            }
        }
    }

    .show-dropdown {
        z-index: 1;
        top: 0;
        right: 0;
        position: absolute;
        height: 52px;
        width: 52px;
        filter: var(--to-color-grey);

        &:hover {
            filter: var(--to-color-green);
        }

        &.flip {
            transform: rotate(180deg);
        }
    }

    @keyframes show {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}
</style>
