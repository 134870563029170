import { reactive, UnwrapNestedRefs } from 'vue';
import ClaimsTravelFormFields from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelFormFields';
import ClaimsTravelObject from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelObject';
import ClaimsTravelStorageFields from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelStorageFields';
import SpaFormStorageService from '@/Services/SpaFormStorageService';
import Form from '@/Assets/Libraries/Form/Form';
import TravelType from '@/Apps/ClaimsTravel/Interfaces/TravelType';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import { Router, useRouter } from 'vue-router';
import { useDefine } from '@/Composables/Define';
import ClaimsTravelStepUid from '@/Apps/ClaimsTravel/Enums/ClaimsTravelStepUid';
import { FormNames as AccidentDetailsFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/FormNames';
import { FormNames as InjuryFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Injury/FormNames';
import { FormNames as OtherFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Other/FormNames';
import { FormNames as TravelChangesFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/TravelChanges/FormNames';
import { FormNames as TravelCancellationFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Cancellation/FormNames';
import { FormNames as LuggageFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Luggage/FormNames';
import { FormNames as UploadFilesFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/UploadFiles/FormNames';
import { FormNames as RecipientDataFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/RecipientData/FormNames';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import FormField from '@/Assets/Libraries/Form/FormField';
import { FormStorage } from '@/Apps/ClaimsTravel/Interfaces/Forms/FormStorage';
import PersonDetails from '@/Interfaces/PersonDetailsInterface';

export default class ClaimsTravelService {
    public static translationType: string = 'claims_travel';
    public storageFields: UnwrapNestedRefs<ClaimsTravelStorageFields> = reactive(
        new (class implements ClaimsTravelStorageFields {
            public allInsuredObjects: ClaimsTravelObject[] = [];
            public personInsuredObjects: ClaimsTravelObject[] = [];
            public formVisibility: Partial<{ [FormKey in keyof ClaimsTravelFormFields]: boolean }> = {};
            public isSubFlowVisible: boolean = false;
            public personFromPolicy: PersonDetails | null = null;
        })(),
    );
    public fields!: UnwrapNestedRefs<Partial<ClaimsTravelFormFields>>;
    public initialStepUrl: string = '';
    public thankYouStepUrl: string = '';
    public tripCancellationRiskIc: string = '';
    public tripInterruptionReasons: string[] = [];
    public whatWasDamaged: string[] = [];
    public travelTypes: TravelType[] = [];
    public whatWasDamagedEnabled: boolean = false;

    private static instance: ClaimsTravelService;
    private spaFormStorageService: SpaFormStorageService = SpaFormStorageService.getInstance();
    private transferStateService: TransferStateService = TransferStateService.getInstance();
    private router: Router = useRouter();

    public static getInstance(): ClaimsTravelService {
        if (!ClaimsTravelService.instance) {
            ClaimsTravelService.instance = new ClaimsTravelService();
        }

        return ClaimsTravelService.instance;
    }

    public init(): void {
        this.restoreStorage();
        this.applyFormStorageProviderCallback();
        this.tripInterruptionReasons = this.transferStateService.get('tripInterruptionReasons');
        this.travelTypes = this.transferStateService.get('travelTypes');
        this.whatWasDamaged = this.transferStateService.get('whatWasDamaged');
        this.whatWasDamagedEnabled = this.transferStateService.get('whatWasDamagedEnabled');
    }

    public storeForm<FormKey extends keyof ClaimsTravelFormFields>(
        formName: FormKey,
        form: Form<ClaimsTravelFormFields[FormKey]>,
    ): void {
        this.fields[formName] = form
            .fields()
            .reduce((accumulator: Partial<ClaimsTravelFormFields[FormKey]>, field: FormField) => {
                accumulator[field.name as keyof ClaimsTravelFormFields[FormKey]] = field.value;

                return accumulator;
            }, {}) as ClaimsTravelFormFields[FormKey];
    }

    public restoreForms(
        forms: Partial<{
            [FormKey in keyof ClaimsTravelFormFields]: Form<ClaimsTravelFormFields[FormKey]>;
        }>,
    ): void {
        Object.entries(forms).forEach(([formName, form]): void => {
            this.restoreForm(formName as keyof ClaimsTravelFormFields, form);
        });
    }

    private restoreForm<
        FormKey extends keyof ClaimsTravelFormFields,
        FieldKey extends keyof ClaimsTravelFormFields[FormKey],
    >(formName: FormKey, form: Form<ClaimsTravelFormFields[FormKey]>): void {
        const formFields = this.fields[formName] as ClaimsTravelFormFields[FormKey];

        if (formFields !== undefined) {
            form.fields()
                .map((field: FormField) => field.name)
                .forEach((fieldName: string): void => {
                    form.field(fieldName as FieldKey).setValue(formFields[fieldName as FieldKey], true);
                });
        }
    }

    public clearStepsAhead(): void {
        switch (this.router.currentRoute.value.name) {
            case ClaimsTravelStepUid.AccidentData: {
                const forms = {
                    ...AccidentDetailsFormNames,
                    ...InjuryFormNames,
                    ...TravelChangesFormNames,
                    ...TravelCancellationFormNames,
                    ...OtherFormNames,
                    ...LuggageFormNames,
                    ...UploadFilesFormNames,
                    ...RecipientDataFormNames,
                };
                this.clearForm(forms);
                this.storageFields.isSubFlowVisible = false;
                break;
            }
            case ClaimsTravelStepUid.AccidentDetails: {
                const forms = {
                    ...UploadFilesFormNames,
                    ...RecipientDataFormNames,
                };
                this.clearForm(forms);
                break;
            }
            default:
        }
    }

    private clearForm(forms: DynamicDictionary): void {
        Object.keys(forms).forEach((key): void => {
            const fieldName: keyof ClaimsTravelFormFields = forms[key as keyof ClaimsTravelFormFields];
            if (useDefine().isSet(this.fields[fieldName])) {
                delete this.fields[fieldName];
                this.storageFields.formVisibility[fieldName as keyof typeof this.storageFields.formVisibility] = false;
            }
        });
    }

    private restoreStorage(): void {
        const storage: Partial<FormStorage> = this.spaFormStorageService.formStorageFields<Partial<FormStorage>>();
        this.fields = storage.fields ?? {};
        this.storageFields = storage.storageFields ? reactive(storage.storageFields) : this.storageFields;
    }

    private applyFormStorageProviderCallback(): void {
        this.spaFormStorageService.applyDataProviderCallback((): FormStorage => {
            return {
                fields: this.fields,
                storageFields: this.storageFields,
            };
        });
    }
}
