<script lang="ts">
import { computed, defineComponent, reactive, Ref, ref, nextTick, UnwrapNestedRefs } from 'vue';
import Error from '@/Services/error.service';
import OneBaseService from '@/Services/OneBaseService';
import { useModule } from '@/Composables/Module';
import OneModule from '@/Interfaces/OneModuleInterface';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { Observable, of } from 'rxjs';
import { FileByLink } from '@/Interfaces/Resources/file.by.link.interface';
import FileUploadCategory from '@/Interfaces/file.upload.category';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import Url from '@/Enums/UrlEnum';
import { useDefine } from '@/Composables/Define';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import { useTranslate } from '@/Composables/Translate';
import { AxiosResponse } from 'axios';
import moment from 'moment/moment';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { useScroll } from '@/Composables/Scroll';
import { FileUploadParams, useFileUpload } from '@/Composables/FileUpload';

const { validResponse, isSet } = useDefine();
const { translate } = useTranslate();

export default defineComponent({
    setup() {
        const error: Error = Error.getInstance();

        const module: OneModule = useModule();
        const fileByLinkUpload: FileUploadParams = useFileUpload();
        const request: AxiosParams = useAxios();

        const ready: Ref<boolean> = ref(false);
        const isLoading: Ref<boolean> = ref(false);
        const isSuccessMessage: Ref<boolean> = ref(false);
        const descriptionMessageText: Ref<string> = ref('btar_files_by_link_expired');
        const filesByLink: UnwrapNestedRefs<FileByLink> = reactive(
            new (class implements FileByLink {
                public clientMessageText: string = '';
                public clientMessageType: string = '';
                public documentUuid: string = '';
                public documentId: string = '';
                public documentNumber: string = '';
                public documentType: string = '';
                public filesUploadUrl: string = '';
                public id: string = '';
                public insuredObjectText: string = '';
                public lastOpened: string = '';
                public minimalFileCreationDate: number | null = null;
                public type: string = '';
                public uploadCategories: FileUploadCategory[] = [];
                public validTo: number | null = null;
            })(),
        );

        const UnixTimestampMultiplier: number = 1000;
        const uploader: string = 'files-by-link';
        const renew: boolean = true;

        const create = (): void => {};

        const beforeModuleMounted = () => {
            module.beforeModuleMounted();
            if (!ready.value) {
                ready.value = true;
                const onExternalDataIsReady: Observable<void> = module.ready.value ? of(void 0) : module.onModuleReady;
                onExternalDataIsReady.subscribe((): void => {
                    nextTick((): void => {
                        loadFilesByLink();
                        fileByLinkUpload.filesRequired.value = false;
                        module.applyModuleType();
                    });
                });
            }
        };

        const moduleMounted = () => {
            module.moduleMounted();
        };

        const prepareAndSubmit = (): void => {
            (fileByLinkUpload.filesUploader.value as DynamicDictionary).touch();
            if (isUploadValid()) {
                submit(documentParams());
            }
        };

        const restartUpload = (): void => {
            isSuccessMessage.value = false;
            loadFilesByLink();
        };

        const loadFilesByLink = (): void => {
            isLoading.value = true;
            const id: string = idFromUri();
            request
                .post(Url.Ajax.filesByLinkGetResource, { id })
                .then((value: DynamicDictionary) => {
                    if (validResponse(value)) {
                        Object.assign(filesByLink, value.data.data.body);
                    } else {
                        applyDescriptionMessage(value);
                    }
                })
                .catch((reason: DynamicDictionary) => {
                    showFailure(reason);
                })
                .finally(() => {
                    isLoading.value = false;
                });
        };

        const applyDescriptionMessage = (value: DynamicDictionary): void => {
            if (isSet(value.data.errors.code)) {
                descriptionMessageText.value = 'btar_files_by_link_' + String(value.data.errors.code).toLowerCase();
            }
        };

        const idFromUri = (): string => {
            const uriSegments: string[] = location.pathname.split('/');

            return uriSegments[uriSegments.length - 1];
        };

        const documentParams = (): Record<string, unknown> => {
            return {
                uploader: uploader,
                messageCode: filesByLink.clientMessageType.toLowerCase(),
                descriptionMessage: filesByLink.clientMessageText.toLowerCase(),
                documentType: filesByLink.documentType.toLowerCase(),
                documentUuid: filesByLink.documentUuid,
                documentId: filesByLink.documentId,
                documentNumber: filesByLink.documentNumber,
                fileByLinkId: idFromUri(),
            };
        };

        const isUploadValid = (): boolean => {
            return isUploaded.value && filesCount.value > 0;
        };

        const isEmptyString = (value: string): boolean => {
            return value.replace(/\s/g, '') === '';
        };

        const documentTypeTranslation = (type: string): string => {
            let translation: string;
            switch (type) {
                case 'POLICY':
                    translation = translate('btar_printout_agreement');
                    break;
                case 'PROPOSAL':
                    translation = translate('btar_printout_proposal');
                    break;
                case 'CLAIM_CASE':
                    translation = translate('btar_printout_claim_case');
                    break;
                default:
                    translation = type;
            }

            return translation;
        };

        const showSuccess = (): void => {
            (fileByLinkUpload.filesUploader.value as DynamicDictionary).clearUploads();
            isSuccessMessage.value = true;
            useScroll().scrollToTop();
        };

        const showFailure = (reason: DynamicDictionary): void => {
            error.show(ErrorType.Error, 'submitUpload', reason);
        };

        const submit = (params: Record<string, unknown>): void => {
            nextTick(() => {
                PopupService.getInstance().show(new OnePopup().withType().loading);
            });
            request
                .post(Url.Ajax.submitUpload, params)
                .then((value: AxiosResponse<DynamicDictionary>) => {
                    if (validResponse(value)) {
                        showSuccess();
                        (fileByLinkUpload.filesUploader.value as DynamicDictionary).endUpload();
                    } else {
                        throw value;
                    }
                })
                .catch((reason: DynamicDictionary) => {
                    showFailure(reason);
                })
                .finally(() => {
                    PopupService.getInstance().hide();
                });
        };

        const minDate: Ref<Date | null> = computed(() => {
            return isSet(filesByLink.minimalFileCreationDate)
                ? new Date(filesByLink.minimalFileCreationDate! * UnixTimestampMultiplier)
                : null;
        });

        const documentTitleText: Ref<string> = computed(() => {
            return documentTypeTranslation(filesByLink.documentType) + ' ' + filesByLink.documentNumber;
        });

        const insuredObjectText: Ref<string> = computed(() => {
            return filesByLink.insuredObjectText;
        });

        const clientMessageText: Ref<string> = computed(() => {
            return !isEmptyString(filesByLink.clientMessageType)
                ? translate('btar_client_message_type_' + filesByLink.clientMessageType.toLowerCase())
                : filesByLink.clientMessageText;
        });

        const isUploaded: Ref<boolean> = computed(() => {
            return fileByLinkUpload.filesUploader.value
                ? !(fileByLinkUpload.filesUploader.value as DynamicDictionary).filesNotUploaded
                : false;
        });

        const filesCount: Ref<number> = computed(() => {
            return fileByLinkUpload.filesUploader.value
                ? (fileByLinkUpload.filesUploader.value as DynamicDictionary).filesCount
                : 0;
        });

        const descriptionMessage: Ref<string> = computed(() => {
            return translate(descriptionMessageText.value);
        });

        const categories: Ref<string[]> = computed(() => {
            return filesByLink.uploadCategories.map((category) => category.caption);
        });

        const isFilesByLinkValid: Ref<boolean> = computed(() => {
            return (
                filesByLink.id !== '' &&
                (!filesByLink.validTo || moment.unix(filesByLink.validTo).isSameOrAfter(moment(), 'day'))
            );
        });

        return {
            ...module,
            ...{
                renew,
                isLoading,
                isSuccessMessage,
                uploader,
                fileByLinkUpload,
                UnixTimestampMultiplier,
                descriptionMessageText,
                filesByLink,
                minDate,
                documentTitleText,
                insuredObjectText,
                clientMessageText,
                isUploaded,
                filesCount,
                descriptionMessage,
                categories,
                isFilesByLinkValid,
                create,
                beforeModuleMounted,
                moduleMounted,
                prepareAndSubmit,
                restartUpload,
                showSuccess,
            },
        };
    },

    mounted() {
        OneBaseService.getInstance().applyApp(this);
    },
});
</script>
