import FormField from './FormField';
import { map, take, combineLatestAll, merge, Observable, Subject, Subscription, forkJoin } from 'rxjs';
import { computed, ComputedRef, markRaw, reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import FormConfig from '@/Assets/Libraries/Form/FormConfig';
import Value from '@/Assets/Libraries/Form/Value';

type AnyFieldValues = { [name: string]: any };
type Fields<Values extends AnyFieldValues> = { [K in keyof Values]: FormField<Values[K]> };

export default class Form<FieldValues extends AnyFieldValues = any> {
    public onChange: Subject<void> = new Subject();
    public onChangeAfterRestore: Subject<void> = new Subject();
    public onValidation: Subject<void> = new Subject();
    private readonly localConfig: Required<FormConfig>;
    private localFields: UnwrapNestedRefs<Fields<FieldValues>> = reactive({} as Fields<FieldValues>);
    private localStates: {
        valid: ComputedRef<boolean>;
        ready: Ref<boolean>;
        attempted: Ref<boolean>;
        touched: ComputedRef<boolean>;
        inputLocked: Ref<boolean>;
        restored: Ref<boolean>;
    } = {
        valid: computed((): boolean => this.visibleFields().every((field: FormField) => field.isValid)),
        ready: ref(false),
        attempted: ref(false),
        touched: computed((): boolean => this.fields().some((field: FormField) => field.isTouched)),
        inputLocked: ref(false),
        restored: ref(false),
    };
    private formName: Ref<string> = ref('');
    private subscriptions: Subscription[] = [];

    public constructor(config?: FormConfig);
    public constructor(name?: string, config?: FormConfig);
    public constructor(nameOrConfig?: string | FormConfig, config?: FormConfig) {
        let tmpName: string = '';
        let tmpConfig: FormConfig | undefined;
        if (typeof nameOrConfig === 'string') {
            tmpName = nameOrConfig;
            tmpConfig = config;
        } else {
            tmpConfig = nameOrConfig;
        }
        this.formName.value = tmpName;
        this.localConfig = {
            useValidationV2: tmpConfig?.useValidationV2 || false,
            errorOnlyAfterOnSubmit: tmpConfig?.errorOnlyAfterOnSubmit || false,
        };
    }

    public addField(formField: FormField): void {
        if (!this.exists(formField.name)) {
            formField.mount(this.localConfig);
            (this.localFields as any)[formField.name] = markRaw(formField);
            this.subscriptions.push(
                formField.onChange.subscribe((): void => {
                    this.validate().then((): void => {
                        this.onChange.next();
                        if (this.localStates.restored.value) {
                            this.onChangeAfterRestore.next();
                        }
                    });
                }),
                formField.onStateChange.subscribe((): void => {
                    this.validate().then();
                }),
            );
        }
    }

    public removeField<N extends keyof Fields<FieldValues>>(fieldName: N): FormField {
        const field: FormField = this.field(fieldName);
        field.destroy();
        delete (this.localFields as any)[fieldName];

        return field;
    }

    public fields(): FormField[] {
        return Object.values(this.localFields);
    }

    public visibleFields(): FormField[] {
        return this.fields().filter((field: FormField) => field.isVisible);
    }

    public invalidFields(): Partial<Fields<FieldValues>> {
        const invalidFields: Partial<Fields<FieldValues>> = {};
        this.visibleFields()
            .filter((field: FormField): boolean => field.isInvalid)
            .forEach((field: FormField): void => {
                (invalidFields as any)[field.name] = field;
            });
        return invalidFields;
    }

    public async submitAttempt(): Promise<void> {
        await Promise.all([this.validate(), this.markAsAttempted()]);
    }

    public async resetSubmitAttempt(): Promise<void> {
        await Promise.all([this.markAsNotAttempted()]);
    }

    public destroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
        this.subscriptions = [];
        Object.keys(this.localFields).forEach((fieldName: string) => this.removeField(fieldName));
    }

    public restoreValues(values: Partial<FieldValues>): void {
        const observables: Observable<any>[] = Object.keys(this.localFields)
            .filter((name: keyof FieldValues): boolean => new Value(values[name]).isNotEmpty())
            .map((name: keyof FieldValues): Observable<any> => {
                this.field(name).patch(values[name]!);
                this.field(name).markAsRestored();
                return this.field(name).onValueSet.pipe(take(1));
            });
        forkJoin(observables)
            .pipe(combineLatestAll())
            .subscribe({
                complete: (): void => {
                    this.localStates.restored.value = true;
                },
            });
    }

    public markAsNotRestored(): void {
        Object.keys(this.localFields).forEach((name: keyof FieldValues): void => {
            this.field(name).markAsNotRestored();
        });
    }

    /**
     * @depricated This method doesn't belong to form library. Must be moved to page/app where this is used.
     */
    public removeRow(rowIndex: number, indexDelimiter: string = '_'): void {
        this.removeFieldsByIndex(rowIndex, indexDelimiter).then((): void => {
            this.remapIndexedFieldNames(indexDelimiter);
        });
    }

    /**
     * @depricated This method doesn't belong to form library. Must be moved to page/app where this is used.
     */
    public invalidRows(indexDelimiter: string = '_'): number[] {
        const invalidRows: number[] = this.fields()
            .filter(
                (field: FormField): boolean => !field.isValid && field.isTouched && field.name.includes(indexDelimiter),
            )
            .map((field: FormField): number => Number(field.name.substring(field.name.indexOf(indexDelimiter) + 1)));

        return [...new Set(invalidRows)];
    }

    public create(formName: string = ''): Form<FieldValues> {
        this.formName.value = formName !== '' ? formName : 'form-' + String(Math.random()).replace('.', '');

        return this;
    }

    public field<N extends keyof Fields<FieldValues>>(fieldName: N): Fields<FieldValues>[N] {
        return (this.localFields as Fields<FieldValues>)[fieldName] || new FormField('');
    }

    public exists<N extends keyof Fields<FieldValues>>(fieldName: N): boolean {
        return Boolean((this.localFields as Fields<FieldValues>)[fieldName]);
    }

    public isValid(): boolean {
        return this.localStates.valid.value;
    }

    public isInvalid(): boolean {
        return !this.isValid();
    }

    public isReady(): boolean {
        return this.localStates.ready.value;
    }

    public isAttempted(): boolean {
        return this.localStates.attempted.value;
    }

    public isTouched(): boolean {
        return this.localStates.touched.value;
    }

    public isInputLocked(): boolean {
        return this.localStates.inputLocked.value;
    }

    public async markAsTouched(): Promise<void[]> {
        return Promise.all(this.visibleFields().map((field: FormField) => field.markAsTouched()));
    }

    public async markAsUntouched(): Promise<void[]> {
        return Promise.all(this.fields().map((field: FormField) => field.markAsUntouched()));
    }

    public async markAsFresh(): Promise<void[]> {
        return Promise.all(this.fields().map((field: FormField) => field.markAsFresh()));
    }

    public async markAsDirty(): Promise<void[]> {
        return Promise.all(this.visibleFields().map((field: FormField): void => field.markAsDirty()));
    }

    public async markAsPristine(): Promise<void[]> {
        return Promise.all(this.fields().map((field: FormField): void => field.markAsPristine()));
    }

    public async markAsAttempted(): Promise<void[]> {
        this.localStates.attempted.value = true;
        return Promise.all(this.fields().map((field: FormField): void => field.markAsAttempted()));
    }

    public sanitize(): void {
        this.fields().forEach((field) => field.sanitize());
    }

    public async clear(): Promise<void> {
        return Promise.all(this.fields().map((field) => field.clear()))
            .then(() => {
                this.onValidation.next();
            })
            .catch(() => {});
    }

    public setReady(): void {
        this.localStates.ready.value = true;
    }

    public lockInput(): void {
        this.localStates.inputLocked.value = true;
    }

    public unlockInput(): void {
        this.localStates.inputLocked.value = false;
    }

    public async validate(): Promise<void> {
        return Promise.all(this.fields().map((field) => field.validate()))
            .then(() => {
                this.onValidation.next();
            })
            .catch(() => {});
    }

    /**
     * @deprecated Use `submitAttempt()` instead.
     */
    public async touch(): Promise<void> {
        return new Promise((resolve): void => {
            this.visibleFields().forEach((field: FormField): void => {
                field.markAsTouched();
                field.markAsDirty();
            });
            resolve();
        });
    }

    public get name(): string {
        return this.formName.value;
    }

    private async markAsNotAttempted(): Promise<void[]> {
        this.localStates.attempted.value = false;
        return Promise.all(this.fields().map((field: FormField): void => field.markAsNotAttempted()));
    }

    private async removeFieldsByIndex(rowIndex: number, indexDelimiter: string): Promise<void> {
        return new Promise((resolve): void => {
            const fieldsToRemove: string[] = Object.keys(this.localFields).filter(
                (name: string): boolean => name.substring(name.indexOf(indexDelimiter)) === indexDelimiter + rowIndex,
            );
            fieldsToRemove.forEach((targetField: string): void => {
                this.removeField(targetField);
            });
            resolve();
        });
    }

    private remapIndexedFieldNames(indexDelimiter: string): void {
        const currentIndexes: string[] = Object.keys(this.localFields)
            .filter((name: string): boolean => name.includes(indexDelimiter))
            .map((name: string) => name.substring(name.indexOf(indexDelimiter)));
        const uniqueIndexes: string[] = [...new Set(currentIndexes)];
        uniqueIndexes.forEach((uniqueIndex: string, index: number): void => {
            Object.keys(this.localFields)
                .filter((name: string): boolean => name.substring(name.indexOf(indexDelimiter)) === uniqueIndex)
                .forEach((name: string): void => {
                    const field: FormField = this.field(name);
                    field.name = name.replace(uniqueIndex, indexDelimiter + index);
                    this.removeField(name);
                    this.addField(field);
                });
        });
    }
}
