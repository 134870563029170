import { computed, reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import TwoWaySupportType from '@/Apps/TwoWayCommunication/Enums/TwoWaySupportTypeEnum';
import TwoWayCommunicationsBlock from '@/Apps/TwoWayCommunication/Interfaces/TwoWayCommunicationsBlockInterface';
import TwoWayStorageFields from '@/Apps/TwoWayCommunication/Interfaces/TwoWayStorageFieldsInterface';
import SpaUserStorage from '@/Services/SpaUserStorageService';
import Agreement from '@/Components/Policies/PolicyBlock/Interfaces/AgreementInterface';
import RequestService from '@/Services/request.service';
import TwoWayAjaxCalls from '@/Apps/TwoWayCommunication/Enums/TwoWayAjaxCallsEnum';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { AxiosResponse } from 'axios';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { useDefine } from '@/Composables/Define';
import UserCredentials from '@/Interfaces/user.credentials.interface';
import User from '@/Services/user.service';
import { useTranslate } from '@/Composables/Translate';
import Claim from '@/Components/Claims/ClaimBlock/Interfaces/ClaimInterface';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import TwoWayMessageTypeIc from '@/Apps/TwoWayCommunication/Enums/TwoWayMessageTypeIcEnum';

export default class TwoWayCommunication {
    public storageFields: UnwrapNestedRefs<TwoWayStorageFields> = reactive(
        new (class implements TwoWayStorageFields {
            public supportType: string = TwoWaySupportType.None;
            public caseSupportType: string = TwoWaySupportType.None;
            public selectedClaim: Claim | null = null;
            public claimsList: Claim[] = [];
            public selectedPolicy: Agreement | null = null;
            public policyList: Agreement[] = [];
            public communicationId: string = '';
            public isReply: boolean = false;
            public isAddNewMessage: boolean = false;
            public addOrReplyType: string = '';
            public newMessageResponseId: string = '';
            public allCommunications: TwoWayCommunicationsBlock[] = [];
        })(),
    );
    private static instance: TwoWayCommunication;
    private communicationsInitialUrl: string = '';
    private communicationsCount: Ref<number> = ref(0);
    private transferStateService: TransferStateService = TransferStateService.getInstance();
    private requestService: RequestService = RequestService.getInstance();

    private constructor() {}

    public static getInstance(): TwoWayCommunication {
        if (!TwoWayCommunication.instance) {
            TwoWayCommunication.instance = new TwoWayCommunication();
            TwoWayCommunication.instance.buildState();
        }

        return TwoWayCommunication.instance;
    }

    public fetchCommunicationsList(): void {
        PopupService.getInstance().show(new OnePopup().withType().loading);
        this.requestService
            .get({
                uri: TwoWayAjaxCalls.List,
                content: {},
                withCache: false,
            })
            .then((response: AxiosResponse<DynamicDictionary>): void => {
                if (useDefine().validResponse(response)) {
                    this.storageFields.allCommunications = response.data.data.body.list;
                }
            })
            .finally((): void => {
                PopupService.getInstance().hide().then();
            });
    }

    public communications(): TwoWayCommunicationsBlock[] {
        return this.storageFields.allCommunications;
    }

    public onlyLatestCommunications(): TwoWayCommunicationsBlock[] {
        const result: TwoWayCommunicationsBlock[] = [];
        if (this.storageFields.allCommunications) {
            const sourceIds: DynamicDictionary = this.storageFields.allCommunications.reduce(function (
                accum: DynamicDictionary,
                item: TwoWayCommunicationsBlock,
            ): DynamicDictionary {
                accum[item.sourceId || item.id] = false;

                return accum;
            }, {});
            this.storageFields.allCommunications.forEach((communication: TwoWayCommunicationsBlock): void => {
                const sourceId: string = communication.sourceId || communication.id;
                if (sourceIds[sourceId] === false) {
                    sourceIds[sourceId] = true;
                    result.push(communication);
                }
            });
        }

        return result;
    }

    public communicationsById(id: string): TwoWayCommunicationsBlock[] {
        let result: TwoWayCommunicationsBlock[] = [];
        const block: TwoWayCommunicationsBlock[] = this.storageFields.allCommunications.filter(
            (value: TwoWayCommunicationsBlock): boolean => {
                return value.id === id;
            },
        );
        if (block.length > 0) {
            const selectedBlock: TwoWayCommunicationsBlock = block[0];
            if (selectedBlock.sourceId) {
                const thread: TwoWayCommunicationsBlock[] = this.filterBySourceId(selectedBlock.sourceId);
                result = result.concat(thread);
                const originalMessage: TwoWayCommunicationsBlock[] = this.storageFields.allCommunications.filter(
                    (communication: TwoWayCommunicationsBlock): boolean => {
                        return communication.id === selectedBlock.sourceId;
                    },
                );
                result = result.concat(originalMessage);
            } else {
                const connectedReplies: TwoWayCommunicationsBlock[] = this.filterBySourceId(selectedBlock.id);
                result = result.concat(connectedReplies);
                result.push(selectedBlock);
            }
        }

        return result;
    }

    public communicationSourceId(): string {
        let result: string = '';
        const block: TwoWayCommunicationsBlock[] = this.storageFields.allCommunications.filter(
            (value: TwoWayCommunicationsBlock): boolean => {
                return value.id === this.storageFields.communicationId;
            },
        );
        if (block.length > 0) {
            const selectedBlock: TwoWayCommunicationsBlock = block[0];
            if (selectedBlock.sourceId) {
                result = selectedBlock.sourceId;
            } else {
                result =
                    this.storageFields.newMessageResponseId !== ''
                        ? this.storageFields.newMessageResponseId
                        : selectedBlock.id;
            }
        }

        return result;
    }

    public typeByIc(ic: string): string {
        let result: string = TwoWaySupportType.Other;
        switch (ic) {
            case TwoWayMessageTypeIc.ExistClaim:
            case TwoWayMessageTypeIc.Claim:
                result = TwoWaySupportType.ExistingClaim;
                break;
            case TwoWayMessageTypeIc.ExistAgreement:
            case TwoWayMessageTypeIc.Agreement:
                result = TwoWaySupportType.ExistingPolicy;
                break;
            default:
        }

        return result;
    }

    public communicationShortBody(block: TwoWayCommunicationsBlock): string {
        const maxLength: number = 120;
        const length: number = this.cleanBodyLength(block.body);
        const ellipsis: string = length > maxLength ? block.body.slice(0, maxLength) + '...' : block.body;

        return '<p>' + ellipsis + '</p>';
    }

    public communicationBody(block: TwoWayCommunicationsBlock): string {
        return block.body;
    }

    public communicationIsBta(block: TwoWayCommunicationsBlock): boolean {
        return !block.isAuthenticatedPersonAuthor;
    }

    public communicationAuthor(block: TwoWayCommunicationsBlock): string {
        let result: string = useTranslate().translate('bta_team');
        if (block.isAuthenticatedPersonAuthor) {
            const currentUsr: UserCredentials = User.getInstance().current;
            result = currentUsr.name;
        }

        return result;
    }

    public unreadCommunications: Ref<number> = computed(() => {
        return this.communicationsCount.value;
    });

    public hasUnreadCommunications: Ref<boolean> = computed((): boolean => {
        return this.communicationsCount.value > 0;
    });

    public markAsRead(): void {
        const communicationId: string = this.storageFields.communicationId;
        if (this.messageIsUnread(communicationId)) {
            this.requestService
                .get({
                    uri: TwoWayAjaxCalls.Read,
                    content: {
                        id: communicationId,
                    },
                    withCache: false,
                })
                .then((response: AxiosResponse<DynamicDictionary>): void => {
                    if (useDefine().validResponse(response)) {
                        this.markAsReadById(communicationId);
                        SpaUserStorage.getInstance().saveUpdatedStorage();
                    }
                })
                .catch((reason: DynamicDictionary) => {
                    Error.log(
                        ErrorType.Error,
                        'TwoWayCommunicationSingle::markAsRead(' + communicationId + ')',
                        reason,
                        true,
                    );
                });
        }
    }

    public communicationsUrl(): string {
        return this.communicationsInitialUrl;
    }

    public actionsAfterRouteChanged(from: string, to: string): void {
        if (
            (from === 'communication-new' && to === 'communication-single') ||
            (from === 'communication' && to === 'communication-new')
        ) {
            this.storageFields.supportType = TwoWaySupportType.None;
            this.storageFields.caseSupportType = TwoWaySupportType.None;
            this.storageFields.isReply = false;
            this.storageFields.isAddNewMessage = false;
        }
        if (to === 'communication') {
            this.clearAll();
        }
        SpaUserStorage.getInstance().saveUpdatedStorage();
    }

    public clearAll(): void {
        this.storageFields.supportType = TwoWaySupportType.None;
        this.storageFields.caseSupportType = TwoWaySupportType.None;
        this.storageFields.isReply = false;
        this.storageFields.isAddNewMessage = false;
        this.storageFields.addOrReplyType = '';
        this.storageFields.newMessageResponseId = '';
        this.storageFields.selectedClaim = null;
        this.storageFields.selectedPolicy = null;
    }

    private filterBySourceId(value: string): TwoWayCommunicationsBlock[] {
        return this.storageFields.allCommunications.filter(
            (communication: TwoWayCommunicationsBlock): boolean => communication.sourceId === value,
        );
    }

    private cleanBodyLength(body: string): number {
        return body.replace(/<(.)*?>/g, '').length;
    }

    private markAsReadById(id: string): void {
        this.storageFields.allCommunications.map((communication: TwoWayCommunicationsBlock): void => {
            if (communication.id === id && !communication.read) {
                communication.read = true;
                this.communicationsCount.value -= 1;
            }
        });
    }

    private messageIsUnread(id: string): boolean {
        let result: boolean = false;
        this.storageFields.allCommunications.map((communication: TwoWayCommunicationsBlock): void => {
            if (communication.id === id && !communication.read) {
                result = true;
            }
        });

        return result;
    }

    private buildState(): void {
        this.communicationsCount.value = this.transferStateService.get('communicationsCount');
        this.communicationsInitialUrl = this.transferStateService.get('communicationsInitialUrl') || '';
    }
}
